/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, quote-props, react/button-has-type, sort-keys */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import Slider from 'react-slick';

import classNames from 'classnames';

import 'slick-carousel/slick/slick.less';
import 'slick-carousel/slick/slick-theme.less';

import '../css/CarouselContainer.less';

import { SatCoreRegister } from '../SatCoreRegistry';

const ControlledCarouselContainer = observer((props) => {
  const { navigationManager } = useContext(MobXProviderContext);
  const sliderRef = useRef(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideCount, setSlideCount] = useState(0);
  const [needsCentering, setNeedsCentering] = useState(false);
  const transitionTimeoutRef = useRef(null);
  const [arrowsThrottled, setArrowsThrottled] = useState(false);
  const throttleTimeoutRef = useRef(null);

  // Extract settings from props to customize
  const {
    beforeChange,
    afterChange,
    onInit,
    className,
    loading = false,
    ...otherProps
  } = props;

  // Determine if interactions should be blocked (either due to loading or transition)
  const isBlocked = loading || isTransitioning;

  // Determine if we need centering based on slides count vs slidesToShow
  useEffect(() => {
    const childCount = React.Children.count(props.children);
    const slidesToShow = props.slidesToShow || 6;
    setNeedsCentering(childCount < slidesToShow);
  }, [props.children, props.slidesToShow]);

  useEffect(() => {
    // Setup global event listener to fix tabbing through carousels
    const handleKeyDown = (e) => {
      if (e.key === 'Tab') {
        // Find all cloned slides and prevent tabbing to them
        const clonedButtons = document.querySelectorAll('.slick-cloned button');
        clonedButtons.forEach((button) => {
          button.setAttribute('tabindex', '-1');
          button.setAttribute('aria-hidden', 'true');
        });

        // Find all hidden (non-active) slides and prevent tabbing to them
        const hiddenButtons = document.querySelectorAll('.slick-slide:not(.slick-active) button');
        hiddenButtons.forEach((button) => {
          button.setAttribute('tabindex', '-1');
          button.setAttribute('aria-hidden', 'true');
        });

        // Make only visible slide buttons tabbable
        const visibleButtons = document.querySelectorAll('.slick-active button');
        visibleButtons.forEach((button) => {
          button.setAttribute('tabindex', '0');
          button.setAttribute('aria-hidden', 'false');
        });
      }
    };

    // Force tabindex setup on initial load
    setTimeout(() => {
      const clonedButtons = document.querySelectorAll('.slick-cloned button');
      clonedButtons.forEach((button) => {
        button.setAttribute('tabindex', '-1');
        button.setAttribute('aria-hidden', 'true');
      });

      const hiddenButtons = document.querySelectorAll('.slick-slide:not(.slick-active) button');
      hiddenButtons.forEach((button) => {
        button.setAttribute('tabindex', '-1');
        button.setAttribute('aria-hidden', 'true');
      });

      const visibleButtons = document.querySelectorAll('.slick-active button');
      visibleButtons.forEach((button) => {
        button.setAttribute('tabindex', '0');
        button.setAttribute('aria-hidden', 'false');
      });
    }, 300);

    // Add global event listener
    document.addEventListener('keydown', handleKeyDown);
    window.addEventListener('focus', handleKeyDown, true);

    // Cleanup on unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('focus', handleKeyDown, true);
    };
  }, []);

  // Set up a MutationObserver to watch for Carousel DOM changes
  useEffect(() => {
    // Function to update tab indexes
    const updateTabIndexes = () => {
      // Set tabindex on all slides to -1 initially
      const allButtons = document.querySelectorAll('.slick-slide button');
      allButtons.forEach((button) => {
        button.setAttribute('tabindex', '-1');
        button.setAttribute('aria-hidden', 'true');
      });

      // Find all cloned slides and explicitly make them not tabbable
      const clonedButtons = document.querySelectorAll('.slick-cloned button');
      clonedButtons.forEach((button) => {
        button.setAttribute('tabindex', '-1');
        button.setAttribute('aria-hidden', 'true');
      });

      // Make only visible slide buttons tabbable
      const carousels = document.querySelectorAll('.controlled-carousel-container');
      carousels.forEach((carousel) => {
        const visibleButtons = carousel.querySelectorAll('.slick-active:not(.slick-cloned) button');
        visibleButtons.forEach((button) => {
          button.setAttribute('tabindex', '0');
          button.setAttribute('aria-hidden', 'false');
        });
      });
    };

    // Initial update
    updateTabIndexes();

    // Set up observer to watch for carousel slide changes
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && (mutation.attributeName === 'class' || mutation.attributeName === 'style')) {
          updateTabIndexes();
        }
      });
    });

    // Start observing the document
    observer.observe(document.body, {
      subtree: true,
      attributes: true,
      attributeFilter: ['class', 'style']
    });

    // Also update on window resize which might change visible slides
    window.addEventListener('resize', updateTabIndexes);

    // Cleanup
    return () => {
      observer.disconnect();
      window.removeEventListener('resize', updateTabIndexes);
    };
  }, []);

  // Set slide count on initialization
  const handleInit = useCallback((slick) => {
    const childCount = React.Children.count(props.children);
    setSlideCount(childCount);

    if (onInit) {
      onInit(slick);
    }
  }, [props.children, props.slidesToShow, onInit]);

  // Handle transition start
  const handleBeforeChange = useCallback((oldIndex, newIndex) => {
    setIsTransitioning(true);
    if (beforeChange) beforeChange(oldIndex, newIndex);

    // Clear any existing timeout to prevent memory leaks
    if (transitionTimeoutRef.current) {
      clearTimeout(transitionTimeoutRef.current);
    }
  }, [beforeChange]);

  // Handle transition end
  const handleAfterChange = useCallback((index) => {
    // Set the current slide index immediately
    setCurrentSlide(index);

    // Clear any existing timeout to prevent memory leaks
    if (transitionTimeoutRef.current) {
      clearTimeout(transitionTimeoutRef.current);
      transitionTimeoutRef.current = null;
    }

    // Set flag to indicate we should not allow click functionality (due to slide transitioning)
    transitionTimeoutRef.current = setTimeout(() => {
      setIsTransitioning(false);
      transitionTimeoutRef.current = null;
    }, 400);

    if (afterChange) afterChange(index);
  }, [afterChange]);

  // Function to handle direct slide clicks with improved transition protection
  const handleSlideClick = (index) => {
    // Don't process clicks if transitioning or loading
    if (isBlocked) {
      return;
    }

    // Force immediate navigation even if already on this slide
    if (sliderRef?.current) {
      // Ensure timeouts are cleared to prevent interference
      if (transitionTimeoutRef.current) {
        clearTimeout(transitionTimeoutRef.current);
        transitionTimeoutRef.current = null;
      }

      // Set focus to the slide after navigation
      setTimeout(() => {
        if (sliderRef?.current) {
          sliderRef.current.slickGoTo(index);

          // Find and focus the slide button after navigation
          setTimeout(() => {
            const activeSlide = document.querySelector(`.slick-slide[data-index="${index}"]`);
            if (activeSlide) {
              const button = activeSlide.querySelector('button');
              if (button) {
                // Focus then immediately blur to avoid visible focus ring after click
                button.focus();
                button.blur();

                // Reset tabindexes to ensure proper tab order
                const allButtons = document.querySelectorAll('.slick-slide button');
                allButtons.forEach((btn) => {
                  btn.setAttribute('tabindex', '-1');
                  btn.setAttribute('aria-hidden', 'true');
                });

                // Set tabindex only for visible slides in current carousel
                const carousel = button.closest('.controlled-carousel-container');
                if (carousel) {
                  const visibleButtons = carousel.querySelectorAll('.slick-active button');
                  visibleButtons.forEach((btn) => {
                    btn.setAttribute('tabindex', '0');
                    btn.setAttribute('aria-hidden', 'false');
                  });
                }
              }
            }
          }, 50);
        }
      }, 0);
    }
  };

  // Methods to control the carousel with improved transition handling
  const goToSlide = useCallback((index) => {
    if (sliderRef?.current && !loading) {
      if (isTransitioning) {
        setIsTransitioning(false);
      }
      sliderRef.current.slickGoTo(index);
    }
  }, [isTransitioning, loading]);

  const goToNext = useCallback(() => {
    if (sliderRef?.current && !loading && !arrowsThrottled) {
      if (isTransitioning) {
        setIsTransitioning(false);
      }
      setArrowsThrottled(true);

      if (throttleTimeoutRef.current) {
        clearTimeout(throttleTimeoutRef.current);
      }
      throttleTimeoutRef.current = setTimeout(() => {
        setArrowsThrottled(false);
        throttleTimeoutRef.current = null;
      }, 500);

      if (sliderRef?.current) {
        sliderRef.current.slickNext();
      }
    }
  }, [isTransitioning, loading, arrowsThrottled]);

  const goToPrev = useCallback(() => {
    if (sliderRef?.current && !loading && !arrowsThrottled) {
      if (isTransitioning) {
        setIsTransitioning(false);
      }
      setArrowsThrottled(true);

      if (throttleTimeoutRef.current) {
        clearTimeout(throttleTimeoutRef.current);
      }
      throttleTimeoutRef.current = setTimeout(() => {
        setArrowsThrottled(false);
        throttleTimeoutRef.current = null;
      }, 500);

      if (sliderRef?.current) {
        sliderRef.current.slickPrev();
      }
    }
  }, [isTransitioning, loading, arrowsThrottled]);

  // Enhanced keyboard navigation for accessibility
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Handle Tab key separately via the global listener
      if (e.key === 'Tab') {
        return;
      }

      // Block keyboard navigation if loading
      if (loading) {
        return;
      }

      // Find all carousel containers
      const carouselContainers = document.querySelectorAll('.controlled-carousel-container');
      let activeCarousel = null;
      let activeButton = null;

      // Check which carousel or button has focus
      for (const container of carouselContainers) {
        if (container === document.activeElement || container.contains(document.activeElement)) {
          activeCarousel = container;
          if (document.activeElement.classList.contains('unit-carousel-card') ||
            document.activeElement.classList.contains('section-carousel-card')) {
            activeButton = document.activeElement;
          }
          break;
        }
      }

      if (activeCarousel) {
        if (e.key === 'Enter' || e.key === ' ') {
          // Handle Enter or Space for selection
          if (activeButton) {
            e.preventDefault();
            activeButton.click();
          }
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [goToNext, goToPrev, loading]);

  // Ensure we clean up transitioning state on unmount
  useEffect(() => {
    return () => {
      setIsTransitioning(false);
      if (transitionTimeoutRef.current) {
        clearTimeout(transitionTimeoutRef.current);
        transitionTimeoutRef.current = null;
      }
      if (throttleTimeoutRef.current) {
        clearTimeout(throttleTimeoutRef.current);
        throttleTimeoutRef.current = null;
      }
    };
  }, []);

  // Extended failsafe to clear transitioning state if it gets stuck 
  useEffect(() => {
    if (isTransitioning) {
      // Clear any existing timeout
      if (transitionTimeoutRef.current) {
        clearTimeout(transitionTimeoutRef.current);
      }

      // Set new timeout to reset transitioning state
      // This is a safety mechanism in case afterChange does not fire
      transitionTimeoutRef.current = setTimeout(() => {
        setIsTransitioning(false);
        transitionTimeoutRef.current = null;
      }, 800);

      return () => {
        if (transitionTimeoutRef.current) {
          clearTimeout(transitionTimeoutRef.current);
          transitionTimeoutRef.current = null;
        }
      };
    }
  }, [isTransitioning]);

  // Enhance with ARIA attributes for accessibility
  const ariaProps = {
    'aria-roledescription': 'carousel',
    'aria-label': props['aria-label'] || 'Carousel',
    'role': 'region',
    'tabIndex': 0
  };

  // Expose control methods through ref
  React.useImperativeHandle(props.controlRef, () => ({
    goToSlide,
    goToNext,
    goToPrev,
    getCurrentSlide: () => currentSlide,
    getSlideCount: () => slideCount,
    isTransitioning: () => isTransitioning,
    isLoading: () => loading,
    isArrowsThrottled: () => arrowsThrottled
  }), [goToSlide, goToNext, goToPrev, currentSlide, slideCount, isTransitioning, loading, arrowsThrottled]);

  const PrevArrow = (props) => {
    const { className, onClick } = props;
    // Enhanced handler that includes throttling
    const handleClick = (e) => {
      // Block clicks if loading or throttled
      if (loading || arrowsThrottled) {
        e.preventDefault();
        return;
      }
      // Set throttled state
      setArrowsThrottled(true);
      // Clear any existing timeout
      if (throttleTimeoutRef.current) {
        clearTimeout(throttleTimeoutRef.current);
      }
      // Set timeout to reset throttling
      throttleTimeoutRef.current = setTimeout(() => {
        setArrowsThrottled(false);
        throttleTimeoutRef.current = null;
      }, 300);

      if (onClick) {
        onClick(e);
      }
    };
    const buttonClass = classNames(
      className, {
        'disabled': loading,
        'force-wait-cursor': (loading || arrowsThrottled)
      }
    );

    return (
      <button
        aria-label='Previous slide'
        className={buttonClass}
        onClick={handleClick}
        type='button' />
    );
  };

  const NextArrow = (props) => {
    const { className, onClick } = props;
    // Enhanced handler that includes throttling
    const handleClick = (e) => {
      // Block clicks if loading or throttled
      if (loading || arrowsThrottled) {
        e.preventDefault();
        return;
      }
      // Set throttled state
      setArrowsThrottled(true);
      // Clear any existing timeout
      if (throttleTimeoutRef.current) {
        clearTimeout(throttleTimeoutRef.current);
      }
      // Set timeout to reset throttling
      throttleTimeoutRef.current = setTimeout(() => {
        setArrowsThrottled(false);
        throttleTimeoutRef.current = null;
      }, 500);

      if (onClick) {
        onClick(e);
      }
    };
    const buttonClass = classNames(
      className, {
        'disabled': loading,
        'force-wait-cursor': (loading || arrowsThrottled)
      }
    );

    return (
      <button
        aria-label='Next slide'
        className={buttonClass}
        onClick={handleClick}
        type='button' />
    );
  };

  const renderSlideItem = (child, index) => {
    return (
      <div
        className={`carousel-slide ${isBlocked ? 'slide-transitioning' : ''}`}
        onClick={(e) => {
          // Stop event propagation to allow button clicks to work directly
          e.stopPropagation();
          // Don't process clicks if transitioning or loading
          if (isBlocked) {
            e.preventDefault();
            return;
          }
          handleSlideClick(index);
        }}
        role='tabpanel'
        style={{ cursor: isBlocked ? 'wait' : 'pointer' }}>
        {React.cloneElement(child, {
          onClick: (e) => {
            e.stopPropagation();
            // Block clicks during transitions or loading
            if (isBlocked) {
              e.preventDefault();
              return;
            }
            // Force click handling
            handleSlideClick(index);
            // Call the child's original onClick if it exists
            if (child.props.onClick) {
              child.props.onClick(e);
            }
          },
          className: `${child.props.className || ''} ${isBlocked ? 'carousel-item-transitioning' : ''}`,
          style: {
            ...child.props.style,
            outline: 'none',
            cursor: isBlocked ? 'wait' : 'pointer'
          },
          'data-slide-index': index,
          'aria-disabled': isBlocked ? 'true' : 'false'
        })}
      </div>
    );
  };

  const settings = {
    arrows: true,
    dots: false,
    focusOnSelect: true,
    slidesToShow: 6,
    speed: 300,
    swipe: false,
    ...otherProps, // anything above this can be overridden if passed as a prop
    beforeChange: handleBeforeChange,
    afterChange: handleAfterChange,
    init: handleInit,
    adaptiveHeight: navigationManager.allowCarouselAdaptiveHeight,
    centerPadding: '0px',
    cssEase: 'linear',
    infinite: needsCentering ? false : (otherProps.infinite !== false), // Prevent duplicate visible slides
    variableWidth: needsCentering, // Only use variableWidth when centering
    waitForAnimate: true,
    slidesToScroll: 1, // Force slider to maintain slide count and prevent overflow issues
    touchThreshold: 10, // Reduce sensitivity to prevent accidental swipes
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    accessibility: false, // Need to set as false here (to prevent interference with our custom accessibility handling)
    dotsClass: 'slick-dots keyboard-accessible',
    // Customizable dots for better accessibility
    customPaging(i) {
      return (
        <button
          aria-label={`Go to slide ${i + 1}`}
          aria-selected={currentSlide === i}
          role='tab'
          tabIndex={0}>
          {props.customPaging ? props.customPaging(i) : (i + 1)}
        </button>
      );
    }
  };

  // Combine provided className with our required classes
  const containerClassName = classNames(
    'carousel-container',
    'controlled-carousel-container', {
      'few-slides': needsCentering,
      'is-transitioning': isTransitioning,
      'is-loading': loading,
      'is-arrows-throttled': arrowsThrottled
    }, className
  );

  return (
    <div
      className={containerClassName}
      {...ariaProps}>
      <Slider
        ref={sliderRef}
        {...settings}
        /* NOTE: ControlledCarouselContainer uses custom accessibility, so here we turn off the native react-slick accessibility */
        accessibility={false}>
        {React.Children.map(props.children, (child, index) => renderSlideItem(child, index))}
      </Slider>
    </div>
  );
});

// Add prop for external control
ControlledCarouselContainer.defaultProps = {
  controlRef: { current: null }
};

export default ControlledCarouselContainer;

SatCoreRegister('ControlledCarouselContainer', ControlledCarouselContainer);
