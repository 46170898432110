import { registerClass } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';

import topNavManager from '../managers/TopNavManager';
import userManager from '../managers/UserManager';

export default class TopNavService {
  static topNavHandleKeyDown = (e, menuBarRef) => {
    const isVisible = (element) => window?.getComputedStyle(element)?.display !== 'none';

    const menuItems = Array.prototype.slice.call(menuBarRef.current.querySelectorAll('[role="tab"]')).filter(isVisible);

    const index = menuItems.indexOf(e.target.closest('[role=tab]') || e.target);
    const subMenu = menuItems.indexOf(e.target) === -1;

    if (index === -1) {
      return;
    }

    const moves = {
      ArrowDown: 1,
      ArrowLeft: -1,
      ArrowRight: 1,
      ArrowUp: -1
    };

    switch (e.key) {
      case 'Escape': {
        if (topNavManager.isTopNavClassMenuOpen) {
          e.target.blur();
          topNavManager.setIsTopNavClassMenuOpen(false);
        }
        if (topNavManager.isTopNavProfileMenuOpen) {
          e.target.blur();
          topNavManager.setIsTopNavProfileMenuOpen(false);
        }
        break;
      }
      case 'Tab':
      case 'ArrowRight':
      case 'ArrowLeft': {
        setTimeout(() => {
          if (e.key !== 'Tab') {
            const nextIndex = (menuItems.length + index + moves[e.key]) % menuItems.length;
            (menuItems[nextIndex].querySelector('[role=menu]') || menuItems[nextIndex]).focus();
            e.preventDefault();
          }

          const isWithinClassMenu = !!document.activeElement?.closest('.class-menu');
          if (!isWithinClassMenu && topNavManager.isTopNavClassMenuOpen) {
            topNavManager.setIsTopNavClassMenuOpen(false);
          }

          const isWithinProfileMenu = !!document.activeElement?.closest('.profile-menu');

          if (!isWithinProfileMenu && topNavManager.isTopNavProfileMenuOpen) {
            topNavManager.setIsTopNavProfileMenuOpen(false);
          }
        }, 1);
        break;
      }
      case 'ArrowDown':
      case 'ArrowUp': {
        if (menuItems[index].querySelector('[role=menu]')) {
          !subMenu && menuItems[index].querySelector('[role=menu]').focus();
        } else {
          const nextIndex = (menuItems.length + index + moves[e.key]) % menuItems.length;
          (menuItems[nextIndex].querySelector('[role=menu]') || menuItems[nextIndex]).focus();
        }
        e.preventDefault();
        break;
      }
      case 'Home':
      case 'End': {
        const nextIndex = e.key === 'Home' ? 0 : menuItems.length - 1;
        (menuItems[nextIndex].querySelector('[role=menu]') || menuItems[nextIndex]).focus();
        e.preventDefault();
        break;
      }
      case ' ':
      case 'Enter':
        const isClassMenu = menuItems[index]?.className?.includes?.('class-menu');
        const isProfileMenu = menuItems[index]?.className?.includes?.('profile-menu');

        // on 'ClassMenu'
        if (isClassMenu) {
          // handle ClassMenu simulated click
          const classMenu = menuItems[index];

          let isJoinClassButton = document.activeElement?.className?.includes?.('join-class');

          if (isJoinClassButton) {
            const joinClassButton = document.activeElement;
            topNavManager.setIsTopNavClassMenuOpen(false);
            joinClassButton.click();
            return;
          }

          if (!topNavManager.isTopNavClassMenuOpen) {
            classMenu.click();
            topNavManager.setIsTopNavClassMenuOpen(true);
            classMenu.focus();
            return;
          } else {
            let activeMenuItem = classMenu.querySelector('[role=menuitem][aria-selected=true]');

            isJoinClassButton = activeMenuItem?.className?.includes('switch-option');
            if (!isJoinClassButton) {
              const classMenuSelector = classMenu.querySelector('.class-selector');

              activeMenuItem = document.activeElement === classMenuSelector ? activeMenuItem : document.activeElement;

              if (activeMenuItem?.getAttribute('role') === 'menuitem') {
                activeMenuItem.click();
                classMenu.blur();
                topNavManager.setIsTopNavClassMenuOpen(false);
              } else {
                classMenu.blur();
                topNavManager.setIsTopNavClassMenuOpen(false);
              }
            }
            return;
          }
        }

        // on 'ProfileMenu'
        if (isProfileMenu) {
          // handle ProfileMenu simulated click
          const profileMenu = menuItems[index];

          if (!topNavManager.isTopNavProfileMenuOpen) {
            profileMenu.click();
            topNavManager.setIsTopNavProfileMenuOpen(true);
            // profileMenu.focus();

            setTimeout(() => {
              const firstProfileMenuItem = profileMenu.querySelector('.profile-menu-selector.dropdown .menu [role="menuitem"]');
              firstProfileMenuItem.focus();
            }, 1);
            return;
          } else {
            const firstProfileMenuItem = profileMenu.querySelector('.profile-menu-selector.dropdown .menu [role="menuitem"]');

            let activeMenuItem = profileMenu.querySelector('[role=menuitem][aria-selected=true]');

            activeMenuItem = document.activeElement === firstProfileMenuItem ? activeMenuItem : document.activeElement;

            if (activeMenuItem?.getAttribute('role') === 'menuitem') {
              activeMenuItem.click();
              profileMenu.blur();
              topNavManager.setIsTopNavProfileMenuOpen(false);
            } else {
              profileMenu.blur();
              topNavManager.setIsTopNavProfileMenuOpen(false);
            }
          }
          return;
        }

        // on default
        if (menuItems[index].querySelector('[role=menu]')) {
          if (subMenu) {
            menuItems[index].querySelector('[role=menuitem][aria-selected=true]')?.click();
          } else {
            menuItems[index].querySelector('[role=menu]').focus();
          }
        } else {
          menuItems[index].click();
        }
        break;
    }
  };

  static getTopNavAnchorHref = (pathname, viewSelection) => {
    if (!pathname) {
      return;
    }
    const searchParams = new URLSearchParams();
    if (viewSelection) {
      searchParams.append('view', viewSelection);
    }
    if (topNavManager.allowAuthKeyTopNavUrlParamAuthentication) {
      searchParams.append('authKey', Auth.authKey);
      if (pathname !== '/switch-role') {
        searchParams.append('activePermissionId', userManager.activePermissionId);
      }
    }
    const searchParamsStr = searchParams.toString();
    const href = searchParamsStr ? `${pathname}?${searchParamsStr}` : pathname;

    return href;
  }
}

registerClass('TopNavService', TopNavService);
