import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Auth from '../managers/AuthManager';

import topNavManager from '../managers/TopNavManager';

import { isWhitelistedAuthKeyPathname as _isWhitelistedAuthKeyPathname } from '../constants/AuthConstants';

export default function withAuth(AuthComponent) {
  // eslint-disable-next-line react/prefer-stateless-function
  return class AuthWrapped extends Component {
    render() {
      const { history, location } = this.props;

      const urlParams = new URLSearchParams(location.search || window.location.search);

      const authKey = urlParams.get('authKey');

      if (authKey) {
        const isWhitelistedAuthKeyPathname = location?.pathname ? _isWhitelistedAuthKeyPathname(location.pathname) : false;

        if (Auth.loggedIn()) {
          if (!isWhitelistedAuthKeyPathname) {
            // we do not want certain values (such as authKey) to persist in the urlParams; so here, we remove them
            urlParams.delete('authKey');
            urlParams.delete('activePermissionId');
            history.replace({
              ...location,
              search: urlParams?.toString?.() || ''
            });
          }
          // default behavior; allow access to AuthComponent (MainView)
          return (<AuthComponent history={history} />);
        } else {
          if (topNavManager?.allowAuthKeyTopNavUrlParamAuthentication && !isWhitelistedAuthKeyPathname) {
            // send user back to Login.js, where user will then be logged in via the provided urlParam authKey
            history.replace({
              pathname: '/signin',
              state: { from: location }
            });
            return null;
          } else {
            // default behavior; return to Login without defining `location.state.from`
            return (<Redirect to='/signin' />);
          }
        }
      } else if (Auth.loggedIn()) {
        // default behavior; allow access to AuthComponent (MainView)
        return (<AuthComponent history={history} />);
      } else {
        // default behavior; return to Login without defining `location.state.from`
        return (<Redirect to='/signin' />);
      }
    }
  };
}
