import React from 'react';
import { Menu } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import '../css/TopNav.less';

const LogoButtons = ({ history, href = undefined, logoImg = undefined, navButton } = {}) => {
  const Logo = SatCoreComponent('Logo');
  return (
    <Menu.Item as='a' header href={href} tabIndex='-1'>
      <Logo history={history} logoImg={logoImg} navButton={navButton} />
    </Menu.Item>
  );
};

export default LogoButtons;

SatCoreRegister('LogoButtons', LogoButtons);
