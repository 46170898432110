import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { debounce, throttle } from 'lodash';

import classNames from 'classnames';

import { Button, Icon, Input, Menu } from 'semantic-ui-react';

import { SatCoreRegister } from '../SatCoreRegistry';

import { ASSIGNMENT_MENU_STATUS } from '../managers/AssignmentManager';
import { VIEW_SELECTION } from '../managers/NavigationManager';

import { setDocumentTitleFromSelectionView } from '../utils';

export default
@inject('assignmentManager')
@observer
class AssignmentMenu extends Component {
  debouncedSearch = debounce(async (menuStatus, search, sort) => {
    await this.resetSearch(menuStatus, search, sort);
    const { assignmentManager } = this.props;
    if (assignmentManager.loadingStudentAssignmentMenu) {
      assignmentManager.setLoadingStudentAssignmentMenu(false);
    }
  }, 500);

  throttledSearch = throttle(async (menuStatus, search, sort) => {
    await this.resetSearch(menuStatus, search, sort);
    const { assignmentManager } = this.props;
    if (assignmentManager.loadingStudentAssignmentMenu) {
      assignmentManager.setLoadingStudentAssignmentMenu(false);
    }
  }, 3000);

  constructor(props) {
    super(props);

    const { menuStatus, sort, search, t } = this.props;

    this.state = {
      menuStatus: (menuStatus) || ASSIGNMENT_MENU_STATUS.CURRENT,
      sort: (sort) || 'asc',
      search: (search) || '',
      buttonIcon: (sort === 'desc') ? 'up arrow' : 'down arrow',
      buttonLabel: (sort === 'desc') ? 'Sort: Last' : 'Sort: First',
      titleName: t(ASSIGNMENT_MENU_STATUS.getTitle(
        (menuStatus) || ASSIGNMENT_MENU_STATUS.CURRENT
      ), 'Missing translation in AssignmentMenu')
    };
  }

  async componentDidMount() {
    const { assignmentManager } = this.props;
    await this.resetSearch(this.state.menuStatus, this.state.search, this.state.sort);
    assignmentManager.setCurrentAssignmentMenu(this.state.menuStatus);
  }

  componentDidUpdate() {
    const { assignmentManager, t } = this.props;
    setDocumentTitleFromSelectionView(VIEW_SELECTION.ASSIGNMENTS, {
      VIEW_SELECTION,
      suffix: t(ASSIGNMENT_MENU_STATUS.getTitle(assignmentManager.currentAssignmentMenu))
    });
  }

  resetSearch = async (menuStatus, search, sort) => {
    await this.fetchSearch(menuStatus, search, sort);
  }

  fetchSearch = async (menuStatus, search, sort) => {
    await this.props.fetchAssignments(menuStatus, search, sort);
  }

  handleChangeSearch = async (_event, { value }) => {
    if (value.length > 2 || value.length === 0) {
      const { state } = this;
      this.debouncedSearch(state.menuStatus, value, state.sort);
    }
    this.setState({ search: value });
  }

  handleChangeStatusMenu = async (_event, { name }) => {
    const { assignmentManager, t } = this.props;

    if (assignmentManager.loadingStudentAssignmentMenu) {
      return;
    }
    const { state } = this;

    assignmentManager.setLoadingStudentAssignmentMenu(true);
    assignmentManager.clearAssignments();

    const title = t(ASSIGNMENT_MENU_STATUS.getTitle(name),
      'Missing translation in AssignmentMenu'
    );
    this.setState({ menuStatus: name, titleName: title });
    assignmentManager.setCurrentAssignmentMenu(name);

    this.throttledSearch(name, state.search, state.sort);
  }

  handleChangeSort = async () => {
    const { sort, menuStatus, search } = this.state;
    if (sort === 'asc') {
      this.setState({ sort: 'desc', buttonIcon: 'up arrow', buttonLabel: 'Sort: Last' });
      await this.resetSearch(menuStatus, search, 'desc');
    } else {
      this.setState({ sort: 'asc', buttonIcon: 'down arrow', buttonLabel: 'Sort: First' });
      await this.resetSearch(menuStatus, search, 'asc');
    }
  }

  render() {
    const { assignmentManager, t } = this.props;

    const { loadingStudentAssignmentMenu } = assignmentManager;

    // TODO remove // const { menuStatus } = this.props;
    const { buttonLabel, buttonIcon, menuStatus, search } = this.state;
    return (
      <>
        <div className='sa-header-row sa-header-row-img'>
          <div className='sa-header-label'>{t('AssignmentLabel', 'Missing assignment label translation')}</div>
          <div className='sa-menu-control'>
            <Menu className='student-menu'>
              {[
                [t('CurrentAssignments'), ASSIGNMENT_MENU_STATUS.CURRENT],
                [t('DueTodayAssignments'), ASSIGNMENT_MENU_STATUS.TODAY],
                [t('ClosedAssignments'), ASSIGNMENT_MENU_STATUS.CLOSED],
                [t('GradedAssignments'), ASSIGNMENT_MENU_STATUS.GRADED]
              ].map(([children, status]) => (
                <Menu.Item key={status}
                  active={menuStatus === status}
                  as='button'
                  className={classNames(status, {
                    'loading-student-assignment-menu': loadingStudentAssignmentMenu
                  })}
                  name={status}
                  onClick={this.handleChangeStatusMenu}
                  tabIndex='0'>
                  <div>{children}</div>
                </Menu.Item>
              )
              )}
            </Menu>
          </div>
        </div>

        <div className='sa-second-header-row'>
          <div className='sa-second-header-label'>
            {/* placeholder - second header has been removed per DEMO-1713 */}
            {/* {menuStatus ? t(ASSIGNMENT_MENU_STATUS.getTitle(menuStatus)) : ''} */}
          </div>
          <div className='sa-search-n-sort'>
            <Button icon labelPosition='right' onClick={this.handleChangeSort}>
              {buttonLabel}
              <Icon name={buttonIcon} />
            </Button>
            <Input
              aria-label={t('searchContentAriaLabel')}
              className='search-input'
              icon='search'
              name='search'
              onChange={this.handleChangeSearch}
              placeholder={t('SearchPlaceholder', 'No placeholder translation')}
              type='text'
              value={search} />
          </div>
        </div>
      </>
    );
  }
}

SatCoreRegister('AssignmentMenu', AssignmentMenu);
