/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Container } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import RichTextEditor from '../RichTextEditor';
import Modal from '../Modal';

import '../../css/TeacherFeedBackModal.less';

export default
@inject('assignmentManager', 'gradebookManager')
@observer
class TeacherFeedbackModal extends Component {
  constructor(props) {
    super(props);
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.state = {
      feedback: '',
      disableSave: false
    };
  }

  async componentDidMount() {
    const { assignmentManager, assignmentInstanceId } = this.props;

    const feedback = await assignmentManager.viewAssignmentFeedback(assignmentInstanceId);

    if (feedback !== null && feedback !== '') {
      this.setState({
        feedback
      });
    }
  }

  cancel = () => {
    this.setState({
      feedback: ''
    });

    this.closeTeacherFeedbackModal();
  }

  closeTeacherFeedbackModal = () => {
    this.props.closeTeacherFeedbackModal();
  }

  editorChange = (value) => {
    this.setState({
      feedback: value
    });
  }

  submitFeedback = async () => {
    if (this.state.disableSave) {
      return false;
    }
    this.setState({ disableSave: true });
    const { assignmentInstanceId, assignmentManager, gradebookManager } = this.props;
    const result = await assignmentManager.updateAssignmentFeedback(
      assignmentInstanceId,
      this.state.feedback
    );
    this.setState({ disableSave: false });

    if (result) {
      gradebookManager.setAssignmentInstanceFeedback(assignmentInstanceId, (this.state.feedback.length > 0));
      this.closeTeacherFeedbackModal();
    } else {
      this.setState({
        serverError: true,
        serverErrorMsg: 'Something went wrong when saving the feedback, please try again'
      });
    }
  }

  render() {
    const { feedbackOpen, isReadOnly, studentName, t } = this.props;

    const { ModalBanner } = this;
    return (
      <>
        <Modal
          className='TeacherFeedbackModal EditAssignmentModal'
          closeOnDimmerClick={false}
          closeOnEscape={true}
          onClose={this.cancel}
          open={feedbackOpen}
          size='small'>
          <ModalBanner
            label={t('feedBack')}
            onClose={this.cancel}
            title={studentName} />
          <Modal.Content scrolling>
            <Container>
              <div className='editor-container'>
                <RichTextEditor
                  data={this.state.feedback}
                  disabled={isReadOnly}
                  onChange={this.editorChange} />
                {!isReadOnly && <div>{t('editDescription')}</div>}
              </div>

            </Container>
          </Modal.Content>
          <Modal.Actions>
            {!isReadOnly && (
              <Button
                basic
                className='cancelButton'
                onClick={this.cancel}
                primary
                type='button'>
                {t('cancel')}
              </Button>
            )}
            <Button
              className='ui primary button saveButton'
              loading={this.state.disableSave}
              onClick={async () => {
                if (!isReadOnly) {
                  await this.submitFeedback();
                } else {
                  this.cancel();
                }
              }}
              type='button'>
              {t(isReadOnly ? 'ok' : 'save')}
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

SatCoreRegister('TeacherFeedbackModal', TeacherFeedbackModal);
