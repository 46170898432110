import React from 'react';

import { action, makeObservable, observable } from 'mobx';

import { Button, Icon, Popup } from 'semantic-ui-react';

import classNames from 'classnames';

import { ASSIGNMENT_MENU_STATUS, ASSIGNMENT_STATUS, DELIVERY_MODES, PDF_DELIVERY_FORMATS } from './AssignmentManager';
import { CONTENT_ITEM_TYPES, CONTENT_MODE } from './ContentManager';

import gradebookManager from './GradebookManager';

import ResourcePacingService from '../services/ResourcePacingService';
import UtilityService from '../services/UtilityService';

import { register } from '../i18n';
import { DATE_FORMAT_STRATEGIES, dateFormat } from '../utils';

const t = register('StudentContentCardManager');

export class StudentContentCardManager {
  @observable isCleared = false;

  @observable isLoading = false;

  @observable studentContentCardDateFormatStrategy = 'fullDateNoYear';

  constructor() {
    makeObservable(this);
  }

  @action setIsLoading = (isLoading) => {
    this.isLoading = isLoading;
  }

  // TODO remove in favor of using `dateFormat`
  // convertToLongDate = (d) => {
  //   if (d !== null && d != '') {
  //     Moment.locale('en');
  //     return Moment(new Date(d)).format('dddd, MMMM Do');
  //   }
  // }

  formatTime = (str) => {
    str = str.replace(/\s/g, '').replace(/^0+/, '');
    return str.toLowerCase();
  }

  handleAssignmentViewPresent = (params) => {
    const { doNotDisable } = params;

    if (params.assignment.status !== ASSIGNMENT_STATUS.LOCKED) {
      if (doNotDisable) {
        this.setIsLoading(false);
      } else {
        this.setIsLoading(true);
      }
    } else if (params.assignment.status === ASSIGNMENT_STATUS.CLOSED || ASSIGNMENT_STATUS.COMPLETED) {
      // placeholder
    }
    params.handlePresent(params);
    if (!params.menuStatus || params.menuStatus === undefined || !params.assignment.contentViewerCompatible) {
      setTimeout(() => {
        this.setIsLoading(false);
      }, 3000);
    }
  }

  getAssignmentStatusValues = (assignment, courseElement) => {
    let statusClass = '';
    let pseudoDisabledClass = '';
    let buttonLabel = '';
    let buttonClassname = '';
    let popupMsg = '';
    let popupWide;

    const isStudentReview = assignment.studentReview;

    // Need to handle special case for DocReader/PDF & Flowpaper which should not show review until graded.
    const isPdfResource = assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.PDF_RESOURCE;
    const isFileResource = assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.FILE_RESOURCE;
    const isFlowpaper = assignment.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.FLOWPAPER;
    const isPdfAndFlowpaper = assignment.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF_AND_FLOWPAPER;
    const isDocReader = assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.DOCREADER && PDF_DELIVERY_FORMATS.DOCREADER;
    const showLockedUntilGraded = (isPdfResource && (isFlowpaper || isPdfAndFlowpaper)) || isDocReader;
    const hasAssignmentGrade = (typeof assignment.grade === 'number');
    const allowReview = (showLockedUntilGraded && assignment.hasManualScoredItems) ? hasAssignmentGrade : true;

    let withinLateSubmitDate = false;
    if (assignment.lateSubmitDate) {
      const now = new Date();
      const lateSubmitDate = new Date(assignment.lateSubmitDate);
      withinLateSubmitDate = now < lateSubmitDate;
    }

    if (assignment.status === ASSIGNMENT_STATUS.STARTED) {
      statusClass = 'icon-started';
      buttonLabel = 'continue';
    } else if (assignment.status === ASSIGNMENT_STATUS.READY) {
      statusClass = 'icon-ready';
      if (isFileResource) {
        buttonLabel = 'download';
      } else {
        buttonLabel = 'start';
      }
    } else if (assignment.status === ASSIGNMENT_STATUS.LOCKED) {
      statusClass = 'icon-locked';
      pseudoDisabledClass = 'pseudo-disabled';
      popupMsg = this.getStudentActionButtonLockedMessage({ assignment });
      popupWide = 'very';
      if (isFileResource) {
        buttonLabel = 'download';
      } else {
        buttonLabel = 'start';
      }
    } else if (assignment.status === ASSIGNMENT_STATUS.CLOSED) {
      statusClass = 'icon-closed';
      if (isFileResource) {
        buttonLabel = 'download';
      } else if (isStudentReview) {
        // for types that showLockedUntilGraded only show review if manual score and graded (allowReview)
        if (assignment.submitted && allowReview) {
          buttonLabel = 'review';
        } else {
          if (assignment.allowLateSubmission && !assignment.studentSubmitted && withinLateSubmitDate) {
            buttonLabel = 'turnInLate';
            buttonClassname = 'turn-in-late';
            statusClass = 'icon-started';
          } else {
            buttonLabel = 'locked';
            pseudoDisabledClass = 'pseudo-disabled';
            popupMsg = t('closedLockedMessage', 'StudentContentCardManager message missing.');
          }
        }
      } else {
        // for types that showLockedUntilGraded always show locked if studentReview is false
        if (!assignment.submitted || showLockedUntilGraded) {
          if (assignment.allowLateSubmission && !assignment.studentSubmitted && withinLateSubmitDate) {
            buttonLabel = 'turnInLate';
            buttonClassname = 'turn-in-late';
            statusClass = 'icon-started';
          } else {
            buttonLabel = 'locked';
            pseudoDisabledClass = 'pseudo-disabled';
            popupMsg = t('closedLockedMessage', 'StudentContentCardManager message missing.');
          }
        }
      }
    } else if (assignment.status === ASSIGNMENT_STATUS.COMPLETED) {
      statusClass = 'icon-completed';
      if (isFileResource) {
        buttonLabel = 'download';
      } else if (isStudentReview) {
        if (assignment.submitted) {
          buttonLabel = 'review';
        } else {
          if (assignment.allowLateSubmission && !assignment.studentSubmitted && withinLateSubmitDate) {
            buttonLabel = 'turnInLate';
            buttonClassname = 'turn-in-late';
            statusClass = 'icon-started';
          } else {
            buttonLabel = 'locked';
            pseudoDisabledClass = 'pseudo-disabled';
            popupMsg = t('completedLockedMessage', 'StudentContentCardManager message missing.');
          }
        }
      } else {
        if (!assignment.submitted) {
          if (assignment.allowLateSubmission && !assignment.studentSubmitted && withinLateSubmitDate) {
            buttonLabel = 'turnInLate';
            statusClass = 'icon-started';
          } else {
            buttonLabel = 'locked';
            pseudoDisabledClass = 'pseudo-disabled';
            popupMsg = t('completedLockedMessage', 'StudentContentCardManager message missing.');
          }
        } else {
          buttonLabel = 'tryAgain';
        }
      }
    } else {
      statusClass = '';
      buttonLabel = '-';
    }

    if (courseElement?.licenseExpired || assignment?.licenseExpired) {
      // TODO uncomment if we decide to show expiredLicenseButtonViewMsg to student
      // popupMsg = t('expiredLicenseButtonViewMsg');
      popupMsg = '';
      pseudoDisabledClass = 'pseudo-disabled';
    }

    return {
      buttonClassname,
      buttonLabel,
      popupMsg,
      popupWide,
      pseudoDisabledClass,
      statusClass
    };
  }

  @action getStatusClass = (assignment) => this.getAssignmentStatusValues(assignment).statusClass

  @action getButtonLabel = (assignment) => this.getAssignmentStatusValues(assignment).buttonLabel

  @action getPseudoDisabledClass = (assignment) => this.getAssignmentStatusValues(assignment).pseudoDisabledClass

  @action getPopupMessage = (assignment) => this.getAssignmentStatusValues(assignment).popupMsg

  /** Returns student action buttons for assignment card */
  @action getStudentAssignmentActionButtons = (
    menuStatus, assignment, disabled = false, hideTryAgain = false, handlePresent, handleSelfPacedOpen, doNotDisable, {
      // locale
    } = {}
  ) => {
    // If we are in the menu context, check for proper status for current tab
    let isCurrent = false;
    if (menuStatus !== undefined && (menuStatus === ASSIGNMENT_MENU_STATUS.CURRENT || menuStatus === ASSIGNMENT_MENU_STATUS.TODAY)) {
      isCurrent = true;
    }
    let isOpen = false;
    if (menuStatus === undefined && (ASSIGNMENT_STATUS.getCheckableStatuses().includes(assignment.status))) {
      isOpen = true;
    }
    let isReview = false;
    if (assignment.status === ASSIGNMENT_STATUS.CLOSED || assignment.status === ASSIGNMENT_STATUS.COMPLETED) {
      isReview = true;
    }

    const statusValues = this.getAssignmentStatusValues(assignment);
    const { pseudoDisabledClass } = statusValues;
    const { buttonLabel } = statusValues;

    const isFileResource = assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.FILE_RESOURCE;

    if (isCurrent || (isOpen && !isReview) || isFileResource) {
      const button = (
        <Button
          aria-label={`${assignment.contentViewerCompatible ?
            t(buttonLabel) : t('studentView', 'View')} ${this.contentTitle(assignment, null)}`}
          className={disabled ? null : pseudoDisabledClass}
          disabled={disabled || this.isLoading}
          onClick={() => {
            if (pseudoDisabledClass) {
              return;
            }
            if (isFileResource) {
              const fileDownloadUrl = CONTENT_ITEM_TYPES.getFileDownloadUrl(assignment.contentItemId);
              window.open(fileDownloadUrl);
            } else {
              this.handleAssignmentViewPresent({
                assignment, contentMode: CONTENT_MODE.ASSESS, handlePresent, menuStatus, doNotDisable
              });
            }
          }}
          primary>
          {assignment.contentViewerCompatible ? t(buttonLabel) : t('studentView', 'View')}
        </Button>
      );
      if (pseudoDisabledClass !== '') {
        return (
          <Popup
            content={(
              <>
                <div className='fg-column'>
                  {this.getStudentActionButtonLockedMessage({ assignment })}
                </div>
              </>
            )}
            trigger={button}
            wide='very' />
        );
      }
      return button;
    }
    return this.getStudentCourseDeliveryButton(assignment, null, menuStatus, disabled, hideTryAgain, handlePresent, handleSelfPacedOpen, doNotDisable);
  }

  getStudentActionButtonLockedMessage = ({ assignment } = {}) => {
    return (
      new Date(assignment.timezoneStartDate).valueOf() > Date.now() ? (
        <>
          <div className='card-label'>
            <strong>{t('startDateLabel')}</strong>
            {/* {this.convertToLongDate(assignment.timezoneStartDate)} */}
            {dateFormat(assignment.timezoneStartDate, this.studentContentCardDateFormatStrategy)}
          </div>
          <div className='card-label'>
            <strong>{t('startTimeLabel')}</strong>
            {this.formatTime(assignment.timezoneStartTime)}
          </div>
        </>
      ) : (
        <>
          <div>
            <strong>{t('lockedByTeacher')}</strong>
          </div>
        </>
      )
    );
  }

  /** Returns student action buttons for course tree leaf card */
  @action getStudentCourseActionButtons = (
    assignment, courseElement, hasActivities, numberOfActivities, deliveryMode,
    allowStudentPacedAttempt, handleAssignmentViewPresent, handleAssignmentsOpenCallback,
    handleSelfPacedOpen, doNotDisable
  ) => {
    let pseudoDisabledClass = '';
    let buttonLabel = '';
    let popupMsg = '';
    let popupWide;

    const contentItemEntityTypeId = assignment?.contentItemEntityTypeId || courseElement?.contentItemEntityTypeId;

    const isFileResource = contentItemEntityTypeId === CONTENT_ITEM_TYPES.FILE_RESOURCE;

    if (assignment) {
      // Get buttons, css classes and messages based on assignment status
      // this.getAssignmentStatusValues(assignment, courseElement);
      const statusValues = this.getAssignmentStatusValues(assignment, courseElement);
      pseudoDisabledClass = statusValues.pseudoDisabledClass;
      buttonLabel = statusValues.buttonLabel;
      popupMsg = statusValues.popupMsg;
      popupWide = statusValues.popupWide;
    } else if (!assignment && hasActivities && numberOfActivities > 1) {
      // eslint-disable-next-line max-len
      if (deliveryMode === DELIVERY_MODES.STUDENT_ALWAYS_ASSIGNABLE || deliveryMode === DELIVERY_MODES.STUDENT_ALWAYS_READ_ONLY_UNTIL_ASSIGNED) {
        // buttonLabel = `Open (${numberOfActivities})`;
        buttonLabel = 'openNumberOfActivities';
      }
    } else if (isFileResource) {
      buttonLabel = 'download';
    } else {
      buttonLabel = '';
    }

    const contentTitle = this.contentTitle(assignment, courseElement);

    // const turnInLate = buttonLabel === 'Turn in Late';
    const turnInLate = buttonLabel === 'turnInLate';

    if (courseElement.licenseExpired) {
      // TODO uncomment if we decide to show expiredLicenseButtonViewMsg to student
      // popupMsg = t('expiredLicenseButtonViewMsg');
      popupMsg = '';
      pseudoDisabledClass = 'pseudo-disabled';
    }

    if (pseudoDisabledClass !== '' && popupMsg) {
      return (
        <Popup
          content={(
            <>
              <div className='card-label'>{popupMsg}</div>
            </>
          )}
          trigger={(
            <Button aria-label={`${t(buttonLabel)} ${contentTitle}`} className={`leaf-button ${pseudoDisabledClass}`} primary>
              {t(buttonLabel)}
            </Button>
          )}
          wide={popupWide} />
      );
    } else if (hasActivities) {
      if (buttonLabel === 'review' || buttonLabel === 'tryAgain') {
        return (this.getStudentCourseDeliveryButton(
          assignment, courseElement, null, false, false, handleAssignmentViewPresent, handleSelfPacedOpen, doNotDisable
        ));
      }
      return (
        !!buttonLabel && (
          <Button
            aria-label={`${t(buttonLabel, { numberOfActivities })} ${contentTitle}`}
            className={`leaf-button as ${pseudoDisabledClass}`}
            disabled={this.isLoading || courseElement.licenseExpired}
            onClick={() => {
              if (buttonLabel === 'download') {
                if (pseudoDisabledClass === 'pseudo-disabled') {
                  return;
                }
                const contentItemId = assignment?.contentItemId || courseElement?.contentItemId;
                const fileDownloadUrl = CONTENT_ITEM_TYPES.getFileDownloadUrl(contentItemId);
                window.open(fileDownloadUrl);
              } else if (assignment) {
                if (isFileResource) {
                  const contentItemId = assignment?.contentItemId || courseElement?.contentItemId;
                  const fileDownloadUrl = CONTENT_ITEM_TYPES.getFileDownloadUrl(contentItemId);
                  window.open(fileDownloadUrl);
                } else {
                  this.handleAssignmentViewPresent({
                    assignment, contentMode: CONTENT_MODE.ASSESS, handlePresent: handleAssignmentViewPresent, menuStatus: null, turnInLate, doNotDisable
                  });
                }
              } else {
                handleAssignmentsOpenCallback();
              }
            }}
            primary>
            {t(buttonLabel, { numberOfActivities })}
          </Button>
        )
      );
    } else if (!hasActivities) {
      if (isFileResource) {
        buttonLabel = 'download';
      } else {
        buttonLabel = 'start';
      }

      if (!allowStudentPacedAttempt && deliveryMode === DELIVERY_MODES.STUDENT_ALWAYS_READ_ONLY_UNTIL_ASSIGNED) {
        return (
          <Button
            aria-label={`${t(buttonLabel)} ${contentTitle}`}
            className={`leaf-button as ${pseudoDisabledClass}`}
            disabled={true}
            primary>
            {t(buttonLabel)}
          </Button>
        );
      } else if (allowStudentPacedAttempt || isFileResource) {
        if (isFileResource) {
          buttonLabel = 'download';
        } else {
          buttonLabel = 'start';
        }

        return (
          <Button
            aria-label={`${t(buttonLabel)} ${contentTitle}`}
            className={`leaf-button as ${pseudoDisabledClass}`}
            disabled={this.isLoading || courseElement.licenseExpired}
            onClick={() => {
              if (!pseudoDisabledClass) {
                if (isFileResource) {
                  const contentItemId = assignment?.contentItemId || courseElement?.contentItemId;
                  const fileDownloadUrl = CONTENT_ITEM_TYPES.getFileDownloadUrl(contentItemId);
                  window.open(fileDownloadUrl);
                } else {
                  handleSelfPacedOpen(CONTENT_MODE.ASSESS, courseElement);
                }
              }
            }}
            primary>
            {t(buttonLabel)}
          </Button>
        );
      }
    }
  }

  @action setStudentContentCardDateFormatStrategy = (dateFormatStrategy) => {
    const isValidDateFormatStrategy = !dateFormatStrategy || (
      typeof dateFormatStrategy === 'string' && (
        DATE_FORMAT_STRATEGIES.includes(dateFormatStrategy)
      )
    );
    if (isValidDateFormatStrategy) {
      this.studentContentCardDateFormatStrategy = dateFormatStrategy;
    }
  }

  getStudentCourseDeliveryButton = (assignment, courseElement, menuStatus, disabled, hideTryAgain, handlePresent, handleSelfPacedOpen, doNotDisable) => {
    let reviewButtonClassName = 'leaf-button as';
    let tryAgainButtonClassName = 'leaf-button try-again';

    // Check if we are from within Assignments area
    if (menuStatus && menuStatus !== undefined) {
      // if we are not in closed or graded, get out as there should be no review button
      if (menuStatus !== ASSIGNMENT_MENU_STATUS.CLOSED && menuStatus !== ASSIGNMENT_MENU_STATUS.GRADED) {
        return false;
      }
      reviewButtonClassName = '';
      tryAgainButtonClassName = 'try-again';
    }

    // check for assignment details, and student pacing/attempts
    const pacingMode = ResourcePacingService.pacingMode(assignment);
    const attempts = ResourcePacingService.attempts(assignment);
    const allowStudentPacedAttempt = this.getAllowStudentPacedAttempt(assignment, pacingMode, attempts, 1, hideTryAgain);

    // Get buttons, css classes and messages based on assignment status
    // this.getAssignmentStatusValues(assignment, courseElement);
    const statusValues = this.getAssignmentStatusValues(assignment, courseElement);
    const { buttonLabel } = statusValues;
    const { buttonClassname } = statusValues;
    const { popupMsg } = statusValues;
    let { pseudoDisabledClass } = statusValues;

    // If a courseElement was passed in use that as it means we are in course context
    const selfPacedOpenObject = courseElement || assignment;

    const contentTitle = this.contentTitle(assignment, courseElement);

    const turnInLate = buttonLabel === 'turnInLate';

    let shouldDisablePopupContent;

    if (courseElement?.licenseExpired || assignment?.licenseExpired) {
      // TODO uncomment if we decide to show expiredLicenseButtonViewMsg to student
      // popupMsg = t('expiredLicenseButtonViewMsg');
      pseudoDisabledClass = 'pseudo-disabled';

      shouldDisablePopupContent = true; // TODO comment out if we decide to show expiredLicenseButtonViewMsg to student
    }

    return (
      <>
        {(!!buttonLabel && buttonLabel !== 'tryAgain') &&
          ((pseudoDisabledClass !== '' && (popupMsg || courseElement?.licenseExpired || assignment?.licenseExpired) ? (
            <Popup
              content={(
                <>
                  <div className='card-label'>{popupMsg}</div>
                </>
              )}
              disabled={shouldDisablePopupContent}
              trigger={(
                <Button
                  aria-label={`${t(buttonLabel)} ${contentTitle}`}
                  className={disabled ? buttonClassname : `${buttonClassname} ${pseudoDisabledClass}`}
                  disabled={disabled}
                  primary>
                  {t(buttonLabel)}
                </Button>
              )} />
          ) : (
            <Button
              aria-label={`${t(buttonLabel)} ${contentTitle}`}
              className={buttonClassname + reviewButtonClassName + (disabled ? '' : pseudoDisabledClass)}
              disabled={disabled || this.isLoading}
              onClick={() => !pseudoDisabledClass && this.handleAssignmentViewPresent({
                assignment,
                contentMode: turnInLate ? CONTENT_MODE.ASSESS : CONTENT_MODE.REVIEW,
                handlePresent,
                menuStatus,
                turnInLate,
                doNotDisable
              })}
              primary>
              {t(buttonLabel)}
            </Button>
          )
          ))}
        {allowStudentPacedAttempt && (
          <Button
            aria-label={`${t('tryAgain')} ${contentTitle}`}
            className={classNames(tryAgainButtonClassName, pseudoDisabledClass)}
            disabled={this.isLoading}
            onClick={() => !pseudoDisabledClass && handleSelfPacedOpen(CONTENT_MODE.ASSESS, selfPacedOpenObject)}
            primary>
            <Icon name='redo alternate' />
            {t('tryAgain')}
          </Button>
        )}
      </>
    );
  }

  getAllowStudentPacedAttempt = (assignment, pacingMode, attempts, numberOfActivities, hideTryAgain = false) => {
    let allowStudentPacedAttempt = false;
    if (pacingMode === 'student_paced' && !hideTryAgain) {
      let passesAssignmentValidation = true;
      //  If there is an assignment we need to check various things
      if (assignment) {
        const hasAttempts = attempts > assignment.timesAssigned;
        passesAssignmentValidation = (hasAttempts && !assignment.hasUnsubmittedActivities && assignment.status === ASSIGNMENT_STATUS.COMPLETED && assignment.submitted);
      }
      if (passesAssignmentValidation) {
        allowStudentPacedAttempt = attempts > numberOfActivities;
      }
    }
    return allowStudentPacedAttempt;
  }

  /** Return values for assignmentGrade and assignmentGradeClass */
  getAssignmentGradeValues = (assignment) => {
    let assignmentGrade, assignmentGradeClass;

    const hasAssignmentGrade = typeof assignment.grade === 'number';
    const isScoresReleased = assignment.scoresReleased;

    // we need to ensure the student submitted the assignment.
    // if the assignment was not submitted, we should not attempt to show the grade
    if (assignment.submitted && isScoresReleased) {
      if (hasAssignmentGrade) {
        assignmentGrade = `${assignment.grade}%`;
        assignmentGradeClass = gradebookManager.getGradeClassName(assignment.grade);
      } else if (assignment.submitted) {
        // if no grade for a manual scored item we want to show waiting on grade.
        if (assignment.hasManualScoredItems) {
          assignmentGrade = t('waitingOnGrade');
          assignmentGradeClass = 'grade-low';
        }
      } else {
        assignmentGrade = t('notAvailable');
        assignmentGradeClass = 'grade-not-available';
      }
    }
    return { assignmentGrade, assignmentGradeClass };
  }

  /** clears all data from the manager */
  clearAll() {
    this.isCleared = true;
  }

  // Cards could have an assignment or not so check what we have preferring use of assignment data.
  contentTitle(assignment, courseElement) {
    let title = '';
    if (assignment) {
      title = UtilityService.stripHtmlTagsAndStrip(assignment.resourceWebTitle ? assignment.resourceWebTitle : assignment.name);
    } else if (courseElement) {
      title = UtilityService.stripHtmlTagsAndStrip(courseElement.name ? courseElement.name : courseElement.contentItemName);
    }
    return title;
  }
}

export default new StudentContentCardManager();
