/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable sort-keys */
import React, { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import InlineEditor from '../ckeditor-custom/build/ckeditor';
import InlineEditor from 'ckeditor5-custom-build';

import classNames from 'classnames';

// eslint-disable-next-line max-len
export const CKEDITOR_DEV_LICENSE_KEY = 'UzNGT2ZsNTJhZ2hCSTRZZFU3MjRYem41S0FTNkN4aWljdm43UUF1Y0tGK25PaEd1Wm55QVgyMlduZHM2bWxBUy1NakF5TlRBeE1ETT0=';

class RichTextEditor extends Component {
  editor = null;

  constructor(props) {
    super(props);

    this.state = {
      characters: 0,
      words: 0,
      maxCharacters: this.props.maxCharacters
    };
  }

  render() {
    const config = {
      licenseKey: CKEDITOR_DEV_LICENSE_KEY,
      toolbar: ['bold', 'italic', 'underline'],
      wordCount: { maxCharacters: this.props.maxCharacters, onUpdate: (counts) => this.setState(counts) },
      typing: {
        transformations: {
          remove: ['oneHalf', 'oneThird', 'twoThirds', 'oneForth', 'threeQuarters'],
          extra: [
            { from: /\b(1\/2)\b/, to: '½' },
            { from: /\b(1\/3)\b/, to: '⅓' },
            { from: /\b(2\/3)\b/, to: '⅔' },
            { from: /\b(1\/4)\b/, to: '¼' },
            { from: /\b(3\/4)\b/, to: '¾' }

          ]
        }
      },
      placeholder: this.props.placeHolder
    };

    return (
      <div className={classNames('ckeditor', this.props.additionalClassNames)}>
        <CKEditor
          config={config}
          data={this.props.data}
          disabled={this.props.disabled || false}
          editor={InlineEditor}
          onBlur={(_event, _editor) => {
            // placeholder
          }}
          onChange={(_event, editor) => {
            const data = editor.getData();
            this.props.onChange(data);
          }}
          onFocus={(_event, _editor) => {
            // placeholder
          }}
          onInit={(editor) => {
            this.editor = editor;
            if (this.props.onInit !== undefined) {
              this.props.onInit(editor);
            }
          }} />
        {
          this.props.maxCharacters ? (
            <p style={{ float: 'right' }}>
              {this.state.characters}
              {' '}
              /
              {' '}
              {this.props.maxCharacters}
            </p>
          )
            :
            <></>
        }

      </div>
    );
  }
}

export default RichTextEditor;
