import { action, observable } from 'mobx';

import Auth from './AuthManager';

const SCORE_CELL_GRADER_MANAGER_ENDPOINTS = {
  ADD_OR_UPDATE_ENTITY_COMMENT: '/api/addUpdateComment',
  FETCH_ENTITY_COMMENT: '/api/viewComment',
  UPDATE_TEST_ITEM_SCORE: '/api/updateTestItemScore'
};

export class ScoreCellGraderManager {
  @observable activeObservationalScoreCellKey = null;
  @observable shouldForceClearActiveObservationalScoreCellKey = false;

  @action clearAll = () => {
    this.activeObservationalScoreCellKey = null;
    this.shouldForceClearActiveObservationalScoreCellKey = false;
  }

  @action setActiveObservationalScoreCellKey = (scoreCellKey) => {
    this.activeObservationalScoreCellKey = scoreCellKey || null;
  }

  @action setShouldForceClearActiveObservationalScoreCellKey = (toggle) => {
    this.shouldForceClearActiveObservationalScoreCellKey = toggle;
  }

  // TODO
  /* @action */fetchEntityComment = async ({ entityId } = {}) => {
    try {
      const { FETCH_ENTITY_COMMENT } = SCORE_CELL_GRADER_MANAGER_ENDPOINTS;

      let apiUrl = `${Auth.ecms}${FETCH_ENTITY_COMMENT}`;
      apiUrl += `?entityId=${entityId}`;
      const response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
      if (response?.status === 'SUCCESS') {
        return response.comment;
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  /* @action */updateTestItemScore = async ({
    activityId,
    lessonElementId,
    score = '',
    studentId,
    subscore = null
  } = {}) => {
    try {
      const { UPDATE_TEST_ITEM_SCORE } = SCORE_CELL_GRADER_MANAGER_ENDPOINTS;

      const postBody = {
        activityId,
        lessonElementId,
        score,
        studentId,
        subscore
      };
      const response = await Auth.fetch(`${Auth.ecms}${UPDATE_TEST_ITEM_SCORE}`, {
        body: postBody,
        method: 'POST'
      });
      if (response.status === 'SUCCESS') {
        // placeholder
        return;
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // TODO
  /* @action */updateEntityComment = async ({
    comment,
    entityId,
    entityTypeId
  } = {}) => {
    try {
      const { ADD_OR_UPDATE_ENTITY_COMMENT } = SCORE_CELL_GRADER_MANAGER_ENDPOINTS;

      const apiUrl = `${Auth.ecms}${ADD_OR_UPDATE_ENTITY_COMMENT}`;
      const postBody = {
        comment,
        entityId,
        entityTypeId
      };
      const response = await Auth.fetch(apiUrl, {
        body: postBody,
        method: 'POST'
      });
      if (response?.status === 'SUCCESS') {
        // placeholder
        return;
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    }
  }
}

export default new ScoreCellGraderManager();
