import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Container, Header, Segment } from 'semantic-ui-react';

import '../css/NotFoundView.less';

import { SatCoreRegister } from '../SatCoreRegistry';
import { renderButton } from '../utils';
import UtilityService from '../services/UtilityService';

const NotFoundView = observer((props) => {
  const { history, t } = props;

  const { /* managers */ } = useContext(MobXProviderContext);

  const [loadingNotFoundView, setLoadingNotFoundView] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoadingNotFoundView(false);
    }, 2000);
  }, []);

  const handleReturnToHome = () => {
    history.push('/');
  };

  return !loadingNotFoundView ? (
    <div className='NotFoundView'>
      <Container className='not-found-view-container' text>
        <Segment textAlign='center'>
          <Header as='h1' className='not-found-view-header'>
            {t('notFoundViewHeader')}
          </Header>
          <div className='not-found-view-body'>
            {UtilityService.reactHtmlParserWrapper(t('notFoundViewBody')).parsed}
          </div>
          <div className='not-found-view-actions'>
            {renderButton({
              buttonKey: t('returnToHome'),
              doNotTranslate: true,
              onClick: handleReturnToHome,
              primary: true
            })}
          </div>
        </Segment>
      </Container>
    </div>
  ) : null;
});
export default NotFoundView;

SatCoreRegister('NotFoundView', NotFoundView);
