import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import classNames from 'classnames';

import '../css/SatelliteTab.less';
import Auth from '../managers/AuthManager';

import { SatCoreRegister } from '../SatCoreRegistry';

import ContentService from '../services/ContentService';
import CourseService from '../services/CourseService';

import { focusableElementList } from '../utils';

export default
@inject('contentManager', 'courseManager', 'classroomManager', 'userManager')
@observer
class SatelliteTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftSide: true,
      tabOpen: false,
    };

    this.contentPanelRef = React.createRef();
  }

  async componentDidMount() {
    window.removeEventListener('message', this.handleTagSatSearch);
    window.addEventListener('message', this.handleTagSatSearch);

    const {
      classroomManager,
      courseManager
    } = this.props;
    const forRelatedContent = true;

    courseManager.setIsLoading(false);
    courseManager.clearCourseElementSearch(forRelatedContent);
    // courseManager.setIsLoading(false);
    this.courseOptions = [];
    const { currentClassroomId } = classroomManager;
    const courseList = courseManager.getCourseList(currentClassroomId);

    let initialCourseId;

    if (courseList.length > 1 && !initialCourseId) {
      const urlParams = new URLSearchParams(window.location.search);
      initialCourseId = urlParams.get('courseId');
      courseManager.setCurrentCourseId(initialCourseId);
    } else if (this.courseOptions.length === 1 && !initialCourseId) {
      // only 1 course available in dropdown
      initialCourseId = this.courseOptions[0].value;
      courseManager.setCurrentCourseId(initialCourseId);
    }

    if (!initialCourseId) {
      return;
    }

    await this.getCourseItems({ courseContentItemId: initialCourseId });
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleTagSatSearch);
  }

  getCourseItems = async () => {
    const { courseManager } = this.props;

    const courseId = courseManager.courseResourceIdForRelatedItems;

    this.courseIds = [courseId];

    if (courseManager.courseContentItemIdForRelatedItems === null) {
      courseManager.setCourseContentItemIdForRelatedItems(courseManager.courseContentItemIdForRelatedItems);
    }
    courseManager.setCourseContentItemIds([courseManager.courseContentItemIdForRelatedItems], true);

    await CourseService.searchCourseResourceElements(true);
  }

  handleTagSatSearch = async (event) => {
    const { courseManager, userManager } = this.props;
    const { isStudent } = userManager;
    if (event.origin === Auth.lesson && event.data.type && event.data.type === 'current_tags' && !isStudent) {
      if (event.data.tagList && event.data.tagList.length > 0) {
        courseManager.setTagIds(event.data.tagList.split(','), true);
        ContentService.setSatelliteTabShowing(true);
        await courseManager.searchCourseResourceElements(true, event.data.relatedContentSearchAndLogic);
      } else {
        courseManager.setTagIds('', true);
        ContentService.setSatelliteTabShowing(false);
      }
    }
    if (event.origin === Auth.lesson && event.data.type && event.data.type === 'access_assignments' && isStudent) {
      ContentService.setSatelliteTabShowing(event.data.previousAssignments);
    }
  }

  handleTabClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(
      (prevState) => ({ tabOpen: !prevState.tabOpen }), () => {
        const { tabOpen } = this.state;
        if (tabOpen && this.contentPanelRef.current) {
          const firstFocusable = this.contentPanelRef.current.querySelector(focusableElementList);
          firstFocusable?.focus();
        }
      }
    );
  };

  render() {
    const { additionalClassName = '', ChildComponent, tabName, contentManager, courseManager, userManager } = this.props;
    const { lessonPlayerShowing } = contentManager;
    const { isStudent } = userManager;
    const { leftSide, tabOpen } = this.state;

    let satelliteTabClassName = 'satelliteTab';
    satelliteTabClassName += additionalClassName ? ` ${additionalClassName}` : '';

    let tabFinalName = 'Related Content';
    if (tabName !== null && tabName !== undefined) {
      tabFinalName = tabName;
    }

    if (!isStudent && ChildComponent !== null && ChildComponent !== undefined &&
      lessonPlayerShowing && (courseManager.tagIds && courseManager.tagIds.length > 0) && contentManager.satelliteTabShowing) {
      return (
        <div className={classNames({ left: leftSide, open: tabOpen }, satelliteTabClassName)}>
          <div ref={this.contentPanelRef} className={classNames('contentPanel', 'relatedContent')}>
            <ChildComponent />
          </div>
          <div className={classNames({ left: leftSide }, 'satelliteTabButton', 'relatedContent')}
            onClick={(e) => this.handleTabClick(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                this.handleTabClick(e);
              }
            }}
            role='tab'
            tabIndex='0'>
            <div className={classNames({ 'flyout-tab-label': true })}>{tabFinalName}</div>
          </div>
        </div>
      );
    }
    if (isStudent && ChildComponent !== null && ChildComponent !== undefined && lessonPlayerShowing && contentManager.satelliteTabShowing) {
      return (
        <div className={classNames({ left: leftSide, open: tabOpen }, satelliteTabClassName)}>
          <div ref={this.contentPanelRef} className={classNames('contentPanel', 'pastAssignments')}>
            <ChildComponent />
          </div>
          <div className={classNames({ left: leftSide }, 'satelliteTabButton', 'pastAssignments')}
            onClick={(e) => this.handleTabClick(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                this.handleTabClick(e);
              }
            }}
            role='tab'
            tabIndex='0'>
            <div className={classNames({ 'flyout-tab-label': true })}>{tabFinalName}</div>
          </div>
        </div>
      );
    }

    return (
      <></>
    );
  }
}

SatCoreRegister('SatelliteTab', SatelliteTab);
