import { action, computed, makeObservable, observable, toJS } from 'mobx';

export class ReportTableManager {
  constructor() {
    makeObservable(this);
  }

  @observable loadingTable = false;

  @observable reportTableData = [];

  @observable shouldReinitializeReportTableExpandedRows = false;
  @observable shouldUpdateReportTableExpandedRows = false;

  @observable reportCourseTableExpandedRowMap = new Map();
  @observable reportStandardsTableExpandedRowMap = new Map();

  @observable reportTableScoreSummaryBarCellConfig = [];

  @observable reportTableFacultyCellsConfig = [];
  @observable hiddenReportTableFacultyCellsMap = new Map();

  @observable activeReportTablePage = 1;

  @action clearAll = () => {
    this.setLoadingTable(false);
    this.setActiveReportTablePage(1);
    this.setReportTableData([]);
    this.setReportTableScoreSummaryBarCellConfig([]);
    this.setReportTableFacultyCellsConfig([]);
    this.clearHiddenReportTableFacultyCellsMap();
  }

  @action clearExpandedReportTableRows = () => {
    this.shouldReinitializeReportTableExpandedRows = false;
    this.shouldUpdateReportTableExpandedRows = false;
    this.reportCourseTableExpandedRowMap = new Map();
    this.reportStandardsTableExpandedRowMap = new Map();
  }

  @action setLoadingTable = (toggle) => {
    this.loadingTable = toggle;
  }

  @action setReportTableData = (reportTableData) => {
    this.reportTableData = reportTableData;
  }

  @action setShouldReinitializeReportTableExpandedRows = (toggle) => {
    this.shouldReinitializeReportTableExpandedRows = toggle;
  }

  @action setShouldUpdateReportTableExpandedRows = (toggle) => {
    this.shouldUpdateReportTableExpandedRows = toggle;
  }

  /** @param {Map<string, boolean} tableExpandedRowMap `Map<elementId, isExpanded>` */
  @action setReportCourseTableExpandedRowMap = (tableExpandedRowMap, {
    shouldPrependCachedExpandedRowMap
  } = {}) => {
    if (!shouldPrependCachedExpandedRowMap) {
      this.reportCourseTableExpandedRowMap = tableExpandedRowMap;
    } else {
      const combinedExpandedRowMap = new Map([
        ...toJS(this.reportCourseTableExpandedRowMap),
        ...tableExpandedRowMap
      ]);
      this.reportCourseTableExpandedRowMap = combinedExpandedRowMap;
    }
  }

  /** @param {Map<string, boolean>} tableExpandedRowMap `Map<standardId, isExpanded>` */
  @action setReportStandardsTableExpandedRowMap = (tableExpandedRowMap, {
    shouldPrependCachedExpandedRowMap
  } = {}) => {
    if (!shouldPrependCachedExpandedRowMap) {
      this.reportStandardsTableExpandedRowMap = tableExpandedRowMap;
    } else {
      const combinedExpandedRowMap = new Map([
        ...toJS(this.reportStandardsTableExpandedRowMap),
        ...tableExpandedRowMap
      ]);
      this.reportStandardsTableExpandedRowMap = combinedExpandedRowMap;
    }
  }

  @action setReportTableScoreSummaryBarCellConfig = (reportTableScoreSummaryBarCellConfig) => {
    this.reportTableScoreSummaryBarCellConfig = reportTableScoreSummaryBarCellConfig;
  }

  @action setReportTableFacultyCellsConfig = (reportTableFacultyCellsConfig) => {
    this.reportTableFacultyCellsConfig = reportTableFacultyCellsConfig;
  }

  @action setHiddenReportTableFacultyCellsMap = (map) => {
    this.hiddenReportTableFacultyCellsMap = new Map(map);
  }

  @action addToHiddenReportTableFacultyCellsMap = (cellObj) => {
    if (cellObj?.id) {
      this.hiddenReportTableFacultyCellsMap.set(cellObj.id, cellObj);
    }
  }

  @action removeFromHiddenReportTableFacultyCellsMap = (cellObjId) => {
    if (cellObjId) {
      this.hiddenReportTableFacultyCellsMap.delete(cellObjId);
    }
  }

  @action clearHiddenReportTableFacultyCellsMap = () => {
    this.hiddenReportTableFacultyCellsMap.clear();
  }

  @action setActiveReportTablePage = (activePage) => {
    this.activeReportTablePage = activePage;
  }

  @computed get isAllHiddenReportTableFacultyCells() {
    return this.reportTableFacultyCellsConfig.length === this.hiddenReportTableFacultyCellsMap.size;
  }
}

export default new ReportTableManager();
