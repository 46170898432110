import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import { Chart } from 'react-google-charts';

import { Loader } from 'semantic-ui-react';

import '../css/ColorKeyCard.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { getStyleVar, stripNonNumeric } from '../utils';

import PopupService from '../services/PopupService';
import UtilityService from '../services/UtilityService';

/**
 * Generate a **ColorKeyCard** from **colorKeyDataArray** (a given array of colors & labels)
 * ```
 * type ColorKeyDataArray = {
 *   color: string,
 *   count?: number,
 *   id?: string,
 *   label: string,
 *   subLabel?: string
 * }[];
 * ```
 */
const ColorKeyCard = observer((props) => {
  const {
    additionalClassNames = '',
    boldLabel = true,
    boldScoreLabel = true,
    boldSubLabel = false,
    colorKeyDataArray,
    iconKeyDataArray,
    infoIconPopupText, // if empty, `color-key-label-wrapper` will not be shown
    keyFlexPercent, // determines max keys per row. default is auto. see ColorKeyCard.less for more info
    maxWidth = 'auto',
    showPieChart = false,
    showCounts = false,
    t,
    useColorAsClassName = false,
    width = 'auto'
  } = props;
  const { /* managers */ } = useContext(MobXProviderContext);

  const InfoIcon = SatCoreComponent('InfoIcon');

  const renderPieChart = () => {
    const pieChartData = [
      ['pieChartDataKeys', 'pieChartDataCounts'], // placeholder header for Chart data
      ...colorKeyDataArray?.map((data) => [t(data.label), data.count])
    ];
    const fontName = getStyleVar('--theme-font-family');
    const fontSize = +stripNonNumeric(getStyleVar('--theme-font-size'));
    const pieChartOptions = {
      chartArea: { height: '120', left: 8, top: 8, width: '120' },
      fontName,
      fontSize,
      legend: 'none',
      pieSliceText: 'value',
      sliceVisibilityThreshold: 0,
      tooltip: {
        textStyle: {
          fontName,
          fontSize
        }
      },
      ...props.pieChartOptions
    };
    return (
      <div className='color-key-card-pie-chart'>
        <Chart
          chartType='PieChart'
          data={pieChartData}
          height='136px'
          loader={<Loader active inline />}
          options={pieChartOptions}
          width='136px' />
      </div>
    );
  };

  const getColorKeys = () => {
    return (
      <>
        {colorKeyDataArray?.map((key, index) => {
          const wrapInPopup = !!(key.descriptionBody || key.descriptionHeader);
          return (
            <div key={`${index}-${key.label}`}
              className={classNames(
                `color-key ${index} key-flex-${keyFlexPercent ? `${keyFlexPercent}` : 'default'}`,
                key.className
              )}>
              <div className={classNames('square', key.colorSquareClassName, {
                [key.color]: useColorAsClassName
              })} style={!useColorAsClassName ? { backgroundColor: key.color } : {}}>
                {wrapInPopup ?
                  PopupService.renderPopup({
                    content: UtilityService.reactHtmlParserWrapper(t(key.descriptionBody)).parsed,
                    header: UtilityService.reactHtmlParserWrapper(t(key.descriptionHeader)).parsed,
                    position: 'bottom center',
                    trigger: (
                      <span style={{ color: 'transparent', cursor: 'pointer' }}>
                        ---
                      </span>)
                  }) : ''}
              </div>
              <div className='label-wrapper'>
                {showCounts && (
                  <div className={`label-score-count${useColorAsClassName ? ` ${key.color}` : ''}${boldScoreLabel ? ' bold-label' : ''}`}>
                    {key.count}
                  </div>
                )}
                <div className={`label${boldLabel ? ' bold-label' : ''}`}>
                  {t(key.label)}
                </div>
                {key.subLabel && (
                  <div className={`sub-label${boldSubLabel ? ' bold-label' : ''}`}>
                    {t(key.subLabel)}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const renderColorKeys = (withWrapper = true) => {
    return (
      <div className={`color-keys ${iconKeyDataArray ? 'iconKeys' : ''}`}>
        {getColorKeys()}
      </div>
    );
  };

  const renderIconKeys = () => {
    return (
      <div className={`color-keys ${iconKeyDataArray ? 'iconKeys' : ''}`}>
        <div className='color-key-block'>
          {iconKeyDataArray?.map((key, index) => {
            return (
              <div key={`${index}-${key.label}`}
                className={classNames(
                  `color-key ${index} key-flex-${keyFlexPercent ? `${keyFlexPercent}` : 'default'}`,
                  key.className
                )}>
                <div className='square'>
                  {key.icon}
                </div>
                <div className='label-wrapper'>
                  <div className={`label${boldLabel ? ' bold-label' : ''}`}>
                    {t(key.label)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {colorKeyDataArray?.length && (
          <div className='color-key-block'>
            {getColorKeys()}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {infoIconPopupText && (
        <div className='color-key-label-wrapper'>
          <div className='color-key-label-text'>
            {t('colorKey')}
          </div>
          <div className='info-icon'>
            <InfoIcon popupOptions={{ content: infoIconPopupText, on: 'click' }} />
          </div>
        </div>
      )}
      <div className={`color-key-card-container${additionalClassNames ? ` ${additionalClassNames}` : ''}`}
        style={{ maxWidth, width }}>
        {showPieChart && renderPieChart()}
        {iconKeyDataArray ? renderIconKeys() : renderColorKeys()}
      </div>
    </>
  );
});
export default ColorKeyCard;

SatCoreRegister('ColorKeyCard', ColorKeyCard);
