/* eslint-disable react/jsx-sort-props */
import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import { Input, Loader } from 'semantic-ui-react';

import '../css/GradebookObservationalScoreCellRollover.less';

import { SatCoreRegister } from '../SatCoreRegistry';

import GradebookObservationalService from '../services/gradebook/GradebookObservationalService';

import { renderButton, safeNumberToString, safeToNumber } from '../utils';

import RichTextEditor from './RichTextEditor';

const GradebookObservationalScoreCellRollover = observer((props = {}) => {
  const {
    labelActionButtonFullCredit = '', // custom string can be passed here, else default will be used
    labelActionButtonSaveScore = '', // custom string can be passed here, else default will be used
    labelCommentInput = '', // custom string can be passed here, else default will be used
    labelMaxScore = '', // custom string can be passed here, else default will be used
    labelScoreInput = '', // custom string can be passed here, else default will be used
    scoreCellDetails = {},
    t
  } = props;

  const { gradebookManager } = useContext(MobXProviderContext);

  const { activeGradebookTable, activeGradebookType } = gradebookManager;

  const [loadingObservationalScoreCellRollover, setLoadingObservationalScoreCellRollover] = useState(true);

  const [observationalScoreStr, setObservationalScoreStr] = useState('');

  const [initialObservationalComment, setInitialObservationalComment] = useState();
  const [observationalComment, setObservationalComment] = useState();

  useEffect(() => {
    (async () => {
      const studentScoreStr = safeNumberToString(scoreCellDetails?.studentScore);

      setObservationalScoreStr(studentScoreStr);

      setLoadingObservationalScoreCellRollover(false);

      const _initialObservationalComment = await GradebookObservationalService.fetchGradebookObservationalScoreCellComment(
        { scoreCellDetails }
      );
      setInitialObservationalComment(_initialObservationalComment || '');
      setObservationalComment(_initialObservationalComment || '');
    })();
  }, []);

  const handleChangeObservationalScoreInput = (event) => {
    const str = event?.target?.value;

    if (str === '') {
      setObservationalScoreStr('');
      return;
    }
    if (typeof str !== 'string' || Number.isNaN(Number(str))) {
      return;
    }
    const num = parseFloat(str);
    if (num < 0) {
      return;
    }
    const hasMultipleLeadingZeros = /^0{2,}/.test(str);
    if (hasMultipleLeadingZeros) {
      return;
    }

    // eslint-disable-next-line prefer-template
    const maxScoreStr = '' + (scoreCellDetails?.maxScore || 0);
    const maxScore = parseFloat(maxScoreStr);

    const MAX_DECIMAL_POINTS = 2;

    if (num >= 0 && num <= maxScore) {
      const split = str.split('.');

      const decimalPoints = split.length > 1 ? split[1].length : 0;

      if (decimalPoints <= MAX_DECIMAL_POINTS) {
        setObservationalScoreStr(str);
      }
    } else if (num > maxScore) {
      setObservationalScoreStr(maxScore.toFixed(MAX_DECIMAL_POINTS));
    } else {
      setObservationalScoreStr('');
    }
  };

  const handleClickFullCreditActionButton = async (event, _data) => {
    const _observationalScoreStr = safeNumberToString(scoreCellDetails.maxScore);
    setObservationalScoreStr(_observationalScoreStr);

    await props.handleSubmitGradebookObservationalScoreCellData?.(event, {
      // ...(data || {}),
      initialObservationalComment,
      observationalComment,
      observationalScore: safeToNumber(_observationalScoreStr)
    });
  };

  const handleClickSaveScoreActionButton = async (event, _data) => {
    await props.handleSubmitGradebookObservationalScoreCellData?.(event, {
      // ...(data || {}),
      initialObservationalComment,
      observationalComment,
      observationalScore: safeToNumber(observationalScoreStr)
    });
  };

  // TODO uncomment if we decide to implement close ('X') button on top of rollover
  // const renderCloseButtonWrapper = () => {
  //   return (
  //     <div className='observational-rollover-close-button-wrapper'>
  //       <Button basic icon='close' size='mini' onClick={props.handleCloseRollover}
  //         title={t('close')} />
  //       {/* {renderButton({
  //         icon: 'close',
  //         onClick: handleCloseRollover,
  //         size: 'mini',
  //       })} */}
  //     </div>
  //   );
  // };

  const renderObservationalScoreInputWrapper = () => {
    return (
      <div className='observational-score-input-wrapper'>
        <div className='observational-score-input-label'>
          {labelScoreInput || t('labelScoreInput')}
        </div>
        <Input className='observational-score-input'
          onChange={handleChangeObservationalScoreInput}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              handleClickSaveScoreActionButton();
            }
          }}
          value={observationalScoreStr} />
        <div className='observational-max-score-label'>
          {labelMaxScore || t('labelMaxScore', {
            maxScore: scoreCellDetails.maxScore
          })}
        </div>
      </div>
    );
  };

  const renderObservationalCommentInputWrapper = () => {
    return (
      <div className='observational-comment-input-wrapper'>
        <div className='observational-comment-input-label'>
          {labelCommentInput || t('labelCommentInput')}
        </div>
        <div className='observational-comment-input-box-outer'
          onClick={(event) => event.stopPropagation()}>
          <RichTextEditor
            additionalClassNames='observational-comment-ckeditor'
            data={observationalComment}
            onChange={(value) => {
              setObservationalComment(value);
            }} />
        </div>
      </div>
    );
  };

  const renderObservationalActionButtonsWrapper = () => {
    return (
      <div className='observational-action-buttons-wrapper'>
        <div className='observational-action-button-full-credit-outer'>
          {renderButton({
            additionalClassNames: 'observational-action-button-full-credit',
            basic: true,
            buttonKey: labelActionButtonFullCredit || t('labelActionButtonFullCredit'),
            doNotTranslate: true,
            onClick: handleClickFullCreditActionButton
          })}
        </div>
        <div className='observational-action-button-save-score-outer'>
          {renderButton({
            additionalClassNames: 'observational-action-button-save-score',
            buttonKey: labelActionButtonSaveScore || t('labelActionButtonSaveScore'),
            doNotTranslate: true,
            onClick: handleClickSaveScoreActionButton,
            primary: true
          })}
        </div>
      </div>
    );
  };

  return !loadingObservationalScoreCellRollover ? (
    <div className={classNames(
      'GradebookObservationalScoreCellRollover cell-score-popup-content-wrapper',
      activeGradebookType, activeGradebookTable
    )}>
      {/* TODO uncomment if we decide to implement close ('X') button on top of rollover */}
      {/* {renderCloseButtonWrapper()} */}
      {props.renderScoreLabelContent_questionStem(scoreCellDetails)}
      {renderObservationalScoreInputWrapper()}
      {renderObservationalCommentInputWrapper()}
      {renderObservationalActionButtonsWrapper()}
    </div>
  ) : (
    <div className={classNames(
      'GradebookObservationalScoreCellRollover cell-score-popup-content-wrapper rollover-loading',
      activeGradebookType, activeGradebookTable
    )}>
      <Loader active />
    </div>
  );
});
export default GradebookObservationalScoreCellRollover;

SatCoreRegister('GradebookObservationalScoreCellRollover', GradebookObservationalScoreCellRollover);
