import { registerClass } from '../../SatCoreRegistry';

import scoreCellGraderManager from '../../managers/ScoreCellGraderManager';

export default class GradebookObservationalService {
  static fetchGradebookObservationalScoreCellComment = async ({
    scoreCellDetails
  } = {}) => {
    return scoreCellGraderManager.fetchEntityComment({
      entityId: scoreCellDetails.activityResponseId
    });
  }

  static handleSubmitGradebookObservationalScoreCellData = async ({
    observationalScoreCellData,
    scoreCellDetails
  } = {}) => {
    if (!observationalScoreCellData) {
      return;
    }

    const { initialObservationalComment, observationalComment, observationalScore } = observationalScoreCellData;
    if (observationalScore !== scoreCellDetails?.studentScore) {
      await scoreCellGraderManager.updateTestItemScore({
        activityId: scoreCellDetails.activityId,
        lessonElementId: scoreCellDetails.elementId,
        score: observationalScoreCellData.observationalScore,
        studentId: scoreCellDetails.student.userId
      });
    }

    if (typeof observationalComment === 'string' && initialObservationalComment !== observationalComment) {
      await scoreCellGraderManager.updateEntityComment({
        comment: observationalComment,
        entityId: scoreCellDetails.activityResponseId,
        entityTypeId: 'response'
      });
    }
  }
}

registerClass('GradebookObservationalService', GradebookObservationalService);
