/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { debounce } from 'lodash';

import { Dropdown, Menu } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

export default
@inject('topNavManager', 'userManager')
@observer
class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.Avatar = SatCoreComponent('Avatar');
    this.DropdownObserver = SatCoreComponent('DropdownObserver');
  }

  getDropdownOptions = (options) => options.filter((option) => option).map((option) => {
    const { topNavManager } = this.props;
    return ({
      as: (props) => {
        if (option.href) {
          return (
            <a {...props}
              aria-label={option.text} href={option.href} role='menuitem' tabIndex={topNavManager.isTopNavProfileMenuOpen ? '0' : '-1'} />
          );
        } else {
          return (
            <div {...props}
              aria-label={option.text} role='menuitem' tabIndex={topNavManager.isTopNavProfileMenuOpen ? '0' : '-1'} />
          );
        }
      },
      key: option.text.toLowerCase().replace(/\s+/g, '_'),
      ...option
    });
  });

  render() {
    const { Avatar, DropdownObserver } = this;

    const { topNavManager, userManager, options, t } = this.props;

    const {
      hasTopNavProfileMenuAvatarTrigger,
      shouldTriggerProfileMenuOnHover,
      treatTopNavTabsAsAnchorTags
    } = topNavManager;

    const profileMenuTextLabel = t('profileMenuTextLabel');

    return (
      <Menu.Item
        as={!treatTopNavTabsAsAnchorTags ? 'a' : 'div'}
        className='tnav-tab profile-menu'
        id='profile-menu'
        onClick={() => {
          if (shouldTriggerProfileMenuOnHover) {
            return;
          }
          topNavManager.setIsTopNavProfileMenuOpen(true);
        }}
        onMouseEnter={() => {
          if (!shouldTriggerProfileMenuOnHover) {
            return;
          }
          debounce(() => {
            topNavManager.setIsTopNavProfileMenuOpen(true);
          }, 100);
        }}
        onMouseLeave={() => {
          topNavManager.setIsTopNavProfileMenuOpen(false);

          const isWithinProfileMenu = !!document.activeElement?.closest?.('.profile-menu');

          if (isWithinProfileMenu) {
            document.activeElement?.blur?.();
          }
        }}
        role='tab'
        tabIndex='-1'>
        <div className='aria-offscreen' id='profile-menu-instructions'>
          {topNavManager.isTopNavProfileMenuOpen ? t('ariaProfileMenuOpenInstructions') : t('ariaProfileMenuClosedInstructions')}
        </div>
        <DropdownObserver>
          <Dropdown
            aria-describedby='profile-menu-instructions'
            aria-expanded={topNavManager.isTopNavProfileMenuOpen}
            aria-haspopup='menu'
            aria-label={t('ariaProfileMenuDropdownLabel')}
            as={(props) => <div {...props} role='menu' />}
            className='profile-menu-selector'
            icon={hasTopNavProfileMenuAvatarTrigger ? null : undefined}
            onClick={() => {
              if (!shouldTriggerProfileMenuOnHover && !topNavManager.isTopNavProfileMenuOpen) {
                topNavManager.setIsTopNavProfileMenuOpen(true);
              }
            }}
            open={topNavManager.isTopNavProfileMenuOpen}
            options={this.getDropdownOptions(options)}
            simple={shouldTriggerProfileMenuOnHover}
            tabIndex='0'
            text={!hasTopNavProfileMenuAvatarTrigger ? profileMenuTextLabel : undefined}
            trigger={hasTopNavProfileMenuAvatarTrigger ? <Avatar fullProfileImageUrl={userManager.fullProfileImageUrl} /> : undefined} />
        </DropdownObserver>
      </Menu.Item>
    );
  }
}

SatCoreRegister('ProfileMenu', ProfileMenu);
