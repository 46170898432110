import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Dropdown } from 'semantic-ui-react';

import '../../css/reports/ReportDropdown.less';

import { SatCoreRegister } from '../../SatCoreRegistry';

import { REPORT_FAILURE_BEHAVIOR_TYPE } from '../../services/reports/ReportConstants';

import { getSessionStorageItem } from '../../utils';

import ReportTableService from '../../services/reports/ReportTableService';

const ReportStandardsDropdown = observer((props) => {
  const { t } = props;

  const {
    reportIdentityManager,
    reportOverrideManager,
    reportStandardsManager,
    reportTableManager
  } = useContext(MobXProviderContext);

  /** equivalent to componentDidMount(), i.e. only called after initial render */
  useEffect(() => {
    (async () => {
      reportStandardsManager.setSelectedReportCmapInfo(
        getSessionStorageItem('selectedReportCmapContentItemId'), {
          contentItemId: getSessionStorageItem('selectedReportCmapContentItemId'),
          cutScore: getSessionStorageItem('selectedReportCmapCutScore'),
          id: getSessionStorageItem('selectedReportCmapId'),
          name: getSessionStorageItem('selectedReportCmapName')
        }
      );
    })();
  }, []);

  const handleChangeReportStandardsDropdown = async (_event, data) => {
    if (reportOverrideManager.shouldExpandAllReportTableRowsByDefault) {
      reportTableManager.clearExpandedReportTableRows();
    }

    const { reportCmapObjsByInstitutionMap } = reportStandardsManager;

    const cmapId = data.value;
    const cmapObj = reportCmapObjsByInstitutionMap.get(cmapId);

    reportStandardsManager.setSelectedReportCmapInfo(cmapId, cmapObj);

    if (reportIdentityManager.isFacultyAdminReport) {
      await reportIdentityManager.fetchReportResponseJson({
        curriculumMapId: cmapId,
        // TODO remove // failureBehaviorType: REPORT_FAILURE_BEHAVIOR_TYPE.FALLBACK_AND_FORCE_NO_DATA_STATE
        failureBehaviorType: REPORT_FAILURE_BEHAVIOR_TYPE.NORMAL
      });
    } else {
      ReportTableService.forceRerenderReportTable();
    }
  };

  const renderReportStandardsDropdown = () => {
    const { reportStandardsDropdownOptions, selectedReportCmapContentItemId } = reportStandardsManager;
    const { reportInfoClassNames } = reportIdentityManager;

    let placeholder;
    if (!reportStandardsDropdownOptions.length) {
      placeholder = t('noCmapsToDisplay');
    } else {
      placeholder = t('selectCmap');
    }

    return (
      <div className={`report-dropdown report-standards-dropdown ${reportInfoClassNames}`}>
        <div className='dropdown-label'>
          <div className='text'>
            {t('cmaps')}
          </div>
        </div>
        <Dropdown
          disabled={!reportStandardsDropdownOptions?.length}
          onChange={handleChangeReportStandardsDropdown}
          options={reportStandardsDropdownOptions}
          placeholder={t(placeholder)}
          search
          selection
          value={selectedReportCmapContentItemId} />
      </div>
    );
  };
  return renderReportStandardsDropdown();
});
export default ReportStandardsDropdown;

SatCoreRegister('ReportStandardsDropdown', ReportStandardsDropdown);
