/**
 * List of pathnames where authKey URL parameters bypass withAuth's custom handling.
 *
 * For pathames (routes) NOT in this list:
 * - withAuth will strip the authKey from the URL.
 * - If allowAuthKeyTopNavUrlParamAuthentication is true, withAuth will use the authKey.
 *   for authentication and redirect to signin while preserving the original location.
 *
 * For pathnames (routes) IN this list:
 * - withAuth maintains default routing behavior.
 * - The authKey parameter remains in the URL if present (i.e. withAuth will not strip it out).
 * - No special authentication or redirect logic is applied via withAuth.
 * - The component rendered at that route can optionally handle the authKey parameter.
 *
 * Note: Being in this list does not necessarily mean the route requires or uses an authKey -
 * it just indicates withAuth should not implement its custom authKey handling.
 */
export const WHITELISTED_AUTH_KEY_PATHNAMES = [
  '/signin',
  '/loginOneRoster',
  '/register',
  '/activate',
  '/activation',
  '/demolink',
  '/invitation',
  '/forgot-password',
  '/verification',
  '/publisherCourse',
  '/changePassword',
  '/ltiPlayer',
  '/launch',
  '/lesson-portal',
  '/lrnPrint',
  '/lti-courses',
  '/certificate',
  '/trial'
];

/**
 * Checks if a pathname is in the list of paths where authKey query parameter handling is delegated elsewhere.
 *
 * For these whitelisted paths, the withAuth HOC will not:
 * - Strip the authKey from the URL.
 * - Attempt to use the authKey for authentication.
 * - Redirect to signin with preserved location state.
 *
 * This allows the components rendered at these paths to handle the authKey parameter themselves
 * as needed (e.g., login, registration, LTI launch flows).
 *
 * @param {string} pathname - The pathname to check.
 * @returns {boolean} `true` if the pathname starts with any of the whitelisted paths.
 */
export const isWhitelistedAuthKeyPathname = (pathname) => {
  return WHITELISTED_AUTH_KEY_PATHNAMES.some((whitelistedPathname) => {
    return pathname.startsWith(whitelistedPathname);
  });
};
