/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import classNames from 'classnames';

import { Link, Redirect } from 'react-router-dom';

import {
  Button, Form, Grid, Header,
  Image, Input, Message, Segment
} from 'semantic-ui-react';

import '../css/Login.less';

// import edLink from '../img/edlink-logo.svg';
// import oneRoster from '../img/logo-ims-global.png';
import classLink from '../img/classlink-logo.png';
import clever from '../img/logo-clever-01.svg';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { register } from '../i18n';

import Auth from '../managers/AuthManager';
import navigationManager, { VIEW_SELECTION } from '../managers/NavigationManager';

import DynamicSatelliteService from '../services/DynamicSatelliteService';
import InitService from '../services/InitService';

import { getCssUrlArgument, getStyleVar, removeSessionStorageItem, setSessionStorageItem } from '../utils';

const t = register('Login');

export default
@inject('appManager', 'contentManager', 'dynamicSatelliteManager', 'userManager')
@observer
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allowCheckOnlineStatus: true,
      classLinkUrl: false,
      cleverUrl: false,
      edLinkUrl: false,
      email: '',
      isOnline: true,
      loadedLogin: false,
      loadingLoginSubmit: false,
      loginError: false,
      password: '',
      passwordInputType: 'password'
    };

    this.errorRef = React.createRef();

    this.Footer = SatCoreComponent('Footer');
    this.Logo = SatCoreComponent('Logo');
    this.OfflineMessageBox = SatCoreComponent('OfflineMessageBox');
    this.SatCoreLoader = SatCoreComponent('SatCoreLoader');
    this.ShowPasswordButton = SatCoreComponent('ShowPasswordButton');

    // this.initClever();
    // this.initClassLink();
    // this.initEdLink();
  }

  async componentDidMount() {
    this.setState({ loadedLogin: false });

    const { history, location, userManager } = this.props;
    if (location?.state?.from?.search?.includes?.('authKey')) {
      const fromUrlParams = new URLSearchParams(location.state.from.search);

      const authKey = fromUrlParams.get('authKey');
      const activePermissionId = fromUrlParams.get('activePermissionId') || undefined;

      userManager.setActivePermissionId(activePermissionId);

      const success = await userManager.checkUser(authKey, { activePermissionId });

      if (success) {
        // we do not want certain values (such as authKey) to persist in the urlParams; so here, we remove them
        fromUrlParams.delete('authKey');
        fromUrlParams.delete('activePermissionId');
        history.replace({
          ...location.state.from,
          search: fromUrlParams?.toString?.() || ''
        });
        return;
      }
    }

    removeSessionStorageItem('dynamicPublisherSatelliteCode');

    const { dynamicSatelliteManager } = this.props;

    // TODO remove // navigationManager.setView(VIEW_SELECTION.LOGIN);

    InitService.initActiveUserRoleAsHtmlBodyAttr('login');

    await this.fetchOnlineStatus();

    const THIRTY_SECONDS = 30000;

    const intervalForCheckOnlineStatus = setInterval(async () => {
      await this.fetchOnlineStatus();
    }, THIRTY_SECONDS);
    this.setState({
      intervalForCheckOnlineStatus
    });

    if (dynamicSatelliteManager.isDynamicSatellite) {
      await DynamicSatelliteService.fetchDynamicSatelliteByDomain();
      await this.initSsoUrlsIfApplicable();
      setTimeout(() => {
        navigationManager.setView(VIEW_SELECTION.LOGIN);
        this.setState({ loadedLogin: true });
      }, 500);
    } else {
      await this.initSsoUrlsIfApplicable();
      navigationManager.setView(VIEW_SELECTION.LOGIN);
      this.setState({ loadedLogin: true });
    }

    const urlParams = new URLSearchParams(this.props.location.search);
    if (urlParams.has('notification')) {
      const notificationId = urlParams.get('notification');
      setSessionStorageItem('c2c_notificationId', notificationId);
    }
  }

  componentWillUnmount() {
    const { intervalForCheckOnlineStatus } = this.state;
    clearInterval(intervalForCheckOnlineStatus);
  }

  initSsoUrlsIfApplicable = async () => {
    await this.initClever();
    await this.initClassLink();
    await this.initEdLink();
  }

  fetchOnlineStatus = async () => {
    const { appManager } = this.props;
    const { allowCheckOnlineStatus } = this.state;
    if (allowCheckOnlineStatus) {
      const response = await appManager.fetchOnlineStatus();
      this.setState({
        isOnline: response?.status === 'SUCCESS'
      });
    }
  }

  initClever = async () => {
    const { authProviderButtonsEnabled } = this.props;

    if (authProviderButtonsEnabled) {
      const url = await Auth.getCleverLoginUrl();
      url && this.setState({ cleverUrl: url });
    }
  }

  initClassLink = async () => {
    const { authProviderButtonsEnabled } = this.props;

    if (authProviderButtonsEnabled) {
      const url = await Auth.getClassLinkLoginUrl();
      url && this.setState({ classLinkUrl: url });
    }
  }

  initEdLink = async () => {
    const { authProviderButtonsEnabled } = this.props;

    if (authProviderButtonsEnabled) {
      const url = await Auth.getEdLinkLoginUrl();
      url && this.setState({ edLinkUrl: url });
    }
  }

  handleShowPasswordPressed = (name, isPasswordInput) => {
    const inputType = isPasswordInput ? 'password' : 'text';
    this.setState({ [name]: inputType });
  }

  handleFormClever = async (e) => {
    e.preventDefault();
    try {
      if (this.state.cleverUrl) {
        window.location = this.state.cleverUrl;
      }
    } catch (e) {
      console.error(e);
    }
  }

  handleFormClassLink = async (e) => {
    e.preventDefault();
    try {
      if (this.state.classLinkUrl) {
        window.location = this.state.classLinkUrl;
      }
    } catch (e) {
      console.error(e);
    }
  }

  handleFormEdLink = async (e) => {
    e.preventDefault();
    try {
      if (this.state.edLinkUrl) {
        window.location = this.state.edLinkUrl;
      }
    } catch (e) {
      console.error(e);
    }
  }

  handleClickExternalPurchaseUrlButton = async (e, externalPurchaseUrl) => {
    e.preventDefault();
    window.open(externalPurchaseUrl, '_blank');
  }

  handleFormOneRoster = async (e) => {
    e.preventDefault();
    try {
      if (this.state.oneRosterEnabled) {
        window.location = '/loginOneRoster';
      }
    } catch (e) {
      console.error(e);
    }
  }

  getSingleSignOnButtons = () => {
    const { t } = this.props;
    const { cleverUrl, classLinkUrl, edLinkUrl } = this.state;

    const externalPurchaseUrl = getCssUrlArgument(getStyleVar('--login-external-purchase-url'));

    if (cleverUrl || classLinkUrl || edLinkUrl || externalPurchaseUrl) {
      return (
        <Segment className='sso-button-list'>
          {cleverUrl && (
            <Button className='clever sso-button' onClick={this.handleFormClever}>
              <Image alt='' className='clever-logo' src={clever} />
              <span className='clever-label'>
                {t('buttonSignInSsoCleverLabel')}
              </span>
            </Button>
          )}
          {classLinkUrl && (
            <Button className='classlink sso-button' onClick={this.handleFormClassLink}>
              <Image alt='' className='classlink-logo' src={classLink} />
              <span className='classlink-label'>
                {t('buttonSignInSsoClassLinkLabel')}
              </span>
            </Button>
          )}
          {edLinkUrl && (
            <Button className='edlink sso-button' onClick={this.handleFormEdLink}>
              <span className='edlink-label'>
                {t('buttonSignInSsoEdLinkLabel')}
              </span>
            </Button>
          )}
          {/* NOTE: This is not SSO but lives directly underneath all the SSO buttons in the same segment */}
          {externalPurchaseUrl && (
            <Button className='external-purchase-button sso-button'
              onClick={(event) => {
                this.handleClickExternalPurchaseUrlButton(event, externalPurchaseUrl);
              }}>
              <span className='external-purchase-button-label'>
                {t('buttonExternalPurchaseLabel')}
              </span>
            </Button>
          )}
        </Segment>
      );
    }
    return null;
  }

  getHelpLink = () => {
    const { userManager } = this.props;
    let helpLink = '';
    if (userManager.clientPrefix === 'CARN') {
      helpLink = 'https://www.carnegielearning.com/texas-help/article/lesson-materials-login-help/';
    }
    if (helpLink) {
      return (
        <Segment className='login-help-link'>
          <a href={helpLink} rel='noreferrer' target='_blank'>
            Need help logging in?
          </a>
        </Segment>
      );
    }
  }

  signUp = () => {
    this.props.history.push('/register');
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleFormSubmit = async (e) => {
    e.preventDefault();
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.loadingLoginSubmit) {
      return undefined;
    }
    this.setState({
      loadingLoginSubmit: true,
      loginError: false
    });
    try {
      // eslint-disable-next-line react/destructuring-assignment
      const loggedIn = await Auth.login(this.state.email, this.state.password);
      if (loggedIn) {
        setTimeout(() => {
          this.setState({ loadingLoginSubmit: false });
        }, 1500);
        // eslint-disable-next-line react/destructuring-assignment
        this.props.history.replace('/');
      } else {
        this.setState({
          loginError: true,
        }, () => {
          if (this.errorRef.current) {
            this.errorRef.current.focus();
          }
        });
        setTimeout(() => {
          this.setState({ loadingLoginSubmit: false });
        }, 1500);
      }
    } catch (error) {
      console.error(error);
    }
  }

  renderRegistrationMessage = () => {
    const { props } = this;
    const { contentManager, dynamicSatelliteManager, registrationMessage, shouldUseDefaultRegistrationMessage } = props;

    let { registrationMessageUrl } = props;

    if (!registrationMessageUrl && dynamicSatelliteManager.isDynamicSatellite) {
      // check if we have the url stored as a css variable (and use it if it exists)
      // note we currently only expect this to occur in dynamic satellites.
      registrationMessageUrl = getCssUrlArgument(getStyleVar('--dynamic-login-registration-url'));
    }

    const { resourceIFrameUrl, resourceIFrameUrlStudent } = contentManager;
    if (shouldUseDefaultRegistrationMessage) {
      registrationMessageUrl = registrationMessageUrl || resourceIFrameUrl || resourceIFrameUrlStudent || '';
      return registrationMessageUrl ? (
        <div className='message-wrapper message-wrapper-default'>
          <div className='signin-message'>
            {!!props.iconHelp && <Image alt='' src={props.iconHelp} />}
            <a href={registrationMessageUrl}
              rel='noopener noreferrer'
              target='_blank'>
              {t('helpAndQuestions')}
            </a>
          </div>
        </div>
      ) : null;
    } else {
      return registrationMessage ? <div className='message-wrapper'>{registrationMessage}</div> : null;
    }
  }

  render() {
    const { loginBottomLogo, registerButtonExplanation, showFooter, userManager } = this.props;

    const { loadingLoginSubmit } = this.state;

    const { Footer, Logo, ShowPasswordButton } = this;

    if (Auth.loggedIn()) {
      return <Redirect to='/' />;
    }
    let loginCenterLogoLinkJsx = (
      <div className='login-center-logo' />
    );
    let loginCenterLogoLink = '';
    if (userManager.clientPrefix === 'CARN') {
      loginCenterLogoLink = 'https://carnegielearning.com';
    }
    if (loginCenterLogoLink) {
      loginCenterLogoLinkJsx = (
        <a className='login-center-logo'
          href={loginCenterLogoLink}
          rel='noreferrer'
          target='_blank' />
      );
    }

    // TODO remove
    // // to work around empowered not passing props in override.  Can be removed if we ever update
    // // Empowered and fix the LoginOverride.
    // const username = (t) ? t('username', 'Username/email') : 'Username/email';

    const { OfflineMessageBox, SatCoreLoader } = this;
    const { isOnline, loadedLogin, loginError } = this.state;

    return !loadedLogin ? <SatCoreLoader /> : (
      <div className={classNames('login-container', {
        'login-offline': !isOnline
      })}>
        <div className='login-body-wrapper'>
          <Grid className='login-body' textAlign='center' verticalAlign='middle'>
            <Grid.Column className={classNames('max-width-558 login-body-inner', {
              'login-body-inner-offline': !isOnline
            })}>
              <Form>
                <div className='login-form-top-spacer' />
                {loginCenterLogoLinkJsx}
                {isOnline && (
                  <Header as='h2' attached='top' block className='login-form-header'>
                    <Logo />
                    <span className='header-text login-header-text'>
                      {t('loginHeader')}
                    </span>
                  </Header>
                )}
                {isOnline ? (
                  <Segment attached className='element-body-login'>
                    <Form.Field>
                      <label htmlFor='email'>{t('username')}</label>
                      <Input
                        autoComplete='username'
                        id='email'
                        name='email'
                        onChange={this.handleChange}
                        placeholder={t('emailPlaceholder')}
                        type='email' />
                    </Form.Field>
                    <Form.Field>
                      <label htmlFor='password'>{t('password')}</label>
                      <Input
                        autoComplete='current-password'
                        className='login-password'
                        id='password'
                        label={(
                          <ShowPasswordButton
                            isPassword={(this.state.passwordInputType === 'password')}
                            name='passwordInputType'
                            onFlip={this.handleShowPasswordPressed} />
                        )}
                        labelPosition='right'
                        name='password'
                        onChange={this.handleChange}
                        onKeyPress={(e) => e.stopPropagation()}
                        placeholder='Password'
                        type={this.state.passwordInputType} />
                      <Link className='forgot-link' color='black' to='/forgot-password'>
                        {t('forgotPassword')}
                      </Link>
                    </Form.Field>

                    <Form.Field>
                      <Segment className='button-list'>
                        <div>
                          <Button className='login'
                            loading={loadingLoginSubmit}
                            onClick={this.handleFormSubmit}
                            primary>
                            {t('buttonSignInLabel')}
                          </Button>
                        </div>
                        {registerButtonExplanation}
                        <div>
                          <Button basic className='login sign-up'
                            onClick={this.signUp} primary>
                            {t('buttonSignUpLabel')}
                          </Button>
                        </div>
                      </Segment>
                      <div
                        ref={this.errorRef} // Allows this to be focused when we have a login error so screen reader reads it out
                        aria-live='assertive'
                        style={{ display: loginError ? 'block' : 'none' }}
                        tabIndex={-1}>
                        {loginError && (
                          <div className='mobile-error login-error'>
                            <Message negative>
                              <Message.Header>Invalid username or password</Message.Header>
                              <p>Please try again.</p>
                            </Message>
                          </div>
                        )}
                      </div>
                      {this.getSingleSignOnButtons()}
                      {this.getHelpLink()}
                    </Form.Field>
                    {this.renderRegistrationMessage()}
                  </Segment>
                ) : (
                  <OfflineMessageBox />
                )}
              </Form>
              {!!loginBottomLogo && (
                <div className='login-bottom-logo-wrapper'>
                  <Image alt='' className='login-bottom-logo' src={loginBottomLogo} />
                </div>
              )}
            </Grid.Column>
          </Grid>
        </div>
        {showFooter && <Footer />}
      </div>
    );
  }
}

Login.defaultProps = {
  authProviderButtonsEnabled: true
};

SatCoreRegister('Login', Login);
