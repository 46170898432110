import { action, makeObservable, observable } from 'mobx';

export class BandwidthManager {
  @observable currentBitRate = 0;

  constructor() {
    makeObservable(this);
  }

  @action setCurrentBitRate = (value) => {
    this.currentBitRate = value;
  }

  @action measureAndStoreBitrate() {
    this.setCurrentBitRate(0);

    if (navigator.connection) {
      const { downlink } = navigator.connection;
      this.setCurrentBitRate(downlink);

      navigator.connection.addEventListener('change', () => {
        const { downlink: updatedDownlink } = navigator.connection;
        this.setCurrentBitRate(updatedDownlink);
        // console.log(`From event: ${updatedDownlink}`);
      });

      // console.log(`From initial set using navigator: ${downlink}`);

      return downlink;
    } else {
      this.fetchTestFile();
    }
  }

  @action async fetchTestFile() {
    const startTime = performance.now();
    const cacheBuster = `?cb=${new Date().getTime()}`;
    // const testFileUrl = `https://assets.content2classroom.com/random1MB_dnld.txt${cacheBuster}`;
    const testFileUrl = `https://c2c-satellite-assets.s3.us-gov-west-1.amazonaws.com/random1MB_dnld.txt${cacheBuster}`;

    try {
      const response = await fetch(testFileUrl, {
        method: 'GET',
        cache: 'no-store',
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate, proxy-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0',
          // Add a random header value
          'X-Requested-With': cacheBuster
        },
        credentials: 'same-origin'
      });
      const blob = await response.blob();
      const actualFileSize = blob.size; // Get actual file size in bytes

      const endTime = performance.now();
      const durationInSeconds = (endTime - startTime) / 1000;
      const bitrateInMbps = (actualFileSize * 8) / (durationInSeconds * 1000000);

      this.setCurrentBitRate(bitrateInMbps);
      // console.log(`From file fetch : ${bitrateInMbps}`);
      return bitrateInMbps;
    } catch (error) {
      console.error('Error measuring bitrate:', error);
      this.setCurrentBitRate(0);
      return 0;
    }
  }
}

export default new BandwidthManager();
