/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import classNames from 'classnames';

import { Container, Loader } from 'semantic-ui-react';

import '../css/Gradebook.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { GRADEBOOK_CONTEXT, GRADEBOOK_TYPE, OBSERVATIONAL_GRADER_TOGGLE_STATUS } from '../constants';

import { CONTENT_ITEM_TYPES } from '../managers/ContentManager';
import { DIALOG_NAMES } from '../managers/DialogManager';
import { VIEW_SELECTION } from '../managers/NavigationManager';

import AssignmentService from '../services/AssignmentService';
import EngagementService from '../services/EngagementService';
import GradebookDetailsService from '../services/gradebook/GradebookDetailsService';
import GradebookMainService from '../services/gradebook/GradebookMainService';
import GradebookSummaryService from '../services/gradebook/GradebookSummaryService';
import ImageService from '../services/ImageService';
import ReportBreadcrumbService from '../services/reports/ReportBreadcrumbService';
import ResourcePacingService from '../services/ResourcePacingService';

export default
@inject(
  'assignmentManager', 'classroomManager', 'contentManager',
  'courseManager', 'dialogManager', 'engagementManager',
  'exportManager', 'gradebookManager', 'navigationManager',
  'productManager', 'userManager')
@observer
class Gradebook extends Component {
  constructor(props) {
    super(props);
    // TODO remove, unused // this.GradebookCard = SatCoreComponent('GradebookCard');
    // TODO remove, unused // this.GradebookDataBar = SatCoreComponent('GradebookDataBar');
    this.BreadCrumbs = SatCoreComponent('BreadCrumbs');
    this.FileViewerModal = SatCoreComponent('FileViewerModal');
    this.FullscreenModal = SatCoreComponent('FullscreenModal');
    this.GradebookHeader = SatCoreComponent('GradebookHeader');
    this.GradebookTable = SatCoreComponent('GradebookTable');
    this.state = {
      loading: true,
      showAnswers: false
    };
  }

  async componentDidMount() {
    const { classroomManager, engagementManager, gradebookManager, navigationManager, t } = this.props;
    const urlParams = new URLSearchParams(window.location.search);

    gradebookManager.setForceRefreshAllGradebookData(false);

    const activeGradebookType = gradebookManager.activeGradebookTypeOnGradebookComponentMount || GRADEBOOK_TYPE.AGGREGATE;

    let activeGradebookTable = gradebookManager.activeGradebookTableOnGradebookComponentMount || gradebookManager.defaultGradebookTable;

    if (activeGradebookTable === GRADEBOOK_CONTEXT.DETAILS || activeGradebookTable === GRADEBOOK_CONTEXT.LIKERT) {
      // we need to ensure 'details' view is allowed for the current assignment
      const assignment = await this.getGradebookCurrentAssignment();

      const entityTypeId = assignment.contentItemEntityTypeId || assignment.entityTypeId;

      const isAssessment = entityTypeId === 'assessment';
      const isLesson = entityTypeId === 'lesson';
      const isLearnosity = entityTypeId?.includes?.('learnosity');

      const { allowLearnosityGradebookDetail } = gradebookManager;

      const allowGradebookDetails = isAssessment || isLesson || (isLearnosity && allowLearnosityGradebookDetail);

      if (!allowGradebookDetails) {
        // gradebook 'details' view is not allowed, so here we set activeGradebookTable to 'summary' instead
        activeGradebookTable = GRADEBOOK_CONTEXT.SUMMARY;
      }
    }

    if (activeGradebookTable === GRADEBOOK_CONTEXT.ENGAGEMENT) {
      engagementManager.clearEngagementTable();
      await this.refreshAllGradebookEngagementData();
    }

    gradebookManager.setActiveGradebookType(activeGradebookType);
    gradebookManager.setActiveGradebookTable(activeGradebookTable);

    gradebookManager.setActiveGradebookTypeOnGradebookComponentMount(null);
    gradebookManager.setActiveGradebookTableOnGradebookComponentMount(null);

    gradebookManager.setActiveGradebookDetailsHorizontalScoresPage(1);

    gradebookManager.setObservationalGraderToggleStatus(OBSERVATIONAL_GRADER_TOGGLE_STATUS.INACTIVE);

    navigationManager.setView(VIEW_SELECTION.GRADEBOOK);

    if (navigationManager.pathList) {
      const reportId = urlParams.get('reportId');

      const currentRouterUrl = `${window.location.pathname}${window.location.search}`;

      if (reportId) {
        await ReportBreadcrumbService.initBreadcrumbs();
        await navigationManager.setBreadcrumb({
          path: {
            name: t('allAssignments'),
            routerUrl: currentRouterUrl
          }
        });
      } else {
        const pathName = navigationManager.pathList?.[0]?.name;
        const fromAssignments = urlParams.get('fromAssignments') === 'true';

        if (pathName === t('classrooms') || pathName === t('assignmentsLabel')) {
          if (pathName === t('classrooms') && navigationManager.pathList.length === 1 && fromAssignments) {
            // breadcrumb state was lost; reinitialize and assume 'Assignments' is the root
            navigationManager.clearAllPaths(true);
            await navigationManager.setBreadcrumb({
              path: {
                name: t('assignmentsLabel'),
                routerUrl: `/assignments?view=${VIEW_SELECTION.ASSIGNMENTS}`
              }
            });
          }
          await navigationManager.setBreadcrumb({
            fromView: VIEW_SELECTION.GRADEBOOK,
            path: {
              name: t('gradebookLabel', 'Gradebook'),
              routerUrl: currentRouterUrl
            }
          });
        }
      }
    }

    const classroomId = urlParams.get('classroomId');

    if (classroomId) {
      await classroomManager.fetchClassroomRoster(classroomId);
    }
    await this.refreshAllGradebookData();

    this.setState({ loading: false, currentClassroomId: classroomId });
  }

  async componentDidUpdate() {
    const { gradebookManager } = this.props;
    if (gradebookManager.forceRefreshAllGradebookData) {
      gradebookManager.setForceRefreshAllGradebookData(false);
      await this.refreshAllGradebookData();
    }
  }

  componentWillUnmount() {
    const { productManager } = this.props;
    clearInterval(this.timer);
    this.timer = null;
    productManager.setShouldShowExpiredLicenseWarning(false);
  }

  // re-fetch the count of activities being created until there are none
  poll = async () => {
    const { gradebookManager } = this.props;

    const prevCount = gradebookManager.assignmentsProcessingCount;

    const assignment = await this.getGradebookCurrentAssignment();
    await gradebookManager.fetchAssignmentsProcessingCount(assignment);

    const newCount = gradebookManager.assignmentsProcessingCount;

    if (gradebookManager.assignmentsProcessingCount === 0) {
      clearInterval(this.timer);
      this.timer = null;
    }

    if (prevCount !== newCount) {
      this.refreshAllGradebookData();
    }
  }

  refreshAllGradebookData = async (page = null) => {
    const FIRST_PAGE = 0;
    page = page >= 0 ? page : FIRST_PAGE;
    this.setState({ loading: true });

    const { gradebookManager, history } = this.props;

    const {
      activeGradebookTable,
      isGradebookDetails,
      isGradebookLikert,
      isGradebookStandards,
      isGradebookSummary,
      isGradebookTyping
    } = gradebookManager;

    gradebookManager.setGradebookManagerLoadingFlagWhileHidingViewActive(true);

    gradebookManager.setGradebookTableRowsTotalFromBackend(null);
    gradebookManager.setHasMoreAggregateTableRows(null);

    gradebookManager.setGradebookDetails(null);

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('assignmentId')) {
      const assignment = await this.getGradebookCurrentAssignment();

      if (!assignment) {
        console.error('Gradebook.refreshAllGradebookData: assignment not found');
        // eslint-disable-next-line no-alert
        alert('An error occurred while attempting to fetch data. Please try again.');
        history.push('/');
        return;
      }

      const sortObj = gradebookManager.gradebookTableSortMap.get(activeGradebookTable) || {};

      const sortColumn = sortObj.sortColumn || null;
      const sortDirection = sortObj.sortDirection || 'ascending';

      /* REFRESH GRADEBOOK TABLE, WHICHEVER IS ACTIVE */
      const pageSize = undefined, clearFirst = true;
      if (isGradebookSummary) {
        gradebookManager.setAllowLoadMoreAggregateTableRows(false);

        if (GradebookSummaryService.isSpecialAggregateGradebookSummarySortCase(sortColumn)) {
          gradebookManager.reverseGradebookTableSortDirection(sortDirection);
          await GradebookSummaryService.handleSpecialAggregateGradebookSummarySortCase(sortColumn);
        } else {
          const functions = {
            isSpecialAggregateGradebookSummarySortCase: GradebookSummaryService.isSpecialAggregateGradebookSummarySortCase
          };
          await gradebookManager.fetchAggregateGradebookData(
            assignment, sortColumn, sortDirection, page,
            pageSize, clearFirst, functions
          );
        }

        await gradebookManager.fetchAssignmentsProcessingCount(assignment);
        if (gradebookManager.assignmentsProcessingCount > 0 && !this.timer) {
          this.timer = setInterval(() => this.poll(), 20000);
        }

        gradebookManager.setAllowLoadMoreAggregateTableRows(true);
      } else if (isGradebookDetails || isGradebookLikert || isGradebookTyping) {
        gradebookManager.clearAssignmentInstances();
        gradebookManager.clearGradebookDetails();

        if (GradebookDetailsService.isSpecialAggregateGradebookDetailsSortCase(sortColumn)) {
          gradebookManager.reverseGradebookTableSortDirection(sortDirection);
          await GradebookDetailsService.handleSpecialAggregateGradebookDetailsSortCase(sortColumn);
        } else {
          const pageSize = undefined;
          const clearStudentsFirst = true;
          const clearAllFirst = true;
          const functions = {
            isSpecialAggregateGradebookDetailsSortCase: GradebookDetailsService.isSpecialAggregateGradebookDetailsSortCase
          };
          await gradebookManager.fetchAggregateGradebookDetails({
            assignment,
            clearAllFirst,
            clearStudentsFirst,
            functions,
            page,
            pageSize,
            sortDirection,
            sortField: sortColumn
          });
        }
      } else if (isGradebookStandards) {
        await gradebookManager.fetchAggregateGradebookStandards(assignment);
      }
      this.refreshGradebookState(assignment);
      gradebookManager.setGradebookManagerLoadingFlagWhileHidingViewActive(false);
    }
  }

  getGradebookCurrentAssignment = async () => {
    const { assignmentManager, gradebookManager } = this.props;

    const urlParams = new URLSearchParams(window.location.search);
    const assignmentId = urlParams.get('assignmentId');

    if (!gradebookManager.currentAssignment || (gradebookManager.currentAssignment.id !== assignmentId)) {
      const assignment = await gradebookManager.fetchGradebookData(assignmentId, {}, true);
      return assignment;
    }
    let assignment = gradebookManager.currentAssignment;
    if (!assignment) {
      assignment = assignmentManager.fetchActivity(assignmentId);
    }
    return assignment;
  }

  fetchCourseActivityInformation = async (assignment) => {
    const { assignmentManager } = this.props;
    const contentImageUrl = ImageService.getImageUrl(assignment);

    const result = await assignmentManager.fetchCourseActivityInformation(assignment.id);
    if (result) {
      const title = result.webTitle ? result.webTitle : result.contentName;
      this.handleOpenInfo(
        title,
        result.contentDescription,
        result.resourceInformation, contentImageUrl,
        null // don't show assignment instruction since the gradebook represents multiple assignments.
      );
    }
  }

  handleOpenInfo = (
    infoName,
    contentItemDescription,
    resourceInfo,
    contentItemImageUrl,
    instruction
  ) => {
    const { dialogManager } = this.props;

    dialogManager.setOpenDialog(DIALOG_NAMES.INFO, {
      contentItemDescription,
      contentItemImageUrl,
      infoName,
      resourceInfo,
      studentInstruction: instruction
    }, () => dialogManager.closeDialog(DIALOG_NAMES.INFO));
  }

  handleAnswerKey = async (assignment) => {
    const { contentManager, userManager } = this.props;
    const { currentClassroomId } = this.state;
    const option = await contentManager.getOptionsForAnswerKey(
      assignment.attachmentContentItemId, CONTENT_ITEM_TYPES.PDF_RESOURCE,
      currentClassroomId, origin, userManager.isTeacher, userManager.userId
    );
    const { playerType, viewUrl } = option;

    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      return;
    }
    await contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);
  }

  handleExportGradebook = async () => {
    const { dialogManager, gradebookManager, exportManager } = this.props;

    const assignmentId = gradebookManager.currentAssignmentId;
    const { currentAssignment } = gradebookManager;

    const { allowExportFullGradebookStandards } = exportManager;
    const { activeGradebookTable, allowGradebookStandards } = gradebookManager;

    if (allowGradebookStandards && allowExportFullGradebookStandards && activeGradebookTable !== 'standards') {
      // we need to determine if we should show 'Standards Export' radio option for ExportGradesModal
      // ---
      // ensure we have latest gradebook standards data before opening ExportGradesModal
      this.setState({ loading: true });
      await gradebookManager.fetchAggregateGradebookStandards(currentAssignment);
      this.setState({ loading: false });
    }

    dialogManager.setOpenDialog(DIALOG_NAMES.EXPORT_ASSIGNMENT, {
      ...this.props,
      assignment: currentAssignment,
      assignmentId,
      isFromGradebook: true
    }, this.closeExportAssignment);
  }

  closeExportAssignment = () => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.EXPORT_ASSIGNMENT);
  }

  handleGotoCourse = () => {
    const { courseManager, history, gradebookManager, navigationManager } = this.props;
    const { currentAssignment } = gradebookManager;
    const { classroomId, courseContentItemId, courseResourceParentElementId } = currentAssignment;
    courseManager.setAllowFetchCourseData(true); // in case we were coming from reports and this wasn't reset
    courseManager.setAllowCourseTreeLeafFooter(false); // in case we were coming from reports and this wasn't reset
    navigationManager.navigateToCourseContent(classroomId, courseContentItemId, courseResourceParentElementId, history, false);
  }

  // TODO remove, unused (the gradebook is **always** aggregate now)
  // handleClickAggregateGradebookButton = async () => {
  //   const { gradebookManager, navigationManager, t } = this.props;
  //   gradebookManager.clearAssignmentInstances();
  //   if (gradebookManager.activeGradebookTable === 'details') {
  //     gradebookManager.setGradebookManagerLoadingFlagWhileHidingViewActive(true);
  //   }
  //   let paramStr = window.location.search;
  //   paramStr = paramStr || '';
  //   const routerUrl = `/gradebook${paramStr}`;
  //   await navigationManager.setBreadcrumb({
  //     fromView: VIEW_SELECTION.GRADEBOOK,
  //     path: {
  //       name: t('aggregateGradebook', 'All Assignments'),
  //       routerUrl
  //     }
  //   });
  //   await this.refreshAllGradebookData();
  // }

  handleEngagementButtonClick = async () => {
    const { gradebookManager } = this.props;

    // gradebookManager.clearAssignmentInstances();

    // if (gradebookManager.activeGradebookTable === 'details') {
    gradebookManager.setGradebookManagerLoadingFlagWhileHidingViewActive(true);
    // }
    // TODO remove, unused
    // let paramStr = window.location.search;
    // paramStr = paramStr || '';
    // const routerUrl = `/gradebook${paramStr}`;
    // await navigationManager.setBreadcrumb({
    //   fromView: VIEW_SELECTION.GRADEBOOK,
    //   path: {
    //     name: t('aggregateGradebook', 'All Assignments'),
    //     routerUrl
    //   }
    // });
    await this.refreshAllGradebookEngagementData();
  }

  refreshAllGradebookEngagementData = async (page = null) => {
    const FIRST_PAGE = 0;
    page = page >= 0 ? page : FIRST_PAGE;
    this.setState({ loading: true });

    const { gradebookManager, history } = this.props;

    gradebookManager.setGradebookManagerLoadingFlagWhileHidingViewActive(true);

    gradebookManager.setGradebookTableRowsTotalFromBackend(null);
    gradebookManager.setHasMoreAggregateTableRows(null);

    gradebookManager.setGradebookDetails(null);

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('assignmentId')) {
      const assignment = await this.getGradebookCurrentAssignment();

      if (!assignment) {
        console.error('Gradebook.refreshAllGradebookData: assignment not found');
        // eslint-disable-next-line no-alert
        alert('An error occurred while attempting to fetch data. Please try again.');
        history.push('/');
        return;
      }

      gradebookManager.setAllowLoadMoreAggregateTableRows(false);
      await EngagementService.fetchAggregateGradebookEngagement(assignment);

      this.refreshGradebookState(assignment);
      gradebookManager.setAllowLoadMoreAggregateTableRows(true);
      gradebookManager.setGradebookManagerLoadingFlagWhileHidingViewActive(false);
    }
  }

  handleOpenAddAssignmentModal = async (data, isReassign = false) => {
    const { assignmentManager, classroomManager, contentManager, courseManager, dialogManager, gradebookManager } = this.props;
    this.setState({ loading: true });
    // data is either an activityInstance or assignment, so figure out which one.
    // the assignment passed in for bulk checked may not be one related to checked rows
    // so we are only using it for getting the content ids and defaults for the assign modal.
    const activityId = (data.activityId) ? data.activityId : data.id;
    const assignment = await assignmentManager.fetchActivity(activityId);
    let courseResourceElement = null;

    if (assignment) {
      // eslint-disable-next-line max-len, no-nested-ternary
      const classroomId = (assignment.classroomId) ? assignment.classroomId : (assignment.domainTypeId === 'classroom') ? assignment.domainId : null;
      classroomManager.setCurrentClassroomId(classroomId);
      courseResourceElement = await courseManager.fetchCourseResourceElement(assignment.courseResourceElementId);
    }
    const contentImageUrl = ImageService.getImageUrl(assignment);
    this.setState({ loading: false });
    if (assignment && courseResourceElement) {
      const contentItemEntityId = courseResourceElement.entityId;
      const contentItemEntityTypeId = assignment.entityTypeId || courseResourceElement.type || courseResourceElement.entityTypeId;
      const contentItemEntitySubTypeId = assignment.contentItemEntitySubTypeId || assignment.entitySubTypeId;
      let learnosityActivityResourceId = null;

      // If this is a learnosity activity get proper content item entityId to use as learnosityActivityResourceId, first check cache
      if (contentItemEntityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE) {
        // get the learnosity activity resource id to use as contentItemEntityIds, first check cache
        let learnosityActivityResourceContentItem = contentManager.getContentItem(courseResourceElement.entityId);
        if (!learnosityActivityResourceContentItem) {
          await contentManager.fetchContentItem(courseResourceElement.entityId);
          learnosityActivityResourceContentItem = contentManager.getContentItem(courseResourceElement.entityId);
        }
        if (learnosityActivityResourceContentItem
          && learnosityActivityResourceContentItem.entityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE) {
          learnosityActivityResourceId = learnosityActivityResourceContentItem.entityId;
        }
      }

      dialogManager.setOpenDialog(DIALOG_NAMES.ADD_ASSIGNMENT, {
        ...this.props,
        alternateModeId: courseResourceElement.alternateModeIdOverride || courseResourceElement.alternateModeId,
        contentDueDate: ResourcePacingService.dueDate(courseResourceElement),
        contentDuration: ResourcePacingService.duration(courseResourceElement),
        contentImageUrl,
        contentItemEntityId,
        contentItemEntityTypeId,
        contentItemEntitySubTypeId,
        contentItemId: courseResourceElement.entityId || assignment.contentItemId,
        contentName: courseResourceElement.name || courseResourceElement.name || assignment.name,
        contentSubname: courseResourceElement.description,
        contentTimeframeStartDate: courseResourceElement.timeframeStartDate,
        contentTimeframeEndDate: courseResourceElement.timeframeEndDate,
        timeframeStartDateStr: courseResourceElement.timeframeStartDateStr,
        timeframeEndDateStr: courseResourceElement.timeframeEndDateStr,
        timeframeStartTimeStr: courseResourceElement.timeframeStartTimeStr,
        timeframeEndTimeStr: courseResourceElement.timeframeEndTimeStr,
        courseContentItemId: assignment.courseContentItemId,
        courseElementToggleDefaults: {
          includeInReports: courseResourceElement.includeInReportsOverride || courseResourceElement.includeInReports,
          scoresReleased: courseResourceElement.scoresReleasedOverride || courseResourceElement.scoresReleased,
          studentInstruction: courseResourceElement.instructionOverride || courseResourceElement.instruction,
          studentReview: courseResourceElement.studentReviewOverride || courseResourceElement.studentReview
        },
        courseResourceElementId: courseResourceElement.id || assignment.courseResourceElementId,
        hideAllClassesAssignType: true,
        learnosityActivityResourceId,
        modeOverrideAllowed: courseResourceElement.modeOverrideAllowed,
        resourceMode: courseResourceElement.resourceMode,
        standards: courseResourceElement.standardsInfo
      }, () => {
        dialogManager.closeDialog(DIALOG_NAMES.ADD_ASSIGNMENT);
        gradebookManager.setGradebookManagerLoadingFlagWhileHidingViewActive(true);
        gradebookManager.clearBulkCheckedAssignmentMembers();
        this.setState({ loading: true });
        this.refreshAllGradebookData().then(() => {
          this.setState({ loading: false });
          gradebookManager.setGradebookManagerLoadingFlagWhileHidingViewActive(false);
        });
      });
    }
  }

  handleOpenEditBulkAssignmentModal = async (instanceInfo) => {
    const { assignmentManager, dialogManager, gradebookManager, history, courseManager } = this.props;
    const { currentClassroomId } = this.state;

    this.setState({ loading: true });
    AssignmentService.setBoundaryStartEndDates();

    const activityId = gradebookManager.bulkActivityIds?.[0] || instanceInfo.activityId;
    const assignment = await assignmentManager.fetchActivity(activityId);

    const courseElement = await courseManager.fetchCourseResourceElement(assignment.courseResourceElementId);
    const contentImageUrl = ImageService.getImageUrl(assignment);
    this.setState({ loading: false });

    dialogManager.setOpenDialog(DIALOG_NAMES.EDIT_BULK_ASSIGNMENT, {
      activityNumber: instanceInfo.activityNumber,
      assignmentId: assignment.id,
      bulkActivityIds: gradebookManager.bulkActivityIds,
      bulkActivityNumbers: gradebookManager.bulkActivityNumbers,
      classroomId: currentClassroomId,
      contentImageUrl,
      courseElement,
      contentTimeframeStartDate: courseElement.timeframeStartDate,
      contentTimeframeEndDate: courseElement.timeframeEndDate,
      timeframeStartDateStr: courseElement.timeframeStartDateStr,
      timeframeEndDateStr: courseElement.timeframeEndDateStr,
      timeframeStartTimeStr: courseElement.timeframeStartTimeStr,
      timeframeEndTimeStr: courseElement.timeframeEndTimeStr,
      history
    }, () => {
      dialogManager.closeDialog(DIALOG_NAMES.EDIT_BULK_ASSIGNMENT);
      gradebookManager.setGradebookManagerLoadingFlagWhileHidingViewActive(true);
      this.setState({ loading: true });
      this.refreshAllGradebookData().then(() => {
        this.setState({ loading: false });
        gradebookManager.setGradebookManagerLoadingFlagWhileHidingViewActive(false);
      });
    });
  }

  hideIframe = () => {
    this.setState({ showAnswers: false });
  }

  refreshGradebookState(assignment) {
    this.setState({
      loading: false,
    });
  }

  renderGradebook = () => {
    const { loading } = this.state;
    const { gradebookManager, history, userManager } = this.props;

    const {
      activeGradebookTable, activeGradebookType, currentAssignment, currentAssignmentId
    } = gradebookManager;

    const isReadOnly = GradebookMainService.isGradebookReadOnly();

    const {
      BreadCrumbs, FullscreenModal, GradebookHeader, GradebookTable
    } = this;

    const { gradebookManagerLoadingFlagWhileHidingViewActive, forceRefreshAllGradebookData } = gradebookManager;

    return (
      <Container className={`gradebook-root-container grade-book-view ${activeGradebookType} ${activeGradebookTable}`} fluid>
        <div className={classNames(`gradebook-container ${activeGradebookType} ${activeGradebookTable}`, {
          'is-read-only': isReadOnly
        })}>
          <div className='gradebook-breadcrumbs-wrapper'>
            {(!loading && !gradebookManagerLoadingFlagWhileHidingViewActive && !forceRefreshAllGradebookData)
              && <BreadCrumbs history={history} />}
          </div>
          <div className={`gradebook-inner-container ${activeGradebookType} ${activeGradebookTable}`}>
            {(loading || gradebookManagerLoadingFlagWhileHidingViewActive || forceRefreshAllGradebookData)
              ? <Loader active /> : currentAssignmentId && (
                <div className='grade-book-layout'>
                  <div className='right-col'>
                    <GradebookHeader
                      {...this.props}
                      // TODO remove, unused // onClickAggregateGradebookButton={this.handleClickAggregateGradebookButton}
                      assignment={currentAssignment}
                      assignmentEntityTypeId={currentAssignment.contentItemEntityTypeId || currentAssignment.entityTypeId}
                      onClickAssignButton={this.handleOpenAddAssignmentModal}
                      onClickEditBulkAssignmentButton={this.handleOpenEditBulkAssignmentModal}
                      onClickExportGradebook={this.handleExportGradebook}
                      onClickGotoCourse={this.handleGotoCourse}
                      onClickGradebookContextDetailsButton={this.refreshAllGradebookData}
                      onClickGradebookContextEngagementButton={this.handleEngagementButtonClick}
                      onClickGradebookContextLikertButton={this.refreshAllGradebookData}
                      onClickGradebookContextStandardsButton={this.refreshAllGradebookData}
                      onClickGradebookContextSummaryButton={this.refreshAllGradebookData}
                      onClickGradebookContextTypingButton={this.refreshAllGradebookData}
                      onClickInfoIconButton={() => this.fetchCourseActivityInformation(currentAssignment)} />
                    <GradebookTable
                      {...this.props}
                      activeGradebookTable={activeGradebookTable}
                      assignmentEntityTypeId={currentAssignment.contentItemEntityTypeId || currentAssignment.entityTypeId}
                      handleOpenEditBulkAssignmentModal={this.handleOpenEditBulkAssignmentModal}
                      onClickAssignButton={this.handleOpenAddAssignmentModal}
                      refreshAllGradebookData={this.refreshAllGradebookData} />
                  </div>
                </div>
              )}
          </div>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {this.state.showAnswers && gradebookManager.graderUrl ? (
            <FullscreenModal
              closeIframeCallback={this.hideIframe}
              contentName='Answers'
              isTeacher={userManager.isTeacher}
              page='lesson-player'
              // TODO: put here correct contentName for correct iframe title
              url={gradebookManager.graderUrl} />
          ) : null}
        </div>
      </Container>
    );
  }

  render() {
    return (
      <>
        {this.renderGradebook()}
      </>
    );
  }
}

SatCoreRegister('Gradebook', Gradebook);
