import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import classNames from 'classnames';

import { kebabCase } from 'lodash';

import { Container, Form, Label, Loader, Pagination, Popup } from 'semantic-ui-react';

import '../css/AssessmentItemShopPanel.less';
import Auth from '../managers/AuthManager';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import CustomCourseResourceService from '../services/CustomCourseResourceService';
import CourseService from '../services/CourseService';

import AssessmentItemCard from './AssessmentItemCard';

/** AssessmentItemShopModal aka resourceItemShopModal is shared among two features: 'COURSE SEARCH' and 'LEARNOSITY CUSTOM COURSES' */
export default
@inject(
  'assessmentManager',
  'classroomManager',
  'courseManager'
)
@observer
class AssessmentItemShopPanel extends Component {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    // props specifying what this modal is being used for
    assessmentItemsSearch: false,
    courseResourcesSearch: true,
  };

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.CautionBanner = SatCoreComponent('CautionBanner');
    this.CourseLayout = SatCoreComponent('CourseLayout');
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
  }

  getInitialState() {
    return {
      activePage: 1,
      customAssessmentName: '',
      customAssessmentSubtitle: '',
      disableAdd: false,
      isCustomAssessmentModalErrorMsgVisible: false,
      serverError: false,
      serverErrorMsg: ''
    };
  }

  handlePageChange = async (_event, pageInfo) => {
    const { courseManager } = this.props;
    const { activePage } = pageInfo;

    this.setState({ activePage });
    courseManager.setActivePageRelated(activePage);
    await CourseService.searchCourseResourceElements(true);
  }

  handleFetchCourseResources= async (searchCondition = null, searchTerms = null, searchTags = null, searchStandards = null, page) => {
    await CustomCourseResourceService.fetchCourseResourcesList(searchCondition, searchTerms, searchTags, searchStandards, page);
  }

  renderAssessmentItemModalContent = () => {
    return (
      <div className={classNames('assessment-item-search-modal-content-container', 'course-search-container')}>
        <div className='assessment-item-search-modal-content-rest scrolling content'>
          <div className='assessment-item-search-modal-content-mid'>
            {/* placeholder */}
          </div>
          <div className='assessment-item-search-modal-content-right'>
            {this.renderAssessmentItemList()}
          </div>
        </div>
      </div>
    );
  }

  renderAssessmentItemList = () => {
    const { CourseLayout } = this;
    const { courseManager, history } = this.props;

    const activePage = courseManager.activePageRelated;

    const { assessmentManager, t } = this.props;

    const { length } = courseManager.relatedContentElementList;

    const { totalPagesRelated } = courseManager;
    const { totalItemsRelated } = courseManager;
    const itemCount = courseManager.relatedContentElementList.length;

    let courseSearchResultsCounts = '';
    courseSearchResultsCounts = `Showing ${itemCount} of ${totalItemsRelated} results`;

    const paginationJsx = (
      <Pagination
        activePage={activePage}
        onPageChange={this.handlePageChange}
        totalPages={totalPagesRelated} />
    );

    let headerContentJsx = <></>;
    if (length > 0) {
      headerContentJsx = paginationJsx;
    } else if (!courseManager.isLoading && courseManager.relatedContentElementList.length === 0) {
      headerContentJsx = t('noResultsZeroStateMsg');
    } else if (assessmentManager.assessmentItemsLoading || courseManager.isLoading) {
      headerContentJsx = (
        <Loader active className='modal-loader modal-loader-small-font' />
      );
    } else if (assessmentManager.originalAssessmentItems.size > 0) {
      headerContentJsx = t('noResultsZeroStateMsg');
    }
    return (
      <Container className='assessment-item-list-container' fluid>
        <div className={classNames('modal-content-header', {
          'assessment-item-list-header-sticky': !assessmentManager.assessmentItemsLoading && !courseManager.isLoading
        })}>
          {(assessmentManager.assessmentItemsLoading || courseManager.isLoading) ?
            <div className='results-counts'>Loading ...</div> :
            <div className='results-counts'>{courseSearchResultsCounts}</div>}
          <div className='modal-content-header-pagination'>
            {headerContentJsx}
          </div>
        </div>
        {(courseManager.relatedContentElementList.length > 0) && (
          <Container className='class-content resources-container class-details-inner-container' fluid>
            <CourseLayout
              courseElementList={courseManager.relatedContentElementList}
              forRelatedContent={true}
              fromSatelliteTab={true}
              history={history}
              isCourseSearch={true}
              showBanners={true}
              showTags={true} />
          </Container>
        )}
        {/* TODO remove, header pagination is now positioned as sticky for this modal */}
        {/* TODO so there is no longer a need for footer pagination here */}
        {/* <div className='modal-content-footer'>
          {!!length && length >= 5 && (
          <div className='modal-content-footer-pagination'>
            {paginationJsx}
          </div>
          )}
        </div> */}
      </Container>
    );
  }

  render() {
    const { t } = this.props;

    return (
      <div className='panelWrapper related-resources'>
        <h2 className='assessment-panel-title'>{t('relatedResources')}</h2>
        {this.renderAssessmentItemModalContent()}
      </div>
    );
  }
}

SatCoreRegister('AssessmentItemShopPanel', AssessmentItemShopPanel);
