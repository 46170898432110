/* eslint-disable no-nested-ternary */
/* eslint-disable no-prototype-builtins */
/* eslint-disable max-len */
/* eslint-disable sort-keys */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Button, Container, Form, Header, Image,
  Input, Loader, Message, Popup, Radio
} from 'semantic-ui-react';

import ReactModalDraggable from 'react-modal-resizable-draggable';

import { DateInput, TimeInput } from 'semantic-ui-calendar-react';

import '../../css/EditBulkAssignmentModal.less';
import assessmentDefaultCardImage from '../../img/default-assessment.svg';
import info from '../../img/group-1583.svg';

import { customAssessmentDefaultCardImageUri } from '../../services/UriService';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { ASSIGNMENT_STATUS, ASSIGNMENT_TYPE } from '../../managers/AssignmentManager';
import { CONTENT_ITEM_TYPES, CONTENT_ITEM_SUB_TYPES } from '../../managers/ContentManager';
import { VIEW_SELECTION } from '../../managers/NavigationManager';

import AssignmentService from '../../services/AssignmentService';

import ActivityModeDropdown from '../ActivityModeDropdown';
import RosterService from '../../services/RosterService';

import RichTextEditor from '../RichTextEditor';

export default
@inject(
  'accommodationsManager', 'assignmentManager', 'classroomManager',
  'contentManager', 'courseManager', 'gradebookManager', 'navigationManager'
)
@observer
class EditBulkAssignmentModal extends Component {
  constructor(props) {
    super(props);

    const { t } = this.props;

    this.state = this.getInitialState();
    this.statusDescriptionList = [];
    this.statusDescriptionList[ASSIGNMENT_STATUS.LOCKED] = t('lockedDescription');
    this.statusDescriptionList[ASSIGNMENT_STATUS.READY] = t('readyDescription');
    this.statusDescriptionList[ASSIGNMENT_STATUS.STARTED] = t('startedDescription');
    this.statusDescriptionList[ASSIGNMENT_STATUS.CLOSED] = t('closedDescription');
    this.statusDescriptionList[ASSIGNMENT_STATUS.PREVIEW] = t('previewDescription');
    this.statusDescriptionList[ASSIGNMENT_STATUS.COMPLETED] = t('completedDescription');

    this.AccommodationsTogglerSection = SatCoreComponent('AccommodationsTogglerSection');
    this.DeleteAssignmentModal = SatCoreComponent('DeleteAssignmentModal');
    this.ModalBannerDraggable = SatCoreComponent('ModalBannerDraggable');
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
    this.SCRadio = SatCoreComponent('SCRadio');
    this.TimeAdjustModal = SatCoreComponent('TimeAdjustModal');
  }

  getInitialState() {
    const { gradebookManager, courseElement, contentTimeframeStartDate, contentTimeframeEndDate,
      timeframeStartDateStr, timeframeEndDateStr, timeframeStartTimeStr, timeframeEndTimeStr
     } = this.props;
    const formattedTimeframeStartDate = timeframeStartDateStr// these are now formatted on BE ? dateFormat(timeframeStartDate, 'twoDigitDate') : null;
    const formattedTimeframeEndDate = timeframeEndDateStr// these are now formatted on BE ? dateFormat(timeframeEndDate, 'twoDigitDate') : null;
    const formattedTimeframeStartTime = timeframeStartTimeStr;// these are now formatted on BE ? dateFormat(timeframeStartDate, 'twoDigitTime') : null;
    const formattedTimeframeEndTime = timeframeEndTimeStr;// these are now formatted on BE ? dateFormat(timeframeEndDate, 'twoDigitTime') : null;

    return {
      changeAccommodationTogglerSectionChecked: false,
      activityModeId: courseElement.alternateModeIdOverride || courseElement.alternateModeId,
      allowLateSubmission: false,
      assignment: null,
      changeEndDateChecked: false,
      changeInstructionChecked: false,
      changeLateSubmitDateChecked: false,
      changeModeChecked: false,
      changeAdditionalSettingsChecked: false,
      changeReportsChecked: false,
      changeStartDateChecked: false,
      changeStatusChecked: false,
      endAfterClassroomEndErrored: false,
      endDateSetErrored: false,
      hasServerError: false,
      ignoreItemOptionShuffle: false,
      includeInReports: courseElement.includeInReports || false,
      instruction: courseElement.instruction || '',
      lateSubmitDate: null,
      lateSubmitTime: null,
      lockedAfterStartErrored: false,
      nickname: '',
      noSelectionStatus: false,
      openWarning: false,
      randomizeQuestions: true,
      readyAfterEndErrored: false,
      scoresReleased: courseElement.scoresReleased || false,
      sectionRadioValue: 'activity-details-section', // defaulted
      serverErrorMsg: null,
      showKeyboardingSettings: false,
      startBeforeClassroomStartErrored: false,
      startDateAfterEndDateErrored: false,
      startDateSetErrored: false,
      startedAfterEndErrored: false,
      status: null,
      statusDescription: null,
      studentReview: courseElement.studentReview || false,
      submitRadioValue: null,
      timeframeStartDate: contentTimeframeStartDate,
      timeframeEndDate: contentTimeframeEndDate,
      formattedTimeframeStartDate,
      formattedTimeframeEndDate,
      formattedTimeframeStartTime,
      formattedTimeframeEndTime,
      timezoneEndDate: gradebookManager.latestTimezoneEndDate,
      timezoneEndTime: gradebookManager.latestTimezoneEndTime,
      timezoneStartDate: gradebookManager.earliestTimezoneStartDate,
      timezoneStartTime: gradebookManager.earliestTimezoneStartTime,
      unlockedBeforeClassroomStartErrored: false,
      unlockedBeforeStartErrored: false
    };
  }

  async componentDidMount() {
    const { assignmentId, assignmentManager, classroomId, classroomManager, courseElement } = this.props;
    const { alwaysShowTypingSettingsForLessonAssessment } = assignmentManager;
    const baseAssignment = await assignmentManager.getAssignmentAsync(assignmentId);
    const {
      imageUrl, name, nickname, status
    } = baseAssignment;
    let { additionalPropertiesJson } = baseAssignment;
    if (!additionalPropertiesJson) {
      additionalPropertiesJson = {};
    }
    const contentItemEntityTypeId = baseAssignment ? (baseAssignment.entityTypeId || baseAssignment.contentItemEntityTypeId) : courseElement.type;
    const contentItemEntitySubTypeId = baseAssignment ? (baseAssignment.entitySubTypeId || baseAssignment.contentItemEntitySubTypeId) : courseElement.subType;

    const allowLateSubmission = additionalPropertiesJson.hasOwnProperty('allowLateSubmission') ? additionalPropertiesJson.allowLateSubmission : false;
    const lateSubmitDateStr = additionalPropertiesJson.hasOwnProperty('lateSubmitDate') ? additionalPropertiesJson.lateSubmitDate : null;
    let lateSubmitDate = null;
    let lateSubmitTime = null;
    if (lateSubmitDateStr) {
      const tempLateDate = new Date(lateSubmitDateStr);
      lateSubmitDate = tempLateDate.toLocaleDateString();
      lateSubmitTime = tempLateDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    const randomizeQuestions = additionalPropertiesJson.hasOwnProperty('shuffle_items') ? additionalPropertiesJson.shuffle_items : false;
    const ignoreItemOptionShuffle = additionalPropertiesJson.hasOwnProperty('ignoreItemOptionShuffle') ? additionalPropertiesJson.ignoreItemOptionShuffle : false;

    // Keyboarding: If the satellite allows to always show keyboarding settings, check the resource types,
    // as Lesson, Assessment and Keyboarding Resource are all valid.
    // If not, only allow for Keyboarding resource.
    const showKeyboardingSettings = alwaysShowTypingSettingsForLessonAssessment ? (
      contentItemEntityTypeId === CONTENT_ITEM_TYPES.KEYBOARDING_RESOURCE ||
      contentItemEntityTypeId === CONTENT_ITEM_TYPES.LESSON ||
      contentItemEntityTypeId === CONTENT_ITEM_TYPES.ASSESSMENT
    ) : contentItemEntityTypeId === CONTENT_ITEM_TYPES.KEYBOARDING_RESOURCE;

    this.setState({ showKeyboardingSettings });

    let keyboardingResource = null;
    if (showKeyboardingSettings) {
      keyboardingResource = await this.fetchContentItemResource(baseAssignment.contentItemId);
    }

    const typingAllowBackspace = additionalPropertiesJson.hasOwnProperty('typingAllowBackspace') ? additionalPropertiesJson.typingAllowBackspace : keyboardingResource ? keyboardingResource.typingAllowBackspace : false;
    const typingText = additionalPropertiesJson.hasOwnProperty('typingText') ? additionalPropertiesJson.typingText : keyboardingResource ? keyboardingResource.typingText : '';
    const typingDuration = additionalPropertiesJson.hasOwnProperty('typingDurationInSeconds') ? additionalPropertiesJson.typingDurationInSeconds : keyboardingResource ? keyboardingResource.typingDuration : 0;
    const typingMinimumSpeed = additionalPropertiesJson.hasOwnProperty('typingMinSpeed') ? additionalPropertiesJson.typingMinSpeed : keyboardingResource ? keyboardingResource.typingMinimumSpeed : 0;
    const typingMinimumAccuracy = additionalPropertiesJson.hasOwnProperty('typingMinAccuracy') ? additionalPropertiesJson.typingMinAccuracy : keyboardingResource ? keyboardingResource.typingMinimumAccuracy : 0;

    let classroom = classroomManager.getClassroom(classroomId);
    if (!classroom) {
      classroom = await classroomManager.fetchClassroom(classroomId);
    }

    const assignment = {
      name,
      imageUrl,
      status,
      timezoneStartDate: '',
      timezoneStartTime: '',
      timezoneEndDate: '',
      timezoneEndTime: '',
      includeInReports: null,
      scoresReleased: null,
      studentReview: null,
      instruction: '',
      courseResourceElementId: baseAssignment.courseResourceElementId,
      modeOverrideAllowed: baseAssignment.modeOverrideAllowed,
      contentItemEntityTypeId,
      contentItemEntitySubTypeId,
      additionalPropertiesJson: baseAssignment.additionalPropertiesJson,
      resourceWebSubtitle: baseAssignment.resourceWebSubtitle,
      resourceWebTitle: baseAssignment.resourceWebTitle,
      assignmentType: baseAssignment.assignEntityTypeId,
      classroomTimezoneStartDate: classroom.timezoneStartDate,
      classroomStartTime: classroom.startTime,
      classroomTimezoneEndDate: classroom.timezoneEndDate,
      classroomEndTime: classroom.endTime,
    };

    this.setState({
      allowLateSubmission,
      // this is just a base assignment from the first assignment selected.
      // can be used for the title and for the validation object.
      assignment: { ...assignment },
      ignoreItemOptionShuffle,
      randomizeQuestions,
      lateSubmitDate,
      lateSubmitTime,
      nickname,
      status,
      statusDescription: this.statusDescriptionList[status],
      typingAllowBackspace,
      typingText,
      typingDuration,
      typingMinimumSpeed,
      typingMinimumAccuracy
    });
  }

  getDefaultImage() {
    const { courseManager } = this.props;
    const isCustomCourse = courseManager.isCustomCourse(courseManager.currentCourseId);

    let customLeafCardImageClassNames = 'default';
    let defaultCustomLeafCardImg = assessmentDefaultCardImage;

    if (courseManager.useSpecialCustomCourseCardImages && isCustomCourse) {
      customLeafCardImageClassNames += 'custom-default-special';
      defaultCustomLeafCardImg = customAssessmentDefaultCardImageUri;
    }
    return (
      <Image
        alt='Custom default lesson image'
        className={customLeafCardImageClassNames}
        src={defaultCustomLeafCardImg} />
    );
  }

  fetchContentItemResource = async (contentItemId) => {
    const { contentManager } = this.props;
    const keyboardingResource = await contentManager.fetchResourceItem(contentItemId);
    return keyboardingResource;
  }

  handleChangeAssignmentStatus = (event) => {
    const status = event.target.value;

    if (status === ASSIGNMENT_STATUS.LOCKED || status === ASSIGNMENT_STATUS.STARTED || status === ASSIGNMENT_STATUS.READY) {
      this.setState({
        changeStartDateChecked: true,
        changeEndDateChecked: true
      });
    }

    this.setState({
      status,
      statusDescription: this.statusDescriptionList[status]
    });
  }

  handleChangeToggle = (_event, data, toggleKey) => {
    this.setState({
      [toggleKey]: data.checked
    });
  }

  handleChangeStartDate = (_event, value) => {
    this.setState({ timezoneStartDate: value });
  }

  handleChangeEndDate = (_event, value) => {
    this.setState({ timezoneEndDate: value });
  }

  handleChangeStartTime = (_event, value) => {
    this.setState({
      timezoneStartTime: value ? value.toUpperCase() : ''
    });
  }

  handleChangeEndTime = (_event, value) => {
    this.setState({
      timezoneEndTime: value ? value.toUpperCase() : ''
    });
  }

  handleChangeLateSubmitDate = (event, { value }) => {
    this.setState({ lateSubmitDate: value ? value.toUpperCase() : '' });
  };

  handleChangeLateSubmitTime = (event, { value }) => {
    this.setState({ lateSubmitTime: value ? value.toUpperCase() : '' });
  };

  handleClickRandomizeQuestions = (_event) => {
    // const checked = _event.target.value;
    const { randomizeQuestions } = this.state;
    this.setState({
      randomizeQuestions: !randomizeQuestions
    });
  }

  handleClickIgnoreItemOptionShuffle = (_event) => {
    // const checked = _event.target.value;
    const { ignoreItemOptionShuffle } = this.state;
    this.setState({
      ignoreItemOptionShuffle: !ignoreItemOptionShuffle
    });
  }

  handleChangeStatusChecked = (_event) => {
    // const checked = _event.target.value;
    const { changeStatusChecked } = this.state;
    this.setState({
      changeStatusChecked: !changeStatusChecked
    });
  }

  handleChangeStartDateChecked = (_event) => {
    const { changeStartDateChecked } = this.state;
    this.setState({
      changeStartDateChecked: !changeStartDateChecked
    });
  }

  handleChangeEndDateChecked = (_event) => {
    const { changeEndDateChecked } = this.state;
    this.setState({
      changeEndDateChecked: !changeEndDateChecked
    });
  }

  handleChangeLateSubmitDateChecked = (_event) => {
    const { changeLateSubmitDateChecked } = this.state;
    this.setState({
      changeLateSubmitDateChecked: !changeLateSubmitDateChecked
    });
  }

  handleChangeReportsChecked = (_event) => {
    const { changeReportsChecked } = this.state;
    this.setState({
      changeReportsChecked: !changeReportsChecked
    });
  }

  handleChangeInstructionChecked = (_event) => {
    const { changeInstructionChecked } = this.state;
    this.setState({
      changeInstructionChecked: !changeInstructionChecked
    });
  }

  handleChangeAccommodationTogglerSectionChecked = (_event) => {
    const { changeAccommodationTogglerSectionChecked } = this.state;
    this.setState({
      changeAccommodationTogglerSectionChecked: !changeAccommodationTogglerSectionChecked
    });
  }

  handleChangeModeChecked = (_event) => {
    const { changeModeChecked } = this.state;
    this.setState({
      changeModeChecked: !changeModeChecked
    });
  }

  handleClickActivityNumberLink = () => {
    const { assignmentId, classroomManager, gradebookManager, history } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const classroomId = urlParams.get('classroomId') || props.classroomId || classroomManager.currentClassroomId;
    const routerUrl = `/gradebook?assignmentId=${assignmentId}&view=${VIEW_SELECTION.GRADEBOOK}&fromAssignments=true&classroomId=${classroomId}`;
    history.push(routerUrl);
    this.cancel();
  }

  handleChangeSectionRadio = async (event) => {
    const sectionRadioValue = event.target.value;
    this.setState({ sectionRadioValue });
  }

  handleChangeSubmitRadio = async (event) => {
    const submitRadioValue = event.target.value;
    this.setState({ submitRadioValue });
  }

  handleChangeNickname = (event) => {
    const { name, value } = event.target;
    if (value.length < 201) {
      this.setState((prevState) => {
        const newState = { ...prevState };
        newState[name] = value;
        return newState;
      });
      this.validate();
    }
  }

  handleChangeAdditionalSettingsChecked = (_event) => {
    const { changeAdditionalSettingsChecked } = this.state;
    this.setState({
      changeAdditionalSettingsChecked: !changeAdditionalSettingsChecked
    });
  }

  submitEditAssignment = async () => {
    const { sectionRadioValue } = this.state;
    if (sectionRadioValue === 'activity-details-section') {
      this.submitEditAssignmentDetails();
    } else if (sectionRadioValue === 'activity-submit-section') {
      this.submitAssignmentSubmission();
    }
  }

  submitEditAssignmentDetails = async () => {
    this.setState({ isSubmitting: true });

    const { bulkActivityIds, assignmentManager, classroomManager } = this.props;
    const { hasError } = this.validate();
    if (hasError) {
      return;
    }

    const {
      activityModeId,
      assignment,
      allowLateSubmission,
      changeAccommodationTogglerSectionChecked,
      changeEndDateChecked,
      changeInstructionChecked,
      changeLateSubmitDateChecked,
      changeModeChecked,
      changeAdditionalSettingsChecked,
      changeReportsChecked,
      changeStartDateChecked,
      changeStatusChecked,
      ignoreItemOptionShuffle,
      lateSubmitDate,
      lateSubmitTime,
      nickname,
      randomizeQuestions,
      typingAllowBackspace,
      typingText,
      typingDuration,
      typingMinimumSpeed,
      typingMinimumAccuracy
    } = this.state;

    let {
      status, timezoneStartDate, timezoneStartTime,
      timezoneEndDate, timezoneEndTime, includeInReports,
      scoresReleased, studentReview, instruction,
    } = this.state;

    const { additionalPropertiesJson } = assignment;

    status = (changeStatusChecked && status) ? status : null;

    timezoneStartDate = (changeStartDateChecked && timezoneStartDate) ? timezoneStartDate : null;
    timezoneStartTime = (changeStartDateChecked && timezoneStartTime) ? timezoneStartTime : null;
    timezoneEndDate = (changeEndDateChecked && timezoneEndDate) ? timezoneEndDate : null;
    timezoneEndTime = (changeEndDateChecked && timezoneEndTime) ? timezoneEndTime : null;

    let startDateTime;
    let endDateTime;

    if (changeStartDateChecked && timezoneStartDate && timezoneStartTime) {
      startDateTime = assignmentManager.convertJSStringToJSDate(timezoneStartDate, timezoneStartTime);
    }

    if (changeEndDateChecked && timezoneEndDate && timezoneEndTime) {
      endDateTime = assignmentManager.convertJSStringToJSDate(timezoneEndDate, timezoneEndTime);
    }

    includeInReports = (changeReportsChecked) ? includeInReports : null;
    scoresReleased = (changeReportsChecked) ? scoresReleased : null;
    studentReview = (changeReportsChecked) ? studentReview : null;

    instruction = (changeInstructionChecked) ? instruction : null;

    if (changeModeChecked) {
      additionalPropertiesJson.publisherModeId = activityModeId;
    }

    if (changeAdditionalSettingsChecked) {
      additionalPropertiesJson.shuffle_items = randomizeQuestions;
      additionalPropertiesJson.ignoreItemOptionShuffle = ignoreItemOptionShuffle;

      // Keyboarding
      additionalPropertiesJson.typingAllowBackspace = typingAllowBackspace;
      additionalPropertiesJson.typingText = typingText;
      additionalPropertiesJson.typingDurationInSeconds = typingDuration;
      additionalPropertiesJson.typingMinSpeed = typingMinimumSpeed;
      additionalPropertiesJson.typingMinAccuracy = typingMinimumAccuracy;
    }

    if (changeLateSubmitDateChecked) {
      additionalPropertiesJson.allowLateSubmission = allowLateSubmission;
      if (allowLateSubmission) {
        additionalPropertiesJson.lateSubmitDate = assignmentManager.convertAssignmentDateToJavaString(assignmentManager.convertJSStringToJSDate(lateSubmitDate, lateSubmitTime));
      }
    }

    const changes = {
      changeAccommodationTogglerSectionChecked,
      changeStatusChecked,
      changeStartDateChecked,
      changeEndDateChecked,
      changeReportsChecked,
      changeInstructionChecked,
      changeModeChecked,
      changeAdditionalSettingsChecked,
      changeLateSubmitDateChecked,
      status,
      timezoneStartDate,
      timezoneStartTime,
      timezoneEndDate,
      timezoneEndTime,
      timezoneStartAsDate: startDateTime,
      timezoneEndAsDate: endDateTime,
      includeInReports,
      scoresReleased,
      studentReview,
      instruction,
      additionalPropertiesJson,
      nickname: nickname ? nickname.trim() : ''
    };

    const result = await AssignmentService.updateAllAssignments(bulkActivityIds, changes);

    if (result) {
      // DO OTHER THINGS HERE, LIKE REFRESH
      this.setState({ isSubmitting: false });
      await classroomManager.fetchClassroomData(classroomManager.currentClassroomId);
      this.closeEditAssignmentModal();
    } else {
      this.setState({
        isSubmitting: false,
        hasServerError: true,
        serverErrorMsg: 'Something went wrong when saving the assignment, please try again'
      });
    }
  }

  submitAssignmentSubmission = async () => {
    this.setState({ isSubmitting: true });
    const { assignmentManager, bulkActivityIds, classroomManager } = this.props;
    const { submitRadioValue } = this.state;
    let result;
    if (submitRadioValue === 'activity-submit') {
      result = await assignmentManager.teacherSubmitActivities(bulkActivityIds);
    } else {
      result = await assignmentManager.teacherUnsubmitActivities(bulkActivityIds);
    }

    if (result) {
      // DO OTHER THINGS HERE, LIKE REFRESH
      this.setState({ isSubmitting: false });
      await classroomManager.fetchClassroomData(classroomManager.currentClassroomId);
      this.closeEditAssignmentModal();
    } else {
      this.setState({
        isSubmitting: false,
        hasServerError: true,
        serverErrorMsg: 'Something went wrong when saving the assignment, please try again'
      });
    }
  }

  validate = () => {
    const {
      allowLateSubmission,
      assignment,
      status,
      timezoneStartDate,
      timezoneStartTime,
      timezoneEndDate,
      timezoneEndTime,
      includeInReports,
      lateSubmitDate,
      lateSubmitTime,
      nickname,
      studentReview,
      scoresReleased,
      sectionRadioValue,
      changeStatusChecked,
      changeAccommodationTogglerSectionChecked,
      changeStartDateChecked,
      changeEndDateChecked,
      changeReportsChecked,
      changeInstructionChecked,
      changeModeChecked,
      changeAdditionalSettingsChecked,
      changeLateSubmitDateChecked,
      timeframeStartDate,
      timeframeEndDate
    } = this.state;

    const assignmentToValidate = {
      allowLateSubmission,
      ...assignment,
      includeInReports,
      lateSubmitDate,
      lateSubmitTime,
      nickname,
      scoresReleased,
      status,
      studentReview,
      timezoneEndDate,
      timezoneEndTime,
      timezoneStartDate,
      timezoneStartTime,
      timeframeStartDate,
      timeframeEndDate
    };

    const checksToValidate = {
      changeAccommodationTogglerSectionChecked,
      changeEndDateChecked,
      changeInstructionChecked,
      changeLateSubmitDateChecked,
      changeModeChecked,
      changeAdditionalSettingsChecked,
      changeReportsChecked,
      changeStartDateChecked,
      changeStatusChecked
    };

    const { hasError, errors } = AssignmentService.validateEditBulkAssignmentModal(assignmentToValidate, sectionRadioValue, checksToValidate);
    return { hasError, errors };
  }

  closeEditAssignmentModal = async () => {
    const { gradebookManager } = this.props;
    await gradebookManager.setAllAssignmentInstanceBulkChecked(false);
    await gradebookManager.clearAssignmentInstancesBulkChecked();
    this.props.closeEditAssignment();
  }

  cancel = () => {
    this.setState(this.getInitialState());
    this.closeEditAssignmentModal();
  }

  closeWarning = () => {
    this.setState({ openWarning: false });
  }

  deleteAssignments = async () => {
    const { assignmentManager, classroomManager, deleteAssignment, bulkActivityIds } = this.props;
    await assignmentManager.deleteAssignments(bulkActivityIds);
    this.closeEditAssignmentModal();
    await classroomManager.fetchClassroomData(classroomManager.currentClassroomId);
    deleteAssignment && await deleteAssignments(bulkActivityIds);
  }

  editorChange = (data, _assignment) => {
    if (data) {
      this.setState({ instruction: data });
    } else {
      this.setState({ instruction: '<p></p>' });
    }
  }

  setLateSubmission = (allowLateSubmission) => {
    const { timezoneEndDate, timezoneEndTime } = this.state;
    if (allowLateSubmission) {
      this.setState({ lateSubmitDate: timezoneEndDate, lateSubmitTime: timezoneEndTime });
    }
    this.setState({ allowLateSubmission });
  }

  handleChangeActivityMode = (activityModeId) => {
    this.setState({ activityModeId });
  }

  handleChangeTypingBackspace = (value) => {
    this.setState({typingAllowBackspace: value});
  }

  handleChangeTypingText = (_event, { value }) => {
    this.setState({typingText: value});
  };

  handleChangeTypingDuration = (_event, { value }) => {
    this.setState({typingDuration: value});
  };

  handleChangeMinimumSpeed = (_event, { value }) => {
    this.setState({typingMinimumSpeed: value});
  };

  handleChangeMinimumAccuracy = (_event, { value }) => {
    this.setState({typingMinimumAccuracy: value});
  };

  renderLeftColumnContent = (assignmentReady) => {
    const { hasServerError, serverErrorMsg } = this.state;
    const { bulkActivityNumbers, t } = this.props;
    return (
      <>
        <Header as='h3' className='section-header'>{t('selectedAssignments', 'Selected Assignments')}</Header>
        <Container className='field-wrapper'>
          {this.renderAssignmentNames(bulkActivityNumbers)}
        </Container>
        <Message
          className='start-message'
          content={serverErrorMsg}
          error
          visible={hasServerError} />
      </>
    );
  }

  renderRightColumnContent = (hasError = false, errorMsgString = '') => {
    const { SCRadio } = this;
    const { t } = this.props;
    const { assignment, sectionRadioValue } = this.state;
    const contentItemEntityTypeId = assignment?.contentItemEntityTypeId;
    return (
      <>
        <div className='section-header'>
          <SCRadio
            key='activity-details-section'
            checked={sectionRadioValue === 'activity-details-section'}
            label='Activity Details'
            name='sectionRadio'
            onChange={this.handleChangeSectionRadio}
            value='activity-details-section' />
        </div>
        {(sectionRadioValue === 'activity-details-section') && this.renderAssignmentDetailsSection(hasError, errorMsgString)}

        <div className='section-header'>
          <SCRadio
            key='activity-delete-section'
            checked={sectionRadioValue === 'activity-delete-section'}
            label={t('deleteAssignments')}
            name='sectionRadio'
            onChange={this.handleChangeSectionRadio}
            value='activity-delete-section' />
        </div>
        {(sectionRadioValue === 'activity-delete-section') && this.renderDeleteAssignmentSection()}

        {contentItemEntityTypeId !== CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE && (
          <div className='section-header'>
            <SCRadio
              key='activity-submit-section'
              checked={sectionRadioValue === 'activity-submit-section'}
              label={t('submitOrUnsubmitAssignments')}
              name='sectionRadio'
              onChange={this.handleChangeSectionRadio}
              value='activity-submit-section' />
          </div>
        )}
        {(sectionRadioValue === 'activity-submit-section') && this.renderSubmitAssignmentSection()}
      </>
    );
  }

  renderAssignmentDetailsSection = (hasError, errorMsgString) => {
    const { assignmentManager, courseElement } = this.props;
    // const { includeAssignmentAccommodations } = accommodationsManager;
    const { modeOverrideAllowed } = courseElement;

    const { assignment, showKeyboardingSettings } = this.state;
    const contentItemEntityTypeId = assignment?.contentItemEntityTypeId;
    const showAdditionalSettings = !!assignment && (
      // assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.LESSON ||
      // assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.ASSESSMENT ||
      contentItemEntityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE ||
      contentItemEntityTypeId === CONTENT_ITEM_TYPES.KEYBOARDING_RESOURCE
    );
    return (
      <>
        <div className='section-controls section-spacing'>
          {this.renderStatusSection()}
          {this.renderTimeframeSection(hasError, errorMsgString)}
          {this.renderDateRangeSection(hasError, errorMsgString)}
          {assignmentManager.lateSubmissionOptionAllowed && this.renderLateSubmissionSection(hasError, errorMsgString)}
          <Message hidden={errorMsgString !== 'undefined' && !errorMsgString.length > 0} negative>{errorMsgString}</Message>
          {modeOverrideAllowed && this.renderModeOverrideSection()}
          {this.renderReportsAndGradingSection()}
          {(showAdditionalSettings || showKeyboardingSettings) && this.renderAdditionalSettingsSection()}
          {this.renderStudentInstructionSection()}
          {this.renderAccommodationTogglerSection()}
        </div>
      </>
    );
  }

  renderDeleteAssignmentSection = () => {
    const { assignment } = this.state;
    const { DeleteAssignmentModal } = this;
    return (
      <>
        <div className='section-controls section-spacing'>
          <Container>
            <Form.Field>
              <DeleteAssignmentModal assignment={assignment} deleteFunction={this.deleteAssignments} />
            </Form.Field>
          </Container>
        </div>
      </>
    );
  }

  renderSubmitAssignmentSection = () => {
    const { SCRadio } = this;
    const { t } = this.props;
    const { submitRadioValue } = this.state;
    return (
      <>
        <div className='section-controls section-spacing'>
          <Container>
            <Form.Field>
              <div>
                <SCRadio
                  key='activity-submit-section'
                  checked={submitRadioValue === 'activity-submit'}
                  label={t('submitAssignments')}
                  name='submitRadio'
                  onChange={this.handleChangeSubmitRadio}
                  value='activity-submit' />
              </div>
            </Form.Field>
            <Form.Field>
              <div>
                <SCRadio
                  key='activity-unsubmit-section'
                  checked={submitRadioValue === 'activity-unsubmit'}
                  label={t('unsubmitAssignments')}
                  name='submitRadio'
                  onChange={this.handleChangeSubmitRadio}
                  value='activity-unsubmit' />
              </div>
            </Form.Field>
          </Container>
        </div>
      </>
    );
  }

  /* SECTION: STATUS */
  renderStatusSection = () => {
    const { SCCheckbox } = this;
    const { t } = this.props;
    const { changeStatusChecked, statusDescription } = this.state;
    return (
      <>
        <SCCheckbox
          key='selection-status'
          checked={changeStatusChecked}
          label={t('status')}
          onChange={this.handleChangeStatusChecked}
          readOnly />
        <Container className='field-wrapper'>
          <Container className='status-controls'>
            <Form.Field className='status-name'>
              <Header as='h4' className='section-sub-header'>
                {t('name')}
              </Header>
              {this.renderAssignmentStatusRadioButton(ASSIGNMENT_STATUS.LOCKED)}
              {this.renderAssignmentStatusRadioButton(ASSIGNMENT_STATUS.READY)}
              {this.renderAssignmentStatusRadioButton(ASSIGNMENT_STATUS.STARTED)}
              {this.renderAssignmentStatusRadioButton(ASSIGNMENT_STATUS.CLOSED)}
              {this.renderAssignmentStatusRadioButton(ASSIGNMENT_STATUS.COMPLETED)}
            </Form.Field>
            <Form.Field className='status-description'>
              <Header as='h4' className='section-sub-header'>
                {t('description')}
              </Header>
              <div className={`status-description${!changeStatusChecked ? ' pseudo-disabled-text' : ''}`}>
                {statusDescription}
              </div>
            </Form.Field>

          </Container>
        </Container>
      </>
    );
  }

  /* SECTION: Timeframe DATE RANGE */
  renderTimeframeSection = (hasError, errorMsgString = '') => {
    const {
      timeframeStartDate, timeframeEndDate, formattedTimeframeStartDate, formattedTimeframeStartTime,
      formattedTimeframeEndDate, formattedTimeframeEndTime
    } = this.state;
    const { t } = this.props;

    if (timeframeStartDate || timeframeEndDate) {
      return (
        <>
          <div className='section-header header-no-control'>
            {t('timeFrame')}
          </div>
          <div className='section-subtext'>
            {t('timeFrameDescription')}
          </div>
          <Container className='field-wrapper'>
            <Container className='time-display'>
              <Form.Field>
                <label>Start Date: </label>
                <span>{formattedTimeframeStartDate}</span>
              </Form.Field>
              <Form.Field>
                <label>Start Time: </label>
                <span>{formattedTimeframeStartTime}</span>
              </Form.Field>
            </Container>
            <Container className='time-display'>
              <Form.Field>
                <label>End Date: </label>
                <span>{formattedTimeframeEndDate}</span>
              </Form.Field>
              <Form.Field>
                <label>End Time: </label>
                <span>{formattedTimeframeEndTime}</span>
              </Form.Field>
            </Container>
          </Container>
        </>
      )
    } else {
      return (<></>);
    }
  }

  /* SECTION: DATE RANGE */
  renderDateRangeSection = (_hasError, _errorMsgString = '') => {
    const { SCCheckbox } = this;
    const { t } = this.props;
    const {
      assignment, timeframeStartDate, timeframeEndDate, timezoneEndDate, timezoneEndTime,
      timezoneStartDate, timezoneStartTime, changeStartDateChecked, changeEndDateChecked
    } = this.state;
    return (
      <>
        <div className='section-header header-no-control'>
          {t('dateRange')}
        </div>
        {(timeframeStartDate || timeframeEndDate) && (
          <div className='section-subtext'>
            {t('dateRangeDescription')}
          </div>
        )}
        <Container className='field-wrapper'>
          <Container className='time-controls'>
            <Form.Field>
              <SCCheckbox
                key='selection-startdate'
                checked={changeStartDateChecked}
                onChange={this.handleChangeStartDateChecked}
                readOnly />
            </Form.Field>
            <Form.Field>
              <DateInput
                animation='false'
                className='date-input'
                dateFormat='MM/DD/YYYY'
                disabled={!this.state.changeStartDateChecked}
                iconPosition='left'
                label={t('startDate')}
                name='startDate'
                onChange={(event, { value }) => this.handleChangeStartDate(event, value)}
                placeholder={t('startDate')}
                value={timezoneStartDate} />
            </Form.Field>
            <Form.Field>
              <TimeInput
                animation='false'
                className='time-input'
                disabled={!this.state.changeStartDateChecked}
                iconPosition='left'
                label={t('startTime')}
                name='startDate'
                onChange={(e, { value }) => this.handleChangeStartTime(e, value, assignment)}
                placeholder={t('startTime')}
                timeFormat='AMPM'
                value={timezoneStartTime} />
            </Form.Field>
          </Container>
        </Container>
        <Container className='field-wrapper'>
          <Container className='time-controls'>
            <Form.Field>
              <SCCheckbox
                key='selection-enddate'
                checked={changeEndDateChecked}
                onChange={this.handleChangeEndDateChecked}
                readOnly />
            </Form.Field>
            <Form.Field>
              <DateInput
                animation='false'
                className='date-input'
                dateFormat='MM/DD/YYYY'
                disabled={!this.state.changeEndDateChecked}
                iconPosition='left'
                label={t('endDate')}
                name='endDate'
                onChange={(event, { value }) => this.handleChangeEndDate(event, value)}
                placeholder={t('endDate')}
                value={timezoneEndDate} />
            </Form.Field>
            <Form.Field>
              <TimeInput
                animation='false'
                className='time-input'
                disabled={!this.state.changeEndDateChecked}
                iconPosition='left'
                label={t('endTime')}
                name='endTime'
                onChange={(event, { value }) => this.handleChangeEndTime(event, value)}
                placeholder={t('endTime')}
                timeFormat='AMPM'
                value={timezoneEndTime} />
            </Form.Field>
          </Container>
        </Container>
      </>
    );
  }

  /* SECTION: LATE SUBMISSION */
  renderLateSubmissionSection = (_hasError, _errorMsgString = '') => {
    const { SCCheckbox } = this;
    const { assignmentManager, t } = this.props;
    const { assignment, allowLateSubmission, changeLateSubmitDateChecked, lateSubmitDate, lateSubmitTime } = this.state;
    return (
      <>
        {(assignmentManager.lateSubmissionOptionAllowed &&
          (assignment.assignmentType !== ASSIGNMENT_TYPE.CLASSROOM && assignment.assignmentType !== ASSIGNMENT_TYPE.ALL_CLASSES)) && (
          <>
            <Form.Field>
              <SCCheckbox
                key='selection-enddate'
                checked={changeLateSubmitDateChecked}
                label={t('lateSubmissionOption')}
                onChange={this.handleChangeLateSubmitDateChecked}
                readOnly />
            </Form.Field>
            <Header as='h3'>
              <div className='allow-late-submit-control'>
                <Radio
                  checked={allowLateSubmission}
                  disabled={!changeLateSubmitDateChecked}
                  label={t('lateSubmission')}
                  onChange={() => { this.setLateSubmission(!allowLateSubmission); }}
                  toggle />
                <Popup
                  content={t('lateSubmissionText')}
                  hideOnScroll
                  on='click'
                  position='top center'
                  trigger={<Image alt='' className='img-info' src={info} />} />
              </div>
            </Header>
            <Container className='field-wrapper'>
              {allowLateSubmission && (
                <Container className='time-controls'>
                  <Form.Field>
                    <DateInput
                      animation='false'
                      className='date-input'
                      dateFormat='MM/DD/YYYY'
                      disabled={!changeLateSubmitDateChecked}
                      iconPosition='left'
                      label={t('lateSubmitDateLabel')}
                      name='endDate'
                      onChange={this.handleChangeLateSubmitDate}
                      placeholder={t('dueDate')}
                      value={(lateSubmitDate !== null) ? lateSubmitDate : ''} />
                  </Form.Field>
                  <Form.Field>
                    <TimeInput
                      animation='false'
                      className='time-input'
                      disabled={!changeLateSubmitDateChecked}
                      iconPosition='left'
                      label='&nbsp;'
                      name='lateSubmitTime'
                      onChange={this.handleChangeLateSubmitTime}
                      placeholder={t('dueTime')}
                      timeFormat='AMPM'
                      value={(lateSubmitTime !== null) ? lateSubmitTime : ''} />
                  </Form.Field>
                </Container>
              )}
            </Container>
          </>
        )}
      </>
    );
  }

  /* SECTION: REPORTS AND GRADING */
  renderReportsAndGradingSection = () => {
    const { SCCheckbox } = this;
    const { t } = this.props;
    const { changeReportsChecked } = this.state;
    return (
      <>
        <Container className='section-wrapper reports-and-grading'>
          <SCCheckbox
            key='selection-reports'
            checked={changeReportsChecked}
            label={t('reportsAndGrading')}
            onChange={this.handleChangeReportsChecked}
            readOnly />
          <div className='section-subtext'>
            {t('reportsAndGradingDescription')}
          </div>
          {this.renderAssignmentReleaseToggle('includeInReports', 'releaseToReportsLabel')}
          {this.renderAssignmentReleaseToggle('scoresReleased')}
          {this.renderAssignmentReleaseToggle('studentReview')}

          {/* TODO DEMO-746, DEMO-907 */}
          {/* TODO remove the below code if we confirm we want to ALWAYS show the review toggle (regardless of assignment type) */}
          {/* {contentManager.isControlledReviewableType(
          assignment.contentItemEntityTypeId,
          assignment.pdfDeliveryFormat
        ) && this.renderAssignmentReleaseToggle('studentReview')} */}
        </Container>
      </>
    );
  }

    /* SECTION: Theme Override */
    renderModeOverrideSection = () => {
      const { SCCheckbox } = this;
      const { t } = this.props;
      const { activityModeId, assignment, changeModeChecked } = this.state;
      return (
        <>
          <Container className='section-wrapper mode'>
            <div className='section-header'>
              <SCCheckbox
                key='selection-mode'
                checked={changeModeChecked}
                label={t('modeOption')}
                onChange={this.handleChangeModeChecked}
                readOnly />
            </div>
            <div className='section-subtext'>
              {t('modeOptionDescription')}
            </div>
            <ActivityModeDropdown
              activityMode={activityModeId}
              courseResourceElementId={assignment.courseResourceElementId}
              disabled={!changeModeChecked}
              setActivityMode={this.handleChangeActivityMode} />
          </Container>
        </>
      );
    }

  /* SECTION: STUDENT INSTRUCTION */
  renderStudentInstructionSection = () => {
    const { SCCheckbox } = this;
    const { assignment, changeInstructionChecked, instruction } = this.state;

    let editorDataStr = null;
    if (instruction) {
      editorDataStr = instruction;
    } else {
      editorDataStr = '<p></p>';
    }
    return (
      <Container className='section-wrapper instruction'>
        <SCCheckbox
          key='selection-instruction'
          checked={changeInstructionChecked}
          label='Student Instructions (click to edit)'
          onChange={this.handleChangeInstructionChecked}
          readOnly />
        <Container className='editor-container'>
          <Form.Field>
            <RichTextEditor
              data={editorDataStr}
              disabled={!this.state.changeInstructionChecked}
              maxCharacters={500}
              onChange={(data) => this.editorChange(data, assignment)} />
          </Form.Field>
        </Container>
      </Container>
    );
  }

  /* SECTION: ACCOMMODATIONS */
  renderAccommodationTogglerSection = () => {
    const { accommodationsManager } = this.props;
    const { includeAssignmentAccommodations } = accommodationsManager;

    const { changeAccommodationTogglerSectionChecked } = this.state;

    const { assignment } = this.state;
    const rosterItems = RosterService.getSelectedUsersRoster({ assignment });

    const { AccommodationsTogglerSection } = this;

    return includeAssignmentAccommodations && (
      <>
        <AccommodationsTogglerSection {...this.props}
          assignment={assignment}
          rosterItems={rosterItems}
          sectionCheckboxChecked={changeAccommodationTogglerSectionChecked}
          sectionCheckboxOnChange={this.handleChangeAccommodationTogglerSectionChecked}
          sectionCheckboxShow
          style={{ marginTop: '30px' }} />
      </>
    );
  }

  renderAdditionalSettingsSection = () => {
    const { SCCheckbox, handleChangeAdditionalSettingsChecked } = this;
    const {
      assignment,
      changeAdditionalSettingsChecked,
      ignoreItemOptionShuffle,
      randomizeQuestions,
      showKeyboardingSettings,
      typingAllowBackspace,
      typingDuration,
      typingMinimumAccuracy,
      typingMinimumSpeed,
      typingText,
    } = this.state;
    const { t } = this.props;

    const { contentItemEntitySubTypeId, contentItemEntityTypeId } = assignment;

    const {
      allowBackspaceEdit,
      allowBackpaceShow,
      allowDurationEdit,
      allowDurationShow,
      allowMinSpeedEdit,
      allowMinSpeedShow,
      allowMinAccuracyEdit,
      allowMinAccuracyShow,
      allowTestTextEdit,
      allowTestTextShow
    } = CONTENT_ITEM_SUB_TYPES.getVisibilityPermissions(contentItemEntitySubTypeId);

    return (
      <Container className='section-wrapper additional-settings'>
        <SCCheckbox
          key='selection-randomize'
          checked={changeAdditionalSettingsChecked}
          label='Additional Settings'
          onChange={handleChangeAdditionalSettingsChecked}
          readOnly />
        <Container className='additional-settings-container'>
          <Form.Field>
            {contentItemEntityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE && (
              <Radio
                checked={randomizeQuestions}
                disabled={!changeAdditionalSettingsChecked}
                label={t(randomizeQuestions, 'Randomize question order')}
                name='randomizeQuestions'
                onClick={(event) => this.handleClickRandomizeQuestions(event)} />
            )}
          </Form.Field>
          <Form.Field>
            {contentItemEntityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE && (
              <div className='shuffleItemsWrapper'>
                <Radio
                  checked={!ignoreItemOptionShuffle}
                  label={t('ignoreItemOptionShuffle', 'Ignore item option shuffle')}
                  name='ignoreItemOptionShuffle'
                  onClick={(event) => this.handleClickIgnoreItemOptionShuffle(event)} />
                <Popup
                  content={t('ignoreItemOptionShuffleMessage')}
                  hideOnScroll
                  on='hover'
                  trigger={<Image alt='' className='img-info' src={info} />}
                  wide />
              </div>
            )}
          </Form.Field>
          {showKeyboardingSettings && (
            <>
              {allowBackpaceShow && (
                <Form.Field>
                  <div className='resource-typing-input-wrapper'>
                    <Radio
                      checked={typingAllowBackspace}
                      disabled={!changeAdditionalSettingsChecked || !allowBackspaceEdit}
                      label={t('allowBackSpaceLabel')}
                      onChange={() => { this.handleChangeTypingBackspace(!typingAllowBackspace); }}
                      toggle />
                  </div>
                </Form.Field>
              )}
              {allowTestTextShow && (
                <Form.Field>
                  <div className='resource-option-label'>{t('typingTextLabel')}</div>
                  <div className='resource-typing-input-wrapper'>
                    <Input
                      className='input-typing-text'
                      disabled={!changeAdditionalSettingsChecked || !allowTestTextEdit}
                      onChange={this.handleChangeTypingText}
                      size='mini'
                      value={typingText} />
                  </div>
                </Form.Field>
              )}
              {allowDurationShow && (
                <Form.Field>
                  <div className='resource-option-label'>{t('typingDurationLabel')}</div>
                  <div className='resource-typing-input-wrapper'>
                    <Input
                      className='input-typing-text'
                      disabled={!changeAdditionalSettingsChecked || !allowDurationEdit}
                      onChange={this.handleChangeTypingDuration}
                      size='mini'
                      value={typingDuration} />
                  </div>
                </Form.Field>
              )}
              <div className='resource-typing-input-group'>
                {allowMinSpeedShow && (
                  <Form.Field>
                    <div className='resource-typing-input-wrapper col'>
                      <div className='resource-option-label'>{t('typingMinimumSpeedLabel')}</div>
                      <Input
                        className='input-typing-text'
                        disabled={!changeAdditionalSettingsChecked || !allowMinSpeedEdit}
                        onChange={this.handleChangeMinimumSpeed}
                        size='mini'
                        value={typingMinimumSpeed} />
                    </div>
                  </Form.Field>
                )}
                {allowMinAccuracyShow && (
                  <Form.Field>
                    <div className='resource-typing-input-wrapper col'>
                      <div className='resource-option-label'>{t('typingMinimumAccuracyLabel')}</div>
                      <Input
                        className='input-typing-text'
                        disabled={!changeAdditionalSettingsChecked || !allowMinAccuracyEdit}
                        onChange={this.handleChangeMinimumAccuracy}
                        size='mini'
                        value={typingMinimumAccuracy} />
                    </div>
                  </Form.Field>
                )}
              </div>
            </>
          )}
        </Container>
        {/* <Form.Field>  TODO - unhide this when this feature gets rolled out
            { (assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.LESSON ||
                assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.ASSESSMENT) &&
              <Radio
                checked={this.state.showCorrectAnswers}
                label={'Show feedback'}
                name='showCorrectAnswers'
                onClick={(event) => this.handleClickShowCorrectAnswers(event)}/>
            }
          </Form.Field> */}
      </Container>
    );
  }

  renderAssignmentStatusRadioButton = (status, _translationKey = null) => {
    const { t } = this.props;
    const { SCRadio } = this;
    return (
      <SCRadio
        additionalClassNames={`radio-assignment-status ${status}`}
        // eslint-disable-next-line react/destructuring-assignment
        checked={this.state.status === status}
        // eslint-disable-next-line react/destructuring-assignment
        disabled={!this.state.changeStatusChecked}
        label={t(status)}
        // TODO remove // label={translationKey ? t(translationKey) : ASSIGNMENT_STATUS.getFlag(status)}
        name='statusGroup'
        onChange={(event) => this.handleChangeAssignmentStatus(event)}
        value={status} />
    );
  }

  renderAssignmentReleaseToggle = (toggleKey, toggleLabel = null) => {
    const { t } = this.props;
    return (
      <Radio
        checked={this.state[toggleKey]}
        disabled={!this.state.changeReportsChecked}
        label={t(toggleLabel || toggleKey)}
        onChange={(event, data) => {
          this.handleChangeToggle(event, data, toggleKey);
        }}
        toggle />
    );
  }

  renderAssignmentNames = (bulkActivityNumbers) => {
    const { t } = this.props;
    const assignmentArrayContent = [];

    if (bulkActivityNumbers) {
      if (bulkActivityNumbers.length > 0) {
        const usedActivityNumbers = [];
        bulkActivityNumbers.map((activityNumber, index) => {
          // only show the same activity number once.
          if (!usedActivityNumbers.includes(activityNumber)) {
            assignmentArrayContent.push(<Form.Field key={index}>{t('assignment')} {activityNumber}</Form.Field>);
            usedActivityNumbers.push(activityNumber);
          }
          return true;
        });
      }
    }
    if (assignmentArrayContent.length < 1) {
      assignmentArrayContent.push(<Form.Field>{t('noAssignmentNames')}</Form.Field>);
    }
    return assignmentArrayContent;
  }

  render() {
    const {
      assignmentManager, contentImageUrl, editAssignmentOpen, t
    } = this.props;

    const { assignment, isSubmitting, nickname } = this.state;

    const assignmentReady = (assignment !== null && assignment !== undefined);

    const { ModalBannerDraggable } = this;

    const { TimeAdjustModal } = this;

    const { hasError = false, errors } = this.validate();
    const errorMsgString = AssignmentService.getApplicableErrorMessagesForAssignment(errors);

    if (assignmentReady) {
      const {
        resourceWebTitleObj, resourceWebSubtitleObj
      } = AssignmentService.getAssignmentTitleAndDescriptionParserWrapperObjs(assignment);

      const nicknameText = nickname && nickname !== 'undefined' ? nickname : '';

      return (
        <div className='EditBulkAssignmentModal'>
          <ReactModalDraggable
            className='editBulkAssignmentModal'
            disableKeystroke={true}
            isOpen={editAssignmentOpen}
            onRequestClose={(event) => {
              const isOverlay = event?.target?.className === 'flexible-modal-mask';
              if (!isOverlay) {
                this.cancel(assignment);
              }
            }}>
            <div className='modalBody'>
              {/* MODAL HEADER CONTENT */}
              <ModalBannerDraggable
                label={t('editAssignment')}
                onClose={() => this.cancel(assignment)} />
              <div className='content'>
                <div className='assignment-banner'>
                  {(contentImageUrl !== null &&
                    contentImageUrl !== undefined && contentImageUrl !== '')
                    ? <Image src={contentImageUrl} /> : this.getDefaultImage()}
                  <div className='title-wrapper'>
                    <div
                      className='flex-header'
                      title={resourceWebTitleObj.stripped}>
                      {resourceWebTitleObj.parsed}
                    </div>
                    <div
                      className='flex-subheader'
                      title={resourceWebTitleObj.stripped}>
                      {resourceWebSubtitleObj.parsed}
                    </div>
                    {assignmentManager.useAssignmentNickname && (
                      <div className='nickname-wrapper'>
                        <Container className='nickname-container'>
                          <div className='label'>
                            {`${t('nicknameLabel', 'Nickname')}`}:
                          </div>
                          <Form.Field>
                            <Input
                              className='nickname-input'
                              name='nickname'
                              onChange={this.handleChangeNickname}
                              placeholder={t('nicknamePlaceholder', 'Enter nickname...')}
                              value={nicknameText} />
                          </Form.Field>
                        </Container>
                      </div>
                    )}
                  </div>
                </div>

                {/* MODAL BODY CONTENT */}
                <div className='ui form'>
                  <Container className='assignment-wrapper-modal'>
                    <div className='left-column'>
                      {this.renderLeftColumnContent(assignmentReady)}
                    </div>
                    <div className='right-column'>
                      {this.renderRightColumnContent(hasError, errorMsgString)}
                    </div>
                  </Container>
                </div>
              </div>
              {/* TODO possibly remove — `TimeAdjustModal` appears to be unused */}
              <TimeAdjustModal close={this.closeWarning} open={this.state.openWarning} status={this.state.warningStatus} />

              {/* MODAL ACTION BUTTONS */}
              <div className='actions'>
                <Button
                  basic
                  className='cancelButton'
                  onClick={() => this.cancel(assignment)}
                  primary
                  type='button'>
                  {t('cancel')}
                </Button>
                <Button
                  className='ui primary button saveButton'
                  disabled={hasError}
                  onClick={this.submitEditAssignment}
                  type='button'>
                  {t('save')}
                  {isSubmitting && (
                    <Loader
                      active
                      inline
                      size='tiny'
                      style={{ marginLeft: '4px', marginRight: '-4px' }} />
                  )}
                </Button>
              </div>
            </div>
          </ReactModalDraggable>
        </div>
      );
    }
    return (<></>);
  }
}

SatCoreRegister('EditBulkAssignmentModal', EditBulkAssignmentModal);
