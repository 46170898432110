import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Grid } from 'semantic-ui-react';

import '../css/RoleSwitcherView.less';

import { SatCoreRegister } from '../SatCoreRegistry';

import { getSessionStorageItem } from '../utils/session';

import UserService from '../services/UserService';

const RoleSwitcherView = observer((props) => {
  const { history, t } = props;

  const { topNavManager, userManager } = useContext(MobXProviderContext);

  const roles = UserService.getAvailableRoles();

  const [firstRowColumnCount, secondRowColumnCount] = UserService.getRoleColumnCountsPerRowArray();

  const firstRow = roles.slice(0, firstRowColumnCount);
  const secondRow = secondRowColumnCount ? roles.slice(firstRowColumnCount) : [];

  useEffect(() => {
    (async () => {
      if (topNavManager.isTopNavProfileMenuOpen) {
        topNavManager.setIsTopNavProfileMenuOpen(false);
      }
      const activePermissionId = userManager.activePermissionId || getSessionStorageItem('activePermissionId');
      if (activePermissionId && activePermissionId !== 'null') {
        userManager.setActivePermissionId(activePermissionId);
        // if an active permission (role) exists, go to that role's dashboard
        history.push('/');
      }
    })();
  }, []);

  const handleClickRoleCard = (permissionId) => {
    userManager.setActivePermissionId(permissionId);
    history.push('/');
  };

  const renderRoleCard = (permissionId) => {
    return (
      <Grid.Column key={permissionId}
        className='role-grid-column'>
        <div className='role-card'
          onClick={() => handleClickRoleCard(permissionId)}>
          <div className='role-top-label'>
            {t(permissionId)}
          </div>
          <div className='role-bottom-label'>
            {t('enter')}
          </div>
        </div>
      </Grid.Column>
    );
  };

  return !userManager.activePermissionId && (
    <div className='role-switcher-view'>
      <div className='role-switcher-grid-wrapper'>
        <Grid className='role-switcher-grid'>
          {!!firstRowColumnCount && (
            <Grid.Row
              centered
              className='role-first-grid-row'
              columns={firstRowColumnCount}>
              {firstRow.map((permissionId) => renderRoleCard(permissionId))}
            </Grid.Row>
          )}
          {!!secondRowColumnCount && (
            <Grid.Row
              centered
              className='role-second-grid-row'
              columns={secondRowColumnCount}>
              {secondRow.map((permissionId) => renderRoleCard(permissionId))}
            </Grid.Row>
          )}
        </Grid>
      </div>
    </div>
  );
});
export default RoleSwitcherView;

SatCoreRegister('RoleSwitcherView', RoleSwitcherView);
