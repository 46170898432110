import React, { useContext, useEffect } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import { SatCoreRegister } from '../SatCoreRegistry';

import '../css/ExternalDashboard.less';

import { VIEW_SELECTION } from '../managers/NavigationManager';

import Auth from '../managers/AuthManager';

import { TOP_NAV_NAME } from '../constants';

import { isProductionEnvironmentUrl } from '../utils';

const ExternalDashboard = observer((props = {}) => {
  const { topNavManager, userManager } = useContext(MobXProviderContext);

  const { history, t } = props;

  useEffect(() => {
    (async () => {
      if (history?.action === 'POP') {
        // User has likely landed on this component via browser back/forward navigation (or browser refresh).
        // So here, we assume the user intention was to return to the satellite dashboard.
        window.location.replace(`/?view=${VIEW_SELECTION.DASHBOARD}`);
      } else {
        let externalDashboardUrlKey, externalDashboardUrl;

        const activeTopNavName = topNavManager.activeTopNavName || TOP_NAV_NAME.TopNav;

        if (isProductionEnvironmentUrl(Auth.ecms)) {
          externalDashboardUrlKey = `${activeTopNavName}_externalDashboardUrlProd`;
          externalDashboardUrl = t(externalDashboardUrlKey);
        } else {
          externalDashboardUrlKey = `${activeTopNavName}_externalDashboardUrlQA`;
          externalDashboardUrl = t(externalDashboardUrlKey);
        }

        if (!externalDashboardUrl) {
          console.error(`ExternalDashboard: t(${externalDashboardUrlKey}) is required, but appears to be either empty or missing.`);
          window.location.replace(`/?view=${VIEW_SELECTION.DASHBOARD}`);
          return;
        }

        if (topNavManager.allowAuthKeyTopNavUrlParamAuthentication) {
          const urlObj = new URL(externalDashboardUrl);
          urlObj.searchParams.append('authKey', Auth.authKey);
          urlObj.searchParams.append('activePermissionId', userManager.activePermissionId);

          const externalDashboardUrlWithAuthKey = urlObj.toString();

          window.location.href = externalDashboardUrlWithAuthKey;
        } else {
          window.location.href = externalDashboardUrl;
        }
      }
      setTimeout(() => {
        // if we are still on the `/externalDashboard` route after 3000ms, force redirect back to satellite dashboard
        if (window.location.pathname?.includes?.('/externalDashboard')) {
          window.location.replace(`/?view=${VIEW_SELECTION.DASHBOARD}`);
        }
      }, 3000);
    })();
  }, []);

  return (
    <div className='ExternalDashboard'>
      {/* This works as a placeholder component, as it is expected the user will redirect to an external link within a few milliseconds. */}
      {/* See `showExternalDashboard()` methods within the TopNav components for more information. */}
    </div>
  );
});
export default ExternalDashboard;

SatCoreRegister('ExternalDashboard', ExternalDashboard);
