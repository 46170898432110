/* eslint-disable prefer-destructuring */
import React, { useEffect, useRef } from 'react';

import { SatCoreRegister } from '../SatCoreRegistry';

/**
 * **DropdownObserver** is a component that observes dropdown menu items and automatically focuses
 * on the item that is selected (aria-selected=true). If the selected item contains a button,
 * the button will receive focus instead.
 *
 * @component
 * @example
 * return (
 *   <DropdownObserver>
 *     <Dropdown ... />
 *   </DropdownObserver>
 * );
 */
const DropdownObserver = ({ children } = {}) => {
  const dropdownRef = useRef();

  useEffect(() => {
    const config = { attributes: true, childList: true, subtree: true };

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'aria-selected') {
          const target = mutation.target;

          // If aria-selected is true, check for a button inside the element
          if (target.getAttribute('aria-selected') === 'true') {
            const button = target.querySelector('button');
            // Focus the button if found, otherwise focus the target itself
            if (button) {
              button.focus();
            } else {
              target.focus();
            }
          }
        }
      });
    });

    const dropdownMenu = dropdownRef.current;
    // Observe the dropdown menu for changes
    if (dropdownMenu) {
      observer.observe(dropdownMenu, config);
    }

    // Cleanup function to disconnect the observer on unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div ref={dropdownRef} className='DropdownObserver'>
      {children}
    </div>
  );
};
export default DropdownObserver;

SatCoreRegister('DropdownObserver', DropdownObserver);
