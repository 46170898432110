import qs from 'qs';

export const generateQuery = (params) => qs.stringify(params, { arrayFormat: 'brackets', skipNulls: true });
export const generateUrl = (url, options) => {
  const { hash, ...params } = options || {};
  const query = generateQuery(params);
  return `${url}${query ? `?${query}` : ''}${hash ? `#${hash}` : ''}`;
};

/**
 * @param {URLSearchParams} urlParams
 * @param {string[]} paramsToRemove
 */
export const removeUrlParams = (urlParams, paramsToRemove = []) => {
  for (const param of paramsToRemove) {
    urlParams.delete(param);
  }
  return urlParams;
};

export const isProductionEnvironmentUrl = (locationOrigin = '') => {
  const nonProdEnvironmentPattern = /(?:qaprod-|qa-|dev\.|ast-|staging-|localhost)/ig;

  locationOrigin = locationOrigin || window?.location?.origin || '';

  // if match for `nonProdEnvironmentPattern` is not found, we assume locationOrigin is a production url
  return !locationOrigin?.match?.(nonProdEnvironmentPattern);
};
