/* eslint-disable react/jsx-wrap-multilines */
import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import { Button, Icon, Image, Popup } from 'semantic-ui-react';

import '../css/GradebookHeader.less';

import assessmentDefaultCardImage from '../img/default-assessment.svg';
import iconInfoBlack from '../img/icon-info.svg';
import iconInfoWhite from '../img/icon-info-white.svg';
import iconObservationalPencil from '../img/icon-observational-pencil.svg';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { GRADEBOOK_CONTEXT } from '../constants';

import { getStyleVar } from '../utils';

import AssignmentService from '../services/AssignmentService';
import EngagementService from '../services/EngagementService';
import GradebookMainService from '../services/gradebook/GradebookMainService';
import ImageService from '../services/ImageService';
import PopupService from '../services/PopupService';
import UtilityService from '../services/UtilityService';

import { customAssessmentDefaultCardImageUri } from '../services/UriService';

const GradebookHeader = observer((props) => {
  const {
    // TODO remove, unused // onClickAggregateGradebookButton,
    assignment,
    assignmentEntityTypeId,
    onClickAssignButton,
    onClickEditBulkAssignmentButton,
    onClickExportGradebook,
    onClickGotoCourse,
    onClickGradebookContextDetailsButton,
    onClickGradebookContextEngagementButton,
    onClickGradebookContextLikertButton,
    onClickGradebookContextStandardsButton,
    onClickGradebookContextSummaryButton,
    onClickGradebookContextTypingButton,
    onClickInfoIconButton,
    t
  } = props;

  const {
    courseManager, dialogManager, engagementManager, gradebookManager, userManager
  } = useContext(MobXProviderContext);
  const { activeGradebookTable, activeGradebookType, useGradebookHeaderResourceInfo } = gradebookManager;

  const ColorKeyCard = SatCoreComponent('ColorKeyCard');
  const EngagementButtonBar = SatCoreComponent('EngagementButtonBar');

  const isReadOnly = GradebookMainService.isGradebookReadOnly();

  const showInfoIconButton = AssignmentService.showAssignmentInfoIconButton(assignment, false);

  const entityTypeId = assignmentEntityTypeId || assignment.contentItemEntityTypeId || assignment.entityTypeId;

  const isAssessment = entityTypeId === 'assessment';
  const isLesson = entityTypeId === 'lesson';
  const isLearnosity = entityTypeId?.includes?.('learnosity');

  const handleClickGradebookContextButton = (event) => {
    const { value } = event.target.dataset;
    // clear selections when changing views.
    gradebookManager.clearBulkCheckedAssignmentMembers();
    switch (value) {
      // TODO remove, unused
      // case 'aggregate':
      //   gradebookManager.setActiveGradebookType('aggregate');
      //   return onClickAggregateGradebookButton();
      case GRADEBOOK_CONTEXT.SUMMARY:
        gradebookManager.setActiveGradebookDetailsHorizontalScoresPage(1);
        gradebookManager.setActiveGradebookTable(GRADEBOOK_CONTEXT.SUMMARY);
        return onClickGradebookContextSummaryButton();
      case GRADEBOOK_CONTEXT.DETAILS:
        gradebookManager.setActiveGradebookTable(GRADEBOOK_CONTEXT.DETAILS);
        return onClickGradebookContextDetailsButton();
      case GRADEBOOK_CONTEXT.LIKERT:
        gradebookManager.setActiveGradebookTable(GRADEBOOK_CONTEXT.LIKERT);
        return onClickGradebookContextLikertButton();
      case GRADEBOOK_CONTEXT.TYPING:
        gradebookManager.setActiveGradebookTable(GRADEBOOK_CONTEXT.TYPING);
        return onClickGradebookContextTypingButton();
      case GRADEBOOK_CONTEXT.ENGAGEMENT:
        gradebookManager.setActiveGradebookTable(GRADEBOOK_CONTEXT.ENGAGEMENT);
        engagementManager.clearEngagementTable();
        engagementManager.setActiveEngagementTable('summary');
        return onClickGradebookContextEngagementButton();
      case GRADEBOOK_CONTEXT.STANDARDS:
        gradebookManager.setActiveGradebookTable(GRADEBOOK_CONTEXT.STANDARDS);
        return onClickGradebookContextStandardsButton();
    }
  };

  const handleClickAssignmentButton = (assignment) => {
    onClickAssignButton(assignment, true); // true for reassign
  };

  const renderAssignmentNameAndDescription = () => {
    const assignmentName = assignment.resourceWebTitle || assignment.name;
    const assignmentDescription = assignment.resourceWebSubtitle;

    const iconInfo = dialogManager.useWhiteInfoIcon ? iconInfoWhite : iconInfoBlack;
    const assignmentNameObj = UtilityService.reactHtmlParserWrapper(assignmentName);
    const assignmentDescriptionObj = UtilityService.reactHtmlParserWrapper(assignmentDescription);
    const contentImageUrl = ImageService.getImageUrl(assignment);

    const assignmentClassroomName = assignment?.classroomName ? (
      t('assignmentClassroomName', { classroomName: assignment.classroomName })
    ) : undefined;

    // Check if the satellite uses the full resource header or just the title/subtitle
    if (useGradebookHeaderResourceInfo) {
      return (
        <div className='assignment-banner'>
          {(contentImageUrl !== null &&
            contentImageUrl !== undefined && contentImageUrl !== '')
            ? <Image alt='' src={contentImageUrl} /> : getDefaultImage()}
          <div className='title-wrapper'>
            <div
              className='flex-header'
              title={assignmentNameObj.stripped}>
              {assignmentNameObj.parsed}
              {showInfoIconButton && (
                <Image
                  alt=''
                  id='info-icon'
                  onClick={onClickInfoIconButton}
                  src={iconInfo} />
              )}
            </div>
            {!!assignmentDescription && (
              <div
                className='flex-subheader flex-subheader-subtitle'
                title={assignmentDescriptionObj.stripped}>
                {assignmentDescriptionObj.parsed}
              </div>
            )}
            {!!assignmentClassroomName && (
              <div
                className='flex-subheader flex-subheader-classroom-name'
                title={assignmentClassroomName}>
                {assignmentClassroomName}
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className='header-h1-wrapper'>
            {PopupService.renderPopup({
              content: assignmentNameObj.stripped,
              trigger: (
                <h1 className='header theme-header-title'>
                  {assignmentNameObj.parsed}
                </h1>
              )
            })}
            {showInfoIconButton && (
              <Image
                alt=''
                onClick={onClickInfoIconButton}
                src={iconInfo} />
            )}
          </div>
          {!!assignmentDescription && (
            <div className='header-h3-wrapper'>
              {PopupService.renderPopup({
                content: assignmentDescriptionObj.stripped,
                trigger: (
                  <h3 className='header gradebook-header-subtitle'>
                    {assignmentDescriptionObj.parsed}
                  </h3>
                )
              })}
            </div>
          )}
          {!!assignmentClassroomName && (
            <div className='header-h3-wrapper'>
              {PopupService.renderPopup({
                content: assignmentClassroomName,
                trigger: (
                  <h3 className='header gradebook-header-classroom-name'>
                    {assignmentClassroomName}
                  </h3>
                )
              })}
            </div>
          )}
        </>
      );
    }
  };

  const getDefaultImage = () => {
    const isCustomCourse = courseManager.isCustomCourse(courseManager.currentCourseId);

    let customLeafCardImageClassNames = 'default';
    let defaultCustomLeafCardImg = assessmentDefaultCardImage;

    if (courseManager.useSpecialCustomCourseCardImages && isCustomCourse) {
      customLeafCardImageClassNames += 'custom-default-special';
      defaultCustomLeafCardImg = customAssessmentDefaultCardImageUri;
    }
    return (
      <Image
        alt='Custom default lesson image'
        className={customLeafCardImageClassNames}
        src={defaultCustomLeafCardImg} />
    );
  };

  const renderGradebookDetailsColorKeyCard = () => {
    const { hasObservationalGradebookData, isGradebookLikert } = gradebookManager;

    const { DETAILS, STANDARDS, LIKERT } = GRADEBOOK_CONTEXT;
    if (activeGradebookTable !== DETAILS && activeGradebookTable !== STANDARDS && activeGradebookTable !== LIKERT) {
      return null;
    }

    const allowAccessibilityTriangle = gradebookManager.allowFacultyGradebookAccessibility;

    let fullCreditLabel, partialCreditLabel, noCreditLabel;

    if (isGradebookLikert) {
      fullCreditLabel = 'likertFullCredit';
      partialCreditLabel = 'likertPartialCredit';
      noCreditLabel = 'likertNoCredit';
    } else {
      fullCreditLabel = 'fullCredit';
      partialCreditLabel = 'partialCredit';
      noCreditLabel = 'noCredit';
    }

    const colorKeyDataArray = [
      {
        color: getStyleVar('--gradebook-detail-full-credit'),
        colorSquareClassName: classNames('full-credit', {
          'accessibility-triangle': allowAccessibilityTriangle
        }),
        label: fullCreditLabel
      },
      {
        color: getStyleVar('--gradebook-detail-partial-credit'),
        colorSquareClassName: classNames('partial-credit', {
          'accessibility-triangle': allowAccessibilityTriangle
        }),
        label: partialCreditLabel
      },
      {
        color: getStyleVar('--gradebook-detail-no-credit'),
        colorSquareClassName: 'no-credit',
        label: noCreditLabel
      }
    ];
    const iconKeyDataArray = [
      {
        // 'No Student Response'
        className: 'no-student-response',
        icon: <span className='cursor-default link-color'>—</span>,
        label: 'noResponse'
        // TODO remove
        // color: 'transparent',
        // colorSquareClassName: classNames('noResponse', {
        //   'accessibility-triangle': allowAccessibilityTriangle
        // }),
        // icon: <span className='clickable link-color'>—</span>,
      },
      {
        // 'Requires Manual Scoring'
        className: 'requires-manual-scoring',
        icon: <Icon className='cursor-default link-color' name='pencil' />,
        label: 'requiresManualScore'
        // TODO remove
        // color: 'transparent',
        // colorSquareClassName: classNames('requiresManualScore', {
        //   'accessibility-triangle': allowAccessibilityTriangle
        // }),
        // icon: <Icon className='clickable link-color' name='pencil' />,
      }
    ];

    let gradebookColorKeyCardWidth;

    if (hasObservationalGradebookData) {
      iconKeyDataArray.push({
        // 'Observational Scoring'
        className: 'observational-scoring',
        icon: <Image className='icon-observational-pencil' src={iconObservationalPencil} />,
        label: 'observationalScoring'
      });
      gradebookColorKeyCardWidth = getStyleVar('--color-key-card-gradebook-observational-width');
    }

    return (
      <div className={`gradebook-colorkeycard-outer-wrapper ${activeGradebookType} ${activeGradebookTable}`}>
        <ColorKeyCard
          additionalClassNames={classNames('gradebook-colorkeycard', {
            observational: hasObservationalGradebookData
          })} colorKeyDataArray={colorKeyDataArray} iconKeyDataArray={iconKeyDataArray}
          maxWidth={gradebookColorKeyCardWidth}
          width={gradebookColorKeyCardWidth} />
      </div>
    );
  };

  const renderGradebookContextButtons = () => {
    const {
      activeGradebookTable,
      allowGradebookStandards,
      allowGradebookTypingContext,
      allowLearnosityGradebookDetail,
      defaultGradebookTable
    } = gradebookManager;

    const { hasConventionalGradebookData, hasLikertGradebookData } = gradebookManager;

    const hasGradebookData = hasConventionalGradebookData || hasLikertGradebookData;

    const showDetailsButton = (hasConventionalGradebookData || defaultGradebookTable === GRADEBOOK_CONTEXT.DETAILS) && (
      (isAssessment || isLesson || (isLearnosity && allowLearnosityGradebookDetail))
    );

    const showLikertButton = hasLikertGradebookData || defaultGradebookTable === GRADEBOOK_CONTEXT.LIKERT;

    let showEngagementButton;

    if (typeof EngagementService.shouldShowGradebookEngagementButton === 'undefined') {
      // default case
      showEngagementButton = hasGradebookData && (
        userManager.hasViewEngagementDataPermission && showDetailsButton
      );
    } else {
      // custom case
      showEngagementButton = hasGradebookData && (
        EngagementService.shouldShowGradebookEngagementButton(entityTypeId)
      );
    }

    const showStandardsButton = hasGradebookData && allowGradebookStandards;

    const showTypingButton = hasGradebookData && allowGradebookTypingContext;

    const allowedGradebookContextKeys = [];

    if (showDetailsButton) {
      allowedGradebookContextKeys.push(GRADEBOOK_CONTEXT.DETAILS);
    }
    if (showLikertButton) {
      allowedGradebookContextKeys.push(GRADEBOOK_CONTEXT.LIKERT);
    }
    if (showTypingButton) {
      allowedGradebookContextKeys.push(GRADEBOOK_CONTEXT.TYPING);
    }
    if (showEngagementButton && !isLearnosity) {
      allowedGradebookContextKeys.push(GRADEBOOK_CONTEXT.ENGAGEMENT);
    }
    if (showStandardsButton) {
      allowedGradebookContextKeys.push(GRADEBOOK_CONTEXT.STANDARDS);
    }

    // add summary button first in the list if at least one other button is allowed
    if (allowedGradebookContextKeys.length) {
      allowedGradebookContextKeys.unshift(GRADEBOOK_CONTEXT.SUMMARY);
    }

    const lastIndex = allowedGradebookContextKeys.length - 1;

    return (
      <div className='gradebook-header-context-buttons-wrapper btns-summary-and-details-wrapper'>
        {allowedGradebookContextKeys.map((gradebookContextKey, index) => {
          return (
            <Button
              key={gradebookContextKey}
              className={classNames(gradebookContextKey, {
                'bar-btn-left': index === 0,
                'bar-btn-middle': index !== 0 && index !== lastIndex,
                'bar-btn-right': index === lastIndex
              })}
              data-value={gradebookContextKey}
              onClick={handleClickGradebookContextButton}
              primary={activeGradebookTable === gradebookContextKey}
              size='tiny'>
              {t(gradebookContextKey)}
            </Button>
          );
        })}
      </div>
    );
  };

  const renderEngagementButtonBar = () => {
    const showEngagementButtonBar = (
      gradebookManager.activeGradebookTable === 'engagement' && engagementManager.activeEngagementTable !== 'summary'
    );
    return showEngagementButtonBar ? <EngagementButtonBar /> : null;
  };

  const renderGotoCourseButton = () => {
    return (
      <div className={classNames('btn-goto-course-wrapper', {
        'is-read-only': isReadOnly
      })}>
        <Button
          className='primary goto-course-button'
          onClick={onClickGotoCourse}>
          {t('goToCourseLabel')}
        </Button>
      </div>
    );
  };

  const renderExportGradebookButton = () => {
    return (
      <div className='btn-export-wrapper'>
        <Button
          basic
          className={`btn-export ${activeGradebookTable}`}
          onClick={onClickExportGradebook}
          primary
          type='button'>
          <Icon name='download' />
          {t('export')}
        </Button>
      </div>
    );
  };

  const renderAssignButton = () => {
    if (activeGradebookTable !== 'summary') {
      return null;
    }
    const button = (
      <Button
        className='primary assign-button'
        onClick={() => handleClickAssignmentButton(assignment)}>
        {t('assignButtonLabel')}
      </Button>
    );
    return (
      <div className={classNames('btn-assign-wrapper', {
        'is-read-only': isReadOnly
      })}>
        {button}
      </div>
    );
  };

  const renderBulkUpdateButton = () => {
    const { assignmentToRepInstanceBulkCheckedMap } = gradebookManager;

    if (activeGradebookTable === GRADEBOOK_CONTEXT.SUMMARY) {
      const button = (
        <div className={classNames('btn-bulk-wrapper', {
          'is-read-only': isReadOnly
        })}>
          <Button
            className={`btn-bulk ${activeGradebookTable}`}
            disabled={!assignmentToRepInstanceBulkCheckedMap.size > 0}
            onClick={onClickEditBulkAssignmentButton}
            primary
            type='button'>
            {t('bulkUpdateButtonLabel')}
          </Button>
        </div>
      );
      if (!assignmentToRepInstanceBulkCheckedMap.size > 0) {
        return (
          <Popup
            content={(
              <div>{t('bulkUpdateButtonDisabledMessage')}</div>
            )}
            on='hover'
            position='bottom right'
            trigger={button} />
        );
      }
      return button;
    } else {
      return null;
    }
  };

  return (
    <div className='gradebook-header-container'>
      <div className={`gradebook-header-left ${activeGradebookType} ${activeGradebookTable}`}>
        {renderAssignmentNameAndDescription()}
        {renderGradebookContextButtons()}
        {renderEngagementButtonBar()}
      </div>
      {/* eslint-disable-next-line max-len */}
      <div className={`gradebook-header-right ${activeGradebookType} ${activeGradebookTable} ${useGradebookHeaderResourceInfo ? 'top-align' : ''}`}>
        <div className={classNames('top-button-wrapper', {
          'is-read-only': isReadOnly
        })}>
          {renderGotoCourseButton()}
          {renderExportGradebookButton()}
        </div>
        {renderGradebookDetailsColorKeyCard()}
        <div className={classNames('bottom-button-wrapper', {
          'is-read-only': isReadOnly
        })}>
          {renderAssignButton()}
          {renderBulkUpdateButton()}
        </div>
      </div>
    </div>
  );
});
export default GradebookHeader;

SatCoreRegister('GradebookHeader', GradebookHeader);
