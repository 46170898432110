import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import ClassroomService from '../../services/ClassroomService';
import ReportTableService from '../../services/reports/ReportTableService';

import '../../css/reports/ReportDropdown.less';

import { SatCoreRegister } from '../../SatCoreRegistry';

const ReportGroupFilter = observer((props) => {
  const { t, updateOnClose } = props;

  const {
    classroomManager,
    groupsManager,
    reportIdentityManager,
  } = useContext(MobXProviderContext);

  useEffect(() => {
    (async () => {
      const classroomId = ClassroomService.getCurrentClassroomId();
      const groups = await groupsManager.getGroupsByClassroom(classroomId);
      if (groups) {
        const options = groups.map((group) => {
          return { label: group.name, value: group.id };
        });
        groupsManager.setGroupOptions(options);
      }
    })();
  }, []);

  const handleChange = async (event, data) => {
    groupsManager.setGroupsForReport(event);
  };

  const handleClose = async () => {
    if (updateOnClose) {
      if (groupsManager.groupsChanged) {
        await groupsManager.fetchGroupListMembers();
        ReportTableService.forceRerenderReportTable();
      }
    }
  };

  const renderReportGroupFilter = () => {
    const { reportInfoClassNames } = reportIdentityManager;

    let placeholder;
    if (!groupsManager.groupOptions.length) {
      placeholder = t('noGroups');
    }

    const label = (props.label) ? props.label : t('groupSelectLabel');

    if (!classroomManager.includeGroups) {
      return null;
    }

    return (
      <div className={`report-dropdown report-group-dropdown ${reportInfoClassNames}`}>
        <div className='dropdown-label'>
          <div className='text'>
            {label}
          </div>
        </div>
        <div className='dropdown-control'>
          <ReactMultiSelectCheckboxes
            className='dropdown-control'
            onChange={handleChange}
            onMenuClose={handleClose}
            options={groupsManager.groupOptions}
            placeholderButtonLabel={placeholder}
            value={groupsManager.groups} />
        </div>
      </div>
    );
  };
  return renderReportGroupFilter();
});
export default ReportGroupFilter;

SatCoreRegister('ReportGroupFilter', ReportGroupFilter);
