import classNames from 'classnames';

import { registerClass } from '../../SatCoreRegistry';

import { ASSIGNMENT_STATUS } from '../../managers/AssignmentManager';

import gradebookManager from '../../managers/GradebookManager';

import { toFixed } from '../../utils';
import { GRADEBOOK_LIKERT_CUT_SCORES } from '../../constants';

// const t = register('GradebookLikertService');

export default class GradebookLikertService {
  static getGradebookLikertDetailsTableScoreCellCreditClassName = (
    score, maxScore, isScorable = true, {
      headerInfo, /* student, */trueScoreIndex
    } = {}) => {
    const { FULL_CREDIT, PARTIAL_CREDIT } = GRADEBOOK_LIKERT_CUT_SCORES;

    const allowAccessibilityTriangle = gradebookManager.allowFacultyGradebookAccessibility;
    if (typeof score === 'number' && isScorable) {
      if (!score || score < PARTIAL_CREDIT) {
        return classNames('no-credit');
      } else if (score >= PARTIAL_CREDIT && score < FULL_CREDIT) {
        return classNames('partial-credit', {
          'accessibility-triangle': allowAccessibilityTriangle
        });
      } else if (score >= FULL_CREDIT) {
        return classNames('full-credit', {
          'accessibility-triangle': allowAccessibilityTriangle
        });
      }
      return isScorable ? 'scorable-credit' : '';
    }
    return isScorable ? 'scorable-credit' : '';
  }

  static getLikertGradeStr = ({
    instanceInfo,
  } = {}) => {
    const allowLikertGrade = typeof instanceInfo?.likertGrade === 'number' && (
      instanceInfo.submitted ||
      instanceInfo.status !== ASSIGNMENT_STATUS.CLOSED ||
      instanceInfo.status !== ASSIGNMENT_STATUS.COMPLETED
    );

    const likertGrade = allowLikertGrade ? instanceInfo?.likertGrade : undefined;

    const shouldRound = false;
    const roundedLikertGrade = typeof likertGrade === 'number' ? (
      toFixed(likertGrade, shouldRound) || '—'
    ) : '—';

    return roundedLikertGrade;
  }
}

registerClass('GradebookLikertService', GradebookLikertService);
