import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { withRouter } from 'react-router-dom';

import classNames from 'classnames';

import { Menu } from 'semantic-ui-react';

import '../css/TopNav.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';

import { DIALOG_NAMES } from '../managers/DialogManager';
import { VIEW_SELECTION } from '../managers/NavigationManager';

import { STUDENT_TOP_NAV, TOP_NAV_NAME } from '../constants';

import InitService from '../services/InitService';
import PopupService from '../services/PopupService';
import StudentHelperService from '../services/StudentHelperService';
import TopNavService from '../services/TopNavService';
import UserService from '../services/UserService';

export default
@inject(
  'accessManager',
  'appManager',
  'assignmentManager',
  'classroomManager',
  'contentManager',
  'courseManager',
  'dialogManager',
  'dynamicSatelliteManager',
  'navigationManager',
  'studentProgressManager',
  'topNavManager',
  'userManager')
@observer
class StudentTopNav extends Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
    this.AriaSkipLink = SatCoreComponent('AriaSkipLink');
    this.ClassMenu = SatCoreComponent('ClassMenu');
    this.LogoButtons = SatCoreComponent('LogoButtons');
    this.ProfileMenu = SatCoreComponent('ProfileMenu');
    this.menuBarRef = React.createRef();
  }

  componentDidMount() {
    const { appManager, dynamicSatelliteManager, history, topNavManager } = this.props;

    topNavManager.setActiveTopNavName(TOP_NAV_NAME.StudentTopNav);

    InitService.initActiveUserRoleAsHtmlBodyAttr();

    // TODO remove // if (dynamicSatelliteManager.isDynamicSatellite) {

    // send user to a specified custom 'main' nav tab if the following conditions are true:
    // (1) user has the proper satellite and publisher permissions to see the given tab (i.e. the tab is in the displayed list), and
    // (2) user is on currently on the dashboard tab (i.e. likely via redirection after login), and
    // (3) user has likely not manually clicked on the 'dashboard' nav tab since login (i.e. ?view='dashboard' param is absent), and
    // (4) satelliteLayoutConfig has a 'mainTopNavId' that is **not** the dashboard ('default')

    // TODO need to modify dynamic satellites to use the same mainTopNavId observables that non-dynamic satellites use
    let mainTopNavId;
    if (dynamicSatelliteManager.isDynamicSatellite) {
      mainTopNavId = dynamicSatelliteManager.studentMainTopNavId;
    } else {
      mainTopNavId = topNavManager.studentMainTopNavId;
    }

    const topNavObj = STUDENT_TOP_NAV[mainTopNavId];

    if (topNavObj?.functionKey) {
      const pathname = window?.location?.pathname;
      const urlParams = new URLSearchParams(window.location.search);
      if (mainTopNavId !== 'default' && pathname === '/' && !urlParams?.has?.('view')) {
        this[topNavObj?.functionKey]?.();
      }
    }
    // }

    this.unlisten = history?.listen?.((location, _action) => {
      appManager.setLocation(location);
    });
  }

  componentWillUnmount() {
    InitService.removeHtmlBodyRoleAttr();
    this.unlisten?.();
  }

  handleJoinClass = () => {
    const { dialogManager } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.JOIN_CLASS, null, this.closeJoinClassModal);
  }

  closeJoinClassModal = async ({ classroom } = {}) => {
    const { appManager, classroomManager, dialogManager, userManager } = this.props;
    if (classroom?.id) {
      await StudentHelperService.handleChangeStudentClassroomAndInitData({
        classroom
      });
      dialogManager.closeDialog(DIALOG_NAMES.JOIN_CLASS);
      appManager.setInitializingApp(false);
      classroomManager.setLoaded(true);
      userManager.setLoaded(true);
    } else {
      dialogManager.closeDialog(DIALOG_NAMES.JOIN_CLASS);
    }
  }

  showProfile = () => {
    const { history } = this.props;
    history.push('/profile', { url: history.createHref(history.location) });
  };

  showExternalDashboard = async () => {
    const { history } = this.props;
    await history.push(`/externalDashboard?view=${VIEW_SELECTION.EXTERNAL_DASHBOARD}`);
  }

  showDashboard = async () => {
    const { history, navigationManager } = this.props;
    navigationManager.setView(VIEW_SELECTION.DASHBOARD);
    // TODO remove // this.setCurrentClassroomId(classroomManager.currentClassroomId);
    await history.push(`/?view=${VIEW_SELECTION.DASHBOARD}`);
  };

  showAssignments = async () => {
    const { history, navigationManager } = this.props;
    navigationManager.setView(VIEW_SELECTION.ASSIGNMENTS);
    await history.push(`/student-assignments?view=${VIEW_SELECTION.ASSIGNMENTS}`);
  };

  showProgress = async () => {
    const { history, navigationManager, studentProgressManager } = this.props;
    if (studentProgressManager.allowStudentProgressNavTab) {
      navigationManager.setView(VIEW_SELECTION.PROGRESS);
      await history.push(`/progress?view=${VIEW_SELECTION.PROGRESS}`);
    }
  };

  showWorkspace = async () => {
    const { appManager, history, navigationManager, userManager } = this.props;
    if (appManager.classWorkspaceFeature && userManager.hasManageUploadedFilesPermission) {
      navigationManager.setView(VIEW_SELECTION.WORKSPACE);
      await history.push(`/workspace?view=${VIEW_SELECTION.WORKSPACE}`);
    }
  };

  isViewSelected = (view) => {
    const { navigationManager: { viewSelection } } = this.props;
    return viewSelection !== null && viewSelection === view;
  }

  isViewSelectedClass = (view) => (this.isViewSelected(view) ? 'selected' : null);

  renderMenuItem = (children, view, props, {
    _id,
    /* navTranslationKey = '', */
    navTranslationRolloverKey = '',
    urlPathname
  } = {}) => {
    const { t, topNavManager, userManager } = this.props;

    const { allowAuthKeyTopNavUrlParamAuthentication, treatTopNavTabsAsAnchorTags } = topNavManager;

    const rolloverMessage = t(navTranslationRolloverKey);

    let href;
    if (treatTopNavTabsAsAnchorTags && urlPathname) {
      href = `${urlPathname}?view=${view}`;
      if (allowAuthKeyTopNavUrlParamAuthentication) {
        href += `&authKey=${Auth.authKey}`;
        href += `&activePermissionId=${userManager.activePermissionId}`;
      }
    }

    return (
      PopupService.renderPopup({
        content: rolloverMessage,
        disabled: !rolloverMessage,
        key: _id,
        trigger: (
          <Menu.Item
            aria-selected={!!this.isViewSelectedClass(view)}
            as={href ? 'a' : 'li'}
            href={href}
            role='tab'
            tabIndex='0'
            {...props}
            className={['tnav-tab', this.isViewSelectedClass(view), (props || {}).className].filter((className) => className).join(' ')}
            onClick={async (event, _data) => {
              if (href) {
                event.preventDefault();
              }
              await props.onClick();
            }}>
            {children}
          </Menu.Item>
        ),
        wide: false
      })
    );
  }

  getNavTabs = () => {
    const { getNavTabs } = this.props;
    return getNavTabs !== undefined ? (
      <>
        {getNavTabs(this.props, this)}
        {this.getAdditionalTopNavExternalResources()}
      </>
    ) : (
      <>
        {this.getBaseNavTabs()}
        {this.getAdditionalTopNavExternalResources()}
      </>
    );
  };

  // TODO remove
  // // TODO DEMO-2356 - this is temporary for testing BE report rewrite
  // experimentalClick = async (e) => {
  //   const { history, studentProgressManager, userManager } = this.props;

  //   if (e.shiftKey) {
  //     const entityId = userManager.userId;
  //     const reportId = `${userManager.clientPrefix}_STUDENT_PROGRESS`;

  //     history.push({
  //       pathname: '/reportJsonDiff',
  //       search: `?reportId=${reportId}&entityId=${entityId}`
  //     });
  //   } else {
  //     studentProgressManager.setIsExperimentalReportToggled(!studentProgressManager.isExperimentalReportToggled);
  //     await StudentHelperService.fetchStudentProgressData();
  //   }
  // }

  getBaseNavTabs = () => {
    const {
      accessManager: {
        policies: {
          studentTopNav: {
            assignments: allowClickAssignmentsNavTab,
            books: allowClickCoursesNavTab,
            progress: allowClickProgressNavTab,
            workspace: allowClickWorkspaceNavTab
          }
        }
      },
      appManager,
      courseManager,
      dynamicSatelliteManager,
      studentProgressManager,
      t,
      topNavManager,
      userManager
    } = this.props;

    const { studentTopNavOrderArray } = topNavManager;

    const tabNavOrderArray = (dynamicSatelliteManager.isDynamicSatellite ? (
      dynamicSatelliteManager.studentTopNavOrderArray
    ) : studentTopNavOrderArray) || [];

    return tabNavOrderArray.map((tabNavId) => {
      const tabNavObj = STUDENT_TOP_NAV[tabNavId];

      const { _id, className, functionKey, navTranslationKey, viewSelectionKey } = tabNavObj;

      let disabled = false;
      switch (_id) {
        case STUDENT_TOP_NAV.assignments._id:
          disabled = !allowClickAssignmentsNavTab;
          break;
        case STUDENT_TOP_NAV.courses._id:
          if (!courseManager.allowStudentBooks) {
            return;
          }
          disabled = !allowClickCoursesNavTab;
          break;
        case STUDENT_TOP_NAV.dashboard._id:
          break;
        case STUDENT_TOP_NAV.progress._id:
          if (!studentProgressManager.allowStudentProgressNavTab) {
            return;
          }
          disabled = !allowClickProgressNavTab;
          break;
        case STUDENT_TOP_NAV.workspace._id:
          if (!appManager.classWorkspaceFeature || !userManager.hasManageUploadedFilesPermission) {
            return;
          }
          disabled = !allowClickWorkspaceNavTab;
          break;
      }

      return this.renderMenuItem(t(navTranslationKey), VIEW_SELECTION[viewSelectionKey], {
        className, disabled, onClick: this[functionKey]
      }, {
        ...(tabNavObj || {})
        // navTranslationKey, navTranslationRolloverKey
      });
    }).filter((tabNavObj) => !!tabNavObj);

    // TODO remove
    // // TODO DEMO-2356 - this is temporary for testing BE report rewrite
    // const { userManager } = this.props;
    // const allowExperimentalReport = userManager.hasExperimentalAccessPermission
    //   && userManager.isStudent
    //   && this.isViewSelected(VIEW_SELECTION.PROGRESS);
    // const experimentalLabel = (studentProgressManager.isExperimentalReportToggled) ?
    //   ReactHtmlParser('<div>Experimental<br />Report</div>') : ReactHtmlParser('<div>Original<br />Report</div>');

    // TODO remove
    // return (
    //   <>
    //     {this.renderMenuItem(t('DashboardLabel'), VIEW_SELECTION.DASHBOARD, {
    //       className: 'dashboard', onClick: this.showDashboard
    //     }, { navTranslationKey: 'DashboardLabel', navTranslationRolloverKey: 'DashboardRollover' })}

    //     {this.renderMenuItem(t('AssignmentLabel'), VIEW_SELECTION.ASSIGNMENTS, {
    //       className: 'assignments',
    //       disabled: !assignments,
    //       onClick: this.showAssignments,
    //     }, { navTranslationKey: 'AssignmentLabel', navTranslationRolloverKey: 'AssignmentRollover' })}

    //     {studentProgressManager.allowStudentProgressNavTab && this.renderMenuItem(t('ProgressLabel'), VIEW_SELECTION.PROGRESS, {
    //       className: 'progress',
    //       disabled: !progress,
    //       onClick: this.showProgress
    //     }, { navTranslationKey: 'ProgressLabel', navTranslationRolloverKey: 'ProgressRollover' })}

    //     {allowExperimentalReport && studentProgressManager.allowStudentProgressNavTab && this.renderMenuItem(experimentalLabel,'dummy', {
    //       className: 'progress',
    //       disabled: !progress,
    //       onClick: this.experimentalClick
    //     })}

    //     {courseManager.allowStudentBooks && this.renderMenuItem(t('BookLabel'), VIEW_SELECTION.BOOK, {
    //       className: 'books',
    //       disabled: !books,
    //       onClick: this.showBook
    //     }, { navTranslationKey: 'BookLabel', navTranslationRolloverKey: 'BookRollover' })}

    //     {appManager.classWorkspaceFeature && userManager.hasManageUploadedFilesPermission && (
    //       this.renderMenuItem(t('WorkspaceLabel'), VIEW_SELECTION.WORKSPACE, {
    //         className: 'workspace',
    //         disabled: !workspace,
    //         onClick: this.showWorkspace
    //       }, { navTranslationKey: 'WorkspaceLabel', navTranslationRolloverKey: 'WorkspaceRollover' })
    //     )}
    //   </>
    // );
  };

  getAdditionalTopNavExternalResources = () => {
    const { t, topNavManager } = this.props;

    const { additionalStudentTopNavExternalResources } = topNavManager;

    if (!Array.isArray(additionalStudentTopNavExternalResources) || !additionalStudentTopNavExternalResources?.length) {
      return null;
    }

    return additionalStudentTopNavExternalResources.map((tabNavObj) => {
      if (typeof tabNavObj !== 'object' || !tabNavObj?._id) {
        return;
      }

      const className = tabNavObj.className || classNames('resources', tabNavObj._id);
      const navTranslationKey = tabNavObj.navTranslationKey || tabNavObj._id;
      const viewSelectionKey = tabNavObj.viewSelectionKey || 'resources';

      const handleClickExternalResource = () => {
        if (typeof tabNavObj.url === 'string') {
          window.open(tabNavObj.url);
        }
      };

      return this.renderMenuItem(t(navTranslationKey), VIEW_SELECTION[viewSelectionKey], {
        className, onClick: handleClickExternalResource
      }, {
        ...(tabNavObj || {}),
        className,
        navTranslationKey,
        viewSelectionKey
      });
    }).filter((obj) => obj);
  }

  showBook = async () => {
    const { courseManager, history, navigationManager } = this.props;
    if (courseManager.allowStudentBooks) {
      navigationManager.setView(VIEW_SELECTION.BOOK);
      await history.push(`student-book-courses?view=${VIEW_SELECTION.BOOK}`);
    }
  };

  handleLogout = async () => {
    const { history, navigationManager } = this.props;
    navigationManager.setView(VIEW_SELECTION.LOGIN);
    InitService.doLogoutCleanUp();
    history.replace('/signin');
  }

  /**
   * for students, this will update the classroom id,
   * as well as run the `handleChangeStudentClassroomAndInitData` method
   */
  setCurrentClassroomId = async (classroomId) => {
    const { props } = this;
    if (props.setCurrentClassroomId !== undefined) {
      return props.setCurrentClassroomId(classroomId, props, this);
    }
    await this.handleChangeStudentClassroomAndInitData(classroomId);
  }

  handleChangeStudentClassroomAndInitData = async (classroomId) => {
    const { history } = this.props;
    return StudentHelperService.handleChangeStudentClassroomAndInitData({ classroomId, history });
  }

  renderClassMenu = () => {
    const { ClassMenu, props: { t } } = this;
    return (
      <ClassMenu
        handleChangeStudentClassroom={this.handleChangeStudentClassroomAndInitData}
        handleJoinClass={this.handleJoinClass}
        joinClassLabel={t('joinClass', '+ Join Class')}
        optionTitleLabel={t('optionTitle', 'Switch Classes')} />
    );
  }

  renderProfileMenu = () => {
    const { ProfileMenu, props: { history, profileMenu, t, userManager } } = this;
    const { profile = true, items, logout = true } = profileMenu || {};

    const { topNavManager } = this.props;

    const { treatTopNavTabsAsAnchorTags: treatAsHref } = topNavManager;

    const { allowSwitchRole } = userManager;
    const hasMultipleRoles = UserService.getAvailableRoleCount() > 1;

    const { getTopNavAnchorHref } = TopNavService;

    let options = [logout && { onClick: this.handleLogout, text: t('logout') }];
    if (UserService.hasActivePermissionId()) {
      options = [
        profile && {
          className: 'profile',
          href: treatAsHref ? getTopNavAnchorHref('/profile', VIEW_SELECTION.PROFILE) : undefined,
          onClick: (event, _data) => {
            if (treatAsHref) {
              event.preventDefault();
            }
            return this.showProfile();
          },
          text: t('profile')
        },
        allowSwitchRole && hasMultipleRoles && {
          className: 'switchRole',
          href: treatAsHref ? getTopNavAnchorHref('/switch-role', VIEW_SELECTION.ROLE_SWITCHER) : undefined,
          onClick: (event, _data) => {
            if (treatAsHref) {
              event.preventDefault();
            }
            return UserService.showRoleSwitcher(history);
          },
          text: t('switchRole')
        },
        ...(items || []),
        logout && { onClick: this.handleLogout, text: t('logout') }
      ];
    }
    return (
      <ProfileMenu
        {...profileMenu}
        options={options} />
    );
  }

  renderStudentNavigationMenu = () => {
    const { classroomManager, userManager } = this.props;
    const { allowStudentClassMenuAltPosition } = classroomManager;
    return (
      <Menu.Menu
        aria-label='Student navigation panel'
        as={(props) => <nav {...props} />}
        position='right'
        role='navigation'>
        {allowStudentClassMenuAltPosition && (userManager.hideClassMenuDropdown || this.renderClassMenu())}
        <ul className='nav-list' role='tablist'>
          {this.getNavTabs()}
        </ul>
        {this.renderProfileMenu()}
      </Menu.Menu>
    );
  }

  render() {
    const { additionalClassName = '', location, history, classroomManager, userManager } = this.props;
    const { AriaSkipLink, LogoButtons } = this;
    const { allowStudentClassMenuAltPosition } = classroomManager;

    let topNavClassName = 'topNav';
    topNavClassName += additionalClassName ? ` ${additionalClassName}` : '';
    return (
      <>
        <Menu
          as={(props) => <header id='top-nav-bar' role='tablist' {...props} ref={this.menuBarRef} />}
          className={topNavClassName}
          fixed='top'
          inverted
          onKeyDown={(e) => TopNavService.topNavHandleKeyDown(e, this.menuBarRef)}>
          <LogoButtons history={history} location={location} logoImg={Auth.logoStudent} navButton />
          <AriaSkipLink mainContentId='main-content' />
          {!allowStudentClassMenuAltPosition && (userManager.hideClassMenuDropdown || this.renderClassMenu())}
          {this.renderStudentNavigationMenu()}
        </Menu>
      </>
    );
  }
}

SatCoreRegister('StudentTopNav', withRouter(StudentTopNav));
