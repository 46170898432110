/**
 * LibraryAssessmentShopModal
 * ------------------------
 * NOTE: at this point,
 * `assessment` === `courseElement`
 * and the two terms are used interchangeably.
 */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Button, Container, Dropdown,
  Form, Header, Input,
  Label, Message, Pagination,
  Popup
} from 'semantic-ui-react';
import Modal from '../Modal';
import '../../css/LibraryAssessmentShopModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { ASSESSMENT_TRANSACTION } from '../../managers/AssessmentManager';

import { DIALOG_NAMES } from '../../managers/DialogManager';

export default
@inject(
  'assessmentManager', 'classroomManager', 'courseManager', 'libraryManager', 'userManager', 'dialogManager')
@observer
class LibraryAssessmentShopModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.SatCoreLoader = SatCoreComponent('SatCoreLoader');
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
    this.LibraryResourceCard = SatCoreComponent('LibraryResourceCard');
  }

  componentDidMount = async () => {
    const { courseManager, libraryManager } = this.props;
    const { activePage = 1 } = libraryManager;
    const { currentCourseElementList } = courseManager;
    await this.fetchData(activePage);

    const parentContentItemIds = currentCourseElementList.map((element) => element.contentItemId);
    this.setState({ parentContentItemIds });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { libraryManager } = this.props;
    const { activePage } = libraryManager;
    const { sortColumn, sortDirection, showKey, selectedMenu } = this.state;
    if (prevState.selectedMenu !== selectedMenu ||
        prevState.sortColumn !== sortColumn ||
          prevState.sortDirection !== sortDirection ||
            prevState.showKey !== showKey) {
      this.fetchData(activePage);
    }
  }

  getInitialState = () => ({
    deletedAssessmentName: null,
    hasAssessments: false,
    isSelectedAssessmentsPopupOpen: false,
    libraryCardDatas: [],
    loading: false,
    parentContentItemIds: [],
    searchTypes: 'assessment',
    selectedWorkspace: 'myDrafts',
    serverErrorMsg: null,
    sortColumn: 'modificationDate',
    sortDirection: 'desc',
    sortKey: '',
  })

  closeLibraryAssessmentShopModal = async (didWeAddAssessments = false) => {
    const { closeLibraryAssessmentShopView, currentElementId, libraryManager } = this.props;
    // this.setState(this.getInitialState());
    // this.setState({ loading: true });
    // const { libraryManager } = this.props;
    libraryManager.resetPaginator();
    await libraryManager.clearSelectedNotAttachedResources();
    closeLibraryAssessmentShopView(currentElementId, didWeAddAssessments);
    // this.setState({ loading: false });
  }

  submitAddSelectedAssessmentsToBank = async () => {
    const { loading, isSelectedAssessmentsPopupOpen } = this.state;
    if (loading) {
      return false;
    }
    this.setState({ loading: true });
    if (isSelectedAssessmentsPopupOpen) {
      this.closeLibraryShoppingCart();
    }
    await this.saveResults();
  }

  saveResults = async () => {
    const { libraryManager } = this.props;
    if (libraryManager.selectedNotAttachedAssessmentIds.length > 0) {
      await this.doSaveThenRefresh();
    } else {
      this.setState({ loading: false });
      this.closeLibraryAssessmentShopModal();
    }
  }

  doSaveThenRefresh = async () => {
    const { currentCourseId, currentElementId, libraryManager } = this.props;
    const result = await libraryManager.addAssessmentsToBankUnit(
      currentCourseId,
      currentElementId,
      libraryManager.selectedNotAttachedAssessmentIds
    );
    if (!result) {
      alert('An error occurred when trying to save. Please try again later.');
      this.setState({ loading: false });
    } else {
      await this.doRefresh(true);
    }
  }

  doRefresh = async (closeShoppingExperienceAfterRefresh = true) => {
    const { libraryManager } = this.props;
    const { activePage } = libraryManager;
    await this.fetchData(activePage);
    this.setState({ loading: false });
    if (closeShoppingExperienceAfterRefresh) {
      const didWeAddAssessments = true;
      this.closeLibraryAssessmentShopModal(didWeAddAssessments);
    } else {
      await this.refreshAllAssessmentData();
    }
  }

  openLibraryShoppingCart = () => {
    const { dialogManager, libraryManager } = this.props;
    const { selectedNotAttachedAssessmentIds } = libraryManager;
    const { hasAssessments, parentContentItemIds } = this.state;

    const hasSelectedAssessments = selectedNotAttachedAssessmentIds.length;
    if (hasSelectedAssessments) {
      dialogManager.setOpenDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_CART_VIEW, {
        closeLibraryShoppingCart: this.closeLibraryShoppingCart,
        getLibraryCardOptions: this.getLibraryCardOptions,
        handleCheck: this.handleCheck,
        hasAssessments,
        parentContentItemIds,
        submitAddSelectedAssessmentsToBank: this.submitAddSelectedAssessmentsToBank
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_CART_VIEW));
      this.setState({ isSelectedAssessmentsPopupOpen: true });
    }
  }

  closeLibraryShoppingCart = () => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_CART_VIEW);
    this.setState({ isSelectedAssessmentsPopupOpen: false });
  }

  handlePageChange = async (_event, pageInfo) => {
    this.setState({ loading: true });
    const { activePage } = pageInfo;
    const pageSize = ASSESSMENT_TRANSACTION.PAGE_SIZE;
    await this.fetchData(activePage);
    this.setState({ activePage, loading: false });
  }

  handleSort = async (_event, { value }) => {
    let sortColumn = '';
    let sortDirection = '';
    if (value === 'oldest') {
      sortColumn = 'modificationDate';
      sortDirection = 'asc';
    } else if (value === 'title-asc') {
      sortColumn = 'sortName';
      sortDirection = 'asc';
    } else if (value === 'title-desc') {
      sortColumn = 'sortName';
      sortDirection = 'desc';
    } else {
      // default is "newest"
      sortColumn = 'modificationDate';
      sortDirection = 'desc';
    }
    this.setState({ sortColumn, sortDirection, sortKey: value });
  }

  handleChangeSelectedWorkspace = async (_event, { value }) => {
    this.setState({ selectedWorkspace: value });
    this.refreshData();
  }

  handleChangeSearch = (_event, obj) => {
    const searchText = obj && obj.value ? obj.value : '';
    this.setState({ searchTerms: searchText });
    if (searchText) {
      if (searchText.length >= 3) {
        this.refreshData();
      }
    } else {
      this.refreshData();
    }
  }

  editBankAssessment = (libraryCardData) => {
    alert(`Edit ${libraryCardData.title}. TODO redirect to Assessment Builder`);
    // TODO open resource in preview mode by resource type
  }

  previewBankAssessment = (libraryCardData) => {
    const { handleView } = this.props;
    handleView(
      libraryCardData.id,
      libraryCardData?.libraryResource?.entityTypeId,
      libraryCardData?.libraryResource?.displayName
    );
  }

  showLibraryResourceDetails = (libraryCardData) => {
    const { dialogManager } = this.props;
    // Launch the create new assessment/(bank) modal in edit mode
    dialogManager.setOpenDialog(DIALOG_NAMES.CREATE_NEW_LIBRARY_RESOURCE_MODAL, {
      close: { close },
      onClose: () => { this.onCloseCreateNewLibraryResourceModal; },
      closeCreateNewLibraryResourceModal: { close },
      onCloseCreateNewLibraryResourceModal: this.onCloseCreateNewLibraryResourceModal,
      open: true,
      getAll:true,
      isEdit: false,
      isReadOnly: true,
      selectedResourceType: libraryCardData?.libraryResource?.entityTypeId,
      contentItemId: libraryCardData.id
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.CREATE_NEW_LIBRARY_RESOURCE_MODAL));
  }

  onCloseCopyLibraryResourceModal = async () => {
    const { dialogManager, libraryManager } = this.props;
    const { activePage } = libraryManager;
    dialogManager.closeDialog(DIALOG_NAMES.COPY_LIBRARY_RESOURCE_MODAL);
    this.refreshData(activePage);
  }

  copyLibraryResourceConfirm = (libraryCardData, linkedClassroomNames = null) => {
    const { courseManager, dialogManager, t } = this.props;
    const { currentCourseId, currentElementId } = courseManager;
    dialogManager.setOpenDialog(DIALOG_NAMES.COPY_LIBRARY_RESOURCE_MODAL, {
      contentItemId: libraryCardData?.libraryResource?.id,
      currentCourseId,
      currentElementId,
      onCloseCopyLibraryResourceModal: this.onCloseCopyLibraryResourceModal,
      resourceName: libraryCardData?.libraryResource?.name,
      selectedResourceType: libraryCardData?.libraryResource?.entityTypeId
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.COPY_LIBRARY_RESOURCE_MODAL));
  }

  removeLibraryResourceFromBank = async (libraryCardData) => {
    const { dialogManager, libraryManager } = this.props;
    const { activePage } = libraryManager;
    const { libraryResource, typeDisplayName } = libraryCardData;
    const success = await libraryManager.removeLibraryResourceFromBank(libraryResource.id);
    if (!success) {
      const error = `Error removing ${typeDisplayName} from shared.`;
      this.setState({ serverErrorMsg: error });
    } else {
      this.refreshData(activePage);
    }
    dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);
  }

  confirmRemoveFromShared = (libraryCardData) => {
    const { dialogManager, t } = this.props;
    const title = t('removeFromBankOptionLabel');
    const message = `${t('removeFromBankMessagePrefix')} "${libraryCardData.title}" ${t('removeBankSharedMessageSuffix')}`;
    dialogManager.setOpenDialog(DIALOG_NAMES.CONFIRM, {
      title,
      message,
      cancelButtonClass: 'cancelButton',
      cancelButtonName: t('removeFromBankCancelButton'),
      confirmButtonClass: 'saveButton',
      confirmButtonName: t('removeFromBankConfirmButton'),
      confirmHandler: () => this.removeLibraryResourceFromBank(libraryCardData)
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.CONFIRM));
  }

  showLibraryResourceTagsStandards = async (libraryCardData) => {
    const { dialogManager, libraryManager, t } = this.props;
    // alert(`Show tags and standards ${libraryCardData.typeDisplayName} contentItemId: ${libraryCardData.id} called`);
    const results = await libraryManager.getStandardTagAlignmentsByContentId(libraryCardData.id);
    // Open resource tags and standards modal
    dialogManager.setOpenDialog(DIALOG_NAMES.LIBRARY_STANDARDS_TAGS_MODAL, {
      modalTitle: `${libraryCardData.typeDisplayName} ${t('tagsAndStandardsModalTitle')}`,
      standards: results.standards,
      tags: results.tags
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_STANDARDS_TAGS_MODAL));
  }

  getLibraryCardOptions = (resource) => {
    // const { selectedWorkspace } = this.state;
    const { t } = this.props;
    const options = {};
    // define options - currently these are the same for my drafts and shared selections so no filtering is done
    const edit = {
      key: 'edit',
      label: t('editOptionLabel'),
      callback: this.editBankAssessment
    };
    const preview = {
      key: 'preview',
      label: t('previewOptionLabel'),
      callback: this.previewBankAssessment
    };
    const details = {
      key: 'details',
      label: t('detailsOptionLabel'),
      callback: this.showLibraryResourceDetails
    };
    const copy = {
      key: 'copy',
      label: t('copyOptionLabel'),
      callback: this.copyLibraryResourceConfirm
    };
    const removeFromBank = {
      key: 'remove-from-bank',
      label: t('removeFromBankOptionLabel'),
      callback: this.confirmRemoveFromBank
    };
    const tagsAndStandards = {
      key: 'tags-and-standards',
      label: t('tagsAndStandardsLabel'),
      callback: this.showLibraryResourceTagsStandards
    };
    // only show options appropriate for card inside builder
    // options.edit = edit;
    options.preview = preview;
    options.details = details;
    if (resource.libraryResource.published) {
      options.tagsAndStandards = tagsAndStandards;
    }
    // options.copy = copy;
    // options.removeFromBank = removeFromBank;
    return options;
  }

  handleCheck = (assessment) => {
    const { courseManager, libraryManager } = this.props;
    const { currentCourseId, currentCourseElementList } = courseManager;
    const { parentContentItemIds } = this.state;
    const isAssessmentSelected = libraryManager.selectedNotAttachedResources.has(assessment.id);

    if (isAssessmentSelected) {
      libraryManager.removeSelectedNotAttachedAssessment(assessment.id);
    } else {
      if (parentContentItemIds.includes(assessment.id)) {
        for (const element of currentCourseElementList) {
          if (element.contentItemId === assessment.id) {
            libraryManager.removeAssessmentFromBank(element.elementId, currentCourseId);
            // remove the value from the parent array
            const index = parentContentItemIds.indexOf(assessment.id);
            if (index > -1) {
              parentContentItemIds.splice(index, 1);
            }
          }
        }
      } else {
        libraryManager.setAssessmentSelected(assessment.id);
      }
    }
    this.setState({ parentContentItemIds, serverErrorMsg: null });
  }

  doesNotHaveDuplicateAssessmentName = async (name) => {
    const { assessmentManager } = this.props;
    const assessments = assessmentManager.assessmentList;
    const found = assessments.find((assessment) => assessment.name === name || assessment.displayName === name);
    return !found;
  }

  getAssessments = (selectedOnly = null) => {
    const { libraryManager, t } = this.props;
    const { libraryLoading } = libraryManager;
    const { LibraryResourceCard } = this;
    const { hasAssessments, libraryCardDatas, parentContentItemIds } = this.state;
    const { SCCheckbox } = this;
    const jsxArray = [];
    let assessmentList = [];

    let zeroStateMsg = null;
    if (!selectedOnly) {
      if (!hasAssessments) {
        if (!libraryLoading) {
          zeroStateMsg = t('customAssessmentZeroStateMsg', 'There are no assessments added yet.');
        }
      } else {
        assessmentList = libraryCardDatas;
      }
    } else if (selectedOnly) {
      assessmentList = libraryManager.selectedNotAttachedAssessmentArray;
    }

    if (!selectedOnly && zeroStateMsg) {
      jsxArray.push(
        <div
          key='assessment-modal-zero-state'
          className='assessment-modal-zero-state'>
          <div className='assessment-modal-zero-state-text'>
            {zeroStateMsg}
          </div>
        </div>
      );
      return jsxArray;
    }

    assessmentList.map((assessment, index) => {
      // const { libraryResource } = assessment;
      const libraryCardKebabOptions = this.getLibraryCardOptions(assessment);

      if (!selectedOnly && libraryManager.currentLibraryAssessmentMap.has(assessment.id)) {
        assessment = { ...libraryManager.currentLibraryAssessmentMap.get(assessment.id) };
      }
      if (!selectedOnly || (assessment.selected && !assessment.attached)) {
        const isChecked = libraryManager.selectedNotAttachedResources.has(assessment.id) ||
                            parentContentItemIds.includes(assessment.id);
        jsxArray.push(
          <Form.Field key={`${assessment.id}_FF`} className='add-assessment-field'>
            <SCCheckbox
              key={assessment.id}
              checked={isChecked}
              disabled={assessment.attached}
              onChange={() => { this.handleCheck(assessment); }} />
            <LibraryResourceCard key={index}
              libraryCardKebabOptions={libraryCardKebabOptions}
              libraryCardParams={assessment}
              showLiveBanner={false} />
          </Form.Field>);
      }
      return true;
    });
    return jsxArray;
  }

  fetchData = async (activePage = 1) => {
    const { libraryManager } = this.props;
    const { searchTerms, searchTypes, selectedWorkspace, sortDirection, sortColumn } = this.state;
    let libraryCardDatas = [];
    if (selectedWorkspace === 'live') {
      libraryCardDatas = await libraryManager.fetchLibraryLiveProduct(activePage, 25, searchTypes, sortColumn, sortDirection);
    } else if (selectedWorkspace === 'myDrafts') {
      libraryCardDatas = await libraryManager.fetchLibraryMyDrafts(activePage, 25, searchTypes, sortColumn, sortDirection, searchTerms, true);
    } else if (selectedWorkspace === 'sharedDrafts') {
      libraryCardDatas = await libraryManager.fetchLibrarySharedDrafts(activePage, 25, searchTypes, sortColumn, sortDirection, searchTerms, true);
    } else if (selectedWorkspace === 'itemBank') {
      libraryCardDatas = await libraryManager.fetchLibraryItemBanks(activePage, 25, sortColumn, sortDirection);
    }
    this.setState({ hasAssessments: libraryCardDatas.length > 0, libraryCardDatas });
  }

  refreshData = async (activePage = 1) => {
    // Some of the BE aws operations seem to take a bit to process, so if this is a refresh
    // add some delay before fetch.
    setTimeout(() => {
      this.fetchData(activePage);
    }, 500);
  }

  hasClassesWarning = (linkedClassroomNames) => {
    const { dialogManager, t } = this.props;
    const title = t('hasClassesTitle', 'AddCustomAssessmentModal: hasClassesTitle message missing.');
    let message = t('hasClasses', 'AddCustomAssessmentModal: hasClasses message missing.');
    let index = 0;
    for (const className of linkedClassroomNames) {
      message = `${message + ((index > 0) ? ',' : '')}"${className}"`;
      index++;
    }

    dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);

    dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
      title,
      message,
      closeButtonClass: 'deleteButton',
      closeButtonName: 'Ok'
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
  }

  hideError = () => {
    this.setState({ deletedAssessmentName: null, serverErrorMsg: null });
  }

  render() {
    const { SatCoreLoader } = this;
    const {
      hasAssessments,
      isSelectedAssessmentsPopupOpen,
      libraryCardDatas,
      loading,
      searchTerms,
      selectedWorkspace,
      serverErrorMsg,
      sortKey
    } = this.state;
    const { assessmentBankData, libraryAssessmentShopModalOpen, libraryManager, t } = this.props;
    const { activePage = 1, totalPages, totalResults } = libraryManager;
    const { selectedNotAttachedAssessmentIds } = libraryManager;
    const selectedCount = selectedNotAttachedAssessmentIds.length ? selectedNotAttachedAssessmentIds.length : 0;
    const startResultNumber = activePage <= 1 ? 1 : (activePage - 1) * 25 + 1;
    const endResultNumber = (activePage * 25) > totalResults ? totalResults : activePage * 25;

    const paginationJsx = (
      <>
        <div className='pagination-text'>
          {`${startResultNumber}-${endResultNumber} of ${totalResults}`}
        </div>
        <Pagination
          activePage={activePage}
          onPageChange={this.handlePageChange}
          totalPages={totalPages} />
      </>
    );
    const filtersJsx = (
      <div className='modal-content-header-filters'>
        <Dropdown
          fluid
          name='sort-select'
          onChange={this.handleSort}
          options={[
            { key: 1, text: 'Sort by Newest', value: 'newest' },
            { key: 2, text: 'Sort by Oldest', value: 'oldest' },
            { key: 3, text: 'Sort by Title - Ascending', value: 'title-asc' },
            { key: 4, text: 'Sort by Title - Descending', value: 'title-desc' }
          ]}
          placeholder='Sort by'
          search
          selection
          value={sortKey} />
        <Dropdown
          fluid
          name='sort-select'
          onChange={this.handleChangeSelectedWorkspace}
          options={[
            { key: 1, text: t('myDraftsLabel'), value: 'myDrafts' },
            { key: 2, text: t('sharedDraftsLabel'), value: 'sharedDrafts' },
          ]}
          placeholder='Sort by'
          search
          selection
          value={selectedWorkspace} />
        <Input
          autoFocus={!!searchTerms}
          icon='search'
          name='search'
          onChange={this.handleChangeSearch}
          placeholder={t('searchLabel')}
          type='text'
          value={searchTerms} />
      </div>
    );
    const { length } = libraryCardDatas;

    const bannerLabel = t('modalAddNewCustomAssessment', 'Create New Custom Assessment');

    const modalContentJsx = (
      <>
        {(loading) ? (
          <span className='sat-core-loader-wrapper'>
            <SatCoreLoader />
          </span>
        )
          : (
            <Modal.Content scrolling>
              <Container className='library-assessment-shop-container' fluid>
                <Container className='library-assessment-shop-list-container' fluid>
                  <div className='modal-content-header'>
                    {filtersJsx}
                    <div className='modal-content-header-pagination'>
                      {length ? paginationJsx : (hasAssessments ? t('noResultsZeroStateMsg', 'No results found.') : '')}
                    </div>
                  </div>
                  <div className='error-message-wrapper'>
                    <Message
                      className='custom-assessment-modal-error-msg'
                      content={`${serverErrorMsg}`}
                      error
                      hidden={serverErrorMsg === null}
                      onDismiss={this.hideError} />
                  </div>
                  <div className='library-assessment-shop-list-card-container'>
                    {this.getAssessments()}
                  </div>
                  {/* <div className='modal-content-footer'>
                    {!!length && (
                    <div className='modal-content-footer-pagination'>
                      {paginationJsx}
                    </div>
                    )}
                  </div> */}
                </Container>
              </Container>
            </Modal.Content>
          )}
      </>
    );
    return (
      <>
        <Modal
          className='LibraryAssessmentShopModal'
          closeOnDimmerClick={false}
          closeOnEscape={false}
          open={libraryAssessmentShopModalOpen}
          size='fullscreen'
          style={{ overflow: 'hidden', top: '-14px' }}>
          {(loading) ? (
            <Modal.Content>
              <SatCoreLoader />
            </Modal.Content>
          ) : (
            <>
              <Modal.Header className='modal-header'>
                <Header.Content className='modal-header-bar'>
                  <Popup content={assessmentBankData.title} trigger={(
                    <span className='modal-header-label'>
                      {assessmentBankData.title}
                    </span>
                  )} wide />
                  <div className='modal-header-buttons'>
                    <div className={`selected-count-wrapper ${selectedCount ? 'has-selected-count' : 'has-no-selected-count'}`}>
                      <span className='selected-text-label'>
                        Selected Items
                      </span>
                      <Label circular onClick={this.openLibraryShoppingCart}>
                        {selectedCount}
                      </Label>
                    </div>
                    <Button
                      className='ui primary button saveButton'
                      disabled={isSelectedAssessmentsPopupOpen || selectedCount < 1}
                      loading={loading}
                      onClick={this.submitAddSelectedAssessmentsToBank}>
                      Add Assessments
                    </Button>
                    <Button
                      basic
                      className='cancelButton'
                      disabled={isSelectedAssessmentsPopupOpen}
                      onClick={this.closeLibraryAssessmentShopModal}
                      primary>
                      Exit
                    </Button>
                  </div>
                </Header.Content>
              </Modal.Header>
              <div className='nav-separator' />
              {modalContentJsx}
            </>
          )}
        </Modal>
      </>
    );
  }
}

SatCoreRegister('LibraryAssessmentShopModal', LibraryAssessmentShopModal);
