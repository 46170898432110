import { action, makeObservable, observable } from 'mobx';

import {
  DEFAULT_ADMIN_TOP_NAV_ORDER_ARRAY,
  DEFAULT_DISTRICT_ADMIN_TOP_NAV_ORDER_ARRAY,
  DEFAULT_SCHOOL_ADMIN_TOP_NAV_ORDER_ARRAY,
  DEFAULT_STUDENT_TOP_NAV_ORDER_ARRAY,
  DEFAULT_TEACHER_TOP_NAV_ORDER_ARRAY
} from '../constants';

export class TopNavManager {
  @observable activeTopNavName = undefined;

  // TODO need to move computed topNavOrderArray values in dynamicSatelliteManager to use these observables instead
  @observable defaultAdminTopNavOrderArray = DEFAULT_ADMIN_TOP_NAV_ORDER_ARRAY;
  @observable districtAdminTopNavOrderArray = DEFAULT_DISTRICT_ADMIN_TOP_NAV_ORDER_ARRAY;
  @observable schoolAdminTopNavOrderArray = DEFAULT_SCHOOL_ADMIN_TOP_NAV_ORDER_ARRAY;
  @observable studentTopNavOrderArray = DEFAULT_STUDENT_TOP_NAV_ORDER_ARRAY;
  @observable teacherTopNavOrderArray = DEFAULT_TEACHER_TOP_NAV_ORDER_ARRAY;

  @observable defaultAdminMainTopNavId = 'default';
  @observable districtAdminMainTopNavId = 'default';
  @observable schoolAdminMainTopNavId = 'default';
  @observable teacherMainTopNavId = 'default';
  @observable studentMainTopNavId = 'default';

  @observable allowAuthKeyTopNavUrlParamAuthentication = false;
  @observable treatTopNavTabsAsAnchorTags = false;

  @observable isTopNavClassMenuOpen = false;
  @observable isTopNavProfileMenuOpen = false;

  @observable showFullNotificationBanner = false;

  @observable hasTopNavProfileMenuAvatarTrigger = true; // can be overridden via satellite index.js
  @observable shouldTriggerProfileMenuOnHover = true; // can be overridden via satellite index.js

  @observable additionalAdminTopNavExternalResources = [];
  @observable additionalTeacherTopNavExternalResources = [];
  @observable additionalStudentTopNavExternalResources = [];

  constructor() {
    makeObservable(this);
  }

  @action clearAll = () => {
    this.activeTopNavName = undefined;
    this.isTopNavClassMenuOpen = false;
    this.isTopNavProfileMenuOpen = false;
  }

  @action setActiveTopNavName = (topNavName) => {
    this.activeTopNavName = topNavName;
  }

  @action setDefaultAdminTopNavOrderArray = (defaultAdminTopNavOrderArray) => {
    this.defaultAdminTopNavOrderArray = defaultAdminTopNavOrderArray;
  }

  @action setDistrictAdminTopNavOrderArray = (districtAdminTopNavOrderArray) => {
    this.districtAdminTopNavOrderArray = districtAdminTopNavOrderArray;
  }

  @action setSchoolAdminTopNavOrderArray = (schoolAdminTopNavOrderArray) => {
    this.schoolAdminTopNavOrderArray = schoolAdminTopNavOrderArray;
  }

  @action setTeacherTopNavOrderArray = (teacherTopNavOrderArray) => {
    this.teacherTopNavOrderArray = teacherTopNavOrderArray;
  }

  @action setStudentTopNavOrderArray = (studentTopNavOrderArray) => {
    this.studentTopNavOrderArray = studentTopNavOrderArray;
  }

  @action setDefaultAdminMainTopNavId = (defaultAdminMainTopNavId) => {
    this.defaultAdminMainTopNavId = defaultAdminMainTopNavId;
  }

  @action setDistrictAdminMainTopNavId = (districtAdminMainTopNavId) => {
    this.districtAdminMainTopNavId = districtAdminMainTopNavId;
  }

  @action setSchoolAdminMainTopNavId = (schoolAdminMainTopNavId) => {
    this.schoolAdminMainTopNavId = schoolAdminMainTopNavId;
  }

  @action setTeacherMainTopNavId = (teacherMainTopNavId) => {
    this.teacherMainTopNavId = teacherMainTopNavId;
  }

  @action setStudentMainTopNavId = (studentMainTopNavId) => {
    this.studentMainTopNavId = studentMainTopNavId;
  }

  @action setAllowAuthKeyTopNavUrlParamAuthentication = (allowAuthKeyTopNavUrlParamAuthentication) => {
    this.allowAuthKeyTopNavUrlParamAuthentication = allowAuthKeyTopNavUrlParamAuthentication;
  }

  @action setTreatTopNavTabsAsAnchorTags = (treatTopNavTabsAsAnchorTags) => {
    this.treatTopNavTabsAsAnchorTags = treatTopNavTabsAsAnchorTags;
  }

  @action setIsTopNavClassMenuOpen = (isTopNavClassMenuOpen) => {
    this.isTopNavClassMenuOpen = isTopNavClassMenuOpen;
  }

  @action setIsTopNavProfileMenuOpen = (isTopNavProfileMenuOpen) => {
    this.isTopNavProfileMenuOpen = isTopNavProfileMenuOpen;
  }

  @action setShowFullNotificationBanner = (showBanner) => {
    this.showFullNotificationBanner = showBanner;
  };

  @action setHasTopNavProfileMenuAvatarTrigger = (hasTopNavProfileMenuAvatarTrigger) => {
    this.hasTopNavProfileMenuAvatarTrigger = hasTopNavProfileMenuAvatarTrigger;
  }

  @action setShouldTriggerProfileMenuOnHover = (shouldTriggerProfileMenuOnHover) => {
    this.shouldTriggerProfileMenuOnHover = shouldTriggerProfileMenuOnHover;
  }

  /**
   * Set additional external resources for the top navigation bar based on the user type.
   *
   * @param {'admin' | 'teacher' | 'student'} userRole - `'admin' | 'teacher' | 'student'`
   * @param {{
   *   _id: string,
   *   url: string,
   *   className?: string,
   *   navTranslationKey?: string,
   *   navTranslationRolloverKey?: string,
   *   viewSelectionKey?: string
   * }[]} resources - An array of resource objects to add to the top navigation.
   *
   * This data will be used for the **TopNav** `getAdditionalTopNavExternalResources()` methods.
   *
   * Note, via `getAdditionalTopNavExternalResources()`:
   *
   * * `className` will default to `'resources' + _id` if not provided.
   * * `navTranslationKey` will default to `_id` if not provided.
   * * `navTranslationRolloverKey` will default to `undefined` if not provided.
   * * `viewSelectionKey` will default to `'resources'` if not provided.
   *
   * @example
   * setAdditionalTopNavExternalResources('admin', [
   *   { _id: 'documentation', url: 'https://docs.example.com' },
   *   { _id: 'frequentlyAskedQuestions', url: 'https://faq.example.com', navTranslationKey: 'faqLink' }
   * ]);
   */
  @action setAdditionalTopNavExternalResources(userRole, resources) {
    switch (userRole) {
      case 'admin':
        this.additionalAdminTopNavExternalResources = resources;
        break;
      case 'teacher':
        this.additionalTeacherTopNavExternalResources = resources;
        break;
      case 'student':
        this.additionalStudentTopNavExternalResources = resources;
        break;
    }
  }
}

export default new TopNavManager();
