import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Container, Item, Loader
} from 'semantic-ui-react';

import '../css/GradedAssignmentPanel.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';

import { ASSIGNMENT_MENU_STATUS, ASSIGNMENT_STATUS } from '../managers/AssignmentManager';
import { CONTENT_MODE, PLAYER_TYPES } from '../managers/ContentManager';
import { DIALOG_NAMES } from '../managers/DialogManager';

import AssignmentService from '../services/AssignmentService';
import CourseService from '../services/CourseService';
import ContentService from '../services/ContentService';
import ImageService from '../services/ImageService';

export default
@inject(
  'appManager',
  'assignmentManager',
  'classroomManager',
  'contentManager',
  'dialogManager',
  'navigationManager',
  'studentContentCardManager',
  'tagContentManager',
  'userManager')
@observer
class GradedAssignmentPanel extends Component {
  constructor(props) {
    super(props);

    const { t } = this.props;

    this.AssignmentMenu = SatCoreComponent('AssignmentMenu');
    this.DocReaderModal = SatCoreComponent('DocReaderModal');
    this.FileViewerModal = SatCoreComponent('FileViewerModal');
    this.FullscreenModal = SatCoreComponent('FullscreenModal');
    this.LearnosityPlayerModal = SatCoreComponent('LearnosityPlayerModal');
    this.LearnosityScoringModal = SatCoreComponent('LearnosityScoringModal');
    this.NoAssignmentsMessage = SatCoreComponent('NoAssignmentsMessage');
    this.StudentAssignmentCard = SatCoreComponent('StudentAssignmentCard');

    this.state = {
      assignmentId: null,
      contentImageUrl: null,
      contentItemDescription: null,
      contentItemId: '',
      contentName: '',
      contentSubtitle: null,
      instruction: null,
      loading: false,
      menuStatus: ASSIGNMENT_MENU_STATUS.CURRENT,
      resourceName: null,
      search: '',
      sort: t('DefaultSortOrder', 'asc'),
    };
  }

  async componentDidMount() {
    await this.fetchAssignments();
  }

  renderAssignmentCardsStudent = () => {
    const { assignmentManager } = this.props;
    const { menuStatus } = this.state;
    const { StudentAssignmentCard } = this;
    const items = [];

    assignmentManager.assignmentArray.map((assignment) => {
      const contentImageUrl = ImageService.getImageUrl(assignment);

      items.push(
        <StudentAssignmentCard
          key={`AAC_${assignment.id}`}
          assignment={assignment}
          contentImageUrl={contentImageUrl}
          disabled={assignment.licenseExpired}
          doNotDisable={true}
          handlePresent={this.handlePresent}
          handleSelfPacedOpen={this.handleSelfPacedOpen}
          menuStatus={menuStatus} />
      );
      return true;
    });
    return items;
  };

  fetchAssignments = async () => {
    const { appManager, assignmentManager, classroomManager } = this.props;
    if (appManager.initializingApp) {
      return;
    }

    this.setState({
      loading: true,
      menuStatus: 'graded',
      search: '',
      sort: 'asc',
    });

    const status = ['completed'];

    assignmentManager.setAssignmentStatus(status);
    const classroomId = classroomManager.currentClassroomId;
    const assignmentType = null;
    const assignerType = null;
    const pageSize = null;
    const searchAll = true;

    await assignmentManager.fetchStudentAssignments(
      classroomId, null, status,
      assignmentType, '', null, assignerType,
      0, false, true, pageSize, 'asc', searchAll,
      null
    );
    this.setState({ loading: false });
  }

  handleLoadMore = async (page) => {
    this.setState({ loading: true });
    const { assignmentManager } = this.props;
    await assignmentManager.fetchMoreStudentAssignments(page);
    this.setState({ loading: false });
  }

  handlePresent = async (params) => {
    const {
      assignmentManager, contentManager, studentContentCardManager, tagContentManager, userManager,
    } = this.props;

    const { assignment, contentMode, turnInLate } = params;

    if (contentMode === CONTENT_MODE.ASSESS) {
      const preStartContentItemId = assignment.contentItemId;
      if (ASSIGNMENT_STATUS.getCheckableStatuses().includes(assignment.status)) {
        const isReady = await assignmentManager.checkActivity(assignment.activityId);
        if (isReady) {
          const isOk = await assignmentManager.startActivity(assignment.id, assignment.activityId, turnInLate);
          if (isOk === false) {
            return false;
          }
        } else {
          return false;
        }
      }

      const postStartContentItemId = assignment.contentItemId;
      if (preStartContentItemId !== postStartContentItemId && tagContentManager.useContentTags) {
        // this has been versioned. Make sure the image is added.
        // console.log('*** A new version: ' + postStartContentItemId +' has been created from: '+ preStartContentItemId);
        tagContentManager.createNewCardContentTagFromOld(preStartContentItemId, postStartContentItemId);
      }
    }

    let option = null;
    if (contentMode === CONTENT_MODE.REVIEW) {
      option = await contentManager.getOptionsForStudentReview(
        assignment, window.location.origin, userManager.isTeacher, userManager.userId);
    } else {
      option = await contentManager.getOptionsForStudentAssessment(
        assignment, window.location.origin, userManager.isTeacher, userManager.userId);
    }

    const { playerType, viewUrl, isFlowpaper } = option;

    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');

      if (studentContentCardManager.isLoading) {
        studentContentCardManager.setIsLoading(false);
        this.forceUpdate();
      }
      return;
    }

    contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);

    const contentImageUrl = ImageService.getImageUrl(assignment);

    const { dialogManager } = this.props;
    if (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER) {
      dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
        assignmentId: assignment.id,
        closeModalCallback: this.hideModal,
        contentItemId: assignment.contentItemId,
        contentItemType: assignment.contentItemEntityTypeId,
        contentMode,
        instruction: assignment.activityInstruction,
        resourceName: (assignment.resourceWebTitle !== '' && assignment.resourceWebTitle != null) ?
          assignment.resourceWebTitle : assignment.name
      }, () => dialogManager.closeDialog(DIALOG_NAMES.CONTENT_PREVIEW));
      return;
    }

    if (playerType === PLAYER_TYPES.LESSON_PLAYER) {
      window.open(viewUrl, '_blank');
    }

    const {
      resourceWebTitle, resourceWebSubtitle
    } = AssignmentService.getAssignmentTitleAndDescription(assignment);

    this.setState({
      activityId: assignment.activityId,
      assignmentId: assignment.id,
      contentImageUrl,
      contentItemId: assignment.contentItemId,
      contentMode,
      contentName: resourceWebTitle,
      contentSubtitle: resourceWebSubtitle,
      contentUrl: viewUrl,
      grade: assignment.grade,
      instruction: assignment.activityInstruction,
      isFlowpaper,
      previewContentType: assignment.contentItemEntityTypeId,
      resourceName: assignment.contentItemName
    });
  }

  handleSelfPacedOpen = async (contentMode, assignment) => {
    const { assignmentManager, classroomManager } = this.props;
    // first, create the assignment
    await assignmentManager.studentCreateAssignment(
      assignment.contentItemId, assignment.courseResourceElementId, classroomManager.currentClassroomId, assignment.courseContentItemId);
    // then call to open it.
    if (assignmentManager.getLastAddedAssignment()) {
      this.handlePresent({ assignment: assignmentManager.getLastAddedAssignment(), contentMode });
    }
  }

  hideModal = () => {
    const { menuStatus, search, sort } = this.state;
    this.setState({
      docreaderViewerShowing: false,
      fileViewerShowing: false,
      learnosityPlayerShowing: false,
      learnosityScoringPlayerShowing: false,
    });
    this.fetchAssignments(menuStatus, search, sort);
  }

  hideIframeFromOuterClick = (event) => {
    if ((event.origin === Auth.publisher || event.origin === Auth.lesson) && event.data === 'hideIframe') {
      this.setState({ lessonPlayerShowing: false });
      ContentService.setLessonPlayerShowing(false);
      ContentService.setSatelliteTabShowing(false);
      CourseService.setCourseResourceItemIdForRelatedItems(null);
      CourseService.setCourseContentItemIdForRelatedItems(null);
      CourseService.setTagIds('', true);
      window.removeEventListener('message', this.hideIframeFromOuterClick);
    }
  }

  hideIframe = () => {
    this.setState({ lessonPlayerShowing: false });
  }

  renderFileViewer = () => {
    const { userManager } = this.props;
    const {
      activityId,
      assignmentId,
      contentImageUrl,
      contentUrl,
      contentItemId,
      contentMode,
      contentName,
      instruction,
      isFlowpaper,
      previewContentType,
      resourceName
    } = this.state;
    const { FileViewerModal } = this;
    return (
      <div className='course-content'>
        <FileViewerModal
          activityId={activityId}
          assignmentId={assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={contentImageUrl}
          contentItemId={contentItemId}
          contentMode={contentMode}
          contentName={contentName}
          contentType={previewContentType}
          instruction={instruction}
          isFlowpaper={isFlowpaper}
          isTeacher={userManager.isTeacher}
          page='file-viewer'
          resourceName={resourceName}
          url={contentUrl} />
      </div>
    );
  }

  renderDocreaderViewer = () => {
    const { contentManager, renderDocreaderViewer, userManager } = this.props;
    const {
      assignmentId,
      contentImageUrl,
      contentMode,
      contentName,
      previewContentType,
      instruction,
      isFlowpaper,
      resourceName,
      contentUrl
    } = this.state;
    const { DocReaderModal } = this;
    if (renderDocreaderViewer !== undefined) {
      return renderDocreaderViewer();
    }

    return (
      <div className='course-content'>
        <DocReaderModal
          assignmentId={assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={contentImageUrl}
          contentMode={contentMode}
          contentName={contentName}
          contentType={previewContentType}
          instruction={instruction}
          isFlowpaper={isFlowpaper}
          isTeacher={userManager.isTeacher}
          page='docreader-viewer'
          resourceName={resourceName}
          sessionId={contentManager.currentflowPaperSessionId}
          url={contentUrl} />
      </div>
    );
  }

  renderLearnosityPlayer = () => {
    const { userManager, history } = this.props;
    const {
      assignmentId,
      contentImageUrl,
      contentItemId,
      contentName,
      contentSubtitle,
      instruction,
      resourceName
    } = this.state;
    const { LearnosityPlayerModal } = this;
    return (
      <div className='course-content'>
        <LearnosityPlayerModal
          assignmentId={assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={contentImageUrl}
          contentItemId={contentItemId}
          contentName={contentName}
          contentSubtitle={contentSubtitle}
          history={history}
          instruction={instruction}
          isTeacher={userManager.isTeacher}
          page='learnosity-player'
          resourceName={resourceName} />
      </div>
    );
  }

  renderLearnosityScoringModal = () => {
    const { history, userManager } = this.props;
    const {
      assignmentId,
      contentImageUrl,
      contentItemDescription,
      contentItemId,
      contentName,
      grade,
      instruction,
      resourceName
    } = this.state;
    const { LearnosityScoringModal } = this;
    return (
      <div className='course-content'>
        <LearnosityScoringModal
          activityInstanceId={assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={contentImageUrl}
          contentItemId={contentItemId}
          contentSubTitle={contentItemDescription}
          contentTitle={contentName}
          grade={grade}
          history={history}
          instruction={instruction}
          isTeacher={userManager.isTeacher}
          page='learnosity-scoring'
          resourceName={resourceName}
          studentName={`${userManager.firstName} ${userManager.lastName}`} />
      </div>
    );
  }

  render() {
    const { assignmentManager, t } = this.props;
    const { loading } = this.state;

    const { NoAssignmentsMessage } = this;

    return (
      <>
        <Container className='student-assignment-view-container' fluid>
          {/* eslint-disable-next-line no-nested-ternary */}
          {((!assignmentManager.loaded || loading) && !assignmentManager.assignmentArray.length) ? <Loader active /> :
            (!assignmentManager.assignmentArray.length) ? (
              <>
                <NoAssignmentsMessage message={
                  t('NoGradedAssignments',
                    'Missing translation in GradedAssignmentPanel'
                  )
                } />
              </>
            ) : (
              <Container className='search-gui'>
                <Container className='cards'>
                  <Item.Group>
                    {this.renderAssignmentCardsStudent()}
                  </Item.Group>
                </Container>
              </Container>
            )}
        </Container>
      </>
    );
  }

  renderLessonPlayer = () => {
    const { userManager } = this.props;
    const { contentImageUrl, contentName, contentUrl, instruction } = this.state;
    const { FullscreenModal } = this;

    return (
      <div className='course-content'>
        <FullscreenModal
          className='lesson-player'
          closeIframeCallback={this.hideIframe}
          contentImageUrl={contentImageUrl}
          contentName={contentName}
          instruction={instruction}
          isTeacher={userManager.isTeacher}
          page='lesson-player'
          url={contentUrl} />
      </div>
    );
  }
}

SatCoreRegister('GradedAssignmentPanel', GradedAssignmentPanel);
