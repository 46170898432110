import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { computedFn } from 'mobx-utils';

import { getRegisteredClass } from '../SatCoreRegistry';

import { ONLINE_CONTENT_TYPES } from './TagContentManager';

import Auth from './AuthManager';
import classroomManager from './ClassroomManager';
import productManager from './ProductManager';
import userManager from './UserManager';

import { CoursesApi } from '../api';
import { generateUrl } from '../utils/url';

import { register } from '../i18n';

const t = register('CourseManager');

export const ELEMENT_TYPE = {
  UNIT: 'UNIT',
  ROOT: 'ROOT',
  SECTION: 'SECTION',
  isBranchType: (courseElement) => {
    if (courseElement &&
      (courseElement.type === ELEMENT_TYPE.UNIT ||
       courseElement.type === ELEMENT_TYPE.SECTION ||
       courseElement.type === ELEMENT_TYPE.ROOT)) {
      return true;
    }
    return false;
  }
};

export const COURSE_ENDPOINTS = {
  ADD_COURSES_TO_CLASSROOM: '/api/addCoursesToClassroom',
  ADD_NO_PRODUCT_COURSES_TO_CLASSROOM: '/api/addNoProductCoursesToClassroom',
  ADD_COURSE_ELEMENTS_TO_LTI_COURSE: '/api/createLMSActivities',
  ADD_COURSE_TO_CLASSROOMS: '/api/addCourseToClassrooms',
  ADD_UNIT_TO_COURSE: '/api/addCourseResourceUnit',
  CREATE_COURSE_PLACEHOLDER_RESOURCE: '/api/createCustomCoursePlaceholderResource',
  CREATE_CUSTOM_COURSE: '/api/createCustomCourseResource',
  DELETE_CONTENT_ITEM: '/api/deleteContentItem',
  DELETE_COURSE_RESOURCE_ELEMENT: '/api/deleteCourseResourceElement',
  FETCH_CLASSROOMS_BY_COURSE: '/api/viewClassroomsByCourse',
  FETCH_CLASSROOM_COURSE_CONTENT_ITEM_IDS_FOR_CURRENT_USER: '/api/viewAllClassroomCourses',
  FETCH_COURSE_CLASSROOMS: '/api/viewCourseClassrooms',
  GET_ADMIN_LICENSED_COURSE_DETAILS: '/api/viewAdminCourseDetails',
  GET_ALIGNMENTS_GROUPED_BY_DOCUMENT: '/api/viewAlignmentsGroupedByDocument',
  GET_CLASS_COURSES: '/api/viewClassroomCourses',
  GET_CONTENT_ITEM: '/api/viewContentItem',
  GET_COURSE_ELEMENTS_CMAP_ELEMENT: '/api/viewAssignableUntakenElementsByCmapElement',
  GET_COURSE_ELEMENTS_STANDARD: '/api/viewAssignableUntakenElementsByStandard',
  GET_COURSE_ELEMENTS_STUDENT: '/api/viewCourseElementsByParentForStudent',
  GET_COURSE_ELEMENTS_TEACHER: '/api/viewCourseElementsByParentForClassroom',
  GET_COURSE_RIBBON_ELEMENTS_TEACHER: '/api/viewCourseElementParentHeirarchyController',
  GET_COURSE_LIST: '/api/searchContentItemsByProductLicenseForClassroom',
  GET_COURSE_RESOURCE_ELEMENT: '/api/viewCourseResourceElement',
  GET_DASHBOARD_COURSES: '/api/viewDashboardCourses',
  GET_SINGULAR_COURSE_ELEMENT: '/api/viewCourseElementForClassroom',
  GET_TEACHER_LICENSED_COURSE_DETAILS: '/api/viewCourseDetailsForTeacher',
  MOVE_COURSE_RESOURCE_ELEMENT: '/api/moveCourseResourceElement',
  REMOVE_COURSES: '/api/removeCourseFromClassroom',
  SEARCH_CONTENT_ITEMS: '/api/searchContentItems',
  SEARCH_CONTENT_ITEMS_BY_ADMIN_PRODUCT_LICENSE: '/api/searchContentItemsByAdminProductLicense',
  SEARCH_CONTENT_ITEMS_BY_DISTRICT_WORKSPACE: '/api/searchContentItemsByDistrictWorkspace',
  SEARCH_CONTENT_ITEMS_BY_PRODUCT: '/api/searchContentItemsByProduct',
  SEARCH_CONTENT_ITEMS_BY_WORKSPACE: '/api/searchContentItemsByWorkspace',
  SEARCH_COURSE_RESOURCE_ELEMENTS: '/api/searchCourseResourceElements',
  UPDATE_COURSE_RESOURCE_ELEMENT: '/api/editCourseResourceElement',
  UPDATE_DELIVERY_MODE: '/api/editCourseResourceElementDeliveryModeCustom'
};

export class CourseManager {
  constructor() {
    makeObservable(this);
  }

  @observable courses = new Map(); // courses assigned to the current classroom
  @observable productCourses = new Map(); // all possible courses the user has access to
  @observable selectedNotAttachedProductCourses = new Map();
  @observable courseTreeMap = new Map(); // the detailed structure of the current course tree for the current classroom

  @observable allowFetchCourseData = true;
  @observable allowCourseTreeLeafFooter = false;
  @observable allowRibbonTOC = false;
  @observable allowStudentBooks = true;

  @observable forceShowCourseElementModalAllAssignmentsButtonSet = new Set();

  @observable currentCourseId = null; // the current course selected by the user
  @observable currentCourseName = null; // the current course selected by the user
  @observable currentElementId = ''; // where the user has navigated to in a course tree/map

  @observable newCustomCourseName = null;
  @observable newCustomCourse = null;

  @observable treeLoading = null;

  @observable includeAlignments = false;
  @observable extraActivities = [];

  @observable coursesLoading = false;
  @observable courseElementListLoading = false;
  @observable courseElementListHasMore = false;
  @observable courseElementCourseOptions = [];

  @observable activeTabIndex = 0;
  @observable activeTab = 'publisher_courses';

  @observable searchText = '';
  @observable searchTextTimeout = null;

  @observable truePublisherCoursesCount = undefined;

  @observable activeProductCoursePage = 1;
  // TODO remove // @observable totalPages = 0;
  @observable allowCustomCourses = false;
  @observable useSpecialCustomCourseCardImages = false;

  @observable customCoursesMap = new Map();

  @observable isDeleteCourseWarningModalOpen = false;
  @observable isRenameCourseModalOpen = false;

  @observable warningCourse = null;
  @observable renameCourse = null;
  @observable fullCourseResource = null;

  @observable searchCourseElementList = [];
  @observable relatedContentElementList = [];
  @observable totalPages = 0;
  @observable totalItems = 0;
  @observable totalPagesRelated = 0;
  @observable totalItemsRelated = 0;
  @observable activePage = 1;
  @observable activePageRelated = 1;
  itemsPerPage = 15;
  @observable isLoading = false;

  @observable courseContentItemIds = [];
  resourceName = null;
  @observable tagIds = [];
  @observable curriculumMapElementIds = [];

  @observable previewAsStudent = false;

  @observable useNoProductCourses = false;
  @observable courseResourceItemIdForRelatedItems = null;
  @observable courseContentItemIdForRelatedItems = null;

  @action clearCourseElementSearch(forRelatedContent) {
    if (forRelatedContent) {
      this.relatedContentElementList = [];
      this.totalPagesRelated = 0;
      this.totalItemsRelated = 0;
      this.activePageRelated = 1;
    } else {
      this.searchCourseElementList = [];

      this.totalPages = 0;
      this.totalItems = 0;
      this.activePage = 1;
    }
    this.isLoading = true;

    this.courseContentItemIds = [];
    this.resourceName = null;
    this.tagIds = [];
    this.curriculumMapElementIds = [];
  }

  @action
  setIsLoading = (toggle) => {
    this.isLoading = toggle;
  }

  @action setCourseResourceItemIdForRelatedItems = (id) => {
    this.courseResourceIdForRelatedItems = id;
  }

  @action resetCourseElementSearch() {
    // this.searchCourseElementList = [];
    this.activePage = 1;
    this.isLoading = true;

    // this.courseContentItemIds = [];
    this.resourceName = null;
    this.tagIds = [];
    this.curriculumMapElementIds = [];
  }

  @action setSearchCourseElementList(list) {
    this.searchCourseElementList = list;
  }

  @action setRelatedContentElementList(list) {
    this.relatedContentElementList = list;
  }

  @action setTotalPages(totalPages) {
    this.totalPages = totalPages;
  }

  @action setTotalItems(totalItems) {
    this.totalItems = totalItems;
  }

  @action setTotalPagesRelated(totalPages) {
    this.totalPagesRelated = totalPages;
  }

  @action setTotalItemsRelated(totalItems) {
    this.totalItemsRelated = totalItems;
  }

  @action setActivePage(activePage) {
    this.activePage = activePage;
  }

  @action setActivePageRelated(activePage) {
    this.activePageRelated = activePage;
  }

  @action setCourseContentItemIds(courseContentItemIds, forRelatedContent) {
    this.courseContentItemIds = courseContentItemIds;

    if (forRelatedContent) {
      this.totalPagesRelated = 0;
      this.totalItemsRelated = 0;
      this.activePageRelated = 1;
    } else {
      this.totalPages = 0;
      this.totalItems = 0;
      this.activePage = 1;
    }
  }

  @action setResourceName(resourceName, forRelatedContent) {
    this.resourceName = resourceName;
    if (forRelatedContent) {
      this.totalPagesRelated = 0;
      this.totalItemsRelated = 0;
      this.activePageRelated = 1;
    } else {
      this.totalPages = 0;
      this.totalItems = 0;
      this.activePage = 1;
    }
  }

  @action setTagIds(tagIds, forRelatedContent) {
    this.tagIds = tagIds;
    if (forRelatedContent) {
      this.totalPagesRelated = 0;
      this.totalItemsRelated = 0;
      this.activePageRelated = 1;
    } else {
      this.totalPages = 0;
      this.totalItems = 0;
      this.activePage = 1;
    }
  }

  @action setCurriculumMapElementIds(curriculumMapElementIds, forRelatedContent) {
    this.curriculumMapElementIds = curriculumMapElementIds;
    if (forRelatedContent) {
      this.totalPagesRelated = 0;
      this.totalItemsRelated = 0;
      this.activePageRelated = 1;
    } else {
      this.totalPages = 0;
      this.totalItems = 0;
      this.activePage = 1;
    }
  }

  @action getUnitSectionName(courseElement, orderNumber) {
    const isCustomCourse = this.isCustomCourse();
    if (courseElement && courseElement.name &&
      (courseElement.unitHidden || isCustomCourse)
    ) {
      const { name } = courseElement;
      return name;
    } else if (isCustomCourse) {
      let name = 'Assessments';
      if (userManager.clientPrefix === 'GALL') {
        name = 'Custom ExperTrack Assessments';
        return name;
      }
    } else if (courseElement && courseElement.type === ELEMENT_TYPE.UNIT && !courseElement.unitHidden) {
      const name = `${courseElement.unitName || courseElement.courseUnitName || 'Unit'} ${orderNumber + 1}: ${courseElement.name}`;
      return name;
    } else if (courseElement && courseElement.type === ELEMENT_TYPE.SECTION && !courseElement.unitHidden) {
      const { name } = courseElement;
      return name;
    } else {
      return '';
    }
  }

  @action setTreeLoading = (loading) => {
    this.treeLoading = loading;
  }

  @computed get isTreeLoading() {
    return this.treeLoading;
  }

  @action setAllowFetchCourseData = (toggle) => {
    this.allowFetchCourseData = toggle;
  }

  @action setAllowCourseTreeLeafFooter = (toggle) => {
    this.allowCourseTreeLeafFooter = toggle;
  }

  @action setAllowStudentBooks = (toggle) => {
    this.allowStudentBooks = toggle;
  }

  @action setAllowRibbonTOC= (toggle) => {
    this.allowRibbonTOC = toggle;
  }

  @action setForceShowCourseElementModalAllAssignmentsButton = (courseElementId) => {
    this.forceShowCourseElementModalAllAssignmentsButtonSet.add(courseElementId);
  }

  @action clearForceShowCourseElementModalAllAssignmentsButtonSet = () => {
    this.forceShowCourseElementModalAllAssignmentsButtonSet.clear();
  }

  @action setCourseContentItemIdForRelatedItems = (id) => {
    this.courseContentItemIdForRelatedItems = id;
  }

  @action setCurrentCourseId = (id) => {
    if (id === 'null') {
      this.currentCourseId = null;
    } else {
      this.currentCourseId = id;
    }
  }

  @action setNewCustomCourseName = (name) => {
    this.newCustomCourseName = name;
  }

  @action setNewCustomCourse = (course) => {
    this.newCustomCourse = course;
  }

  @action setCurrentCourseName = (name) => {
    this.currentCourseName = name;
  }

  @action setCurrentElementId = (id) => {
    this.currentElementId = id;
  }

  @action addCourse = (classroomId, newCourse) => {
    if (newCourse && classroomId) {
      const courseArray = this.courses.get(classroomId);
      if (courseArray !== undefined && courseArray.length > 0) {
        const course = courseArray.find((element) => element.id === newCourse.id);
        if (course === undefined) {
          courseArray.push(newCourse);
        } else {
          const index = courseArray.findIndex((element) => element.id === newCourse.id);
          courseArray[index] = course;
          this.courses.set(classroomId, courseArray);
        }
      } else {
        this.courses.set(classroomId, [newCourse]);
      }
    }
  }

  @action removeCourse = (classroomId, id) => {
    if (this.courses.get(classroomId)) {
      const array = this.courses.get(classroomId).filter((element) => (element.id !== id));
      this.courses.set(classroomId, array);
    }
  }

  @action updateCourseName = (classroomId, id, name) => {
    if (this.courses.get(classroomId)) {
      const array = this.courses.get(classroomId);
      for (const course of array) {
        if (course.id === id) {
          course.name = name;
          break;
        }
      }
      this.courses.set(classroomId, array);
    }
  }

  @action addProductCourse = (id, course) => {
    if (course && id) {
      if (!this.productCourses.has(id)) {
        this.productCourses.set(id, course);
      }
      if (course.selected && !course.attached) {
        this.addSelectedNotAttachedProductCourse(course);
      }
    }
  }

  @action setFullCourseResource = (fullCourseResource) => {
    this.fullCourseResource = fullCourseResource;
  }

  @computed get selectedNotAttachedProductCourseIds() {
    return Array.from(this.selectedNotAttachedProductCourses.keys());
  }

  @computed get selectedNotAttachedProductCourseArray() {
    return Array.from(this.selectedNotAttachedProductCourses.values());
  }

  @action addSelectedNotAttachedProductCourse = (course) => {
    if (course && course.id) {
      this.selectedNotAttachedProductCourses.set(course.id, course);
    }
  }

  @action removeSelectedNotAttachedProductCourse = (courseId) => {
    if (courseId && this.selectedNotAttachedProductCourses.has(courseId)) {
      this.selectedNotAttachedProductCourses.delete(courseId);
    }
  }

  @action clearSelectedNotAttachedProductCourses = () => {
    this.selectedNotAttachedProductCourses.clear();
  }

  @action fetchClassroomCourses = async (classroomId, isTeacher, isStudent) => {
    this.clearData();

    if (classroomId === 'product' || classroomId === 'FROM_TEACHER_PRODUCTS_NAV') {
      return null;
    }

    const classroomData = classroomManager.classrooms.get(classroomId);
    let teachersIds = [];
    if (classroomData) {
      teachersIds = [classroomData.leadTeacherId, ...classroomData.coteacherIds];
    }
    try {
      const ProductService = getRegisteredClass('ProductService');
      ProductService.setShouldShowExpiredLicenseWarning(false);

      let shouldShowExpiredLicenseWarning;

      this.setCoursesLoading(true);
      let response;
      if (isStudent) {
        response = await Auth.fetch(`${Auth.ecms}${COURSE_ENDPOINTS.GET_DASHBOARD_COURSES}?classroomId=${classroomId}&sortByName=true`, {
          method: 'GET'
        });
      } else {
        response = await Auth.fetch(`${Auth.ecms}${COURSE_ENDPOINTS.GET_CLASS_COURSES}?classroomId=${classroomId}&includeExtraActivities=${isTeacher}&sortByName=true`, {
          method: 'GET'
        });
      }
      if (response.extraActivities) {
        // note: courseManager.extraActivities is an array of ASSIGNMENTS FROM REMOVED COURSES, if any
        this.extraActivities = [...response.extraActivities];
      }
      let set = true;
      const courses = response?.data || [];

      if (response.status === 'SUCCESS') {
        shouldShowExpiredLicenseWarning = courses.some((course) => course.licenseExpired);

        courses.forEach((course) => {
          if (isStudent && course && course.contextType === 'workbench' && teachersIds.includes(course.ownerId)) return;
          if (set) {
            this.setCurrentCourseId(course.id);
            this.setCurrentElementId('');
            set = false;
          }
          this.addCourse(classroomId, course);
        });

        /*-----------------------------------------------------------
         * Here we are fetching **ALL** available custom courses
         * so we can store it in `customCoursesMap` for future use.
         *
         * e.g. if a given courseId exists in the `customCoursesMap`,
         * `isCustomCourse(courseId)` will return true
         *----------------------------------------------------------*/
        const returnImmediatelyAfterResponse = true;
        const includeBlankCard = false;
        const searchTerms = '';
        const searchTypes = 'course_resource';
        const searchScope = 'MINE';
        const page = 0;
        const pageSize = 999;
        const searchText = '';
        const customCoursesResponse = await this.searchContentItems(
          returnImmediatelyAfterResponse, classroomId, includeBlankCard,
          searchTerms, searchTypes, searchScope, page, pageSize, searchText
        );
        const customCourses = customCoursesResponse.contentItems;
        customCourses.forEach((customCourse) => {
          if (this.newCustomCourseName && customCourse.name === this.newCustomCourseName) {
            this.setNewCustomCourse(customCourse);
          }
          this.addCustomCourse(customCourse.id, customCourse);
          if (this.activeTab === 'custom_courses') {
            this.addProductCourse(customCourse.id, customCourse);
          }
        });
      }
      this.setCoursesLoading(false);
      ProductService.setShouldShowExpiredLicenseWarning(!!shouldShowExpiredLicenseWarning);
    } catch (error) {
      this.setCoursesLoading(false);
      console.error(error);
    }
  }

  /**
   * @returns {Promise<CourseElement>} a singular `courseElement` that corresponds to the provided GET request params
   */
  fetchSingularCourseElementForClassroom = async (
    courseResourceElementId, courseContentItemId, classroomId = null,
    includeAlignments = true, trackAssignedAnywhere = false
  ) => {
    const urlParams = new URLSearchParams(window.location.search);
    classroomId = classroomId || urlParams.get('classroomId') || classroomManager.currentClassroomId;
    const apiUrlPrefix = `${Auth.ecms}${COURSE_ENDPOINTS.GET_SINGULAR_COURSE_ELEMENT}`;
    const apiUrl = generateUrl(apiUrlPrefix, {
      classroomId, courseContentItemId, courseResourceElementId, includeAlignments, trackAssignedAnywhere
    });
    try {
      const response = await Auth.fetch(apiUrl, { method: 'GET' });
      if (response && response.courseResourceElements) {
        return response.courseResourceElements[0];
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @param {string} courseContentItemId
   * @param {{ returnAs: 'array' | 'map' | 'response' }}
   */
  fetchCourseClassrooms = async (courseContentItemId, {
    returnAs = 'map'
  } = {}) => {
    try {
      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.FETCH_COURSE_CLASSROOMS}?courseContentItemId=${courseContentItemId}`;
      const response = await Auth.fetch(apiUrl, { method: 'GET' });

      if (returnAs === 'response') {
        return response;
      } else if (returnAs === 'array') {
        return response?.data || [];
      } else if (returnAs === 'map') {
        const courseClassroomsMap = new Map();
        for (const courseClassroom of (response?.data || [])) {
          courseClassroomsMap.set(courseClassroom.id, courseClassroom);
        }
        return courseClassroomsMap;
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Return an array of courseContentItemIds associated with at least one of the user's classrooms
   */
  // @action
  fetchClassroomCourseContentItemIdsForCurrentUser = async () => {
    try {
      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.FETCH_CLASSROOM_COURSE_CONTENT_ITEM_IDS_FOR_CURRENT_USER}`;
      const response = await Auth.fetch(apiUrl, { method: 'GET' });

      if (response?.status === 'SUCCESS') {
        return response?.data;
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // @action
  fetchTerseClassroomsByCourseForCurrentUser = async ({ courseContentItemId } = {}) => {
    try {
      let apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.FETCH_CLASSROOMS_BY_COURSE}`;
      apiUrl += `?courseContentItemId=${courseContentItemId}`;

      const response = await Auth.fetch(apiUrl, { method: 'GET' });

      if (response?.status === 'SUCCESS') {
        return response?.data;
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  addCourseElementsToLTICourse = async (data = {}) => {
    const body = JSON.stringify(data);

    try {
      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.ADD_COURSE_ELEMENTS_TO_LTI_COURSE}`;
      const response = await Auth.fetch(apiUrl, {
        method: 'POST',
        body,
      });

      if (response.status === 'SUCCESS') {
        return true;
      } else if (response.status === 'FAILURE') {
        throw new TypeError(response.statusMessage || response.error || '');
      }
      return false;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  @action addCustomCourse = (customCourseId, customCourse) => {
    this.customCoursesMap.set(customCourseId, customCourse);
  }

  @action deleteCustomCourse = (customCourseId) => {
    this.customCoursesMap.delete(customCourseId);
  }

  @action addTree = (treeIdObject, tree) => {
    if (treeIdObject && tree) {
      this.courseTreeMap.set(treeIdObject, tree);
    }
  }

  @action clearData = () => {
    this.courses.clear();
    this.courseTreeMap.clear();
    this.currentCourseId = null;
    this.treeLoading = null;
  }

  @action clearCurrentCourse = () => {
    this.currentCourseId = null;
    this.currentCourseName = null;
    this.currentElementId = '';
  }

  @action setProductCourseSelected = (courseId) => {
    const course = this.productCourses.get(courseId);
    let isSelected;
    if (this.selectedNotAttachedProductCourses.has(courseId)) {
      if (course) {
        course.selectedUserProductId = undefined;
      }
      this.removeSelectedNotAttachedProductCourse(courseId);
      isSelected = false;
    } else {
      const { selectedUserProductId } = productManager;
      const defaultProductId = productManager.defaultProduct?.id;
      if (this.activeTab === 'custom_courses') {
        // productId is now required again for api/addCoursesToClassroom
        course.selectedUserProductId = defaultProductId;
      } else if (!selectedUserProductId || selectedUserProductId === 'allProducts') {
        // no `selectedProductId` is specified, so set to undefined
        course.selectedUserProductId = undefined;
        // we do not currently have a specific productId selected via ProductDropdown
        // so here we will use the first productId associated with this course as the default
        const productIdsStr = course?.productsList;
        const productIds = productIdsStr?.split(',');
        // eslint-disable-next-line prefer-destructuring
        course.selectedUserProductId = productIds[0] || defaultProductId;
      } else {
        course.selectedUserProductId = selectedUserProductId;
      }
      this.addSelectedNotAttachedProductCourse(course);
      isSelected = true;
    }
    if (course) {
      course.selected = isSelected;
      course.attached = false;
    }
  }

  @action clearProductCourseSelected = () => {
    this.productCourses.forEach((course) => {
      course.selected = false;
    });
  }

  @action isCustomCourse = (courseId = null, courseName = null, course = null) => {
    const customCourse = this.customCoursesMap.get(courseId);
    const urlParams = new URLSearchParams(window.location.search);
    if (customCourse || (courseName && courseName === this.newCustomCourseName)) {
      return true;
    } else if (urlParams.has('isCustomCourse')) {
      const isCustomCourse = urlParams.get('isCustomCourse') === 'true';
      return isCustomCourse;
    } else if (!course || !course.courseElements) {
      return false;
    }
    const customChild = course.courseElements.find((courseElement) => courseElement.contentType === ONLINE_CONTENT_TYPES.CUSTOM_EXPERTRACK);
    const hasCustomChild = !!customChild;
    return hasCustomChild;
  }

  @action setIncludeAlignments = (includeAlignments) => {
    this.includeAlignments = includeAlignments;
  }

  getCurrentCourse = (classroomId) => {
    const currentCourse = this.getCourse(classroomId, this.currentCourseId);
    return currentCourse;
  }

  @computed get currentCourseElementList() {
    const urlParams = new URLSearchParams(window.location.search);
    // KEY (PART 1) for courseTreeMap - currentCourseId
    let currentCourseId = '';
    const isCurrentCourseIdCourseIdGuid = this.currentCourseId?.length === 32;
    if (isCurrentCourseIdCourseIdGuid) {
      currentCourseId = this.currentCourseId;
    } else {
      const isUrlParamCourseIdGuid = urlParams.get('courseId')?.length === 32;
      if (isUrlParamCourseIdGuid) {
        currentCourseId = urlParams.get('courseId');
      }
    }
    // KEY (PART 2) for courseTreeMap - currentClassroomId
    let currentClassroomId = classroomManager.currentClassroomId || urlParams.get('classroomId');
    if (!currentClassroomId && (userManager.isDistrictAdmin || userManager.isSchoolAdmin)) {
      currentClassroomId = 'product';
    }
    // KEY (PART 3) for courseTreeMap - currentCourseElementId
    let currentCourseElementId = '';
    const isCurrentCourseElementIdGuid = this.currentElementId?.length === 32;
    if (isCurrentCourseElementIdGuid) {
      currentCourseElementId = this.currentElementId;
    } else {
      const isUrlParamElementIdGuid = urlParams.get('elementId')?.length === 32;
      if (isUrlParamElementIdGuid) {
        currentCourseElementId = urlParams.get('elementId');
      }
    }

    const key = currentCourseId + currentClassroomId + currentCourseElementId;
    return this.courseTreeMap.get(key) || [];
  }

  @action
  clearAll = () => {
    this.clearCourseElementCourseOptions();
    this.clearForceShowCourseElementModalAllAssignmentsButtonSet();
    this.clearProductCourses();
    this.clearSelectedNotAttachedProductCourses();
    this.courseElementCourseOptions = [];
    this.courseElementListHasMore = false;
    this.courseTreeMap.clear();
    this.courses.clear();
    this.coursesLoading = false;
    this.currentCourseId = null;
    this.currentCourseKeyIndex = null;
    this.currentElementId = null;
    this.customCoursesMap.clear();
    this.setActiveProductCoursePage(1);
    this.setTruePublisherCoursesCount(undefined);
  }

  @action setCoursesLoading = (toggle) => {
    this.coursesLoading = toggle;
  }

  @action setCourseElementListLoading = (toggle) => {
    this.courseElementListLoading = toggle;
  }

  @action setCourseElementListHasMore = (hasMore) => {
    this.courseElementListHasMore = hasMore;
  }

  @action setCourseElementCourseOptions = (courseResourceElements) => {
    if (!this.courseElementCourseOptions || this.courseElementCourseOptions.length === 0) {
      this.courseElementCourseOptions.push({ key: 'all-content', text: 'All Courses', value: null });
    }
    for (const courseResourceElement of courseResourceElements) {
      if (!this.courseElementCourseOptions.some((element) => element.key === courseResourceElement.courseContentItemId)) {
        this.setCourseElementCourseOption(courseResourceElement.courseContentItemId, courseResourceElement.courseContentItemName);
      }
    }
  }

  @action clearCourseElementCourseOptions = () => {
    this.courseElementCourseOptions = [];
  }

  @action setCourseElementCourseOption = (courseContentItemId, courseContentItemName) => {
    let contentItemName = courseContentItemName;
    if (contentItemName && contentItemName.length > 50) {
      contentItemName = `${contentItemName.slice(0, 47).trim()}...`;
    }
    this.courseElementCourseOptions.push({ key: courseContentItemId, text: contentItemName, value: courseContentItemId });
  }

  @action setActiveTabIndex = (activeTabIndex) => {
    this.activeTabIndex = activeTabIndex;
  }

  @action setActiveTab = (activeTab) => {
    this.activeTab = activeTab;
  }

  @action setSearchText = (searchText) => {
    this.searchText = searchText;
  }

  @action setSearchTextTimeout = (searchTextTimeout) => {
    this.searchTextTimeout = searchTextTimeout;
  }

  @action setTruePublisherCoursesCount = (truePublisherCoursesCount) => {
    this.truePublisherCoursesCount = truePublisherCoursesCount;
  }

  @action setActiveProductCoursePage = (activePage) => {
    this.activeProductCoursePage = activePage;
  }

  // TODO remove
  // @action setTotalPages = (totalCount) => {
  //   const FRONT_END_PRODUCT_COURSE_PAGE_SIZE = 25;
  //   totalCount = totalCount ? +totalCount : 0;
  //   const totalPages = Math.ceil(+totalCount / FRONT_END_PRODUCT_COURSE_PAGE_SIZE);
  //   this.totalPages = totalPages;
  // }

  @action setAllowCustomCourses = (toggle) => {
    this.allowCustomCourses = toggle;
  }

  @action setUseSpecialCustomCourseCardImages = (toggle) => {
    this.useSpecialCustomCourseCardImages = toggle;
  }

  @action setWarningCourse = (course) => {
    this.warningCourse = course;
  }

  @action setRenameCourse = (course) => {
    this.renameCourse = course;
  }

  @action setIsDeleteCourseWarningModalOpen = (toggle) => {
    this.isDeleteCourseWarningModalOpen = toggle;
  }

  @action setIsRenameCourseModalOpen = (toggle) => {
    this.isRenameCourseModalOpen = toggle;
  }

  @action setPreviewAsStudent = (previewAsStudent) => {
    this.previewAsStudent = previewAsStudent;
  }

  @action setUseNoProductCourses = (useNoProductCourses) => {
    this.useNoProductCourses = useNoProductCourses;
  }

  getClassroomCourseCount = computedFn(function (classroomId) {
    if (this.courses.has(classroomId)) {
      const courses = this.courses.get(classroomId);
      if (courses !== null) {
        return courses.length;
      }
    }
    return 0;
  });

  getCourseList = computedFn(function (classroomId) {
    if (this.courses && this.courses.get(classroomId) !== undefined) {
      return toJS(this.courses.get(classroomId));
    }
    return [];
  });

  getCourseElementListById(id) {
    if (this.courseTreeMap.get(id)) {
      return this.courseTreeMap.get(id);
    }
    return [];
  }

  @computed get productCourseNames() {
    const names = this.productCourseList.map((course) => course.name);
    return names;
  }

  @computed get isProductCourseListEmpty() {
    return !this.productCourseList.length;
  }

  @computed get isCustomCourseMapEmpty() {
    return !this.customCoursesMap.size;
  }

  @computed get productCourseList() {
    if (this.productCourses) {
      return Array.from(toJS(this.productCourses).values());
    }
    return [];
  }

  @computed get pagedProductCourses() {
    const PAGE_SIZE = 25;
    const start = (this.activeProductCoursePage - 1) * PAGE_SIZE;
    const end = (this.activeProductCoursePage) * PAGE_SIZE;
    const map = new Map(Array.from(this.productCourses.entries()).slice(start, end));
    return map;
  }

  @action clearProductCourses = () => {
    this.productCourses.clear();
  }

  @computed get selectedProductCourses() {
    const array = [];
    this.productCourses.forEach((course) => {
      if (course.selected) {
        array.push(course.id);
      }
    });
    return array;
  }

  @computed get selectedAndAttachedProductCourses() {
    const array = [];
    this.productCourses.forEach((course) => {
      if (course.selected && !course.attached) {
        array.push(course.id);
      }
    });
    return array;
  }

  @computed get deletedProductCourses() {
    const array = [];
    this.productCourses.forEach((course) => {
      if (!course.selected && course.attached) {
        array.push(course.id);
      }
    });
    return array;
  }

  @computed get customCourseNames() {
    const names = this.customCoursesList.map((course) => course.name);
    return names;
  }

  @computed get customCoursesList() {
    return Array.from(this.customCoursesMap.values());
  }

  @action
  createCustomCourse = async (classroomId, resourceName, resourceDescription, unitName) => {
    try {
      if (unitName === 'Assessments' && userManager.clientPrefix === 'GALL') {
        unitName = 'Custom ExperTrack Assessments';
      }
      const body = {
        classroomId,
        resourceName,
        unitName,
        resourceDescription
      };
      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.CREATE_CUSTOM_COURSE}?forceUpdate=true`;
      const response = await Auth.fetch(apiUrl, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  createCoursePlaceholderResource = async (courseContentItemId, parentCourseResourceElementId, resourceType = 'learnosity_activity_resource', resourceName, resourceDescription, orderNumber = 0, optionProfileId) => {
    try {
      const body = {
        courseContentItemId,
        parentCourseResourceElementId,
        orderNumber,
        resourceType,
        resourceName,
        resourceDescription,
        optionProfileId,
        expertTrack: 'true'
      };

      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.CREATE_COURSE_PLACEHOLDER_RESOURCE}?forceUpdate=true`;
      const response = await Auth.fetch(apiUrl, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  addCourseToClassrooms = async (classroomIds = [], courseContentItemId, productId = null) => {
    try {
      if (!classroomIds?.length) {
        // no specified classrooms found, so just return
        return;
      }
      if (!courseContentItemId) {
        throw new TypeError('courseManager.addCourseToClassrooms: courseContentItemId is required');
      }

      // const baseBody = {
      //   classroomIds: classroomIds.toString(),
      //   courseContentItemId
      // };
      // const body = productId ? { ...baseBody, productId } : baseBody;

      const body = {
        classroomIds: classroomIds.toString(),
        courseContentItemId,
        productId: productId || ''
      };
      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.ADD_COURSE_TO_CLASSROOMS}`;
      const response = await Auth.fetch(apiUrl, {
        body, method: 'POST'
      });
      return response.status === 'SUCCESS';
    } catch (error) {
      console.error(error);
    }
  }

  addCoursesToClassroom = async (classroomId, courseContentItemIds = []) => {
    try {
      const courseIdAndProductIdJsonArr = [];

      // Some satellites don't associate courses to products so use other api
      if (this.useNoProductCourses && this.activeTab === 'custom_courses') {
        return await this.addNoProductCoursesToClassroom(classroomId, courseContentItemIds);
      }

      for (const courseContentItemId of courseContentItemIds) {
        const course = this.selectedNotAttachedProductCourses.get(courseContentItemId);
        // productId is back to being required for api/addCoursesToClassroom
        if (course?.selectedUserProductId) {
          courseIdAndProductIdJsonArr.push({
            courseContentItemId,
            productId: course.selectedUserProductId || ''
          });
        }
      }
      const body = {
        classroomId,
        courses: JSON.stringify(courseIdAndProductIdJsonArr)
      };
      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.ADD_COURSES_TO_CLASSROOM}?forceUpdate=true`;
      const response = await Auth.fetch(apiUrl, {
        body, method: 'POST'
      });
      if (response && response.status === 'SUCCESS') {
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  addNoProductCoursesToClassroom = async (classroomId, courseContentItemIds = []) => {
    try {
      const courseIdJsonArr = [];
      for (const courseContentItemId of courseContentItemIds) {
        courseIdJsonArr.push({
          courseContentItemId,
        });
      }
      const body = {
        classroomId,
        courses: JSON.stringify(courseIdJsonArr)
      };
      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.ADD_NO_PRODUCT_COURSES_TO_CLASSROOM}?forceUpdate=true`;
      const response = await Auth.fetch(apiUrl, {
        body, method: 'POST'
      });
      if (response && response.status === 'SUCCESS') {
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  createCourseResourceUnit = async (courseContentItemId, unitName, unitDescription) => {
    try {
      const body = {
        courseContentItemId,
        description: unitDescription,
        name: unitName,
        imageAssetName: t('imageAssetNameUnit')
      };
      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.ADD_UNIT_TO_COURSE}`;
      const response = await Auth.fetch(apiUrl, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  updateCourseResourceUnit = async (courseContentItemId, unitName, unitDescription) => {
    // TODO - not sure if this is needed yet.
    // try {
    //   const body = {
    //     courseContentItemId,
    //     description: unitDescription,
    //     name: unitName,
    //   };
    //   const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.UPDATE_COURSE_RESOURCE_UNIT}`;
    //   const response = await Auth.fetch(apiUrl, {
    //     method: 'POST',
    //     body
    //   });
    //   if (response && response.status === 'SUCCESS') {
    //     return true;
    //   }
    // } catch (error) {
    //   console.error(error);
    //   return false;
    // }
  }

  removeCoursesFromClassroom = async (classroomId, resourceContentItemIds) => {
    try {
      const body = {
        classroomId,
        courseContentItemIds: resourceContentItemIds.toString()
      };
      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.REMOVE_COURSES}?reactErrorType=true&forceUpdate=true`;
      const response = await Auth.fetch(apiUrl, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        resourceContentItemIds.forEach((id) => {
          this.removeCourse(classroomId, id);
        });
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  courseTreeLoaded = (id) => {
    if (this.courseTreeMap.get(id)) {
      return true;
    }
    return false;
  }

  getCourse = (classroomId, courseId) => {
    const courseArray = this.courses.get(classroomId);
    if (courseArray !== undefined && courseArray.length > 0) {
      for (let i = 0; i < courseArray.length; i++) {
        if (courseArray[i].id === courseId) {
          return courseArray[i];
        }
      }
    }
    return null;
  }

  /**
   * Fetch and store available regular (publisher) OR custom courses
   *
   * The type of courses fetched will be determined by the value of `this.activeTab`
   *
   * if `this.activeTab === 'publisher_courses'` — regular courses will be fetched and stored.
   *
   * if `this.activeTab === 'custom_courses'` — custom courses will be fetched and stored.
   */
  @action
  fetchUserCourseList = async (
    classroomId, page = 0, serverPageSize, searchText = '', sortColumn = 'sortName', sortDirection = 'asc'
  ) => {
    this.setCoursesLoading(true);
    let courseResponse = null;
    if (this.activeTab === 'publisher_courses') {
      /* FETCH REGULAR COURSES */
      try {
        let apiUrlParams = '?includeBlankCard=false';
        apiUrlParams += `&productCode=${Auth.publisherSatelliteCode}`;
        apiUrlParams += `&classroomId=${classroomId}`;
        apiUrlParams += `&searchText=${searchText}`;
        apiUrlParams += '&searchTypes=course_resource';
        apiUrlParams += `&skip=${(page ? page - 1 : 0) * serverPageSize}`;
        apiUrlParams += `&pageSize=${serverPageSize}`;
        apiUrlParams += `&sort[0][field]=${sortColumn}`;
        apiUrlParams += `&sort[0][dir]=${sortDirection}`;
        const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.GET_COURSE_LIST}${apiUrlParams}`;
        courseResponse = await Auth.fetch(apiUrl, {
          method: 'GET'
        });
        // for `publisher_courses`, we need to store initial pageTotal result.
        // this allows us to determine if we should show 'courseZeroStateMsg'
        // for AddCourseToClassModal
        if (this.truePublisherCoursesCount === undefined) {
          const count = +courseResponse.pageTotal;
          this.setTruePublisherCoursesCount(count || 0);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      /* FETCH CUSTOM COURSES */
      try {
        const returnImmediatelyAfterResponse = true;
        const includeBlankCard = false;
        const searchTerms = searchText;
        const searchTypes = 'course_resource';
        const searchScope = 'MINE';
        courseResponse = await this.searchContentItems(
          returnImmediatelyAfterResponse, classroomId, includeBlankCard,
          searchTerms, searchTypes, searchScope, page, serverPageSize, searchText
        );
      } catch (error) {
        console.error(error);
      }
    }
    if (courseResponse && courseResponse.status === 'SUCCESS') {
      if (searchText || page > 0) {
        this.clearProductCourses();
        if (searchText) {
          this.clearProductCourses();
        }
      }
      const courses = courseResponse.contentItems;
      // eslint-disable-next-line array-callback-return
      courses.map((course) => {
        const cachedCourse = this.getCourse(classroomId, course.id);
        if (cachedCourse) {
          course.selected = true;
          course.attached = true;
        } else {
          course.selected = false;
          course.attached = false;
        }
        this.addProductCourse(course.id, course);
      });
      // TODO remove
      // if (serverPageSize < 999) {
      //   this.setTotalPages(courseResponse.pageTotal);
      // } else {
      //   this.setTotalPages(courses?.length);
      // }
      this.setCoursesLoading(false);
      const success = true;
      return success;
    }
    const success = false;
    return success;
  }

  searchContentItems = async (
    returnImmediatelyAfterResponse = false,
    classroomId,
    includeBlankCard = false,
    searchTerms = '',
    searchTypes = 'course_resource',
    searchScope = 'MINE',
    page = 0,
    pageSize,
    searchText = ''
  ) => {
    this.setCoursesLoading(true);
    try {
      const body = {
        classroomId,
        includeBlankCard,
        pageSize: pageSize || 25,
        searchScope,
        searchTerms,
        searchTypes,
        skip: !pageSize ? 0 : (page ? page - 1 : 0) * pageSize
      };
      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.SEARCH_CONTENT_ITEMS}`;
      const courseResponse = await Auth.fetch(apiUrl, { body, method: 'POST' });
      if (returnImmediatelyAfterResponse) {
        return courseResponse;
      }
      if (courseResponse && courseResponse.status === 'SUCCESS') {
        if (searchText || page > 0) {
          this.clearProductCourses();
          if (searchText) {
            this.clearProductCourses();
          }
        }
        const courses = courseResponse.contentItems;
        // eslint-disable-next-line array-callback-return
        courses.map((course) => {
          const cachedCourse = this.getCourse(classroomId, course.id);
          if (cachedCourse) {
            course.selected = true;
            course.attached = true;
          } else {
            course.selected = false;
            course.attached = false;
          }
          this.addProductCourse(course.id, course);
        });
        // TODO remove
        // this.setTotalPages(Math.ceil(+courseResponse.pageTotal / pageSize));
        this.setCoursesLoading(false);
        return !!courseResponse.status;
      }
      this.setCoursesLoading(false);
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  updateCourseElementDetails = async (courseElement) => {
    try {
      const {
        contentItemId,
        elementId,
        name,
        description,
        resourceInfo,
        duration,
        unitHidden
      } = courseElement;

      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.UPDATE_COURSE_RESOURCE_ELEMENT}`;
      let apiUrlParams = `?courseContentItemId=${contentItemId || ''}`;
      apiUrlParams += `&courseResourceElementId=${elementId || ''}`;
      apiUrlParams += `&name=${name || ''}`;
      apiUrlParams += `&description=${description || ''}`;
      apiUrlParams += `&resourceInfo=${resourceInfo || ''}`;
      apiUrlParams += `&duration=${duration || null}`;
      apiUrlParams += `&unitHidden=${unitHidden || null}`;

      await Auth.fetch(`${apiUrl}${apiUrlParams}`, {
        method: 'POST'
      });
    } catch (error) {
      console.error(error);
    }
  }

  fetchCourseResourceElement = async (courseResourceElementId) => {
    let courseResponse = null;
    try {
      courseResponse = await Auth.fetch(
        `${Auth.ecms}${COURSE_ENDPOINTS.GET_COURSE_RESOURCE_ELEMENT}?elementId=${courseResourceElementId}`, {
          method: 'GET'
        });
    } catch (error) {
      console.error(error);
    }
    if (courseResponse && courseResponse.status === 'SUCCESS') {
      return courseResponse.courseResourceElements && courseResponse.courseResourceElements[0];
    }
  }

  fetchCourseContentItem = async (courseContentItemId, classroomId) => {
    let courseResponse = null;
    try {
      courseResponse = await Auth.fetch(`${Auth.ecms}${COURSE_ENDPOINTS.GET_CONTENT_ITEM}?contentItemId=${courseContentItemId}`, {
        method: 'GET'
      });
    } catch (error) {
      console.error(error);
    }
    if (courseResponse && courseResponse.status === 'SUCCESS') {
      const courses = courseResponse.contentItems;
      courses.forEach((course) => {
        this.addCourse(classroomId, course);
        this.setCurrentCourseId(course.id);
        this.setCurrentCourseName(course.name);
      });
    }
  }

  fetchAlignmentsGroupedByDocument = async (courseResourceElementContentItemId, courseResourceId) => {
    try {
      let apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.GET_ALIGNMENTS_GROUPED_BY_DOCUMENT}`;
      apiUrl += `?courseResourceElementContentItemId=${courseResourceElementContentItemId}`;
      apiUrl += `&courseResourceId=${courseResourceId}`;

      const response = await Auth.fetch(apiUrl, { method: 'GET' });
      if (response.status === 'SUCCESS') {
        return response.alignments || [];
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  /** Similar to `fetchCourseData`, but only
   * (1) fetch teacher courseElements, and
   * (2) return the data without storing it anywhere */
  fetchTeacherCourseElements = async ({
    classroomId, courseContentItemId, courseResourceElementId, includeAlignments
  } = {}) => {
    try {
      let apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.GET_COURSE_ELEMENTS_TEACHER}`;
      apiUrl += `?classroomId=${classroomId}`;
      apiUrl += `&courseContentItemId=${courseContentItemId}`;
      if (courseResourceElementId) {
        apiUrl += `&courseResourceElementId=${courseResourceElementId}`;
      }
      apiUrl += `&includeAlignments=${includeAlignments}`;

      const response = await Auth.fetch(apiUrl, { method: 'GET' });
      if (response.status === 'SUCCESS') {
        return response.courseResourceElements || [];
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  fetchCourseData = async (
    courseContentItemId, classroomId, courseResourceElementId,
    forceFetchIfAlreadyCached = false, isStudent = false,
    skipSetElement = false
  ) => {
    if (!this.allowFetchCourseData) {
      return;
    }

    let courseResponse = null;
    this.setTreeLoading(true);

    if (courseContentItemId === null || courseContentItemId === undefined || courseContentItemId === '') {
      if (courseResourceElementId === null || courseResourceElementId === undefined || courseResourceElementId === '') {
        this.setTreeLoading(false);
        return courseResponse;
      }
    }

    try {
      const ProductService = getRegisteredClass('ProductService');
      ProductService.setShouldShowExpiredLicenseWarning(false);

      let shouldShowExpiredLicenseWarning;

      // reinit classroomId if undefined
      if (!classroomId) {
        const ClassroomService = getRegisteredClass('ClassroomService');
        classroomId = ClassroomService.getCurrentClassroomId({
          alsoUpdateObservableWithUrlParamIfNull: true
        });
      }

      // reinit courseList if undefined
      if (!this.getCourseList(classroomId)?.length) {
        const CourseService = getRegisteredClass('CourseService');
        await CourseService.fetchClassroomCourses(classroomId);
      }

      // reinit current resource if undefined
      if (!this.courses.get(courseContentItemId)) {
        await this.fetchCourseContentItem(courseContentItemId, classroomId);
      }
      const listId = courseContentItemId + classroomId + (courseResourceElementId || '');
      // if the course data is already loaded no need to go back to the server
      if (this.courseTreeLoaded(listId) && !forceFetchIfAlreadyCached) {
        if (!skipSetElement) {
          this.setCurrentElementId(courseResourceElementId);
        }
        this.setTreeLoading(false);
        return this.courseTreeMap.get(listId);
      }

      // check trackAssignedAnywhere across classes only for custom courses
      const isCustomCourse = this.isCustomCourse(this.currentCourseId);
      // eslint-disable-next-line max-len
      let url = `${Auth.ecms}${COURSE_ENDPOINTS.GET_COURSE_ELEMENTS_TEACHER}?courseContentItemId=${courseContentItemId}&classroomId=${classroomId}&includeAlignments=${this.includeAlignments}&trackAssignedAnywhere=${isCustomCourse}&previewAsStudent=${this.previewAsStudent}`;
      if (isStudent) { // student doesn't use classroom
        // eslint-disable-next-line max-len
        url = `${Auth.ecms}${COURSE_ENDPOINTS.GET_COURSE_ELEMENTS_STUDENT}?courseContentItemId=${courseContentItemId}&classroomId=${classroomId}&includeAlignments=${this.includeAlignments}`;
      }
      if (courseResourceElementId && courseResourceElementId !== '') {
        url += `&courseResourceElementId=${courseResourceElementId}`;
      }
      // get the course data if it's not loaded
      courseResponse = await Auth.fetch(url, { method: 'GET' });

      if (courseResponse.status === 'SUCCESS') {
        if (courseResponse.courseResourceElements) {
          shouldShowExpiredLicenseWarning = courseResponse.courseResourceElements.some((courseElement) => courseElement.licenseExpired);

          this.addTree(listId, courseResponse.courseResourceElements);
          if (!skipSetElement) {
            this.setCurrentElementId(courseResourceElementId);
          }
          this.setTreeLoading(false);
          ProductService.setShouldShowExpiredLicenseWarning(!!shouldShowExpiredLicenseWarning);
          return courseResponse.courseResourceElements;
        }
      }
      this.setTreeLoading(false);
      return [];
    } catch (error) {
      console.error(error);
      this.setTreeLoading(false);
      return [];
    }
  }

  // eslint-disable-next-line max-len
  fetchCourseElementsByStandardData = async (classroomId, standardId, studentId, courseContentItemId, page, pageSize, includeAlignments = false) => {
    let courseResponse = null;
    try {
      this.setTreeLoading(true);
      const listId = classroomId + standardId;
      // eslint-disable-next-line max-len
      let url = `${Auth.ecms}${COURSE_ENDPOINTS.GET_COURSE_ELEMENTS_STANDARD}?standardId=${standardId}&classroomId=${classroomId}&includeAlignments=${includeAlignments}`;
      if (studentId) {
        url = `${url}&studentId=${studentId}`;
      }
      if (courseContentItemId) {
        url = `${url}&courseContentItemId=${courseContentItemId}`;
      }
      if (page != null && page != undefined) {
        url = `${url}&skip=${page}`;
      }
      if (pageSize != null && pageSize !== undefined) {
        url = `${url}&pageSize=${pageSize}`;
      }
      // get the course data if it's not loaded
      courseResponse = await Auth.fetch(url, { method: 'GET' });
      if (courseResponse.status === 'SUCCESS') {
        if (courseResponse.courseResourceElements) {
          // Update data
          this.setCourseElementCourseOptions(courseResponse.courseResourceElements);
          this.addTree(listId, courseResponse.courseResourceElements);

          // Set hasMore
          const courseElements = this.getCourseElementListById(listId);
          if (courseElements && courseElements.length < parseInt(courseResponse.pageTotal)) {
            this.setCourseElementListHasMore(true);
          } else {
            this.setCourseElementListHasMore(false);
          }
          this.setTreeLoading(false);
          return courseResponse.courseResourceElements;
        }
      }
      this.setTreeLoading(false);
      return [];
    } catch (error) {
      console.error(error);
      this.setTreeLoading(false);
    }
  }

  fetchCourseElementsByCmapElementData = async (classroomId, cmapElementId, studentId, courseContentItemId, page, pageSize) => {
    let courseResponse = null;
    try {
      this.setTreeLoading(true);
      const listId = classroomId + cmapElementId;
      // eslint-disable-next-line max-len
      let url = `${Auth.ecms}${COURSE_ENDPOINTS.GET_COURSE_ELEMENTS_CMAP_ELEMENT}?cmapElementId=${cmapElementId}&classroomId=${classroomId}`;
      if (studentId) {
        url = `${url}&studentId=${studentId}`;
      }
      if (courseContentItemId) {
        url = `${url}&courseContentItemId=${courseContentItemId}`;
      }
      if (page != null && page != undefined) {
        url = `${url}&skip=${page}&pageSize=${pageSize}`;
      }
      if (pageSize != null && pageSize !== undefined) {
        url = `${url}&pageSize=${pageSize}`;
      }
      // get the course data if it's not loaded
      courseResponse = await Auth.fetch(url, { method: 'GET' });
      if (courseResponse.status === 'SUCCESS') {
        if (courseResponse.courseResourceElements) {
          this.setCourseElementCourseOptions(courseResponse.courseResourceElements);
          this.addTree(listId, courseResponse.courseResourceElements);
          this.setTreeLoading(false);
          return courseResponse.courseResourceElements;
        }
      }
      this.setTreeLoading(false);
      return [];
    } catch (error) {
      console.error(error);
      this.setTreeLoading(false);
    }
  }

  fetchCourseElementParentHeirarchy = async (
    courseContentItemId, courseResourceElementId, classroomId
  ) => {
    try {
      this.setTreeLoading(true);

      let url = `${Auth.ecms}${COURSE_ENDPOINTS.GET_COURSE_RIBBON_ELEMENTS_TEACHER}?classroomId=${classroomId}&courseContentItemId=${courseContentItemId}`;

      if (courseResourceElementId && courseResourceElementId !== '') {
        url += `&courseResourceElementId=${courseResourceElementId}`;
      } else {
        return null;
      }

      // get the element's parent heirarchy data
      const response = await Auth.fetch(url, { method: 'GET' });

      if (response.status === 'SUCCESS') {
        this.setTreeLoading(false);
        return response;
      }
      this.setTreeLoading(false);
      return null;
    } catch (error) {
      console.error(error);
      this.setTreeLoading(false);
      return null;
    }
  }

  makeAssignable = async (entityId = null, deliveryMode = null) => {
    try {
      const body = {
        entityId,
        courseContentItemId: this.currentCourseId,
        deliveryMode
      };
      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.UPDATE_DELIVERY_MODE}`;
      const response = await Auth.fetch(apiUrl, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        await this.fetchCourseData(this.currentCourseId, classroomManager.currentClassroomId, this.currentElementId, true, false, false);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  deleteCourseResourceElement = async (elementId = null) => {
    try {
      const body = {
        courseContentItemId: this.currentCourseId,
        elementId
      };
      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.DELETE_COURSE_RESOURCE_ELEMENT}`;
      const response = await Auth.fetch(apiUrl, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        await this.fetchCourseData(this.currentCourseId, classroomManager.currentClassroomId, this.currentElementId, true, false, false);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  deleteContentItem = async (courseContentItemId = null, checkClasses = false) => {
    try {
      const body = {
        id: courseContentItemId,
        checkClasses,
        reactErrorType: true
      };
      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.DELETE_CONTENT_ITEM}`;
      const response = await Auth.fetch(apiUrl, {
        method: 'POST',
        body
      });
      return response;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  setVisibilityMode = (courseElements, hiddenIds) => {
    const setVisibilityModeElement = (courseElement) => ({
      ...courseElement,
      elementId: courseElement.id,
      visibilityModeOverride: hiddenIds.includes(courseElement.id) ? 'hidden_from_students' : null,
      unitHidden: courseElement.type === ELEMENT_TYPE.ROOT || courseElement.unitHidden === 'true',
      locked: courseElement.locked === 'true',
      pacingOverrideAllowed: courseElement.pacingOverrideAllowed === 'true',
      orderNum: parseInt(courseElement.orderNum),
      duration: parseInt(courseElement.duration),
      attempts: parseInt(courseElement.attempts),
      courseResourceElementCount: parseInt(courseElement.courseResourceElementCount),
      CourseResourceElements: this.setVisibilityMode(courseElement.CourseResourceElements || [], hiddenIds)
    });
    if (!Array.isArray(courseElements)) return setVisibilityModeElement(courseElements);
    return courseElements.map(setVisibilityModeElement);
  }

  changeFullCourseResourceVisibilityMode = (id, visibilityMode = null, courseElements) => {
    const setVisibilityModeElement = (courseElement) => ({
      ...courseElement,
      ...(id === courseElement.id ? { visibilityModeOverride: visibilityMode === 'visible_to_students' ? null : visibilityMode } : {}),
      CourseResourceElements: this.changeFullCourseResourceVisibilityMode(id, visibilityMode, courseElement.CourseResourceElements || [])
    });
    if (courseElements === undefined) return this.fullCourseResource && (this.fullCourseResource = this.changeFullCourseResourceVisibilityMode(id, visibilityMode, this.fullCourseResource));
    if (!Array.isArray(courseElements)) return setVisibilityModeElement(courseElements);
    return courseElements.map(setVisibilityModeElement);
  }

  @action getFullCourseResource = async (classroomId, courseContentItemId) => {
    this.fullCourseResource = null;
    let [fullCourseResource, hiddenIds] = await Promise.all([
      CoursesApi.getFullCourseResource(courseContentItemId),
      CoursesApi.getHiddenCourseResourceIds(classroomId, courseContentItemId)
    ]);

    if (!fullCourseResource) {
      fullCourseResource = {
        name: 'Quick Nav unavailable, please try again later.',
        type: 'ROOT'
      };
    }
    this.fullCourseResource = this.setVisibilityMode(fullCourseResource, hiddenIds);
  }

  @action searchCourseResourceElements = async (forRelatedContent, relatedContentSearchAndLogic) => {
    const { SEARCH_COURSE_RESOURCE_ELEMENTS } = COURSE_ENDPOINTS;
    if (!forRelatedContent && (this.courseContentItemIds === null || this.courseContentItemIds.length === 0)) {
      return;
    }
    if (forRelatedContent && this.courseContentItemIdForRelatedItems) {
      this.courseContentItemIds = this.courseContentItemIdForRelatedItems;
    }
    if (forRelatedContent && !this.courseContentItemIdForRelatedItems) {
      return;
    }

    this.isLoading = true;
    if (forRelatedContent) {
      this.setRelatedContentElementList([]);
    } else {
      this.setSearchCourseElementList([]);
    }
    try {
      let url = `${Auth.ecms}${SEARCH_COURSE_RESOURCE_ELEMENTS}?courseContentItemIds=${this.courseContentItemIds}`;
      url += `&classroomId=${classroomManager.currentClassroomId}`;
      url += `&skip=${(forRelatedContent) ? this.activePageRelated - 1 : this.activePage - 1}`;
      url += `&pageSize=${this.itemsPerPage}`;
      if (this.resourceName) {
        url += `&name=${this.resourceName}`;
      }
      if (this.tagIds && this.tagIds.length > 0) {
        const tagIdsString = this.tagIds.join(',');
        url += `&tagIds=${tagIdsString}`;
      }
      if (relatedContentSearchAndLogic) {
        url += '&relatedContentSearchAndLogic=true';
      } else if (forRelatedContent) {
        url += '&relatedContentSearchAndLogic=false';
      }
      if (this.curriculumMapElementIds && this.curriculumMapElementIds.length > 0) {
        const curriculumMapElementIdsString = this.curriculumMapElementIds.join(',');
        url += `&curriculumMapElementIds=${curriculumMapElementIdsString}`;
      }
      const response = await Auth.fetch(url, { method: 'GET' });
      if (response.status === 'SUCCESS') {
        if (forRelatedContent) {
          this.setRelatedContentElementList(response.data);
          this.setTotalPagesRelated(response.totalPages);
          this.setTotalItemsRelated(response.totalItems);
        } else {
          this.setSearchCourseElementList(response.data);
          this.setTotalPages(response.totalPages);
          this.setTotalItems(response.totalItems);
        }
      }

      this.isLoading = false;
      return true;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
      return false;
    }
  }

  fetchAdminCourseDetails = async (courseContentItemId, productId) => {
    let courseDetailsResponse = null;
    try {
      let apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.GET_ADMIN_LICENSED_COURSE_DETAILS}`;
      apiUrl += `?courseContentItemId=${courseContentItemId}`;
      apiUrl += `&productId=${productId}`;
      courseDetailsResponse = await Auth.fetch(apiUrl, { method: 'GET' });
    } catch (error) {
      console.error(error);
    }
    if (courseDetailsResponse && courseDetailsResponse.status === 'SUCCESS') {
      return courseDetailsResponse.data;
    } else {
      console.error(courseDetailsResponse);
    }
  }

  fetchTeacherCourseDetails = async (courseContentItemId) => {
    try {
      let apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.GET_TEACHER_LICENSED_COURSE_DETAILS}`;
      apiUrl += `?courseContentItemId=${courseContentItemId}`;
      // TODO unused // apiUrl += `&productId=${productId}`;
      const response = await Auth.fetch(apiUrl, { method: 'GET' });
      if (response?.status === 'SUCCESS') {
        return Array.isArray(response.data) ? response.data[0] || {} : response.data || {};
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  moveCourseResourceElement = async (courseContentItemId, parentElementId, elementId, newIndex) => {
    try {
      const body = {
        courseContentItemId,
        elementId,
        newIndex,
        parentElementId
      };
      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.MOVE_COURSE_RESOURCE_ELEMENT}`;
      const response = await Auth.fetch(apiUrl, {
        body,
        method: 'POST',
      });
      if (response && response.status === 'SUCCESS') {
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}

export default new CourseManager();
