import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ReactHtmlParser from 'react-html-parser';

import {
  Button, Image, Item,
  Popup, Segment
} from 'semantic-ui-react';

import classNames from 'classnames';

import ResourcePacingService from '../services/ResourcePacingService';
import AssignmentService from '../services/AssignmentService';

import iconSubmitted from '../img/icon-submitted.svg';
import iconNotSubmitted from '../img/icon-not-submitted.svg';
import '../css/StudentAssignmentCard.less';

import defaultCardImage from '../img/default-assessment.svg';
import { customAssessmentDefaultCardImageUri } from '../services/UriService';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { ASSIGNMENT_MENU_STATUS, ASSIGNMENT_STATUS } from '../managers/AssignmentManager';
import { CONTENT_ITEM_TYPES } from '../managers/ContentManager';
import { DIALOG_NAMES } from '../managers/DialogManager';

import UtilityService from '../services/UtilityService';

import { dateFormat } from '../utils';

export default
@inject(
  'appManager',
  'assignmentManager',
  'courseManager',
  'dialogManager',
  'studentContentCardManager')
@observer
class StudentAssignmentCard extends Component {
  constructor(props) {
    super(props);
    this.InfoIcon = SatCoreComponent('InfoIcon');
    this.StandardsList = SatCoreComponent('StandardsList');
  }

  componentDidMount() {
    const { studentContentCardManager } = this.props;
    if (studentContentCardManager.isLoading) {
      studentContentCardManager.setIsLoading(false);
    }
  }

  handleSelfPacedOpen = (contentMode, assignment) => {
    const { handleSelfPacedOpen, studentContentCardManager } = this.props;
    studentContentCardManager.setIsLoading(true);
    handleSelfPacedOpen(contentMode, assignment);
    setTimeout(() => {
      studentContentCardManager.setIsLoading(false);
    }, 3000);
  }

  handleFeedBack = async (activityInstanceId) => {
    const { dialogManager } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.STUDENT_FEEDBACK, {
      assignmentInstanceId: activityInstanceId
    }, () => dialogManager.closeDialog(DIALOG_NAMES.STUDENT_FEEDBACK));
  }

  getDefaultImage() {
    const { props } = this;
    const { assignmentManager, courseManager } = props;

    const { customDefaultAssignmentCardImg } = assignmentManager;

    const isCustomCourse = courseManager.isCustomCourse(courseManager.currentCourseId);

    let defaultCustomLeafCardImg = customDefaultAssignmentCardImg || defaultCardImage;
    let customLeafCardImageClassNames = customDefaultAssignmentCardImg ? 'default-override' : 'default';

    if (courseManager.useSpecialCustomCourseCardImages && isCustomCourse) {
      customLeafCardImageClassNames += ' custom-default-special';
      defaultCustomLeafCardImg = customAssessmentDefaultCardImageUri;
    }
    return (
      <Item.Image
        alt='Custom default lesson image'
        className={customLeafCardImageClassNames}
        src={defaultCustomLeafCardImg} />
    );
  }

  popup = (assignment) => {
    const { assignmentManager } = this.props;

    if (assignment.totalActivityInstances > 0) {
      return (
        <>
          <div className='card-label'>
            {assignmentManager.getTranslatedAssignmentTypeFlag(assignment)}
            :
          </div>
          <Popup
            hideOnScroll
            on='hover'
            onOpen={() => {
              assignmentManager.fetchAssignmentMembers(assignment.id);
            }}
            trigger={
              <div className='card-value-sm card-list-text blue clickable'>{assignment.totalActivityInstances}</div>
            }
            wide>
            <>
              {assignmentManager.getAssignmentMembers().map((member, index) => (
                <div key={index} className='card-list-text'>{member}</div>)
              )}
            </>
          </Popup>
        </>
      );
    }
    return (
      <>
        <div className='card-label'>Courses:</div>
        <div className='card-list-text blue'>None</div>
      </>
    );
  }

  getFeedbackButton = (disabled) => {
    const { assignment, t } = this.props;
    return assignment.teacherFeedback ? (
      <Button basic disabled={disabled} onClick={() => this.handleFeedBack(assignment.activityInstanceId)} primary>
        {t('feedback')}
      </Button>
    ) : null;
  }

  render() {
    const {
      additionalClassNames, appManager, assignment, contentImageUrl, dashboard,
      dialogManager, disabled, doNotDisable, handlePresent, hideAllGrades = false,
      hideCourseTitle, hideTryAgain, menuStatus, studentContentCardManager, t
    } = this.props;

    const { InfoIcon } = this;

    // TODO remove
    // studentContentCardManager.setIsLoading(!assignmentManager.loaded);
    const { assignmentGrade, assignmentGradeClass } = studentContentCardManager.getAssignmentGradeValues(assignment);

    const { studentContentCardDateFormatStrategy } = studentContentCardManager;

    const { StandardsList } = this;
    let statusImage = iconNotSubmitted;
    if (menuStatus === undefined || (menuStatus === ASSIGNMENT_MENU_STATUS.CLOSED || menuStatus === ASSIGNMENT_MENU_STATUS.GRADED)) {
      if (assignment && assignment.submitted) {
        statusImage = iconSubmitted;
      }
    }

    // TODO unused
    // // Bookmarks, videos, and plain pdfs should not have a submitted status
    // let displayStatus = true;
    // if (assignment.contentItemEntityTypeId === 'pdf_resource' && assignment.pdfDeliveryFormat === 'pdf') {
    //   displayStatus = false;
    // } else if (['bookmark_resource', 'video_resource'].includes(assignment.contentItemEntityTypeId)) {
    //   displayStatus = false;
    // }

    const {
      resourceWebTitle, resourceWebSubtitle, nickname
    } = AssignmentService.getAssignmentTitleAndDescription(assignment, false);

    let showSubTitle = false;
    if (resourceWebSubtitle !== '' && resourceWebSubtitle) {
      showSubTitle = true;
    }

    let showCourseTitle = false;
    if (!hideCourseTitle && assignment.courseContentItemName !== null && assignment.courseContentItemName !== '') {
      showCourseTitle = true;
    }

    const assignmentTitle = resourceWebTitle;
    const assignmentTitleObj = UtilityService.reactHtmlParserWrapper(
      assignmentTitle
    );

    const transformFn = UtilityService.reactHtmlParserTransform;

    const parsedSubtitle = showSubTitle ?
      ReactHtmlParser(resourceWebSubtitle, {
        transform: transformFn
      }) : null;
    let studentAssignmentCardSubtitleJsx = (
      <span className='expander'>
        {parsedSubtitle}
      </span>
    );
    if (showSubTitle) {
      const trigger = studentAssignmentCardSubtitleJsx;
      const popup = (
        <Popup
          content={parsedSubtitle}
          hoverable
          on='hover'
          trigger={trigger}
          wide='very' />
      );
      studentAssignmentCardSubtitleJsx = popup;
    }
    const visibilityMode = ResourcePacingService.visibilityMode(assignment);
    if (visibilityMode === 'hidden_from_students') {
      return null;
    }

    // If the card is outside the assignment area, menusStatus isn't applicable.
    let isClosed, isGraded = false;
    if (menuStatus) {
      isClosed = menuStatus === ASSIGNMENT_MENU_STATUS.CLOSED;
      isGraded = menuStatus === ASSIGNMENT_MENU_STATUS.GRADED;
    } else {
      isClosed = assignment.status === ASSIGNMENT_STATUS.CLOSED;
      isGraded = assignment.status === ASSIGNMENT_STATUS.COMPLETED;
    }
    const isStudentAssignmentModalOpen = dialogManager.openedDialogs.has(DIALOG_NAMES.STUDENT_ASSIGNMENT);
    // TODO - can we simplify this somehow?
    const showGrade = assignmentGrade && (
      (isClosed || isGraded || isStudentAssignmentModalOpen) ||
                            (isGraded && assignment.scoresReleased));
    const displayTitle = nickname || (assignmentTitleObj ? assignmentTitleObj.parsed[0] : '');
    const displayTitleStripped = nickname || (assignmentTitleObj ? assignmentTitleObj.stripped : '');
    const secondaryTitle = assignmentTitleObj ? assignmentTitleObj.parsed[0] : '';
    const secondaryTitleStripped = assignmentTitleObj ? assignmentTitleObj.stripped : '';
    const isLate = assignment && assignment.studentStartedLate;

    const isFileResource = assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.FILE_RESOURCE;

    const activityInstruction = assignment.activityInstructionOverride || assignment.activityInstruction;

    const parsedActivityInstruction = UtilityService.reactHtmlParserWrapper(activityInstruction)?.parsed;

    const shouldShowActivityInstructionInfoIcon = isFileResource && !!activityInstruction;

    const altText = assignment?.altText ? assignment.altText : null;

    return (
      <Item key={assignment.id} as={Segment}
        className={classNames('student-assignment-card', additionalClassNames, {
          disabled,
          licenseExpired: assignment.licenseExpired
        })}>
        {contentImageUrl ? <Item.Image alt={altText} src={contentImageUrl} /> : this.getDefaultImage()}
        <Item.Content>
          <div className='flex-grid-two'>
            <div className='fg-column-lg'>
              <div className='card-grid-container'>
                <div className='flex-grid-one student-assignment-card-title-wrapper'>
                  <div className='fg-column student-assignment-card-title'>
                    <div
                      className='flex-header'
                      title={displayTitleStripped}>
                      {displayTitle}
                    </div>
                    {shouldShowActivityInstructionInfoIcon && (
                      <div className='student-assignment-card-title-info-icon'>
                        <InfoIcon className='StudentAssignmentCardActivityInstructionInfoIcon'
                          popupOptions={{ content: parsedActivityInstruction, on: 'click' }} useWhiteInfoIcon={false} />
                      </div>
                    )}
                  </div>
                </div>
                {nickname && (
                  <div className='flex-grid'>
                    <div className='flex-grid row'>
                      <div
                        className='secondary-title'
                        title={secondaryTitleStripped}>
                        {secondaryTitle}
                      </div>
                    </div>
                  </div>
                )}
                <div className={classNames('student-assignment-card-course-info', 'flex-grid-spaced', {
                  'reduce-padding': nickname
                })}>
                  <div className='fg-column dash-sub'>
                    <div className='card-label subtitle'>
                      {studentAssignmentCardSubtitleJsx}
                      {(showSubTitle && showCourseTitle) &&
                      <span className='spacer'>|</span>}
                      {/* <span className="expander"><b>Class: </b>{assignment.classroomName}</span>
                      <span className="spacer">|</span> */}
                      {showCourseTitle && (
                        <span className='expander'>
                          <b>{t('course')}</b>
                          {assignment.courseContentItemName}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='fg-column' />
                </div>
                <StandardsList
                  assignmentId={assignment.id}
                  contentImageUrl={contentImageUrl}
                  standards={assignment.standardsInfo}
                  title={resourceWebTitle} />
                <div className={classNames('flex-grid-spaced', 'dash-bar', 'sub-meta', 'student-assignment-card-footer-bottom', {
                  'reduce-padding': !!nickname
                })}>
                  <div className='fg-column'>
                    <div className='status-image'>
                      <Image alt='' className={studentContentCardManager.getStatusClass(assignment)} src={statusImage} />
                    </div>
                    <div className='fg-column'>
                      {(assignment && assignment.submitted) ? (
                        <>
                          <div className='card-label'>{t('submittedLabel')}</div>
                          <div className='card-value'>
                            {dateFormat(assignment.submittedDate, studentContentCardDateFormatStrategy, appManager.locale)}
                            {/* {studentContentCardManager.convertToLongDate(assignment.submittedDate, { locale })} */}
                          </div>
                        </>
                      ) :
                        <div className='card-value card-value-submitted-status'>{t('notSubmitted')}</div>}
                    </div>
                  </div>
                  <div className='fg-column due-date'>
                    <div className='card-label'>
                      {isLate ? t('turnInByDate') : t('dueDate')}
                    </div>
                    <div className='card-value'>
                      {dateFormat(assignment.timezoneEndDate, studentContentCardDateFormatStrategy, appManager.locale)}
                      {/* {studentContentCardManager.convertToLongDate(assignment.timezoneEndDate, { locale })} */}
                    </div>
                  </div>
                  {!(isClosed || isGraded) && (
                    <div className={`fg-column ${dashboard === true ? 'due-time' : ''}`}>
                      {dashboard !== true && <div className='card-label'>{t('dueTime')}</div>}
                      <div className='card-value'>{studentContentCardManager.formatTime(assignment.timezoneEndTime)}</div>
                    </div>
                  )}
                  {(showGrade && !hideAllGrades) && (
                    (!assignment.hasLikertElements || assignment.hasConventionalElements)
                  ) && (
                    <div className={`fg-column${assignmentGrade === t('waitingOnGrade') ? ' waiting-on-grade' : ''}`}>
                      <div className='card-label grade'>{t('grade')}</div>
                      <div className={`card-grade-value ${assignmentGradeClass}`}>
                        {assignmentGrade}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='fg-column-sm'>
              <div className='card-nav-buttons'>
                {studentContentCardManager.getStudentAssignmentActionButtons(
                  menuStatus, assignment, disabled, hideTryAgain, handlePresent, this.handleSelfPacedOpen, doNotDisable)}
                {this.getFeedbackButton(disabled)}
              </div>
            </div>
          </div>
        </Item.Content>
      </Item>
    );
  }
}

SatCoreRegister('StudentAssignmentCard', StudentAssignmentCard);
