import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import {
  Button, Container, Image, List, Loader
} from 'semantic-ui-react';

import classNames from 'classnames';

import imageDefaultClassroom from '../../img/default-classroom.svg';
import iconBook from '../../img/icon-book.svg';
import iconPie from '../../img/icon-pie.svg';

import '../../css/reports/ReportTypeSelectorView.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { ASSIGNMENT_TYPE } from '../../managers/AssignmentManager';
import { VIEW_SELECTION } from '../../managers/NavigationManager';

import { REPORT_FACULTY_TYPE, REPORT_TABLE_TYPE, REPORT_TYPE } from '../../services/reports/ReportConstants';

import PopupService from '../../services/PopupService';
import ReportBreadcrumbService from '../../services/reports/ReportBreadcrumbService';
import ReportIdentityService from '../../services/reports/ReportIdentityService';

import { INSTITUTION_DROPDOWN_MODE } from '../../services/UserInstitutionDropdownConstants';

import UserService from '../../services/UserService';
import { removeSessionStorageItem } from '../../utils';

const ReportTypeSelectorView = observer((props) => {
  const { history, t } = props;

  const {
    assignmentManager,
    classroomManager,
    navigationManager,
    reportIdentityManager,
    reportOverrideManager,
    reportTableManager,
    userManager
  } = useContext(MobXProviderContext);

  const {
    isFacultyClassroomReport,
    isFacultyDistrictReport,
    isFacultySchoolReport,
    urlParamInstitutionId
  } = reportIdentityManager;
  const hasMultipleInstitutions = UserService.hasMultipleInstitutions();
  const fromSchoolCard = ReportIdentityService.userCameFromSchoolCard();

  const { SELECT_REPORT_CLASSROOM_MODE } = INSTITUTION_DROPDOWN_MODE;

  const [classroomStatusDropdownLoading, setClassroomStatusDropdownLoading] = useState(isFacultyClassroomReport);

  const [classroomStatus, setClassroomStatus] = useState('active');

  const BreadCrumbs = SatCoreComponent('BreadCrumbs');
  const ClassroomStatusDropdown = SatCoreComponent('ClassroomStatusDropdown');
  const StateIconComponent = SatCoreComponent('StateIconComponent');
  const UserInstitutionDropdown = SatCoreComponent('UserInstitutionDropdown');

  /** equivalent to componentDidMount(), i.e. only called on initial load */
  useEffect(() => {
    (async () => {
      ReportIdentityService.clearAllReportSessionStorage();

      removeSessionStorageItem('adminClassroomReportFacultyName');
      removeSessionStorageItem('adminClassroomReportLeadTeacherName');

      ReportIdentityService.clearAllReportManagers();
      if (reportOverrideManager.shouldExpandAllReportTableRowsByDefault) {
        reportTableManager.clearExpandedReportTableRows();
      }
      // else {
      //   // TODO if user leaves (then comes back) to the reports view
      //   // TODO uncomment the next line if we decide we should collapse all rows for that case
      //   // reportTableManager.clearExpandedReportTableRows();
      // }
      navigationManager.setView(VIEW_SELECTION.REPORTS);

      isFacultyClassroomReport && await fetchClassroomData();
      await ReportBreadcrumbService.initBreadcrumbs();
    })();
  }, []);

  // TODO unused
  /** equivalent to componentDidUpdate(), i.e. called whenever component is updated */
  // useEffect(() => {
  //   /* placeholder */
  // });

  // TODO unused
  // /** equivalent to componentWillUnmount(), i.e. called whenever component is unmounting */
  // useEffect(() => () => {
  //   /* placeholder */
  // });

  const fetchClassroomData = async () => {
    setClassroomStatusDropdownLoading(true);
    const { currentClassroomId } = classroomManager;

    const page = 0;
    const isStudent = false;
    const userId = null;
    const archived = classroomStatus === 'archived';

    await classroomManager.fetchClassroomData(
      currentClassroomId, page, isStudent, userId, archived, { pageSize: 999 }
    );
    setClassroomStatusDropdownLoading(false);
  };

  const handleSelectReportType = ({ faculty, reportType }) => {
    const reportFacultyType = fromSchoolCard ? REPORT_FACULTY_TYPE.SCHOOL : reportIdentityManager.activeReportFacultyType;
    const reportId = ReportIdentityService.getReportId(reportType, reportFacultyType);
    const reportTableType = REPORT_TABLE_TYPE.SUMMARY;

    const urlParams = new URLSearchParams(window.location.search);
    const institutionName = urlParams.get('institutionName');

    let institutionId;
    if (!urlParamInstitutionId && !userManager.isDistrictAdmin && hasMultipleInstitutions) {
      institutionId = faculty.id;
    } else if (urlParamInstitutionId) {
      institutionId = urlParamInstitutionId;
    }
    const classroomId = isFacultyClassroomReport ? (faculty?.id || null) : null;
    // if classroomId has changed, we need to reset certain fields for AssignmentFilter
    if (userManager.isTeacher) {
      const filteredAssignmentSettings = assignmentManager.filteredAssignmentSettings || {};
      classroomManager.setCurrentClassroomId(classroomId);

      if (classroomId && filteredAssignmentSettings.classroomId) {
        if (classroomId !== filteredAssignmentSettings.classroomId) {
          assignmentManager.setFilteredAssignmentSettings({
            ...filteredAssignmentSettings,
            assignmentType: ASSIGNMENT_TYPE.ALL_CLASSES,
            selectedGroupIds: []
          });
        }
      }
    }

    const reportStartUrl = ReportIdentityService.getReportUrl(
      reportType,
      reportFacultyType,
      reportTableType, {
        classroomId,
        classroomStatus: userManager.isTeacher ? classroomStatus : undefined,
        institutionId: institutionId || null,
        institutionName: fromSchoolCard ? institutionName : null,
        reportId
      }
    );
    history.push(reportStartUrl);
  };

  if (reportIdentityManager.loading) {
    return <Loader active />;
  }

  const handleChangeUserInstitutionDropdown = (_event, _data) => {
    /* placeholder */
  };

  const renderReportBreadcrumbsComponent = () => {
    return (
      <Container className='report-breadcrumbs report-selector-breadcrumbs bread-crumb-wrapper' fluid>
        <BreadCrumbs {...props} />
      </Container>
    );
  };

  const renderReportTypeSelectorHeader = () => {
    return (
      <div className='report-selector-header'>
        <div className='report-selector-header-left title'>
          {t('reports')}
        </div>
        <div className='report-selector-header-mid spacer'>
          {/* placeholder */}
        </div>
        <div className='report-selector-header-right'>
          <>
            {!!props.renderReportSampleButton && props.renderReportSampleButton(props)}
            {isFacultyClassroomReport && (
              <>
                <UserInstitutionDropdown
                  allowSelectAllInstitutions
                  mode={SELECT_REPORT_CLASSROOM_MODE}
                  onChange={handleChangeUserInstitutionDropdown} />
                <ClassroomStatusDropdown {...props}
                  classroomStatus={classroomStatus}
                  classroomStatusDropdownLoading={classroomStatusDropdownLoading}
                  setClassroomStatus={setClassroomStatus}
                  setClassroomStatusDropdownLoading={setClassroomStatusDropdownLoading} />
              </>
            )}
          </>
        </div>
      </div>
    );
  };

  const renderReportTypeSelectorCard = (reportType) => {
    return (
      <div className={`report-selector-card ${reportType}`}>
        <div className='card-top'>
          <div className='card-title'>
            {t(`${reportType}Report`)}
          </div>
          <div className={`card-image ${reportType}`}>
            {renderReportTypeSelectorCardImage(reportType)}
          </div>
        </div>
        <div className='card-bottom'>
          {renderReportTypeSelectorList(reportType)}
        </div>
      </div>
    );
  };

  const renderReportTypeSelectorCardImage = (reportType) => {
    if (reportType === REPORT_TYPE.COURSE) {
      return <Image alt='' src={iconBook} />;
    } else if (reportType === REPORT_TYPE.STANDARDS) {
      return <StateIconComponent />;
    }
  };

  const renderReportTypeSelectorList = (reportType) => {
    const { activeReportFacultyType } = reportIdentityManager;
    let listItemsJsx;
    if (userManager.isDistrictAdmin || isFacultyDistrictReport) {
      listItemsJsx = [renderDefaultReportSelectorListItem(reportType)];
    } else if (isFacultySchoolReport || isFacultyClassroomReport) {
      const selectedDropdownInstitutionId = UserService.getSelectedDropdownInstitutionId(SELECT_REPORT_CLASSROOM_MODE);

      const faculties = isFacultySchoolReport ? userManager.institutions : classroomManager.classroomsArray;

      listItemsJsx = faculties.filter((faculty) => {
        const showArchivedOnly = classroomStatus === 'archived';
        if (isFacultyClassroomReport && ((showArchivedOnly !== faculty.archived) || !faculty.courseCount)) {
          return false;
        } else if (faculty.district || faculty.type?.toLowerCase() === 'district') {
          return false;
        }
        const facultyInstitutionId = isFacultyClassroomReport ? faculty.institutionId : faculty.id;
        if (isFacultySchoolReport) {
          return !urlParamInstitutionId || faculty.id === urlParamInstitutionId;
        } else {
          return !isFacultyClassroomReport || (
            !hasMultipleInstitutions ||
            selectedDropdownInstitutionId === 'allInstitutions' ||
            selectedDropdownInstitutionId === facultyInstitutionId
          );
        }
      }).map((faculty) => {
        return renderReportTypeSelectorFacultyListItem(
          { faculty, reportType }
        );
      });
    }
    const selectorZeroStateJsx = (
      <div className='zero-state'>
        {classroomStatusDropdownLoading ? t('loading') :
          t('selectorZeroState', {
            activeReportFacultyType,
            status: classroomStatus
          })}
      </div>
    );
    let multipleInstitutionClassName = '';
    if (hasMultipleInstitutions && !fromSchoolCard) {
      multipleInstitutionClassName = ' multiple-institutions';
    }
    return (
      <div className={`report-selector-list ${activeReportFacultyType}${multipleInstitutionClassName}`}>
        <List>
          {listItemsJsx?.length ? listItemsJsx : selectorZeroStateJsx}
        </List>
      </div>
    );
  };

  const renderDefaultReportSelectorListItem = (reportType) => {
    return (
      <div key={reportType} className='default-selector-list-item'>
        <div className='default-selector-item-title'>
          {t(`${activeReportFacultyType}Summary`)}
        </div>
        <div className='default-selector-item-button'>
          {renderViewReportButton({ reportType })}
        </div>
      </div>
    );
  };

  const renderReportTypeSelectorFacultyListItem = ({ faculty, reportType }) => {
    let imageUrl, facultyName, institutionName;
    if (isFacultySchoolReport) {
      imageUrl = classroomManager.createClassroomImageUrl(faculty.imageUrl, faculty.fullImageUrl);
      facultyName = t('schoolSummary');
      institutionName = faculty.name;
    } else if (isFacultyClassroomReport) {
      imageUrl = classroomManager.getClassroomImageUrl(faculty.id);
      facultyName = classroomManager.getClassName(faculty.id);
      facultyName = facultyName || `Unnamed Class ${faculty.id}`;
      institutionName = faculty.institutionName || `Unnamed Institution ${faculty.institutionId}`;
    }
    let content;
    if (isFacultyClassroomReport) {
      content = `${facultyName}${institutionName ? ` | ${institutionName}` : ''}`;
    } else {
      content = institutionName;
    }
    return (
      <List.Item key={`classroom_${reportType}_${faculty.id}`}
        disabled={classroomStatusDropdownLoading}>
        <List.Content>
          <List.Description>
            <span>
              {imageUrl === null ? (
                <Image src={imageDefaultClassroom} />
              ) : (
                <Image src={imageUrl} />
              )}
            </span>
            {PopupService.renderPopup({
              className: 'selector-list-item-popup',
              content,
              position: 'bottom left',
              trigger: (
                <div className='selector-classroom-name-wrapper'>
                  <div className='classroom-name'>
                    {facultyName}
                  </div>
                  {hasMultipleInstitutions && (
                    <div className='institution-name'>
                      {institutionName}
                    </div>
                  )}
                </div>
              )
            })}
            <div className='report-selector-list-item-spacer'>
              {/* placeholder */}
            </div>
            {renderViewReportButton({ faculty, reportType })}
          </List.Description>
        </List.Content>
      </List.Item>
    );
  };

  const renderViewReportButton = ({ faculty, reportType }) => {
    return (
      <div className='button-view-report-wrapper'>
        <Button
          className='primary button-view-report'
          onClick={() => handleSelectReportType({ faculty, reportType })}>
          <div className='button-view-report-title'>
            {t('viewReport')}
          </div>
          <div className='button-view-report-image-wrapper'>
            <Image alt='' className='button-pie-image' src={iconPie} />
          </div>
        </Button>
      </div>
    );
  };

  const { activeReportFacultyType } = reportIdentityManager;
  const hasBreadcrumbs = fromSchoolCard;
  return (
    <>
      {hasBreadcrumbs && renderReportBreadcrumbsComponent()}
      <div className={`report-type-selector-view ${activeReportFacultyType}${hasBreadcrumbs ? ' has-breadcrumbs' : ''}`}>
        <Loader active={classroomStatusDropdownLoading} />
        {renderReportTypeSelectorHeader()}
        <div className={classNames('report-selector-cards', {
          'no-standards': !reportOverrideManager.allowReportStandards
        })}>
          {renderReportTypeSelectorCard(REPORT_TYPE.COURSE)}
          {reportOverrideManager.allowReportStandards && (
            renderReportTypeSelectorCard(REPORT_TYPE.STANDARDS)
          )}
        </div>
      </div>
    </>
  );
});
export default ReportTypeSelectorView;

SatCoreRegister('ReportTypeSelectorView', ReportTypeSelectorView);
