import { action, makeObservable, observable } from 'mobx';

import { register } from '../i18n';
import { getRegisteredClass } from '../SatCoreRegistry';

import classroomManager from './ClassroomManager';
import courseManager from './CourseManager';
import productManager from './ProductManager';
import userManager from './UserManager';

import { setDocumentTitleFromBreadCrumbs, setDocumentTitleFromSelectionView } from '../utils/setDocumentTitle';

const t = register('NavigationService');

export const PATH_TYPES = {
  LINK: 'link', /* go to a different page */
  ROOT: 'root', /* same as tree but some special logic when managing breadcrumbs */
  TREE: 'tree' /* stay on current page but change the tree location */
};

export const VIEW_SELECTION = {
  ARCHIVED_CLASSES: 'archived-classes',
  ASSIGNMENTS: 'assignments',
  BOOK: 'book',
  CLASSES: 'classes',
  CLASSROOMS: 'classrooms',
  CLASS_PERFORMANCE: 'class performance',
  CONTENT: 'content',
  COURSES: 'courses',
  DASHBOARD: 'dashboard',
  EDIT_STUDENT_PROFILE: 'edit student profile',
  EXTERNAL_DASHBOARD: 'externalDashboard',
  GRADEBOOK: 'gradebook',
  GROUPS: 'groups',
  LOGIN: 'login',
  LOGIN_ONE_ROSTER: 'login one roster',
  NOTIFICATIONS: 'notifications',
  PRODUCTS: 'products',
  PROFILE: 'profile',
  PROGRESS: 'progress',
  REPORTS: 'reports',
  RESOURCES: 'resources',
  ROLE_SWITCHER: 'role switcher',
  TEST_BUILDER: 'testbuilder',
  USERS: 'users',
  WORKSPACE: 'workspace'
};

export class NavigationManager {
  @observable pathList = [];

  @observable viewSelection = VIEW_SELECTION.DASHBOARD ;

  @observable allowCarouselAdaptiveHeight = false;

  skipTypes = null;

  isStudent = false;

  constructor() {
    makeObservable(this);
    this.clearAllPaths();
  }

  getDefaultBreadcrumb = () => {
    const defaultBreadCrumb = {
      currentClassroomId: null,
      currentCourseId: null,
      currentElementId: null,
      name: t('defaultBreadcrumbLabel', 'Classes'),
      treeNavigationFunction: null, /* only valid if type */
      type: PATH_TYPES.LINK
    };
    return defaultBreadCrumb;
  }

  setIsStudent = (student) => {
    if (student) {
      this.pathList.clear();
    }
    this.isStudent = student;
  }

  setIsAdmin = (isAdmin) => {
    if (isAdmin) {
      this.pathList.clear();
    }
    this.isAdmin = isAdmin;
  }

  initSkipTypes = (skipTypes) => {
    if (skipTypes !== null && skipTypes !== undefined) {
      this.skipTypes = skipTypes.slice();
    }
  }

  navigateToClassroom(classroomId, history, replace) {
    const go = (replace) ? history.replace : history.push;
    if (classroomId) {
      classroomManager.setCurrentClassroomId(classroomId);
    }
    const routerUrl = `/courses?classroomId=${classroomId}&view=${VIEW_SELECTION.COURSES}`;
    go(routerUrl);
  }

  navigateToCourseContent(classroomId, courseId, courseElementId, history, replace) {
    let routerUrl = '';
    const go = (replace) ? history.replace : history.push;
    let isCustomCourse = false;

    if (classroomId) {
      classroomManager.setCurrentClassroomId(classroomId);
    }
    if (courseId && courseId.length > 0) {
      courseManager.setCurrentCourseId(courseId);
      isCustomCourse = courseManager.isCustomCourse(courseId);
    }
    if (courseElementId && courseElementId.length > 0) {
      courseManager.setCurrentElementId(courseElementId);
      routerUrl = `/class?classroomId=${classroomId}&courseId=${courseId}&elementId=${courseElementId}&view=${VIEW_SELECTION.BOOK}&isCustomCourse=${isCustomCourse}`;
    } else {
      routerUrl = `/courses?classroomId=${classroomId}&courseId=${courseId}&view=${VIEW_SELECTION.COURSES}`;
    }
    go(routerUrl);
  }

  async navigateToLastClassroomCourseLocation(classroomId, history, replace) {
    const CourseNavigationService = getRegisteredClass('CourseNavigationService');
    const data = await CourseNavigationService.getCurrentCourseLocation(classroomId);

    if (data) {
      const courseId = data.courseId ? data.courseId : '';
      const courseElementId = data.courseElementId ? data.courseElementId : 'ROOT';
      let isCustomCourse = false;
      if (courseId && courseId.length > 0) {
        courseManager.setCurrentCourseId(courseId);
        isCustomCourse = courseManager.isCustomCourse(courseId);
      }
      let routerUrl = '';
      const go = (replace) ? history.replace : history.push;
      if (courseElementId && courseElementId.length > 0) {
        courseManager.setCurrentElementId(courseElementId);
        routerUrl = `/class?classroomId=${classroomId}&courseId=${courseId}&elementId=${courseElementId}&view=${VIEW_SELECTION.BOOK}&isCustomCourse=${isCustomCourse}`;
      } else {
        routerUrl = `/courses?classroomId=${classroomId}&courseId=${courseId}&view=${VIEW_SELECTION.COURSES}`;
      }
      go(routerUrl);
    } else {
      this.navigateToClassroom(classroomId, history, replace);
    }
  }

  clearSetAssignments = () => {
    this.clearAllPaths();
    const assignmentPath = {
      currentCourseId: null,
      currentClassroomId: null,
      currentElementId: null,
      name: t('assignments'),
      type: PATH_TYPES.LINK,
      treeNavigationFunction: null
    };
    this.addPath(assignmentPath);
  }

  async navigateToClassroomSkipSingleCourse(
    classroomId, courseCount, courseId, _view, history, replace
  ) {
    const go = (replace) ? history.replace : history.push;
    this.setView(VIEW_SELECTION.BOOK);
    if (courseCount === 0) {
      go(`/class?classroomId=${classroomId}&view=${VIEW_SELECTION.BOOK}`);
      return;
    }
    if (courseCount === 1) {
      go(`/class?classroomId=${classroomId}&courseId=${courseId}&elementId=ROOT&view=${VIEW_SELECTION.BOOK}`);
      return;
    }
    go(`/courses?classroomId=${classroomId}&view=${_view}`);
  }

  @action
  clearCoursePaths = () => {
    const tempArray = [];
    for (let i = 0; i < this.pathList.length; ++i) {
      if (this.pathList[i].type !== PATH_TYPES.TREE) {
        tempArray.push(this.pathList[i]);
      }
    }
    this.pathList.clear();
    if (tempArray.length > 0) {
      this.pathList.replace(tempArray);
    }
  }

  @action
  getAdminUrlParams = async (paramsToExcludeFromStr = '') => {
    const urlParams = new URLSearchParams(window.location.search);
    const institutionId = urlParams.get('institutionId');
    const districtId = urlParams.get('districtId');
    const institutionName = urlParams.get('institutionName');
    const classroomId = urlParams.get('classroomId');
    const leadTeacherId = urlParams.get('leadTeacherId');
    const userId = urlParams.get('userId');
    const userName = urlParams.get('userName');
    const content = urlParams.get('content');

    let urlParamStr = '';
    if (institutionId && !paramsToExcludeFromStr.includes('institutionId')) {
      urlParamStr = `institutionId=${institutionId}`;
    }
    if (districtId && !paramsToExcludeFromStr.includes('districtId')) {
      urlParamStr += urlParamStr ? '&' : '';
      urlParamStr += `districtId=${districtId}`;
    }
    if (institutionName && !paramsToExcludeFromStr.includes('institutionName')) {
      urlParamStr += urlParamStr ? '&' : '';
      urlParamStr += `institutionName=${institutionName}`;
    }
    if (classroomId && !paramsToExcludeFromStr.includes('classroomId')) {
      urlParamStr += urlParamStr ? '&' : '';
      urlParamStr += `classroomId=${classroomId}`;
    }
    if (userId && !paramsToExcludeFromStr.includes('leadTeacherId')) {
      urlParamStr += urlParamStr ? '&' : '';
      urlParamStr += `leadTeacherId=${leadTeacherId}`;
    }
    if (userId && !paramsToExcludeFromStr.includes('userId')) {
      urlParamStr += urlParamStr ? '&' : '';
      urlParamStr += `userId=${userId}`;
    }
    if (userName && !paramsToExcludeFromStr.includes('userName')) {
      urlParamStr += urlParamStr ? '&' : '';
      urlParamStr += `userName=${userName}`;
    }
    if (userName && !paramsToExcludeFromStr.includes('content')) {
      urlParamStr += urlParamStr ? '&' : '';
      urlParamStr += `content=${content}`;
    }
    return {
      classroomId,
      content,
      districtId,
      institutionId,
      institutionName,
      leadTeacherId,
      urlParamStr,
      userId,
      userName
    };
  }

  @action
  clearAllPaths = (noAddDefaultBreadcrumb = false) => {
    this.pathList.clear();
    if (!noAddDefaultBreadcrumb
      && !this.isFromProduct && !productManager.FROM_TEACHER_PRODUCTS_NAV
      && !this.isAdmin && !this.isStudent
      && !userManager.isDistrictOrSchoolAdmin
      && this.viewSelection !== VIEW_SELECTION.GRADEBOOK
    ) {
      this.pathList.push(this.getDefaultBreadcrumb());
    }
  };

  /** generalized wrapper method for `addPath()` */
  @action
  setBreadcrumb = async ({
    addAsFirstBreadcrumb = false,
    fromView = VIEW_SELECTION.DASHBOARD,
    path = null,
    paramName = null
  } = {}) => {
    const urlParams = new URLSearchParams(window.location.search);
    let name;
    if (paramName && urlParams.has(paramName)) {
      name = urlParams.get(paramName);
    }
    if (!name) {
      name = path && path.name ? path.name : null;
    }
    let routerUrl = '';
    if (path && path.routerUrl) {
      routerUrl = path.routerUrl;
    } else if (fromView && fromView !== VIEW_SELECTION.DASHBOARD) {
      routerUrl = `/${fromView}?view=${fromView}`;
    } else {
      routerUrl = `/?view=${VIEW_SELECTION.DASHBOARD}`;
    }
    const pathType = path && path.type ? path.type : PATH_TYPES.LINK;
    const treeNavFn = path && path.treeNavigationFunction ? path.treeNavigationFunction : null;
    const classroomId = path && path.currentClassroomId ? path.currentClassroomId : null;
    const courseId = path && path.currentCourseId ? path.currentCourseId : null;
    const elementId = path && path.currentElementId ? path.currentElementId : null;

    this.addPath({
      currentClassroomId: classroomId,
      currentCourseId: courseId,
      currentElementId: elementId,
      fromView,
      name,
      routerUrl,
      treeNavigationFunction: treeNavFn,
      type: pathType
    }, addAsFirstBreadcrumb);
  }

  /** add `path` object to breadcrumbs `pathList` */
  @action
  addPath = (path, addAsFirstBreadcrumb = false) => {
    setDocumentTitleFromBreadCrumbs(path.name);

    /* check if path.name is already in pathList */
    const isPathName = (element) => element.name === path.name;
    const index = this.pathList.findIndex(isPathName);
    if (index >= 0) {
      /* if path.name is already in pathList, truncate pathList to that path item */
      const numOfCrumbsToRemove = this.pathList.length - index - 1;
      this.pathList.splice(-numOfCrumbsToRemove, numOfCrumbsToRemove);
    } else if (addAsFirstBreadcrumb) {
      this.pathList.unshift(path);
    } else {
      /* if path.name is new, just push it to the end of pathList */
      this.pathList.push(path);
    }
  }

  /** remove `path` object from breadcrumbs `pathList` */
  @action
  removePath = (pathIndex) => {
    this.pathList.splice(pathIndex, 1);
  }

  @action
  setView = (viewSelectionValue) => {
    setDocumentTitleFromSelectionView(viewSelectionValue, {
      VIEW_SELECTION
    });
    this.viewSelection = viewSelectionValue;
  }

  @action
  getPathIndex = (path) => {
    const isPathName = (element) => element.name === path.name;
    const index = this.pathList.findIndex(isPathName);
    return index;
  }

  @action
  getPath = (viewSelection) => this.pathList.find((path) => 'fromView' in path && path.fromView === viewSelection);

  @action
  hasPath = (pathName) => this.pathList.find((path) => path.name === pathName);

  @action setAllowCarouselAdaptiveHeight = (allow) => {
    this.allowCarouselAdaptiveHeight = allow;
  }
}

export default new NavigationManager();
