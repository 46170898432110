import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Table } from 'semantic-ui-react';

import '../css/GradebookDetailsTable.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import PopupService from '../services/PopupService';
import GradebookObservationalService from '../services/gradebook/GradebookObservationalService';

const GradebookObservationalScoreCell = observer((props = {}) => {
  const {
    allowSort,
    isDetailScoreCellReadOnly,
    isLoadingGradebookDetailsTable,
    renderScoreLabelContent_questionStem,
    scoreCellDetails = {},
    scoreCellKey,
    scoreTableCellClassNames,
    setAllowSort,
    shouldRenderPopup
  } = props;

  const { gradebookManager, scoreCellGraderManager } = useContext(MobXProviderContext);

  const GradebookObservationalScoreCellRollover = SatCoreComponent('GradebookObservationalScoreCellRollover');

  const [isRolloverOpen, setIsRolloverOpen] = useState(false);

  const [observationalScoreOverride, setObservationalScoreOverride] = useState(scoreCellDetails.studentScore);

  // useEffect(() => {
  //   (async () => {
  //     // TODO placeholder
  //   })();
  // }, []);

  useEffect(() => {
    // Close this cell's rollover if it's no longer the active GradebookObservationalScoreCell.
    // This helps ensure only one GradebookObservationalScoreCellRollover is open at a time,
    // and prevents UI conflicts when other Semantic UI Popups are triggered elsewhere in the application.
    const shouldForceClearActiveScoreCellKey = scoreCellGraderManager.shouldForceClearActiveObservationalScoreCellKey;

    const activeScoreCellKey = scoreCellGraderManager.activeObservationalScoreCellKey;

    const isActiveScoreCellKey = activeScoreCellKey === scoreCellKey;

    if ((shouldForceClearActiveScoreCellKey && (isActiveScoreCellKey || isRolloverOpen)) || (!isActiveScoreCellKey && isRolloverOpen)) {
      handleCloseRollover();
    }
  }, [scoreCellGraderManager.activeObservationalScoreCellKey]);

  const handleSubmitGradebookObservationalScoreCellData = async (event, observationalScoreCellData) => {
    if (!observationalScoreCellData) {
      handleCloseRollover(event, observationalScoreCellData);
      return;
    }

    await GradebookObservationalService.handleSubmitGradebookObservationalScoreCellData({
      observationalScoreCellData,
      scoreCellDetails
    });

    handleCloseRollover(event, observationalScoreCellData);

    setTimeout(() => {
      // per DEMO-2773 requirements, if observational score is updated for a given detail score cell,
      // for a faster user experience (and to prevent the need to refetch all gradebook data),
      // we will update the appropriate score value here via state management
      // (rather than refetching all gradebook data from the backend)
      setObservationalScoreOverride(observationalScoreCellData.observationalScore);
      gradebookManager.updateGradebookDetailCellStudentScore({
        score: observationalScoreCellData.observationalScore,
        scoreIndex: scoreCellDetails.scoreIndex,
        studentIndex: scoreCellDetails.studentIndex
      });
    }, 100);
  };

  const handleOpenRollover = (event, _data) => {
    event?.stopPropagation?.();
    scoreCellGraderManager.setActiveObservationalScoreCellKey(scoreCellKey);
    setIsRolloverOpen(true);
    // // TODO remove
    // // if no existing observational rollovers are already active,
    // // allow selected cell to 'open' and trigger a new rollover
    // if (!scoreCellGraderManager.activeObservationalScoreCellKey) {
    //   event?.stopPropagation?.();
    //   scoreCellGraderManager.setActiveObservationalScoreCellKey(scoreCellKey);
    //   setIsRolloverOpen(true);
    // }
  };

  const handleCloseRollover = (event, _data) => {
    const isCkeditorToolbarOpen = !!event?.target?.closest?.('.ck-toolbar');

    if (isCkeditorToolbarOpen) {
      // user has likely interacted with the ckeditor toolbar within the active GradebookObservationalScoreCellRollover.
      // since the user has technically 'clicked away' from the rollover, semantic ui popup wants to close it.
      // so here we need to prevent the active semantic ui `<Popup />` component from closing its rollover.
      return;
    }

    const observationalCommentInputCkeditorElement = document.querySelector('.observational-comment-ckeditor .ck');

    const hasFocusedCkeditorElement = observationalCommentInputCkeditorElement === document.activeElement;

    if (hasFocusedCkeditorElement) {
      // Force ckeditor to unfocus (which will be inside of the GradebookObservationalScoreCellRollover)
      // prior to closing the rollover. This will allow the ckeditor instance to properly destroy itself
      // before GradebookObservationalScoreCellRollover is closed (prevents app crash issue).
      observationalCommentInputCkeditorElement.blur();

      setTimeout(() => {
        cleanupAndClose();
      }, 100);
    } else {
      cleanupAndClose();
    }
  };

  const cleanupAndClose = () => {
    if (!allowSort) {
      setAllowSort(true);
    }
    if (scoreCellGraderManager.shouldForceClearActiveObservationalScoreCellKey) {
      scoreCellGraderManager.setShouldForceClearActiveObservationalScoreCellKey(false);
    }
    setIsRolloverOpen(false);
    scoreCellGraderManager.setActiveObservationalScoreCellKey(null);
  };

  const { activeObservationalScoreCellKey } = scoreCellGraderManager;

  const scoreLabelContent = shouldRenderPopup ? {
    children: () => {
      return (
        <GradebookObservationalScoreCellRollover
          handleCloseRollover={handleCloseRollover}
          handleSubmitGradebookObservationalScoreCellData={handleSubmitGradebookObservationalScoreCellData}
          renderScoreLabelContent_questionStem={renderScoreLabelContent_questionStem}
          scoreCellDetails={{
            ...scoreCellDetails,
            studentScore: typeof observationalScoreOverride === 'number' ? observationalScoreOverride : scoreCellDetails.studentScore
          }} />
      );
    }
  } : undefined;

  let scoreLabelTrigger;
  if (typeof observationalScoreOverride === 'number') {
    scoreLabelTrigger = (<span>{observationalScoreOverride}</span>);
  } else {
    scoreLabelTrigger = props.scoreLabelTrigger;
  }

  return (
    <Table.Cell
      className={scoreTableCellClassNames}
      onClick={handleOpenRollover}>
      {shouldRenderPopup ? PopupService.renderPopup({
        className: 'gradebook-details-table-popup cell-score-popup cell-observational-score-popup',
        content: scoreLabelContent,
        disabled: props.disabled || isDetailScoreCellReadOnly || isLoadingGradebookDetailsTable,
        on: 'click',
        onClose: (event, data) => {
          handleCloseRollover(event, data);
        },
        onOpen: (event, data) => {
          if (!isRolloverOpen && activeObservationalScoreCellKey === scoreCellKey) {
            handleOpenRollover(event, data);
          }
        },
        open: isRolloverOpen,
        position: 'top right',
        trigger: scoreLabelTrigger,
        // wide: 'very'
      }) : scoreLabelTrigger}
    </Table.Cell>
  );
});
export default GradebookObservationalScoreCell;

SatCoreRegister('GradebookObservationalScoreCell', GradebookObservationalScoreCell);
