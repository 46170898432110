import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Button, Form, Message } from 'semantic-ui-react';

import Modal from '../Modal';

import '../../css/SelectNewLibraryResourceModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { DIALOG_NAMES } from '../../managers/DialogManager';

export default
@inject('libraryManager', 'dialogManager')
class SelectNewLibraryResourceModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.SCRadio = SatCoreComponent('SCRadio');
  }

  getInitialState = () => {
    return {
      error: null,
      selectedResourceType: ''
    };
  }

  continueCreateLibraryResource = async () => {
    const { closeCreateNewLibraryResourceModal, onCloseCreateNewLibraryResourceModal, closeSelectNewLibraryResourceModal, dialogManager } = this.props;
    const { selectedResourceType } = this.state;
    // Launch the create new assessment/(bank) wizard 2nd page.
    dialogManager.setOpenDialog(DIALOG_NAMES.CREATE_NEW_LIBRARY_RESOURCE_MODAL, {
      close: closeCreateNewLibraryResourceModal,
      onCloseCreateNewLibraryResourceModal,
      getAll:true,
      open: true,
      selectedResourceType
    });
    closeSelectNewLibraryResourceModal();
  }

  clearError = () => {
    this.setState({ error: null });
  }

  handleChangeResourceType = async (event) => {
    const selectedResourceType = event.target.value;
    this.setState({ selectedResourceType });
  }

  closeSelectNewLibraryResourceModal = () => {
    const { closeSelectNewLibraryResourceModal, onCloseSelectNewLibraryResourceModal } = this.props;
    onCloseSelectNewLibraryResourceModal();
    closeSelectNewLibraryResourceModal();
  }

  render() {
    const { close, selectNewLibraryResourceOpen, t } = this.props;
    const { selectedResourceType, error } = this.state;
    const { ModalBanner } = this;
    const { SCRadio } = this;
    return (
      <Modal className='select-new-library-resource-modal' open={selectNewLibraryResourceOpen} size='tiny'>
        <ModalBanner
          label={t('modalLabel')}
          onClose={close} />
        <Modal.Content>
          <Form.Field className='select-new-resource-field'>
            <div className='description-text'>{t('descriptionText')}</div>
            {/* <div className='description-sub-text'>{t('descriptionSubText')}</div> */}
            <SCRadio
              checked={selectedResourceType === 'assessment'}
              label={t('assessmentLabel')}
              name='exportGroup'
              onChange={this.handleChangeResourceType}
              value='assessment' />
            <SCRadio
              checked={selectedResourceType === 'assessment-bank'}
              label={t('assessmentBankLabel')}
              name='exportGroup'
              onChange={this.handleChangeResourceType}
              value='assessment-bank' />
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button basic content={t('cancelButtonLabel', 'Cancel')} onClick={() => { this.closeSelectNewLibraryResourceModal(); }} primary />
          <Button content={t('createButtonLabel', 'Create')} disabled={!this.state.selectedResourceType} onClick={() => { this.continueCreateLibraryResource(); }} primary />
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('SelectNewLibraryResourceModal', SelectNewLibraryResourceModal);
