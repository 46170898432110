import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Input, Message, Radio } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { DIALOG_NAMES } from '../managers/DialogManager';
import { VIEW_SELECTION } from '../managers/NavigationManager';

import '../css/Profile.less';

export default @inject(
  'classroomManager', 'dialogManager', 'navigationManager', 'userManager')
@observer
class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false, // eslint-disable-line react/no-unused-state
      firstName: '',
      lastName: '',
      saved: false,
      textHelpEnabled: false,
    };
    this.Avatar = SatCoreComponent('Avatar');
    this.FullScreenModalPage = SatCoreComponent('FullScreenModalPage');
  }

  componentDidMount() {
    const { userManager, navigationManager } = this.props;
    navigationManager.setView(VIEW_SELECTION.PROFILE);
    this.setState({ firstName: userManager.firstName, lastName: userManager.lastName, textHelpEnabled: userManager.textHelpEnabled });
    this.fetchUserOptions();
  }

  onClose = () => {
    const { history: { push, location: { state: { url } = { url: '/' } } } } = this.props;
    push(url);
  };

  verifyChanges = async () => {
    const { classroomManager, userManager } = this.props;
    const { firstName, lastName } = this.state;
    // eslint-disable-next-line sort-keys, react/no-unused-state
    this.setState({ saved: false, error: false });

    let changesHappened = false;

    if (firstName && firstName !== userManager.firstName) {
      changesHappened = true;
    }
    if (lastName && lastName !== userManager.lastName) {
      changesHappened = true;
    }

    if (changesHappened) {
      const result = await userManager.saveChanges({ firstName, lastName });

      if (result) {
        const { isTeacher } = userManager;
        const { currentClassroomId } = classroomManager;

        if (isTeacher && currentClassroomId && classroomManager.getClassroom(currentClassroomId)) {
          // user is a teacher with a cached classroom and profile changes have happened.
          // each classroom object is associated with certain aspects of a teacher's user profile, e.g. name.
          // ---
          // since the teacher's profile information has now changed,
          // any cached classroom where the logged in user is 'lead teacher' is now likely outdated
          // ---
          // refetch the teacher's classroom data to ensure it is up-to-date.
          await classroomManager.fetchClassroomData(classroomManager.currentClassroomId);

          // eslint-disable-next-line sort-keys, react/no-unused-state
          this.setState({ saved: true, error: false });
        } else {
          // eslint-disable-next-line sort-keys, react/no-unused-state
          this.setState({ saved: true, error: false });
        }
      } else {
        // eslint-disable-next-line sort-keys, react/no-unused-state
        this.setState({ saved: false, error: true });
      }
    }
  };

  handleChangeFirstName = (e, data) => {
    this.setState({ firstName: data.value });
  };

  handleChangeLastName = (e, data) => {
    this.setState({ lastName: data.value });
  };

  fetchUserOptions = async () => {
    const { userManager } = this.props;
    await userManager.fetchMyUserOptions();
    this.setState({ textHelpEnabled: userManager.textHelpEnabled });
  };

  toggleAvatarEditor = () => {
    const { dialogManager } = this.props;

    if (dialogManager.openedDialogs.has(DIALOG_NAMES.AVATAR)) {
      // dialogManager.closeDialog(DIALOG_NAMES.AVATAR);
      window.location.reload();
      setTimeout(() => {
        if (dialogManager.openedDialogs.has(DIALOG_NAMES.AVATAR)) {
          dialogManager.closeDialog(DIALOG_NAMES.AVATAR);
        }
      }, 300);
    } else {
      this.verifyChanges(); // save any profile changes prior to launching the avatar editor
      dialogManager.setOpenDialog(DIALOG_NAMES.AVATAR, {}, this.toggleAvatarEditor);
    }
  };

  handleTextHelp = async () => {
    const { userManager } = this.props;
    const { textHelpEnabled } = this.state;
    const toggle = !textHelpEnabled;
    const success = await userManager.updateMyTextHelpOption(toggle);
    if (success) {
      this.fetchUserOptions();
    }
  };

  navToChangePassword = () => {
    const { changePasswordModal, dialogManager, history } = this.props;
    if (!changePasswordModal) {
      return history.push('/change-password');
    }
    dialogManager.setOpenDialog(DIALOG_NAMES.CHANGE_PASSWORD, {});
  };

  renderErrors() {
    const { saved, fail } = this.state;
    return (
      <>
        {saved && (
        <Message positive>
          <Message.Header>Success</Message.Header>
          <p>
            Your profile has been updated.
          </p>
        </Message>
        )}
        {fail && (
        <Message negative>
          <Message.Header>Something went wrong</Message.Header>
          <p>
            Your profile failed to save please try again.
          </p>
        </Message>
        )}
      </>
    );
  }

  renderContent() {
    const { Avatar } = this;
    const { userManager, t } = this.props;
    const { firstName, lastName, textHelpEnabled } = this.state;
    return (
      <div className='profile-container'>
        <div className='profile-header'>
          <div className='profile-txt'>Profile</div>
          <div className='avatar-controls'>
            <Avatar className='profile-avatar' fullProfileImageUrl={userManager.fullProfileImageUrl} />
            <Button className='avatar-button' onClick={this.toggleAvatarEditor} size='mini'>Edit</Button>
          </div>
        </div>
        <div className='profile-fields'>
          <div className='profile-field-txt'>{t('firstName', 'First Name')}</div>
          <Input
            disabled={userManager.isSsoUser || userManager.isStudent}
            name='firstName'
            onChange={this.handleChangeFirstName}
            placeholder='First Name'
            type='text'
            value={firstName} />
          <div className='profile-field-txt'>{t('lastName', 'Last Name')}</div>
          <Input
            disabled={userManager.isSsoUser || userManager.isStudent}
            name='lastName'
            onChange={this.handleChangeLastName}
            placeholder='Last Name'
            type='text'
            value={lastName} />
          <div className='profile-field-txt'>{t('username', 'Username/email')}</div>
          <Input
            disabled={true}
            placeholder='Username/email'
            type='text'
            value={userManager.username} />

          {(userManager.publisherTextHelpActive && !userManager.showTextHelpForAll) ? (
            <>
              <div className='profile-field-txt' />
              <Radio
                checked={textHelpEnabled}
                disabled={false}
                label='Enable Text Help'
                onChange={() => { this.handleTextHelp(); }}
                toggle />
            </>
          ) : ''}

        </div>
        {!userManager.isSsoUser && (
          <Button basic className='profile-change-password-btn' onClick={this.navToChangePassword} primary>
            Change Password
          </Button>
        )}
        <Button className='profile-save-changes-btn' onClick={this.verifyChanges} primary>
          Save
        </Button>
        {this.renderErrors()}
      </div>
    );
  }

  render() {
    const { FullScreenModalPage, props: { fullScreenModal } } = this;
    const { t } = this.props;
    if (!fullScreenModal) {
      return (
        <div className='profile-page'>
          {this.renderContent()}
        </div>
      );
    }
    const profileModalTitle = t('profileModalTitle');
    return (
      <FullScreenModalPage aria-label='Your profile view' className='profile-page' close={this.onClose} title={profileModalTitle}>
        {this.renderContent()}
      </FullScreenModalPage>
    );
  }
}

Profile.defaultProps = {
  changePasswordModal: true,
  fullScreenModal: true
};

SatCoreRegister('Profile', Profile);
