import { registerClass } from '../SatCoreRegistry';

import contentManager, { PLAYER_TYPES } from '../managers/ContentManager';
import userManager from '../managers/UserManager';

export default class ContentService {
  static getContentSessionId = async () => {
    return contentManager.currentflowPaperSessionId;
  }

  static getCurrentContentItemId = () => {
    return contentManager.currentContentItemId;
  }

  static setLessonPlayerShowing = (showing) => {
    contentManager.setLessonPlayerShowing(showing);
  }

  static setSatelliteTabShowing = (showing) => {
    contentManager.setSatelliteTabShowing(showing);
  }

  static getOptionsForFileViewer = async ({
    // activity, // TODO unused
    // activityId = null, // TODO unused
    // activityInstance, // TODO unused
    // activityInstance, // TODO unused
    // attachmentContentItemId, // TODO unused
    // classroomId = null, // TODO unused
    // courseResourceElementId = null, // TODO unused
    // deliveryMode = '', // TODO unused
    // elementId = null, // TODO unused
    // entityTypeId, // TODO unused
    // fromMethodName, // for debugging purposes
    // origin, // TODO unused
    // pdfDeliveryFormat, // TODO unused
    // publisherModeId = null, // TODO unused
    // userId, // TODO unused
    contentItemId,
    contentMode,
    isFlowpaper = false,
    isTeacher
  } = {}) => {
    const resource = await contentManager.fetchResourceItem(contentItemId);
    return {
      contentMode,
      isFlowpaper,
      isTeacher: typeof isTeacher === 'boolean' ? isTeacher : userManager.isTeacher,
      optionContentMode: contentMode,
      playerType: PLAYER_TYPES.FILE_VIEWER,
      viewUrl: resource.presignedAwsUrl || resource.pageUrl
    };
  };

  // TODO unused
  // static getFirstLessonElementIdFromLessonContent = async (contentItemId) => {
  //   const response = await contentManager.fetchLessonContent(contentItemId);
  //   return response?.lessonContent?.structure?.find((structureObj) => {
  //     return !!structureObj?.lessonElementIds?.[0];
  //   })?.lessonElementIds?.[0];
  // }
}

registerClass('ContentService', ContentService);
