import { toJS } from 'mobx';

import { getRegisteredClass, registerClass } from '../../SatCoreRegistry';

import { DELIVERY_MODES } from '../../managers/AssignmentManager';
import { MAX_REPORT_FACULTY_SCORE_CELLS_PER_PAGE } from './ReportConstants';

import reportContextManager from '../../managers/reports/ReportContextManager';
import reportIdentityManager from '../../managers/reports/ReportIdentityManager';
import reportOverrideManager from '../../managers/reports/ReportOverrideManager';
import reportScoreManager from '../../managers/reports/ReportScoreManager';
import reportTableManager from '../../managers/reports/ReportTableManager';

import { getStyleVar, setStyleVar, stripHtmlTags } from '../../utils';

import ReportOverrideService from './ReportOverrideService';

export default class ReportTableService {
  static forceRerenderReportTable = (reportResponseJson) => {
    reportIdentityManager.setReportResponseJson(reportResponseJson || toJS(reportIdentityManager.reportResponseJson));
  }

  /** @param {import('react-table').Row<object>[]} tableFlatRows */
  static setReportTableExpandedRows = (tableFlatRows = [], {
    shouldPrependCachedExpandedRowMap
  } = {}) => {
    if (!Array.isArray(tableFlatRows)) {
      console.error('reportTableManager.setReportTableExpandedRows: tableFlatRows must be an array');
      return;
    }
    const { isCourseReport, isStandardsReport } = reportIdentityManager;

    const tableExpandedRowMap = new Map();
    const tableRowIdProp = isCourseReport ? 'elementId' : 'id';

    for (const tableRow of tableFlatRows) {
      if (tableRow.original && tableRow.original[tableRowIdProp]) {
        tableExpandedRowMap.set(tableRow.original[tableRowIdProp], !!tableRow.isExpanded);
      }
    }
    if (isCourseReport) {
      reportTableManager.setReportCourseTableExpandedRowMap(tableExpandedRowMap, {
        shouldPrependCachedExpandedRowMap
      });
    } else if (isStandardsReport) {
      reportTableManager.setReportStandardsTableExpandedRowMap(tableExpandedRowMap, {
        shouldPrependCachedExpandedRowMap
      });
    }
  }

  /** @param {import('react-table').Row} row */
  static shouldHideTableRow = (row) => {
    const ReportJsonHelperService = getRegisteredClass('ReportJsonHelperService');
    const { isCourseReport } = reportIdentityManager;
    const { isContextProgressReport } = reportContextManager;
    const shouldUseCmapId = ReportOverrideService.shouldUseCmapId();

    const { STUDENT_ALWAYS, TEACHER_ONLY } = DELIVERY_MODES;
    const { elementDeliveryMode } = row.original;

    if (elementDeliveryMode === STUDENT_ALWAYS || elementDeliveryMode === TEACHER_ONLY) {
      return true;
    } else if (isCourseReport && reportScoreManager.shouldHideContentWithoutScores) {
      const courseScoreInfoObj = ReportJsonHelperService.REPORT_SCORE_INFO_BY_ELEMENT_FOR_STUDENTS();
      const childCourseElementIds = row.canExpand ?
        row.subRows.map((subRow) => {
          return subRow.original.elementId;
        }) : [];
      const hasScore = !!row.original.elementId && !!courseScoreInfoObj[row.original.elementId];
      const hasSubRowScore = !!childCourseElementIds.find((elementId) => {
        return !!courseScoreInfoObj[elementId];
      });
      const isRowExemptFromHavingValue = this.isRowExemptFromHavingValue(row);
      return !isRowExemptFromHavingValue && !hasScore && !hasSubRowScore;
    } else if (isContextProgressReport && row.original.id === 'rollupAverageRow') {
      // hide summary row in progress report
      return true;
    } else if (shouldUseCmapId) {
      return row.original.type === 'ROOT' || row.original.type === 'UNITS';
    }
  }

  /** @param {import('react-table').Row} row */
  static hasAllEmptyFacultyCellsInRow = (row) => {
    const { activeReportScorePropName } = reportIdentityManager;
    if (activeReportScorePropName && !row?.cells?.length) {
      return true;
    }
    const found = !!(row.cells.filter((cell) => {
      return cell?.column?.className?.includes('cell-faculty-result');
    }).find((cell) => {
      if (typeof cell === 'number') {
        return !!cell;
      } else {
        return cell && cell?.value && cell.value[activeReportScorePropName];
      }
    }));
    return !found;
  }

  /** @param {import('react-table').Row} row */
  static isRowExemptFromHavingValue = (row) => {
    if (!row) {
      return true;
    }
    const { isCourseReport, isFacultyAdminReport, isStandardsReport } = reportIdentityManager;
    const { allowCourseReportExpandableRowData } = reportOverrideManager;

    const isExpandableRow = this.isExpandableRow({ row });

    if (!allowCourseReportExpandableRowData && isCourseReport && isExpandableRow) {
      return true;
    }

    const shouldUseCmapId = ReportOverrideService.shouldUseCmapId();
    if (shouldUseCmapId && row.original.hasRollup) {
      return false;
    }

    const isStandardUnit = isStandardsReport && row.original.type?.toUpperCase()?.includes('UNIT');
    return (row.canExpand && (isFacultyAdminReport || isStandardsReport)) ||
      (isStandardsReport && (!row.original.name || isStandardUnit));
  }

  static getReportTableExpandedRowMap = () => {
    const { isCourseReport, isStandardsReport } = reportIdentityManager;
    if (isCourseReport) {
      return toJS(reportTableManager.reportCourseTableExpandedRowMap);
    } else if (isStandardsReport) {
      return toJS(reportTableManager.reportStandardsTableExpandedRowMap);
    }
  }

  static isExpandableRow = (props) => {
    return !!this.getExpandableRowClassNameIfApplicable(
      props?.row?.original?.elementType || props?.row?.original?.type, props
    );
  }

  /**
   * @param {'ROOT' | 'SECTION' | 'UNIT' | 'assessment' | 'lesson' | 'resource'} elementType
   */
  static getExpandableRowClassNameIfApplicable = (elementType, props = {}) => {
    elementType = elementType || props?.row?.original?.elementType;
    const isExpandableRow = /ROOT|SECTION|UNIT/.test(elementType) || props?.row?.canExpand;
    const hasRollup = props?.row?.original?.hasRollup;
    if (isExpandableRow) {
      return ` expandable-row${hasRollup ? ' rollup-row' : ''}`;
    } else {
      return '';
    }
  }

  /** show/hide faculty score cells based on current `activeReportTablePage` */
  static handlePaginateFacultyResultCells = ({
    allColumns, setHiddenColumns
  } = {}) => {
    const { activeReportTablePage } = reportTableManager;

    const hiddenColumns = this.getFacultyResultCells(allColumns)
      .filter((_column, index) => {
        const PAGE_SIZE = MAX_REPORT_FACULTY_SCORE_CELLS_PER_PAGE;
        const startIndex = (activeReportTablePage - 1) * PAGE_SIZE;
        const endIndex = (activeReportTablePage) * PAGE_SIZE;

        const inPageRange = index >= startIndex && index < endIndex;
        const hidden = !inPageRange;
        return hidden;
      }).map((column) => column.id);
    setHiddenColumns(hiddenColumns);
  };

  static modifyCertainStyleOffsetsToAccommodateReportTablePaginator = (totalPages) => {
    if (totalPages > 1) {
      // report-table-paginator is visible, so apply the applicable offsets if they do not exist
      if (reportContextManager.isContextProgressReport) {
        if (getStyleVar('--report-progress-numbers-toggler-margin-left-offset') === '0px') {
          setStyleVar('--report-progress-numbers-toggler-margin-left-offset', '-110px');
        }
      }
    } else {
      // report-table-paginator is not visible, so remove the applicable offsets if they exist
      if (reportContextManager.isContextProgressReport) {
        if (getStyleVar('--report-progress-numbers-toggler-margin-left-offset') !== '0px') {
          setStyleVar('--report-progress-numbers-toggler-margin-left-offset', '0px');
        }
      }
    }
  }

  static getTotalFacultyResultCellPages = (allColumns = []) => {
    const totalFacultyResultCellCount = this.getFacultyResultCells(allColumns).length;
    const PAGE_SIZE = MAX_REPORT_FACULTY_SCORE_CELLS_PER_PAGE;
    return Math.ceil(totalFacultyResultCellCount / PAGE_SIZE) || 1;
  }

  static getFacultyResultCells = (allColumns = []) => {
    if (!allColumns || !Array.isArray(allColumns)) {
      return [];
    }
    return allColumns.filter((column) => {
      return column.className?.includes('cell-faculty-result');
    });
  }

  /**
   * Return a modified table row title in the following format:
   * ```
   * `${contentType} - ${originalStrippedRowTitle} - ${elementDescription}`
   * ```
   * e.g.
   * ```
   * 'Study Guide - Events Leading to the American Revolution - Chapter 4'
   * ```
   */
  static appendCourseElementContentTypeAndElementDescriptionToReportTableRowTitle = (row, originalStrippedRowTitle) => {
    const contentType = stripHtmlTags(row?.original?.contentType || '');
    const elementDescription = stripHtmlTags(row?.original?.elementDescription || '');

    let title = contentType;
    if (originalStrippedRowTitle && title !== originalStrippedRowTitle) {
      title += title ? ' - ' : '';
      title += originalStrippedRowTitle;
    }
    if (elementDescription) {
      title += title ? ' - ' : '';
      title += elementDescription;
    }
    return title;
  }
}

registerClass('ReportTableService', ReportTableService);
