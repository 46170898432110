/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button, Container, Dropdown, Input, Loader, Message, Pagination } from 'semantic-ui-react';

import '../css/AdminTestBuilderView.less';

import { debounce } from 'lodash';
import Auth from '../managers/AuthManager';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { VIEW_SELECTION } from '../managers/NavigationManager';

import { DIALOG_NAMES } from '../managers/DialogManager';

import { PLAYER_TYPES } from '../managers/ContentManager';

import { getLibraryResourceMetadata } from '../managers/LibraryManager';

import UtilityService from '../services/UtilityService';
import ItemBankService from '../services/ItemBankService';
import ResourcePacingService from '../services/ResourcePacingService';
import ImageService from '../services/ImageService';

export default @inject(
  'contentManager',
  'courseManager',
  'dialogManager',
  'libraryManager',
  'navigationManager',
  'productManager',
  'userManager',
  'tagContentManager',
  'classroomManager'
)
@observer
class TeacherTestBuilderView extends Component {
  constructor(props) {
    super(props);

    this.LibraryResourceCard = SatCoreComponent('LibraryResourceCard');
    this.BreadCrumbs = SatCoreComponent('BreadCrumbs');
    this.FullscreenModal = SatCoreComponent('FullscreenModal');
    this.DocReaderModal = SatCoreComponent('DocReaderModal');
    this.FileViewerModal = SatCoreComponent('FileViewerModal');

    this.state = {
      deletedLibraryResourceName: null,
      docreaderViewerShowing: false,
      teacherHasValidClassrooms: false,
      fileViewerShowing: false,
      lessonPlayerShowing: false,
      libraryCardData: null,
      productOptions: [],
      searchText: '',
      searchTypes: null,
      selectedMenu: 'myDrafts',
      serverErrorMsg: null,
      showKey: 'all',
      sortColumn: null,
      sortDirection: null,
      sortKey: ''
    };
  }

  componentDidMount = async () => {
    const { libraryManager } = this.props;
    const { activePage = 1 } = libraryManager;
    const {
      navigationManager
    } = this.props;

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('view')) {
      navigationManager.setView(urlParams.get('view'));
    } else {
      navigationManager.setView(VIEW_SELECTION.LIBRARY);
    }
    await this.fetchData(activePage);
    await this.fetchClassroomData();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { sortColumn, sortDirection, showKey, selectedMenu } = this.state;
    if (prevState.selectedMenu !== selectedMenu ||
        prevState.sortColumn !== sortColumn ||
          prevState.sortDirection !== sortDirection ||
            prevState.showKey !== showKey) {
      this.refreshData();
    }
  }

  async componentWillUnmount() {
    const { libraryManager } = this.props;
    libraryManager.setActivePage(1);
  }

  refreshData = async (activePage = 1) => {
    // Some of the BE aws operations seem to take a bit to process, so if this is a refresh
    // add some delay before fetch.
    setTimeout(() => {
      this.fetchData(activePage);
    }, 500);
  }

  clearLibraryCardData = async () => {
    const { libraryManager } = this.props;
    libraryManager.setLibraryLoading(true);
    this.setState({ libraryCardData: null });
  }

  fetchData = async (activePage = 1) => {
    const { libraryManager } = this.props;
    const { searchTypes, selectedMenu, sortDirection, sortColumn } = this.state;
    let libraryCardData = null;
    let productOptions = [];

    await this.clearLibraryCardData();
    let typesToSearch = searchTypes;
    if (selectedMenu === 'myDrafts') {
      typesToSearch = typesToSearch || 'assessment';
      libraryCardData = await libraryManager.fetchLibraryMyDrafts(activePage, 25, typesToSearch, sortColumn, sortDirection);
    }
    this.setState({ libraryCardData, productOptions });
  }

  fetchProductDropdownData = async () => {
    const { productManager, userManager } = this.props;
    const products = await productManager.fetchLicensedCourseProducts(userManager.userId);
    const productOptions = [];
    if (products && products.length > 0) {
      productOptions.push({ key: -1, text: 'All Products', value: 'all' });
      products.forEach((product) => {
        productOptions.push({ key: product.id, text: product.displayName, value: product.id });
      });
    }
    return productOptions;
  }

  fetchClassroomData = async (nextPage = 0) => {
    const { classroomManager } = this.props;
    const page = 0;
    const isStudent = false;
    const userId = null;
    const archived = false;

    await classroomManager.fetchClassroomData(
      null, page, isStudent, userId, archived, {
        pageSize: 999
      }
    );
    // we need to set current classroom so the assign modal will work.
    let teacherHasValidClassrooms = false;
    const classrooms = classroomManager.availableClassroomsArray;
    if (classrooms && classrooms.length > 0) {
      for (const classroom of classrooms) {
        if (classroom.rosterCount > 0) {
          classroomManager.setCurrentClassroomId(classroom.id);
          teacherHasValidClassrooms = true;
          break;
        }
      }
    }
    this.setState({ teacherHasValidClassrooms });
  }

  onPageChange = async (_event, pageInfo) => {
    const { activePage } = pageInfo;
    const { libraryManager } = this.props;
    // console.log(`****Page changed: ${activePage} previous page ${libraryManager.activePage}`);
    this.fetchData(activePage);
  }

  handleMenuClick = async (selectedMenu) => {
    this.setState({
      libraryCardData: null,
      searchText: '',
      searchTypes: null,
      selectedMenu,
      selectedProduct: null,
      serverErrorMsg: null,
      showKey: 'all',
      sortColumn: null,
      sortDirection: null,
      sortKey: null,
    });
  }

  handleShow = async (_event, { value }) => {
    let searchTypes = value;
    if (value === 'all') {
      searchTypes = 'course_resource,assessment';
    }
    this.setState({ searchTypes, showKey: value });
  }

  handleSort = async (_event, { value }) => {
    let sortColumn = '';
    let sortDirection = '';
    if (value === 'oldest') {
      sortColumn = 'modificationDate';
      sortDirection = 'asc';
    } else if (value === 'title-asc') {
      sortColumn = 'sortName';
      sortDirection = 'asc';
    } else if (value === 'title-desc') {
      sortColumn = 'sortName';
      sortDirection = 'desc';
    } else {
      // default is "newest"
      sortColumn = 'modificationDate';
      sortDirection = 'desc';
    }
    this.setState({ sortColumn, sortDirection, sortKey: value });
  }

  onCloseSelectNewLibraryResourceModal = async () => {
    // console.log('Closing select modal');
  }

  onCloseCreateNewLibraryResourceModal = async (data, callback) => {
    const { courseManager, libraryManager } = this.props;
    const { activePage } = libraryManager;
    // if we have assesssment bank data returned, redirect to edit
    if (data && data.entityTypeId && data.entityTypeId === 'course_resource') {
      // build the proper data object
      const metaData = getLibraryResourceMetadata(data);
      const { backgroundImg, cardClassName, cardTypeName, typeDisplayName } = metaData;
      const libraryCardData = {
        backgroundImg,
        cardClassName,
        cardTypeName,
        libraryResource: data,
        title: data.name,
        typeDisplayName
      };
      this.editLibraryResource(libraryCardData);
    } else {
      // otherwise stay on library view and refresh page
      // if a callback was sent, call it, otherwise, do default refresh.
      if (callback) {
        callback();
      } else {
        await courseManager.clearAll(); // clear course data so rename of assessments gets reflected.
        const contentItemId = data?.libraryResource?.id;
        const contentItemName = data?.libraryResource?.name;
        courseManager.setCurrentCourseId(contentItemId);
        courseManager.setCurrentCourseName(contentItemName);
        courseManager.setCurrentElementId('');
        this.refreshData(activePage);
      }
    }
  }

  handleCreateNew = async (option) => {
    const { dialogManager } = this.props;

    // Teachers can only create assessments
    dialogManager.setOpenDialog(DIALOG_NAMES.CREATE_NEW_LIBRARY_RESOURCE_MODAL, {
      close: this.closeCreateNewLibraryResourceModal,
      onCloseCreateNewLibraryResourceModal: this.onCloseCreateNewLibraryResourceModal,
      open: true,
      getAll: true,
      selectedResourceType: 'assessment'
    });
  }

  handleItemBankView = async (libraryCardData) => {
    const { history } = this.props;
    const { dialogManager, tagContentManager, userManager } = this.props;

    // load some things it may need.
    await tagContentManager.fetchAvailableCategoriesWithTags(null, 'ITEM_BANK'); // passing null uses the default set in tag content manager
    await tagContentManager.fetchStandardFrameworkBookmarks(userManager.userId); // passing null uses the default set in tag content manager
    dialogManager.setOpenDialog(DIALOG_NAMES.ITEM_BANK_VIEW, { assessmentItemsSearch: true, history, libraryCardData },
      dialogManager.closeDialog(DIALOG_NAMES.ITEM_BANK_VIEW));
  }

  handleResourceBankView = async (libraryCardData) => {
    const { history } = this.props;
    const { dialogManager, tagContentManager, userManager } = this.props;

    // load some things it may need.
    await tagContentManager.fetchAvailableCategoriesWithTags(null, 'RESOURCE_BANK'); // passing null uses the default set in tag content manager
    await tagContentManager.fetchStandardFrameworkBookmarks(userManager.userId); // passing null uses the default set in tag content manager
    dialogManager.setOpenDialog(DIALOG_NAMES.RESOURCE_BANK_VIEW, {
      assessmentItemsSearch: true,
      history,
      libraryCardData,
      previewLibraryResource: this.previewLibraryResource
    },
    dialogManager.closeDialog(DIALOG_NAMES.RESOURCE_BANK_VIEW));
  }

  handleItemBankDetails = (libraryCardData) => {
    alert(`Details ${libraryCardData.typeDisplayName}.`);
    // TODO open custom itembank card details page
  }

  closeCourseElementModal = async () => {
    const { dialogManager, history } = this.props;
    // the course element modal ends up chaging the url by adding params so reset
    await history.push(`/testbuilder?view=${VIEW_SELECTION.TEST_BUILDER}`);
    dialogManager.closeAllDialogs();
  }

  previewLibraryResource = (libraryCardData) => {
    this.handleView(
      libraryCardData.id,
      libraryCardData?.libraryResource?.entityTypeId,
      libraryCardData?.libraryResource?.displayName
    );
  }

  printPreviewLibraryResource = (libraryCardData) => {
    const url = `${Auth.lesson}/printPreview?contentItemId=${libraryCardData.id}&forceTeacher=${false}&ficon=true&title=${document.title}#${Auth.authKey}`
    window.open(url,'_blank');
  }

  printPreviewLibraryResourceAnswers = (libraryCardData) => {
    const url = `${Auth.lesson}/printPreview?contentItemId=${libraryCardData.id}&forceTeacher=${true}&ficon=true&title=${document.title}#${Auth.authKey}`
    window.open(url,'_blank');
  }

  assignLibraryResource = (libraryCardData) => {
      this.handleAddAssignmentConfirm(libraryCardData);
  }

  editLibraryResource = (libraryCardData) => {
    // TODO open resource in edit mode by resource type
    const { history } = this.props;
    const { courseManager, contentManager, dialogManager } = this.props;

    if (libraryCardData?.libraryResource?.entityTypeId === 'course_resource') {
      if (libraryCardData?.typeDisplayName === 'Course') {
        // if published, we can't edit so redirect to preview
        this.previewLibraryResource(libraryCardData);
      } else {
        // When going into course builder we need to save state for when we return.
        // save current state if we had been in Library Course Builder.
        sessionStorage.setItem('LibraryViewFilter', JSON.stringify(this.state));
        // Open the assessement bank course builder.
        courseManager.clearAll();
        contentManager.clearAll();
        dialogManager.setOpenDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_BANK_VIEW, {
          closeAssessmentBankModal: this.closeAssessmentBankModal,
          handleDetails: this.showLibraryResourceDetails,
          handleTagsAndStandards: this.showLibraryResourceTagsStandards,
          handleShowItemUsageExport: this.showItemUsageExport,
          handleView: this.previewLibraryResource,
          setCurrentLibraryMenu: this.handleMenuClick,
          history,
          libraryCardData
        },
        () => dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_BANK_VIEW));
      }
    } else if (libraryCardData?.libraryResource?.entityTypeId === 'assessment') {
      if (libraryCardData?.libraryResource?.published) {
        // if published, we can't edit so redirect to preview
        this.previewLibraryResource(libraryCardData);
      } else {
        // Open the c2c-lesson assessement builder.
        dialogManager.setOpenDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_BUILDER_VIEW, {
          closeLibraryAssessmentBuilderModal: this.closeLibraryAssessmentBuilderModal,
          contentItemId: libraryCardData?.libraryResource?.id,
          history
        },
        () => dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_BUILDER_VIEW));
      }
    } else if (libraryCardData?.libraryResource?.entityTypeId === 'publisher' && libraryCardData?.libraryResource?.type === 'resource') {
      // for resource banks, we do a preview
      this.previewLibraryResource(libraryCardData)
    } else if (libraryCardData?.libraryResource?.entityTypeId === 'publisher' && libraryCardData?.libraryResource?.type === 'item_bank') {
      // for item banks we open a different open/edit view
      this.handleItemBankView(libraryCardData);
    }
  }

  closeLibraryAssessmentBuilderModal = async () => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_BUILDER_VIEW);
  }

  closeAssessmentBankModal = async () => {
    const { courseManager, dialogManager, history, libraryManager } = this.props;
    const { activePage } = libraryManager;
    // todo close modal
    courseManager.clearCurrentCourse();
    await this.fetchData(activePage);
    await dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_BANK_VIEW);
    await history.push(`/testbuilder?view=${VIEW_SELECTION.TEST_BUILDER}`);
  }

  onCloseCopyLibraryResourceModal = async () => {
    const { courseManager, dialogManager, libraryManager } = this.props;
    const { activePage } = libraryManager;
    dialogManager.closeDialog(DIALOG_NAMES.COPY_LIBRARY_RESOURCE_MODAL);
    await courseManager.clearAll(); // clear course data so rename of assessments gets reflected.
    this.refreshData(activePage);
  }

  copyLibraryResourceConfirm = (libraryCardData, closeCallback = null) => {
    const { dialogManager, t } = this.props;
    if (closeCallback) {
      closeCallback();
    }
    dialogManager.setOpenDialog(DIALOG_NAMES.COPY_LIBRARY_RESOURCE_MODAL, {
      contentItemId: libraryCardData?.libraryResource?.id,
      onCloseCopyLibraryResourceModal: this.onCloseCopyLibraryResourceModal,
      resourceName: libraryCardData?.libraryResource?.name,
      selectedResourceType: libraryCardData?.libraryResource?.entityTypeId
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.COPY_LIBRARY_RESOURCE_MODAL));
  }

  hasClassesWarning = (linkedClassroomNames) => {
    const { dialogManager, t } = this.props;
    const title = t('hasClassesTitle');
    let message = t('hasClasses');
    let index = 0;
    for (const className of linkedClassroomNames) {
      message = `${message + ((index > 0) ? ',' : '')}"${className}"`;
      index++;
    }

    dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);

    dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
      title,
      message,
      closeButtonClass: 'deleteButton',
      closeButtonName: 'Ok'
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
  }

  deleteLibraryResource = async (libraryCardData) => {
    const { courseManager, dialogManager, libraryManager } = this.props;
    const { activePage } = libraryManager;
    dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);
    // console.log(`Delete ${libraryCardData.typeDisplayName} called`);// remove
    let result = false;
    let response = false;
    if (libraryCardData?.libraryResource?.entityTypeId === 'assessment') {
      response = await libraryManager.deleteLibraryAssessment(libraryCardData.id, true);
    } else {
      response = await courseManager.deleteContentItem(libraryCardData.id, false);
    }
    if (response && response.status === 'SUCCESS') {
      result = true;
    }
    if (response && response.status === 'FAILURE') {
      result = false;
      if (response.statusCode === 'HAS_CLASSROOM_LINKS') {
        this.deleteLibraryResourceConfirm(libraryCardData, response.linkedClassroomNames);
        return;
      }
    }
    if (!result) {
      const responseStatusMessage = (response && response.statusMessage) ? response.statusMessage : '';
      console.log(`Error deleting ${libraryCardData.typeDisplayName}: ${libraryCardData.id}`);
      const errorMessage = `Error deleting ${libraryCardData.typeDisplayName}: ${libraryCardData.id} ${responseStatusMessage}`;
      console.log(errorMessage);
      let tempMessage = errorMessage;
      tempMessage = tempMessage.length > 99 ? `${tempMessage.substring(0, 96)}...` : tempMessage;
      this.setState({ deletedLibraryResourceName: null, serverErrorMsg: tempMessage });
    } else {
      let tempMessage = `"${libraryCardData.title}"`;
      tempMessage = tempMessage.length > 99 ? `${tempMessage.substring(0, 96)}...` : tempMessage;
      tempMessage += ' has been removed.';
      await courseManager.clearAll(); // clear course data so assessment banks get updated.
      await this.refreshData(activePage);
      this.setState({ deletedLibraryResourceName: tempMessage, serverErrorMsg: tempMessage });
    }
  }

  deleteLibraryResourceConfirm = (libraryCardData, linkedClassroomNames = null) => {
    const { dialogManager, t } = this.props;
    if (linkedClassroomNames && linkedClassroomNames.length > 0) {
      this.hasClassesWarning(linkedClassroomNames);
    } else {
      const title = t('confirmDeleteTitle');
      const message = `${t('confirmDeletePrefix')} "${libraryCardData.title}" ${t('confirmDeleteSuffix')}`;
      dialogManager.setOpenDialog(DIALOG_NAMES.CONFIRM, {
        title,
        message,
        cancelButtonClass: 'keepButton',
        cancelButtonName: 'No, Cancel',
        confirmButtonClass: 'deleteButton',
        confirmButtonName: 'Yes, Continue',
        confirmHandler: () => this.deleteLibraryResource(libraryCardData)
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.CONFIRM));
    }
  }

  showLibraryResourceDetails = (libraryCardData, onCloseCallback) => {
    const { dialogManager, t, userManager } = this.props;
    const { selectedProduct } = this.state;
    if (libraryCardData?.libraryResource?.isLicensedCourse) {
      dialogManager.setOpenDialog(DIALOG_NAMES.LIBRARY_RESOURCE_DETAILS_MODAL, {
        courseId: libraryCardData.id,
        headerLabel: 'Course Details',
        open: true,
        productId: libraryCardData?.libraryResource?.productsList || selectedProduct,
        resourceCodeLabel: 'Product Code',
        resourceIdLabel: 'Course ID',
        resourceNameLabel: 'Course Name',
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_RESOURCE_DETAILS_MODAL));
    } else if (libraryCardData?.libraryResource?.entityTypeId === 'publisher') {
      dialogManager.setOpenDialog(DIALOG_NAMES.LIBRARY_RESOURCE_DETAILS_MODAL, {
        headerLabel: 'Product Details',
        open: true,
        productId: libraryCardData.id,
        resourceCodeLabel: 'Product Code',
        resourceIdLabel: 'Product ID',
        resourceNameLabel: 'Product Name',
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_RESOURCE_DETAILS_MODAL));
    } else {
      // Launch the create new assessment/(bank) modal in edit mode
      dialogManager.setOpenDialog(DIALOG_NAMES.CREATE_NEW_LIBRARY_RESOURCE_MODAL, {
        close: { close },
        closeCreateNewLibraryResourceModal: { close },
        contentItemId: libraryCardData.id,
        courseResourceElement: libraryCardData.courseElement || null,
        isEditDetails: true,
        isEdit: !libraryCardData?.libraryResource?.published,
        isReadOnly: libraryCardData?.libraryResource?.published,
        isPrintable: libraryCardData?.libraryResource?.printable,
        onClose: () => this.onCloseCreateNewLibraryResourceModal(libraryCardData, onCloseCallback),
        onCloseCreateNewLibraryResourceModal: () => this.onCloseCreateNewLibraryResourceModal(libraryCardData, onCloseCallback),
        open: true,
        selectedResourceType: libraryCardData?.libraryResource?.entityTypeId
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.CREATE_NEW_LIBRARY_RESOURCE_MODAL));
    }
  }

  onLibraryCardClick = (libraryCardData) => {
    const { userManager } = this.props;
    if (userManager.canViewLibraryItemBank) {
      this.editLibraryResource(libraryCardData);
    } else {
      this.previewLibraryResource(libraryCardData);
    }
  }

  hideError = () => {
    this.setState({ deletedLibraryResourceName: null, serverErrorMsg: null });
  }

  getLibraryCardOptions = (resource) => {
    const { libraryManager, userManager } = this.props;
    const { selectedMenu, teacherHasValidClassrooms } = this.state;
    const { t } = this.props;
    const options = {};
    // define common options
    let preview = {};
    let printPreview = {};
    let printPreviewWithAnswers = {};

    if (libraryManager.allowPrintItemBanking && resource.libraryResource.printable) {
      printPreview = {
        key: 'printPreview',
        label: t('previewOptionLabelTeacher'),
        callback: this.printPreviewLibraryResource
      };

      printPreviewWithAnswers = {
        key: 'previewWithAnswers',
        label: t('previewOptionLabelTeacherAnswers'),
        callback: this.printPreviewLibraryResourceAnswers
      };
    }

    if (libraryManager.allowDigitalItemBanking && !resource.libraryResource.printable) {
      preview = {
        key: 'preview',
        label: t('previewOptionLabel'),
        callback: this.previewLibraryResource
      };
    }

    const details = {
      key: 'details',
      label: t('detailsOptionLabel'),
      callback: this.showLibraryResourceDetails
    };
    const copy = {
      key: 'copy',
      label: t('copyOptionLabel'),
      callback: this.copyLibraryResourceConfirm
    };
    const deleteOption = {
      key: 'delete',
      label: t('deleteOptionLabel'),
      callback: this.deleteLibraryResourceConfirm
    };

    // add specific options for each menu selection and resource type
    if (selectedMenu === 'myDrafts') {
      if ((libraryManager.allowDigitalItemBanking && !resource.libraryResource.printable) && !resource.libraryResource.published) {
        options.assign = {
          key: 'assign',
          label: t('assignOptionLabelAssessment'),
          callback: this.assignLibraryResource,
          disabled: (!teacherHasValidClassrooms),
          disabledMessage: t('assignOptionDisabledMessage')
        };
      }

      if (userManager.canViewLibraryItemBank && !resource.libraryResource.published) {
        options.edit = {
          key: 'edit',
          label: t('editOptionLabelAssessment'),
          callback: this.editLibraryResource
        };
      }

      if (!resource.libraryResource.published) {
        options.details = details;
      }

      if (preview['key']) {
        options.preview = preview;
      }

      if (printPreview['key']) {
        options.printPreview = printPreview;
      }

      if (printPreviewWithAnswers['key']) {
        options.printPreviewWithAnswers = printPreviewWithAnswers;
      }

      if (resource.cardTypeName === ('assessment')) {
        options.copy = copy;
      }

      if (!resource.libraryResource.published) {
        options.delete = deleteOption;
      }
    }

    // If new options are added any custom sortLibraryCardKebabOptions (example: Simple Solutions) will need to be updated.
    const customOptions = ItemBankService.sortLibraryCardKebabOptions(options);

    return customOptions;
  }

  closeItemBankViewRefresh = () => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.ITEM_BANK_VIEW);
  }

  handleView = async (contentItemId, entityTypeId, name) => {
    const {
      contentManager,
      dialogManager,
      userManager,
      t
    } = this.props;
    let option = null;
    option = await contentManager.getOptionsForTeacherPreview(
      contentItemId, entityTypeId, null, origin,
      userManager.canViewAsTeacher, userManager.userId,
      null, null,
      null
    );

    const { playerType, viewUrl, isFlowpaper } = option;

    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      return;
    } else if (playerType === null && viewUrl === null) {
      // if playerType and viewURL are null this is an unviewable type.
      if (entityTypeId === 'file_resource') {
        // For file resources, these are download only so show message.
        dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
          title: t('downloadOnlyTitle'),
          message: t('downloadOnlyModalMsg'),
          closeButtonClass: 'deleteButton',
          closeButtonName: 'Okay'
        },
        () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
        return;
      } else {
        // otherwise show generic message.
        dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
          title: t('noPreviewAvailableTitle'),
          message: t('noPreviewAvailableMsg'),
          closeButtonClass: 'deleteButton',
          closeButtonName: 'Ok'
        },
        () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
        return;
      }
    }

    await contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);

    if (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER) {
      dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
        contentItemId,
        contentItemType: entityTypeId,
        resourceName: name
      }, () => dialogManager.closeDialog(DIALOG_NAMES.CONTENT_PREVIEW));
    }
    this.setState({
      lessonPlayerShowing: (playerType === PLAYER_TYPES.LESSON_PLAYER),
      learnosityPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_PLAYER),
      fileViewerShowing: (playerType === PLAYER_TYPES.FILE_VIEWER),
      docreaderViewerShowing: (playerType === PLAYER_TYPES.DOCREADER_VIEWER),
      contentUrl: viewUrl,
      contentMode: '',
      isFlowpaper,
      contentItemId,
      contentItemType: entityTypeId,
      contentName: name,
      assignmentId: '',
      previewContentType: entityTypeId,
      previewContentItemId: '',
      resourceName: '',
      instruction: '',
      contentImageUrl: ''
    });
  }

  hideIframe = () => {
    this.setState({ lessonPlayerShowing: false });
  }

  hideModal = () => {
    this.setState({
      docreaderViewerShowing: false,
      fileViewerShowing: false,
      instruction: '',
      learnosityPlayerShowing: false
    });
  }

  handleAddAssignmentConfirm = (libraryCardData) => {
    const { dialogManager, t } = this.props;
    const title = t('confirmAddAssignmentTitle');
    const message = t('confirmAddAssignmentMsg');
    dialogManager.setOpenDialog(DIALOG_NAMES.CONFIRM, {
      title,
      message,
      cancelButtonClass: 'keepButton',
      cancelButtonName: 'Cancel',
      confirmButtonClass: 'deleteButton',
      confirmButtonName: 'Continue',
      confirmHandler: () => {
        dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);
        this.handleAddAssignment(libraryCardData);
      }
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.CONFIRM));
  };

  handleAddAssignment = async (libraryCardData) => {
    const {
      contentManager,
      courseManager,
      dialogManager,
      libraryManager,
      t
    } = this.props;
    const { activePage } = libraryManager;
    // Call to create course (or get existing), add assessment to course, apply course to all teacher classrooms
    const defaultCourseName = t('assignDefaultCourseName');
    const courseElements = await contentManager.publishTeacherAssessment(libraryCardData.id, null, defaultCourseName);
    let courseElement = null
    if (!courseElements || courseElements.size < 1) {
      alert(`Failed to publish library resource: ${libraryCardData.title} for assignment`);
      this.fetchData(activePage);
      return;
    } else {
      courseElement = courseElements[0];
      courseManager.setCurrentCourseId(courseElement.specificCourseContentItemId);
      const contentImageUrl = ImageService.getImageUrl(libraryCardData?.libraryResource);

      dialogManager.setOpenDialog(DIALOG_NAMES.ADD_ASSIGNMENT, {
        ...this.props,
        allowMultipleResources: false,
        alternateModeId: courseElement.alternateModeIdOverride || courseElement.alternateModeId,
        contentDueDate: ResourcePacingService.dueDate(courseElement),
        contentDuration: ResourcePacingService.duration(courseElement),
        contentImageUrl,
        contentItemEntityId: courseElement.contentItemEntityId,
        contentItemEntityTypeId: courseElement.contentItemEntityTypeId,
        contentItemEntitySubTypeId: courseElement.contentItemEntitySubTypeId,
        contentItemId: courseElement.contentItemId || courseElement.entityId,
        contentName: courseElement.name || libraryCardData.title,
        contentSubname: courseElement.description,
        contentTimeframeStartDate: courseElement.timeframeStartDate,
        contentTimeframeEndDate: courseElement.timeframeEndDate,
        timeframeStartDateStr: courseElement.timeframeStartDateStr,
        timeframeEndDateStr: courseElement.timeframeEndDateStr,
        timeframeStartTimeStr: courseElement.timeframeStartTimeStr,
        timeframeEndTimeStr: courseElement.timeframeEndTimeStr,
        courseContentItemId: courseElement.courseContentItemId || courseElement.specificCourseContentItemId,
        courseElement,
        courseElementToggleDefaults: {
          includeInReports: courseElement.includeInReportsOverride,
          scoresReleased: courseElement.scoresReleasedOverride,
          studentInstruction: courseElement.instructionOverride,
          studentReview: courseElement.studentReviewOverride
        },
        courseResourceElementId: courseElement.id,
        displayOverModal: false,
        showCutScoreAssignType: false,
        standards: courseElement.standardsInfo,
        resourceMode: courseElement.resourceMode,
        modeOverrideAllowed: courseElement.modeOverrideAllowed
      }, async () => {
        dialogManager.closeDialog(DIALOG_NAMES.ADD_ASSIGNMENT);
        this.fetchData(activePage);
      });
    }
  }

  renderFileViewer = () => {
    const { userManager, renderFileViewer } = this.props;
    const { contentImageUrl, contentName, contentUrl, previewContentType, instruction, isFlowpaper, resourceName } = this.state;
    const { FileViewerModal } = this;

    if (renderFileViewer !== undefined) {
      return renderFileViewer();
    }

    return (
      <div className='course-content'>
        <FileViewerModal
          closeModalCallback={this.hideModal}
          contentImageUrl={contentImageUrl}
          contentName={contentName}
          contentType={previewContentType}
          instruction={instruction}
          isFlowpaper={isFlowpaper}
          isTeacher={userManager.isTeacher}
          page='file-viewer'
          resourceName={resourceName}
          url={contentUrl} />
      </div>
    );
  }

  renderDocreaderViewer = () => {
    const { userManager, contentManager, renderDocreaderViewer } = this.props;
    const { contentImageUrl, contentMode, contentName, contentUrl, previewContentType, instruction, isFlowpaper, resourceName } = this.state;
    const { DocReaderModal } = this;
    if (renderDocreaderViewer !== undefined) {
      return renderDocreaderViewer();
    }

    return (
      <div className='course-content'>
        <DocReaderModal
          closeModalCallback={this.hideModal}
          contentImageUrl={contentImageUrl}
          contentMode={contentMode}
          contentName={contentName}
          contentType={previewContentType}
          instruction={instruction}
          isFlowpaper={isFlowpaper}
          isTeacher={userManager.isTeacher}
          page='docreader-viewer'
          resourceName={resourceName}
          sessionId={contentManager.currentflowPaperSessionId}
          url={contentUrl} />
      </div>
    );
  }

  renderLessonPlayer = () => {
    const { FullscreenModal } = this;
    const { userManager } = this.props;
    const { contentImageUrl, contentName, contentUrl } = this.state;
    return (
      <Container className='libraryView' fluid>
        <FullscreenModal
          className={'lesson-player'}
          closeIframeCallback={this.hideIframe}
          contentImageUrl={contentImageUrl}
          contentName={contentName}
          isTeacher={userManager.isTeacher}
          page='lesson-player'
          url={contentUrl} />
      </Container>
    );
  }

  renderMenuView = () => {
    const { libraryManager, t } = this.props;
    const { libraryCardData } = this.state;
    const { libraryLoading } = libraryManager;
    if (libraryCardData && libraryCardData.length > 0) {
      return (
        <>
          {this.renderLibraryViewCards()}
        </>
      );
    } else if (libraryLoading && (!libraryCardData || (libraryCardData && libraryCardData.length < 1))) {
      return (
        <div className='null-state-panel'>
          <Loader active inline />
        </div>
      );
    } else {
      return (
        <div className='null-state-panel'>
          {t('noData')}
        </div>
      );
    }
  }

  renderLibraryViewCards = () => {
    const { libraryCardData, selectedMenu } = this.state;
    const { libraryManager } = this.props;
    const { LibraryResourceCard } = this;
    const libraryCards = [];
    if (libraryCardData && libraryCardData.length > 0) {
      libraryCardData.map((data, index) => {
        const { libraryResource } = data;
        const { inApproval } = libraryResource;
        const { published } = libraryResource;
        const showLiveBanner = libraryManager.allowDigitalItemBanking && (inApproval || published) && (selectedMenu === 'myDrafts');
        const useCardClickTypes = 'course_resource, assessment, publisher'; // TODO should other cards have a body click action?
        const useCardClick = !showLiveBanner && useCardClickTypes.includes(data.libraryResource?.entityTypeId);
        const libraryCardKebabOptions = this.getLibraryCardOptions(data);

        libraryCards.push(
          <LibraryResourceCard key={index}
            libraryCardKebabOptions={libraryCardKebabOptions}
            libraryCardParams={data}
            onBodyClick={useCardClick ? this.onLibraryCardClick : null}
            showLiveBanner={showLiveBanner} />);
      });
    }
    return (
      <div className='builder-view-card-container'>
        {libraryCards}
      </div>
    );
  }

  renderLibraryView() {
    const {
      libraryManager,
      userManager,
      t
    } = this.props;
    const { activePage = 1, totalPages, totalResults } = libraryManager;
    const { libraryCardData, selectedMenu, serverErrorMsg, sortKey } = this.state;
    const startResultNumber = activePage <= 1 ? 1 : (activePage - 1) * 25 + 1;
    const endResultNumber = (activePage * 25) > totalResults ? totalResults : activePage * 25;
    return (
      <Container className='libraryView' fluid>
        <div className='builder-view-container'>
          <div className='builder-view-content-container'>
            <div className='builder-view-content-header'>
              <div className='header-top-row'>
                <div className='header-top-row-top-wrapper'>
                  {selectedMenu === 'myDrafts' && (
                    <div className='create-button-container'>
                      <Button className='create-new-button' onClick={this.handleCreateNew} primary type='button'>{t('createNewButtonLabel')}</Button>
                    </div>
                  )}
                  <div className='error-message-wrapper'>
                    <Message
                      className='builder-view-error-msg'
                      content={`${serverErrorMsg}`}
                      error
                      hidden={serverErrorMsg === null}
                      onDismiss={this.hideError} />
                  </div>
                </div>
                {/* We need to use dangerouslySetInnerHTML here for Simple Solutions using a link in there translation */}
                <div className='content-description-text'>
                {libraryManager.allowPrintItemBanking ? (
                      UtilityService.reactHtmlParserWrapper(t(`${selectedMenu}DescriptionTeacherPrint`)).parsed
                    ) : (
                      UtilityService.reactHtmlParserWrapper(t(`${selectedMenu}DescriptionTeacherDigital`)).parsed
                      /* TODO may need a third option for print and digital */
                )}
                </div>
              </div>
              <div className='header-bottom-row'>
                <div className='left-container'>
                  {(libraryCardData && libraryCardData.length > 0) && (
                  <div className='content-sort-container'>
                    <Dropdown
                      key='sort-select'
                      fluid
                      name='sort-select'
                      onChange={this.handleSort}
                      options={[
                        { key: 1, text: 'Sort by Newest', value: 'newest' },
                        { key: 2, text: 'Sort by Oldest', value: 'oldest' },
                        { key: 3, text: 'Sort by Title - Ascending', value: 'title-asc' },
                        { key: 4, text: 'Sort by Title - Descending', value: 'title-desc' }
                      ]}
                      placeholder='Sort by'
                      search
                      selection
                      value={sortKey} />
                  </div>
                  )}
                </div>
                <div className='paginator-container'>
                  {(libraryCardData && libraryCardData.length > 0) && (
                    <>
                      <div className='pagination-text'>
                        {`${startResultNumber}-${endResultNumber} of ${totalResults}`}
                      </div>
                      <Pagination
                        activePage={activePage}
                        onPageChange={this.onPageChange}
                        totalPages={totalPages} />
                    </>
                  )}
                </div>
              </div>
            </div>
            {this.renderMenuView()}
          </div>
        </div>
      </Container>
    );
  }

  render() {
    const {
      docreaderViewerShowing,
      fileViewerShowing,
      lessonPlayerShowing
    } = this.state;
    if (lessonPlayerShowing) {
      return (this.renderLessonPlayer());
    } else if (fileViewerShowing) {
      return (this.renderFileViewer());
    } else if (docreaderViewerShowing) {
      return (this.renderDocreaderViewer());
    } else {
      return (this.renderLibraryView());
    }
  }
}
SatCoreRegister('TeacherTestBuilderView', TeacherTestBuilderView);
