import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import classNames from 'classnames';

import { Button, Checkbox, Container, Header, Image, Loader } from 'semantic-ui-react';

import '../css/Class.less';
import '../css/CourseListing.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { VIEW_SELECTION } from '../managers/NavigationManager';

import { CONTENT_ITEM_TYPES } from '../managers/ContentManager';

import ClassroomService from '../services/ClassroomService';
import CourseService from '../services/CourseService';
import NavigationService from '../services/NavigationService';
import PopupService from '../services/PopupService';
import UtilityService from '../services/UtilityService';

import chapterDefaultCardImage from '../img/default-chapter.svg';

export default
@inject(
  'assignmentManager', 'courseDeliveryManager', 'courseManager',
  'navigationManager', 'productManager', 'userManager', 'classroomManager'
)
@observer
class ClassDetails extends Component {
  skipRefresh = false;

  constructor(props) {
    super(props);

    this.state = {
      courseSectionMap: new Map(),
      courseUnitArray: [],
      courseUnitResourcesArray: [],
      currentElement: null,
      isLoadingClassDetails: true,
      selectedSection: new Map(),
      selectedUnit: null,
    };

    this.BreadCrumbsCourseDropdown = SatCoreComponent('BreadCrumbsCourseDropdown');
    this.ControlledCarouselContainer = SatCoreComponent('ControlledCarouselContainer');
    this.CourseLayout = SatCoreComponent('CourseLayout');
    this.CourseListBanner = SatCoreComponent('CourseListBanner');
    this.fetchRibbonData = this.fetchRibbonData.bind(this);
  }

  async componentDidMount() {
    await this.initClassDetails();
  }

  // eslint-disable-next-line react/sort-comp
  initClassDetails = async ({
    courseElementId = undefined,
    courseId = undefined,
    fromCourseSearch = false
  } = {}) => {
    this.setState({ isLoadingClassDetails: true });

    const {
      courseManager, navigationManager, productManager
    } = this.props;

    const urlParams = new URLSearchParams(window.location.search);
    const view = urlParams.get('view');
    if (view && !productManager.FROM_TEACHER_PRODUCTS_NAV) {
      navigationManager.setView(urlParams.get('view'));
    } else {
      navigationManager.setView(VIEW_SELECTION.BOOK);
    }

    const previewAsStudent = urlParams.get('previewAsStudent');
    if (previewAsStudent) {
      courseManager.setPreviewAsStudent(true);
    }

    // ensure currentClassroomId is initialized
    ClassroomService.getCurrentClassroomId({ alsoUpdateObservableWithUrlParamIfNull: true });

    if (fromCourseSearch && courseId) {
      courseManager.setCurrentCourseId(courseId);
    } else if (urlParams.has('courseId')) {
      if (courseManager.currentCourseId === null) {
        courseManager.setCurrentCourseId(urlParams.get('courseId'));
      }
    }

    if (this.skipRefresh) {
      this.skipRefresh = false;
    } else {
      let allowHandleRibbonTOCData = true;
      if (productManager.isFromProduct) {
        await CourseService.initCourseDataProduct();
      } else {
        await CourseService.initCourseData(true, true, true, {
          courseElementId, courseId
        });
      }

      if (courseManager.allowRibbonTOC && courseManager.currentElementId && courseManager.currentElementId !== 'ROOT') {
        const response = await this.handleRibbonTOCDataFromGradebook(courseManager.currentElementId);
        if (response && response.courseResourceElements) {
          this.handleRibbonTOCResponseFromGradebook(response.courseResourceElements);
          // don't repeat processing later
          allowHandleRibbonTOCData = false;
        }
      }

      // if Ribbon TOC is turned on, process the course data to extract units, subsections and resources
      if (courseManager.allowRibbonTOC && allowHandleRibbonTOCData) {
        this.handleRibbonTOCData();
      }
    }

    setTimeout(() => {
      this.setState({ isLoadingClassDetails: false });
    }, 500);
  }

  handleOpenSearch = () => {
    this.skipRefresh = true;
  }

  handleToggleHiddenResources = (_event, data) => {
    const shouldShowHiddenResources = data.checked;
    const { courseDeliveryManager } = this.props;
    courseDeliveryManager.setShouldShowHiddenResources(shouldShowHiddenResources);
  }

  async handleRibbonTOCDataFromGradebook(currentElementId) {
    const { courseManager, classroomManager } = this.props;
    const courseId = courseManager.currentCourseId;
    const response = await courseManager.fetchCourseElementParentHeirarchy(courseId, currentElementId, classroomManager.currentClassroomId);
    if (response && response.courseResourceElements) {
      return response;
    }
  }

  handleRibbonTOCResponseFromGradebook(courseResourceElements) {
    const { courseManager } = this.props;
    let { courseUnitArray, courseSectionMap, courseUnitResourcesArray, selectedUnit } = this.state;
    const { selectedSection } = this.state;
    const currentCourseElementId = courseManager.currentElementId;
    // reset the state vars
    courseUnitArray = [];
    courseSectionMap = new Map();
    courseUnitResourcesArray = [];
    let currentSelectedSection = null;
    // loop over the returned heirarchy to build back the state
    for (const courseResourceElement of courseResourceElements) {
      // flatten the object so the element and it's children can be processed
      const courseElements = [];

      if (currentCourseElementId === courseResourceElement.elementId) {
        courseUnitResourcesArray = [];
      }
      // courseElements.push(courseResourceElement);
      courseElements.push(...courseResourceElement.courseResourceElements);

      // debug code
      // for (const child of courseResourceElement.courseResourceElements) {
      //   console.log('child of ' + courseResourceElement.name + ' child name: ' + child.name + ' child id: ' + child.elementId);
      // }
      for (const courseElement of courseElements) {
        if (courseElement.type === 'UNIT') {
          courseUnitArray.push(courseElement);
        } else if (courseElement.type === 'SECTION') {
          let elementArray = [];
          // Get the parent ribbon or create one
          if (courseSectionMap.has(courseElement.parentElementId)) {
            elementArray = courseSectionMap.get(courseElement.parentElementId);
          }
          const existingElem = elementArray.find((element) => element.id === elementArray.id);
          if (!existingElem || existingElem !== 'undefined') {
            elementArray.push(courseElement);
          }
          let thisSectionSelected = false;
          courseSectionMap.set(courseElement.parentElementId, elementArray);
          if (currentCourseElementId === courseElement.elementId && !selectedSection.has(courseElement.elementId)) {
            selectedSection.set(courseElement.elementId, courseElement);
            thisSectionSelected = true;
          }
          // see if parent section should be selected
          if (thisSectionSelected) {
            const parentSection =
              courseResourceElements.find((element) => element.elementId === courseElement.parentElementId && element.type === 'SECTION');
            if (parentSection && parentSection !== 'undefined') {
              selectedSection.set(courseElement.parentElementId, parentSection);
            }
            currentSelectedSection = courseElement;
          }
          // see if the parent unit should be selected.
          const parentUnit =
          courseResourceElements.find((element) => element.elementId === courseElement.parentElementId && element.type === 'UNIT');
          if (parentUnit && parentUnit !== 'undefined') {
            selectedUnit = parentUnit;
          }
        } else {
          courseUnitResourcesArray.push(courseElement);
          // see if the parent unit should be selected.
          const parentUnit =
          courseResourceElements.find((element) => element.elementId === courseElement.parentElementId && element.type === 'UNIT');
          if (parentUnit && parentUnit !== 'undefined') {
            selectedUnit = parentUnit;
          }
        }
      }
    }
    this.setState({
      courseSectionMap,
      courseUnitArray,
      courseUnitResourcesArray,
      currentElement: currentSelectedSection,
      selectedSection,
      selectedUnit
    });
  }

  handleRibbonTOCData() {
    const { courseManager } = this.props;
    const { courseUnitArray, courseSectionMap, courseUnitResourcesArray } = this.state;
    courseManager.currentCourseElementList.forEach((courseElement) => {
      if (courseElement.type === 'UNIT') {
        courseUnitArray.push(courseElement);
      } else if (courseElement.type === 'SECTION') {
        let elementArray = [];
        // Get the parent ribbon or create one
        if (courseSectionMap.has(courseElement.parentElementId)) {
          elementArray = courseSectionMap.get(courseElement.parentElementId);
        }
        elementArray.push(courseElement);
        courseSectionMap.set(courseElement.parentElementId, elementArray);
      } else {
        courseUnitResourcesArray.push(courseElement);
      }
    });
    this.setState({ courseSectionMap, courseUnitArray, courseUnitResourcesArray });
  }

  async onRibbonUnitClick(courseElement) {
    const { history } = this.props;
    const { isLoadingClassDetails } = this.state;
    if (!isLoadingClassDetails) {
      this.setState({
        courseSectionMap: new Map(),
        courseUnitResourcesArray: [],
        isLoadingClassDetails: true,
        selectedSection: new Map(),
        selectedUnit: courseElement
      });
      await NavigationService.handleCourseElementClick(courseElement, history);
      await this.fetchRibbonData(null, courseElement);
    }
  }

  async onRibbonSectionClick(mapKey, courseElement) {
    const { history } = this.props;
    const { isLoadingClassDetails, selectedSection, courseSectionMap } = this.state;
    if (!isLoadingClassDetails) {
      // if we don't have the current element, add it.
      if (!selectedSection.has(courseElement.elementId)) {
        selectedSection.set(courseElement.elementId, courseElement);
      }
      // remove selection for any other sections in the same ribbon.
      const courseElementArray = courseSectionMap.get(courseElement.parentElementId);
      if (courseElementArray) {
        for (const existingCourseElement of courseElementArray) {
          if (courseElement.elementId !== existingCourseElement.elementId) {
            selectedSection.delete(existingCourseElement.elementId);
          }
        }
      }
      this.setState({ courseSectionMap, courseUnitResourcesArray: [], isLoadingClassDetails: true, selectedSection });
      await NavigationService.handleCourseElementClick(courseElement, history);
      await this.fetchRibbonData(mapKey, courseElement);
    }
  }

  trimRibbonSections(mapKey) {
    const { courseSectionMap, selectedSection } = this.state;
    const newSectionMap = new Map();
    let lastFound = false;
    for (const [key, courseElementArray] of courseSectionMap) {
      if (lastFound) {
        // see if the dropped sections had selection records
        for (const courseElement of courseElementArray) {
          if (selectedSection.has(courseElement.elementId)) {
            selectedSection.delete(courseElement.elementId);
          }
        }
        break;
      } else {
        newSectionMap.set(key, courseElementArray);
      }
      if (key === mapKey) {
        lastFound = true;
      }
    }
    this.setState({ courseSectionMap: newSectionMap, selectedSection });
  }

  async fetchRibbonData(mapKey, currentCourseElement) {
    const { courseManager } = this.props;
    const { courseSectionMap } = this.state;
    let courseElementId = courseManager.currentElementId;
    const urlParams = new URLSearchParams(window.location.search);
    const currentCourseId = urlParams.get('courseId') || '';
    courseManager.setCurrentCourseId(currentCourseId);

    if (currentCourseElement) {
      courseElementId = currentCourseElement.elementId;
      this.setState({ currentElement: currentCourseElement });
      courseManager.setCurrentElementId(courseElementId);
    } else {
      // Only delete from courseSectionMap when there's no current element
      if (courseSectionMap) {
        courseSectionMap.delete(courseElementId);
      }
      this.setState({ courseUnitResourcesArray: [] });
    }

    // Update courseSectionMap state separately to avoid overwriting other changes
    this.setState({ courseSectionMap });

    await CourseService.initCourseData(true, true, true, {});

    if (mapKey) {
      // See if we need to trim section ribbons no longer in scope
      this.trimRibbonSections(mapKey);
    }

    this.handleRibbonTOCData();

    setTimeout(() => {
      this.setState({ isLoadingClassDetails: false });
    }, 500);
  }

  renderClassicCourseView() {
    const {
      assignmentManager, courseDeliveryManager, courseManager,
      history, productManager, t, userManager
    } = this.props;

    const { isLoadingClassDetails } = this.state;

    const { activePermissionId } = userManager;
    const { BreadCrumbsCourseDropdown, CourseLayout, CourseListBanner } = this;
    const isCustomCourse = courseManager.isCustomCourse(courseManager.currentCourseId);

    const { FROM_TEACHER_PRODUCTS_NAV } = productManager;

    // eslint-disable-next-line react/destructuring-assignment
    const zeroState = this.props.zerostate || (
      <div className='no-courses'>
        {!isCustomCourse ? t('coursesZeroStateMsg')
          : t('customAssessmentZeroStateMsg', 'There are no assessments added yet.')}
      </div>
    );

    // NOTE: we typically need `hideAddCourseButton` to be true here,
    // as we only want to display '+ Add Course' at the Course Level (ClassCourses.js).
    // ---
    // Instead, when ClassCourses is called, that calls its own instance of CourseListBanner, which will have `hideAddCourseButton=false`
    // ---
    // We currently hide the '+ Add Course' button from all other Course Tree levels (branch, leaf).
    let hideAddCourseButton = true;
    // The only case where this should currently be false is for an old (unused) DEMO-1787 feature (see TeacherProductView for more info)
    if (FROM_TEACHER_PRODUCTS_NAV) {
      hideAddCourseButton = false;
    }

    return courseManager.isTreeLoading || isLoadingClassDetails ? <Loader active /> : (
      <Container className={classNames('class-detail', {
        'teacher-product-class-detail': FROM_TEACHER_PRODUCTS_NAV
      })} fluid>
        <CourseListBanner
          forceHideHeader={false}
          hideAddCourseButton={hideAddCourseButton}
          hideAddStudentButton={true}
          history={history}
          resetClassDetails={this.initClassDetails}
          showBreadCrumbs='bottom'
          showBreadCrumbsDropdown='bottom'
          showCourseSearchButton={assignmentManager.enableCourseSearch}
          title={t('BannerTitle', 'No Class Courses Banner Title')} />
        {(courseManager.currentCourseId !== null
          && courseManager.currentCourseElementList.length > 0) ? (
            <Container
              className={classNames(`class-content resources-container class-details-inner-container ${activePermissionId}`, {
                'has-course-search-btn': assignmentManager.enableCourseSearch
              })} fluid>
              {!courseManager.isTreeLoading && (
                <div className='class-details-header'>
                  <div className='class-details-header-left'>
                    {/* marked as 'display: none' by default */}
                    <BreadCrumbsCourseDropdown className='top' history={history} />
                  </div>
                  <div className='class-details-header-mid'>
                    {/* placeholder */}
                  </div>
                  <div className='class-details-header-right'>
                    {!userManager.isStudent && courseDeliveryManager.showResourcePacingToolbar && (
                      <div className='toggle-hidden-resources-wrapper'>
                        <div className='hidden-resources-toggler-label'>
                          {t('showHiddenResources', 'Show hidden resources')}
                        </div>
                        <Checkbox
                          className='hidden-resources-toggler'
                          defaultChecked={courseDeliveryManager.shouldShowHiddenResources}
                          onChange={this.handleToggleHiddenResources}
                          toggle />
                      </div>
                    )}
                  </div>
                </div>
              )}
              <CourseLayout currentElement={null} history={history} />
            </Container>
          ) : !courseManager.isTreeLoading && !courseManager.currentCourseElementList.length && !isLoadingClassDetails && (
            <Container className='class-content'>
              {zeroState}
            </Container>
          )}
      </Container>
    );
  }

  renderRibbonTOC() {
    const { assignmentManager, courseManager, history, navigationManager, productManager, t, userManager } = this.props;
    const { FROM_TEACHER_PRODUCTS_NAV } = productManager;
    const { activePermissionId } = userManager;
    const { ControlledCarouselContainer, CourseLayout, CourseListBanner } = this;
    const {
      isLoadingClassDetails, courseUnitArray, courseSectionMap, courseUnitResourcesArray, currentElement,
      selectedUnit, selectedSection
    } = this.state;

    navigationManager.clearCoursePaths();

    const selectedSectionJSX = [];

    for (const section of Array.from(selectedSection.values()).map((obj) => obj)) {
      if (selectedSection.size > 0) {
        selectedSectionJSX.push(': ');
      }
      selectedSectionJSX.push(UtilityService.reactHtmlParserWrapper(section.name).parsed);
    }

    let headerTitle = 'Unit';
    if (selectedUnit) {
      const unitName = UtilityService.reactHtmlParserWrapper(selectedUnit.name).parsed;
      headerTitle = <span>{(unitName && unitName !== 'undefined') ? unitName : 'Unit'}{selectedSectionJSX}</span>;
    }

    const carouselSettings = {
      // infinite: true,
      // slidesToScroll: 1,
      // swipe: false
      // swipeToSlide: false
      arrows: true,
      dots: false,
      focusOnSelect: true,
      slidesToShow: 6,
      speed: 300,
      swipe: false
    };

    // eslint-disable-next-line react/destructuring-assignment
    const zeroState = this.props.zerostate || (
      <div className='no-courses'>
        {selectedUnit === null || (selectedUnit !== null && (courseSectionMap.size > 0 && selectedSection.size < courseSectionMap.size)) ?
          t('ribbonNoUnitSelectedMsg') : t('coursesZeroStateMsg')}
      </div>
    );

    const selectedSectionIds = Array.from(selectedSection.keys()).map((obj) => obj);

    return (
      <Container className={classNames('class-detail', {
        'teacher-product-class-detail': FROM_TEACHER_PRODUCTS_NAV
      })} fluid>
        <CourseListBanner
          // showBreadCrumbsDropdown='none'
          forceHideHeader={false}
          hideAddCourseButton={true}
          hideAddStudentButton={true}
          hideThemeHeaderTitle={true}
          history={history}
          onOpenSearchCallback={this.handleOpenSearch}
          resetClassDetails={this.initClassDetails}
          showBreadCrumbs='top'
          showCourseSearchButton={assignmentManager.enableCourseSearch}
          title={t('BannerTitle', 'No Class Courses Banner Title')}
        />
        <div className='headerContainer'>
          {(selectedUnit || selectedSection.size > 0) && (
            <div className='headerWrapper'>
              <Header as='h1' className='theme-header-title'>{headerTitle}</Header>
            </div>
          )}
        </div>
        {(courseUnitArray.length > 0) && (
          <Container className='ribbon-container unit-ribbon'>
            <ControlledCarouselContainer {...carouselSettings}
              className='unit-ribbon-slider'
              loading={courseManager.isTreeLoading || isLoadingClassDetails}>
              {courseUnitArray.map((courseElement, index) => {
                const parsedCourseElementName = (
                  UtilityService.reactHtmlParserWrapper(courseElement.name || t('defaultUnit')).parsed
                );
                const parsedCourseElementDescription = UtilityService.reactHtmlParserWrapper(courseElement.description || '').parsed;

                const unitCarouselCardTextWrapperJsx = (
                  <div className='unit-carousel-card-text-wrapper'>
                    <div className='unit-carousel-card-text-title'>
                      {parsedCourseElementName}
                    </div>
                    {!!parsedCourseElementDescription && (
                      <div className='unit-carousel-card-text-description'>
                        {parsedCourseElementDescription}
                      </div>
                    )}
                  </div>
                );

                return (
                  <Button
                    key={`unit-${index}`}
                    className={classNames('unit-carousel-card', 'course-card-image-wrapper', {
                      default: courseElement.attachmentContentItemId === null,
                      selected: selectedUnit?.elementId === courseElement.elementId
                    })}
                    onClick={() => {
                      this.onRibbonUnitClick(courseElement);
                    }}>
                    <div className={classNames('course-card-image', {
                      default: courseElement.attachmentContentItemId === null,
                      selected: selectedUnit?.elementId === courseElement.elementId
                    })}>
                      {(courseElement.attachmentContentItemId === null) ?
                        <Image src={chapterDefaultCardImage} wrapped />
                        :
                        <Image fluid src={CONTENT_ITEM_TYPES.getContentIdImageUrl(courseElement.attachmentContentItemId)} />}
                    </div>
                    {PopupService.renderPopup({
                      className: 'course-ribbon-carousel-text-popup',
                      content: unitCarouselCardTextWrapperJsx,
                      position: 'bottom center',
                    })}
                  </Button>
                );
              })}
            </ControlledCarouselContainer>
          </Container>
        )}
        {(courseSectionMap.size > 0) &&
          Array.from(courseSectionMap?.entries() || []).map(([mapKey, elementArray], sectionLevel) => {
            const isSubsection = sectionLevel !== 0;

            return (
              <Container key={mapKey} className={classNames('ribbon-container', 'section-ribbon-wrapper', `level-${sectionLevel}`, {
                'subsection-ribbon-wrapper': isSubsection
              })}>
                <Container className={classNames('ribbon-container', 'section-ribbon', {
                  'subsection-ribbon': isSubsection
                })}>
                  <ControlledCarouselContainer {...carouselSettings}
                    className={classNames({
                      'section-ribbon-slider': !isSubsection,
                      'subsection-ribbon-slider': isSubsection
                    })} loading={courseManager.isTreeLoading || isLoadingClassDetails}>
                    {elementArray.map((courseElement, index) => {
                      const parsedCourseElementName = (
                        UtilityService.reactHtmlParserWrapper(courseElement.name || t('defaultSection')).parsed
                      );
                      const parsedCourseElementDescription = UtilityService.reactHtmlParserWrapper(courseElement.description || '').parsed;

                      const sectionCarouselCardTextWrapperTriggerJsx = (
                        <div className={classNames('section-carousel-card-text-wrapper', {
                          'subsection-carousel-card-text-wrapper': isSubsection
                        })}>
                          <div className='section-carousel-card-text-title'>
                            {parsedCourseElementName}
                          </div>
                          {!!parsedCourseElementDescription && !isSubsection && (
                            <div className='section-carousel-card-text-description'>
                              {parsedCourseElementDescription}
                            </div>
                          )}
                        </div>
                      );

                      const sectionCarouselCardTextWrapperContentJsx = (
                        <div className='section-carousel-card-text-wrapper'>
                          <div className='section-carousel-card-text-title'>
                            {parsedCourseElementName}
                          </div>
                          {!!parsedCourseElementDescription && (
                            <div className='section-carousel-card-text-description'>
                              {parsedCourseElementDescription}
                            </div>
                          )}
                        </div>
                      );

                      return (
                        <Button
                          key={`section-${index}`}
                          className={classNames('section-carousel-card', {
                            'selected': selectedSection && selectedSectionIds.includes(courseElement.elementId),
                            'subsection-carousel-card': isSubsection
                          })}
                          onClick={() => { this.onRibbonSectionClick(mapKey, courseElement); }}>
                          {PopupService.renderPopup({
                            className: 'course-ribbon-carousel-text-popup',
                            content: sectionCarouselCardTextWrapperContentJsx,
                            position: 'bottom center',
                            trigger: sectionCarouselCardTextWrapperTriggerJsx
                          })}
                        </Button>
                      );
                    })}
                  </ControlledCarouselContainer>
                </Container>
              </Container>
            );
          }
          )}
        {(courseUnitResourcesArray.length > 0) && (
          <Container className={classNames(`resources-container course-ribbon-carousel-resources-container ${activePermissionId}`)} fluid>
            <CourseLayout
              courseElementList={courseUnitResourcesArray}
              currentElement={currentElement}
              history={history}
              refreshRibbonHandler={this.fetchRibbonData} />
          </Container>
        )}
        {!courseManager.isTreeLoading && (courseManager.allowRibbonTOC && !courseUnitResourcesArray.length) && !isLoadingClassDetails && (
          <Container className='class-content'>
            {zeroState}
          </Container>
        )}
        {(courseManager.isTreeLoading || isLoadingClassDetails) ? (
          <Loader active className='course-ribbon-carousel-toc-loader' inline />
        ) : null}
      </Container>
    );
  }

  render() {
    const { courseManager } = this.props;
    return (
      courseManager.allowRibbonTOC ? (
        this.renderRibbonTOC()
      ) : this.renderClassicCourseView()
    );
  }
}

SatCoreRegister('ClassDetails', ClassDetails);
