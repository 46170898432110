import { action, computed, observable } from 'mobx';

import _ from 'lodash';

import Auth from './AuthManager';

import { CONTENT_ITEM_TYPES, RESOURCE_BANK_CONTENT_ITEM_TYPES } from './ContentManager';

import { COURSE_ENDPOINTS } from './CourseManager';

import { PRODUCT_ENDPOINTS } from './ProductManager';

import UtilityService from '../services/UtilityService';

import articleIcon from '../img/icon-article-resource.svg';
import assessmentIcon from '../img/assessment-card-image.svg';
import assessmentBankSmall from '../img/assessment-collection-card-image.svg';
import assessmentBankIcon from '../img/assessment-collection-card-image.svg';
import assessmentBankIconSmall from '../img/icon-assessment-bank-small.svg';
import audioIcon from '../img/icon-audio-resource.svg';
import bookmarkIcon from '../img/icon-bookmark-resource.svg';
import fileIcon from '../img/icon-file-resource.svg';
import imageIcon from '../img/icon-image-resource.svg';
import itemBankIcon from '../img/item-bank-background-icon.svg';
import learnosityActivityIcon from '../img/icon-learnosity-activity-resource.svg';
import lessonIcon from '../img/icon-lesson-resource.svg';
import micrositeIcon from '../img/icon-microsite-resource.svg';
import pdfIcon from '../img/icon-pdf-resource.svg';
import resourceBankIcon from '../img/icon-resource-bank.svg';
import videoIcon from '../img/icon-resource-video.svg';
// import thisManager from './DocReaderManager';

import { register } from '../i18n';

const t = register('LibraryManager');

export const LIBRARY_ENDPOINTS = {
  ADD_ASSESSMENT_TO_BANK_UNIT: '/api/addDistrictAssessmentsToUnit',
  CHECK_REPORT_SUBSCRIPTION: '/api/checkReportSubscription',
  COPY_ASSESSMENT_RESOURCE: '/api/copyDistrictAssessment',
  CREATE_ASSESSMENT_RESOURCE: '/api/createDistrictAssessment',
  CREATE_DISTRICT_COURSE_RESOURCE: '/api/createDistrictCourseResource',
  DELETE_ASSESSMENT_RESOURCE: '/api/deleteDistrictAssessment',
  GET_ASSESSMENT_DETAILS: '/api/viewDistrictAssessmentDetails',
  GET_STANDARD_ALIGNMENTS_BY_CONTENT_ITEM_ID: '/api/viewContentItemStandards',
  GET_STANDARD_TAG_ALIGNMENTS_BY_CONTENT_ITEM_ID: '/api/viewContentStandardsAndTags',
  IS_RESOURCE_EDITABLE: '/api/isAdminEditable',
  MOVE_TO_LIVE: '/api/publishContentItemToDistrictProduct',
  MOVE_TO_SHARED: '/api/addContentItemToDistrictWorkspace',
  REMOVE_ASSESSMENT_FROM_BANK: '/api/removeDistrictAssessmentFromCourse',
  REMOVE_FROM_LIVE: '/api/unpublishDistrictCourseResource',
  REMOVE_FROM_SHARED: '/api/removeContentItemFromDistrictWorkspace',
  REMOVE_REPORT_SUBSCRIPTION: '/api/deleteReportSubscription',
  SET_REPORT_SUBSCRIPTION: '/api/setReportSubscription',
  SEARCH_CONTENT_ITEMS_BY_DISTRICT_PRODUCT: '/api/searchContentItemsByDistrictProduct',
  SEARCH_CONTENT_ITEMS_BY_DISTRICT_WORKSPACE: '/api/searchContentItemsByWorkspace',
  UPDATE_ASSESSMENT_RESOURCE: '/api/updateDistrictAssessmentDetails',
  VIEW_LESSON_OPTION_PROFILES: '/api/viewLessonOptionsProfilesForSatellite',
  VIEW_LICENSED_WORKSPACE: '/api/viewMyLicensedWorkspaces'
};

export const getLibraryResourceMetadata = (resource, useAlternateStyle = false) => {
  let cardClassName = '';
  let typeDisplayName = '';
  let cardTypeName = '';
  let backgroundImg = '';
  let isLessonOrAssessment = false;
  let isLesson = false;
  if (resource.isLicensedCourse) { // for licensed courses
    const contentImgUrl = CONTENT_ITEM_TYPES.getContentImageUrl(resource.imageUrl);
    backgroundImg = contentImgUrl || assessmentBankIconSmall;
    cardClassName = `course${contentImgUrl ? ' customImg' : ''}`;
    cardTypeName = 'course';
    typeDisplayName = t('courseCardLabel');
  } else if (resource.entityTypeId === 'assessment' && resource.contextType !== 'published' && !useAlternateStyle) {
    // for library assessments, not canned assessments
    const contentImgUrl = CONTENT_ITEM_TYPES.getContentImageUrl(resource.imageUrl);
    backgroundImg = contentImgUrl || assessmentIcon;
    cardClassName = contentImgUrl ? 'assessment customImg' : 'assessment';
    cardTypeName = 'assessment';
    typeDisplayName = t('assessmentCardLabel');
  } else if (resource.entityTypeId === 'course_resource') { // for assessment Banks
    const contentImgUrl = CONTENT_ITEM_TYPES.getContentImageUrl(resource.imageUrl);
    backgroundImg = contentImgUrl || assessmentBankIcon;
    cardClassName = contentImgUrl ? 'assessment-bank customImg' : 'assessment-bank';
    cardTypeName = 'assessment-bank';
    typeDisplayName = t('assessmentBankCardLabel');
  } else if (resource.entityTypeId === 'publisher') { // for resource banks
    const contentImgUrl = CONTENT_ITEM_TYPES.getContentImageUrl(resource.imageUrl, 'productImages');
    backgroundImg = contentImgUrl || resourceBankIcon;
    cardClassName = `resourceBank ${contentImgUrl ? 'customImg' : ''}`;
    cardTypeName = 'resourceBank';
    typeDisplayName = t('resourceBankCardLabel');
  } else { // for all other resources (TBD if we should get specific icons so using resource bank icon by default)
    const contentImgUrl = CONTENT_ITEM_TYPES.getContentImageUrl(resource.imageUrl, 'userFiles');
    let defaultIcon = resourceBankIcon;
    if (resource.entityTypeId === RESOURCE_BANK_CONTENT_ITEM_TYPES.ARTICLE_RESOURCE) {
      defaultIcon = articleIcon;
    }
    if (resource.entityTypeId === RESOURCE_BANK_CONTENT_ITEM_TYPES.ASSESSMENT) {
      defaultIcon = assessmentIcon;
      isLessonOrAssessment = true;
    }
    if (resource.entityTypeId === RESOURCE_BANK_CONTENT_ITEM_TYPES.AUDIO_RESOURCE) {
      defaultIcon = audioIcon;
    }
    if (resource.entityTypeId === RESOURCE_BANK_CONTENT_ITEM_TYPES.FILE_RESOURCE) {
      defaultIcon = fileIcon;
    }
    if (resource.entityTypeId === RESOURCE_BANK_CONTENT_ITEM_TYPES.IMAGE_RESOURCE) {
      defaultIcon = imageIcon;
    }
    if (resource.entityTypeId === RESOURCE_BANK_CONTENT_ITEM_TYPES.BOOKMARK_RESOURCE) {
      defaultIcon = bookmarkIcon;
    }
    if (resource.entityTypeId === RESOURCE_BANK_CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE) {
      defaultIcon = learnosityActivityIcon;
    }
    if (resource.entityTypeId === RESOURCE_BANK_CONTENT_ITEM_TYPES.LESSON) {
      defaultIcon = lessonIcon;
      isLessonOrAssessment = true;
      isLesson = true;
    }
    if (resource.entityTypeId === RESOURCE_BANK_CONTENT_ITEM_TYPES.MICROSITE_RESOURCE) {
      defaultIcon = micrositeIcon;
    }
    if (resource.entityTypeId === RESOURCE_BANK_CONTENT_ITEM_TYPES.PDF_RESOURCE) {
      defaultIcon = pdfIcon;
    }
    if (resource.entityTypeId === RESOURCE_BANK_CONTENT_ITEM_TYPES.VIDEO_RESOURCE) {
      defaultIcon = videoIcon;
    }
    backgroundImg = contentImgUrl || defaultIcon;
    cardClassName = `resourceBankResource ${contentImgUrl ? 'customImg' : ''} ${isLessonOrAssessment ? 'assessmentLessonResource' : ''} ${isLesson ? 'lesson' : ''}`;
    cardTypeName = resource.entityTypeId;
    typeDisplayName = _.capitalize(CONTENT_ITEM_TYPES.getFlag(resource.entityTypeId));
  }
  const metaData = {
    backgroundImg,
    cardClassName,
    cardTypeName,
    typeDisplayName,
  };
  return metaData;
};

export class LibraryManager {
  @observable libraryLoading = false;
  @observable activePage = 1;
  @observable totalPages = 0;
  @observable totalResults = 0;

  @observable allowPrintItemBanking = false;
  @observable allowDigitalItemBanking = true;

  @observable currentLibraryAssessmentMap = new Map();
  @observable selectedNotAttachedResources = new Map();

  @action setLibraryLoading = (toggle) => {
    this.libraryLoading = toggle;
  }

  @action setActivePage = (activePage) => {
    this.activePage = activePage;
  }

  @action setTotalPages = (totalPages) => {
    this.totalPages = totalPages;
  }

  @action setTotalResults = (totalResults) => {
    this.totalResults = totalResults;
  }

  @action setAllowPrintItemBanking = (allowPrintItemBanking) => {
    this.allowPrintItemBanking = allowPrintItemBanking;
  }

  @action setAllowDigitalItemBanking = (allowDigitalItemBanking) => {
    this.allowDigitalItemBanking = allowDigitalItemBanking;
  }

  @action resetPaginator = () => {
    this.setActivePage(1);
    this.setTotalPages(0);
    this.setTotalResults(0);
  }

  // library assessment shopping
  @computed get selectedNotAttachedAssessmentIds() {
    return Array.from(this.selectedNotAttachedResources.keys());
  }

  @computed get selectedNotAttachedAssessmentArray() {
    return Array.from(this.selectedNotAttachedResources.values());
  }

  @action addLibraryAssessment = (assessmentContentItemId, assessment) => {
    if (assessmentContentItemId && assessment) {
      if (!this.currentLibraryAssessmentMap.has(assessmentContentItemId)) {
        this.currentLibraryAssessmentMap.set(assessmentContentItemId, assessment);
      }
    }
  }

  @action clearLibraryAssessments = () => {
    this.currentLibraryAssessmentMap.clear();
  }

  @action addSelectedNotAttachedAssessment = (assessment) => {
    if (assessment && assessment.id) {
      this.selectedNotAttachedResources.set(assessment.id, assessment);
    }
  }

  @action removeSelectedNotAttachedAssessment = (assessmentId) => {
    if (assessmentId && this.selectedNotAttachedResources.has(assessmentId)) {
      this.selectedNotAttachedResources.delete(assessmentId);
    }
  }

  @action clearSelectedNotAttachedResources = () => {
    this.selectedNotAttachedResources.clear();
  }

  @action setAssessmentSelected = (assessmentId) => {
    const assessment = this.currentLibraryAssessmentMap.get(assessmentId);
    let isSelected;
    if (this.selectedNotAttachedResources.has(assessmentId)) {
      this.removeSelectedNotAttachedAssessment(assessmentId);
      isSelected = false;
    } else {
      this.addSelectedNotAttachedAssessment(assessment);
      isSelected = true;
    }
    if (assessment) {
      assessment.selected = isSelected;
      assessment.attached = false;
    }
    // const pagedAssessment = this.pagedAssessmentsMap.get(assessmentId);
    // if (pagedAssessment) {
    //   pagedAssessment.selected = isSelected;
    //   pagedAssessment.attached = false;
    // }
  }
  // end assessment shopping

  @action
  fetchDistrictAssessmentDetails = async (contentItemId) => {
    try {
      const apiUrlParams = `?id=${contentItemId}`;
      const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.GET_ASSESSMENT_DETAILS}${apiUrlParams}`;
      const response = await Auth.fetch(apiUrl, { method: 'GET' });
      if (response.status === 'SUCCESS') {
        return response;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @param {string} name
   * @param {string} description
   * @param {string} unitName
   * @returns {boolean} `response.status === 'SUCCESS'`
   */
  @action
  createLibraryAssessmentBank = async (name, description = null, unitName = null) => {
    try {
      const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.CREATE_DISTRICT_COURSE_RESOURCE}`;
      const body = {
        description,
        name,
        unitName,
        imageAssetName: t('imageAssetNameAssessmentBank')
      };
      const response = await Auth.fetch(apiUrl, {
        body,
        method: 'POST'
      });
      if (response && response.status === 'SUCCESS') {
        return response.data[0];
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @param {string} name
   * @param {string} description
   * @param {string} optionsProfileId (user's district institutionId)
   * @param {string} workspaceId (user's district institutionId)
   * @returns {boolean} `response.status === 'SUCCESS'`
   */
   @action
   createLibraryAssessment = async (name, description = null, optionsProfileId = null, addToWorkspace = false) => {
     try {
       const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.CREATE_ASSESSMENT_RESOURCE}`;
       const body = {
         optionsProfileId,
         name,
         description,
         addToWorkspace,
         imageAssetName: t('imageAssetNameAssessment')
       };
       const response = await Auth.fetch(apiUrl, {
         body,
         method: 'POST'
       });
       if (response && response.status === 'SUCCESS') {
         return response.data[0];
       } else {
         return null;
       }
     } catch (error) {
       console.error(error);
     }
   }

   /**
   * @param {string} name
   * @param {string} description
   * @param {string} optionsProfileId (user's district institutionId)
   * @param {string} workspaceId (user's district institutionId)
   * @returns {boolean} `response.status === 'SUCCESS'`
   */
    @action
    updateLibraryAssessment = async (
      contentItemId, name, description = null, optionsProfileId = null, currentElementId = null,
      startDateTime = null, endDateTime = null, allowViewOutsideTimeframe = false) => {
      try {
        const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.UPDATE_ASSESSMENT_RESOURCE}`;
        const body = {
          id: contentItemId,
          optionsProfileId,
          name,
          description,
          currentElementId,
          startDateTime: startDateTime ? UtilityService.convertAssignmentDateToJavaString(startDateTime) : '',
          endDateTime: endDateTime ?  UtilityService.convertAssignmentDateToJavaString(endDateTime) : '',
          allowViewOutsideTimeframe
        };
        const response = await Auth.fetch(apiUrl, {
          body,
          method: 'POST'
        });
        return response && response.status === 'SUCCESS';
      } catch (error) {
        console.error(error);
      }
    }

    /**
   * @param {string} contentItemId (content item id of the resource)
   * @param {string} name (name of the resource)
   * @param {string} color (color of the resource)
   * @param {string} currentCourseId (optional course to add the resource to post copy)
   * @param {string} currentElementId (optional course element (unit) to add the resource to post copy)
   * @param {string} workspaceId (optional workspaceId of the resource)
   * @param {string} googleFolderId (googleFolderId of the resource)
   * @returns {boolean} `response.status === 'SUCCESS'`
   */
    @action
    async copyLibraryAssessment(contentItemId, name, currentCourseId, currentElementId, workspaceId) {
      try {
        const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.COPY_ASSESSMENT_RESOURCE}`;
        const body = {
          contentItemId,
          name,
          currentCourseId,
          currentElementId,
          workspaceId
        };
        const response = await Auth.fetch(apiUrl, {
          body,
          method: 'POST'
        });
        return response;
      } catch (error) {
        console.error(error);
      }
    }

  /**
   * @param {string} contentItemId (content item id of the resource)
   * @param {string} checkClasses (whether to check for existing classes)
   * @returns {boolean} `response.status === 'SUCCESS'`
   */
    @action
    async deleteLibraryAssessment(contentItemId = null, checkClasses = false, checkAssignments = false) {
      try {
        const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.DELETE_ASSESSMENT_RESOURCE}`;
        const body = {
          id: contentItemId,
          checkClasses,
          checkAssignments,
          reactErrorType: true
        };
        const response = await Auth.fetch(apiUrl, {
          body,
          method: 'POST'
        });
        return response;
      } catch (error) {
        console.error(error);
      }
    }

    /**
   * @param {string} contentItemId (content item id of the resource)
   * @returns {boolean} `response.status === 'SUCCESS'`
   */
     @action
     moveAssessmentBankToLive = async (contentItemId) => {
       try {
         const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.MOVE_TO_LIVE}`;
         const body = {
           contentItemId
         };
         const response = await Auth.fetch(apiUrl, {
           body,
           method: 'POST'
         });
         return response && response.status === 'SUCCESS';
       } catch (error) {
         console.error(error);
       }
     }

     /**
   * @param {string} contentItemId (content item id of the resource)
   * @returns {boolean} `response.status === 'SUCCESS'`
   */
     @action
     moveLibraryResourceToShared = async (contentItemId) => {
       try {
         const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.MOVE_TO_SHARED}`;
         const body = {
           contentItemId
         };
         const response = await Auth.fetch(apiUrl, {
           body,
           method: 'POST'
         });
         return response && response.status === 'SUCCESS';
       } catch (error) {
         console.error(error);
       }
     }

     /**
   * @param {string} courseResourceElementId (course resource element id of the resource being removed)
   * @param {string} courseContentItemId (content item id of the course)
   * @returns {boolean} `response.status === 'SUCCESS'`
   */
    @action
    removeAssessmentFromBank = async (courseResourceElementId, courseContentItemId) => {
      try {
        const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.REMOVE_ASSESSMENT_FROM_BANK}`;
        const body = {
          courseContentItemId,
          elementId: courseResourceElementId,
        };
        const response = await Auth.fetch(apiUrl, {
          body,
          method: 'POST'
        });
        return response && response.status === 'SUCCESS';
      } catch (error) {
        console.error(error);
      }
    }

     /**
    * @param {string} entityId (content item id of the resource)
    * @returns {boolean} `response.status === 'SUCCESS'`
    */
     @action
     checkReportSubscriptionForCourse = async (entityId, reportId) => {
       try {
         const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.CHECK_REPORT_SUBSCRIPTION}`;
         const body = {
           entityId,
           reportId
         };
         const response = await Auth.fetch(apiUrl, {
           body,
           method: 'POST'
         });
         return response && response.status === 'SUCCESS' && response.hasReportSubscription;
       } catch (error) {
         console.error(error);
       }
     }

      /**
     * @param {string} entityId (content item id of the resource)
     * @returns {boolean} `response.status === 'SUCCESS'`
     */
      @action
      setReportSubscriptionForCourse = async (entityId, entityTypeId, reportId) => {
        try {
          const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.SET_REPORT_SUBSCRIPTION}`;
          const body = {
            entityId,
            entityTypeId,
            reportId
          };
          const response = await Auth.fetch(apiUrl, {
            body,
            method: 'POST'
          });
          return response && response.status === 'SUCCESS';
        } catch (error) {
          console.error(error);
        }
      }

       /**
      * @param {string} contentItemId (content item id of the resource)
      * @returns {boolean} `response.status === 'SUCCESS'`
      */
       @action
       removeReportSubscription = async (entityId, reportId) => {
         try {
           const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.REMOVE_REPORT_SUBSCRIPTION}`;
           const body = {
             entityId,
             reportId
           };
           const response = await Auth.fetch(apiUrl, {
             body,
             method: 'POST'
           });
           return response && response.status === 'SUCCESS';
         } catch (error) {
           console.error(error);
         }
       }

    /**
   * @param {string} contentItemId (content item id of the resource)
   * @returns {boolean} `response.status === 'SUCCESS'`
   */
    @action
    removeAssessmentBankFromLive = async (contentItemId) => {
      try {
        const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.REMOVE_FROM_LIVE}`;
        const body = {
          contentItemId
        };
        const response = await Auth.fetch(apiUrl, {
          body,
          method: 'POST'
        });
        return response && response.status === 'SUCCESS';
      } catch (error) {
        console.error(error);
      }
    }

    /**
   * @param {string} contentItemId (content item id of the resource)
   * @returns {boolean} `response.status === 'SUCCESS'`
   */
    @action
    removeLibraryResourceFromShared = async (contentItemId) => {
      try {
        const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.REMOVE_FROM_SHARED}`;
        const body = {
          contentItemId,
        };
        const response = await Auth.fetch(apiUrl, {
          body,
          method: 'POST'
        });
        return response && response.status === 'SUCCESS';
      } catch (error) {
        console.error(error);
      }
    }

    /**
   * @param {string} currentCourseId (content item id of the assessment bank resource)
   * @param {string} currentElementId (course resource element id of the assessment bank unit)
   * @param {Array} selectedAssessmentIds (array of selected assessment content item ids)
   * @returns {boolean} `response.status === 'SUCCESS'`
   */
    @action
    addAssessmentsToBankUnit = async (
      currentCourseId,
      currentElementId,
      selectedAssessmentIds = []
    ) => {
      try {
        const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.ADD_ASSESSMENT_TO_BANK_UNIT}`;
        let assessmentContentItemIds = '';
        if (Array.isArray(selectedAssessmentIds) && selectedAssessmentIds.length > 0) {
          assessmentContentItemIds = selectedAssessmentIds.join(',');
        }
        const body = {
          assessmentContentItemIds,
          courseContentItemId: currentCourseId,
          parentCourseResourceElementId: currentElementId,
        };
        const response = await Auth.fetch(apiUrl, {
          body,
          method: 'POST'
        });
        return response && response.status === 'SUCCESS';
      } catch (error) {
        console.error(error);
      }
    }

  @action addCollectedStandardAlignment = (id, item) => {
    if (item && id) {
      if (!this.collectedStandardAlignments.has(id)) {
        item.name = item.humanCodingScheme;
        // item.sortClass = 'b-custom-category';
        item.sortBy = item.sortClass + item.name;
        this.collectedStandardAlignments.set(id, item);
      }
    }
  }

  @action
  fetchLibraryMyDrafts = async (
    page = 0,
    pageSize = 25,
    searchTypes = 'course_resource,assessment',
    sortColumn = 'modificationDate',
    sortDirection = 'desc',
    searchTerms = '',
    isBuilder = false
  ) => {
    this.setLibraryLoading(true);
    try {
      const myDraftsResources = [];
      const body = {
        searchStandards: '',
        standardSearchCondition: 'OR',
        searchTerms,
        searchScope: 'MINE',
        searchTypes,
        searchSubTypes: '',
        searchTags: '',
        tagSearchCondition: 'OR',
        schools: '',
        workspaces: '',
        take: '25',
        skip: !pageSize ? 0 : (page ? page - 1 : 0) * pageSize,
        page,
        pageSize: pageSize || 25,
        includeBlankCard: false,
      };
      if (sortColumn) {
        body['sort[0][field]'] = sortColumn;
      }
      if (sortDirection) {
        body['sort[0][dir]'] = sortDirection;
      }
      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.SEARCH_CONTENT_ITEMS}`; // using existing api def from courseManager.
      const resourceResponse = await Auth.fetch(apiUrl, { body, method: 'POST' });
      if (resourceResponse && resourceResponse.status === 'SUCCESS') {
        const resources = resourceResponse.contentItems;
        this.clearLibraryAssessments();
        for (const resource of resources) {
          const metaData = getLibraryResourceMetadata(resource);
          const { backgroundImg, cardClassName, cardTypeName, typeDisplayName } = metaData;
          const libraryResource = { id: resource.id, backgroundImg, cardClassName, cardTypeName, title: resource.name, typeDisplayName, libraryResource: resource };
          myDraftsResources.push(libraryResource);
          if (isBuilder && resource.entityTypeId === 'assessment') {
            this.addLibraryAssessment(resource.id, libraryResource);
          }
        }
        this.setTotalPages(Math.ceil(+resourceResponse.pageTotal / pageSize));
        this.setTotalResults(resourceResponse.pageTotal);
        this.setActivePage(page);
        this.setLibraryLoading(false);
        return myDraftsResources;
      } else {
        this.setLibraryLoading(false);
        return false;
      }
    } catch (error) {
      console.error(error);
      this.setLibraryLoading(false);
      return false;
    }
  }

  @action
  fetchLibrarySharedDrafts = async (
    page = 0,
    pageSize,
    searchTypes = 'course_resource,assessment',
    sortColumn = 'modificationDate',
    sortDirection = 'desc',
    searchTerms = '',
    isBuilder = false
  ) => {
    this.setLibraryLoading(true);
    try {
      const sharedDraftsResources = [];
      const body = {
        searchStandards: '',
        standardSearchCondition: 'OR',
        searchTerms,
        searchTypes,
        searchSubTypes: '',
        searchTags: '',
        tagSearchCondition: 'OR',
        schools: '',
        workspaces: '',
        take: '25',
        skip: !pageSize ? 0 : (page ? page - 1 : 0) * pageSize,
        page,
        pageSize: pageSize || 25,
        includeBlankCard: false,
      };
      if (sortColumn) {
        body['sort[0][field]'] = sortColumn;
      }
      if (sortDirection) {
        body['sort[0][dir]'] = sortDirection;
      }
      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.SEARCH_CONTENT_ITEMS_BY_DISTRICT_WORKSPACE}`; // using existing api def from courseManager.
      const resourceResponse = await Auth.fetch(apiUrl, { body, method: 'POST' });
      if (resourceResponse && resourceResponse.status === 'SUCCESS') {
        const resources = resourceResponse.contentItems;
        this.clearLibraryAssessments();
        for (const resource of resources) {
          const metaData = getLibraryResourceMetadata(resource);
          const { backgroundImg, cardClassName, cardTypeName, typeDisplayName } = metaData;
          const libraryResource = { id: resource.id, backgroundImg, cardClassName, cardTypeName, title: resource.name, typeDisplayName, libraryResource: resource };
          sharedDraftsResources.push(libraryResource);
          if (isBuilder && resource.entityTypeId === 'assessment') {
            this.addLibraryAssessment(resource.id, libraryResource);
          }
        }
        this.totalPages = Math.ceil(+resourceResponse.pageTotal / pageSize);
        this.totalResults = resourceResponse.pageTotal;
        this.activePage = page;
        this.setLibraryLoading(false);
        return sharedDraftsResources;
      }
      this.setLibraryLoading(false);
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action
  fetchLibraryLiveProduct = async (
    page = 0,
    pageSize,
    searchTypes = 'course_resource',
    sortColumn = 'modificationDate',
    sortDirection = 'desc'
  ) => {
    this.setLibraryLoading(true);
    try {
      const liveResources = [];
      const body = {
        searchStandards: '',
        standardSearchCondition: 'OR',
        searchTerms: '',
        searchTypes,
        searchSubTypes: '',
        searchTags: '',
        tagSearchCondition: 'OR',
        skip: !pageSize ? 0 : (page ? page - 1 : 0) * pageSize,
        page,
        pageSize: pageSize || 25,
        includeBlankCard: false,
      };
      if (sortColumn) {
        body['sort[0][field]'] = sortColumn;
      }
      if (sortDirection) {
        body['sort[0][dir]'] = sortDirection;
      }
      const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.SEARCH_CONTENT_ITEMS_BY_DISTRICT_PRODUCT}`;
      const liveResponse = await Auth.fetch(apiUrl, { body, method: 'POST' });
      if (liveResponse && liveResponse.status === 'SUCCESS') {
        const liveCourses = liveResponse.contentItems;
        for (const course of liveCourses) {
          const metaData = getLibraryResourceMetadata(course);
          const { backgroundImg, cardClassName, cardTypeName, typeDisplayName } = metaData;
          liveResources.push({ id: course.id, backgroundImg, cardClassName, cardTypeName, title: course.name, typeDisplayName, libraryResource: course });
        }
        this.totalPages = Math.ceil(+liveResponse.pageTotal / pageSize);
        this.totalResults = liveResponse.pageTotal;
        this.activePage = page;
        this.setLibraryLoading(false);
        return liveResources;
      }
      this.setLibraryLoading(false);
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action
  fetchLibraryItemBanks = async (
    page = 0,
    pageSize = 25,
    sortColumn = 'modificationDate',
    sortDirection = 'desc'
  ) => {
    this.setLibraryLoading(true);
    try {
      const itemBankResources = [];
      let apiUrlParams = `?pageSize=${pageSize}`;
      const skip = !pageSize ? 0 : (page ? page - 1 : 0) * pageSize;
      apiUrlParams += `&skip=${skip}`;
      if (sortColumn) {
        apiUrlParams += `&sort[0][field]=${sortColumn}`;
        apiUrlParams += `&sort[0][dir]=${sortDirection}`;
      }
      const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.VIEW_LICENSED_WORKSPACE}${apiUrlParams}`;
      const itemBankResponse = await Auth.fetch(apiUrl, { method: 'GET' });
      if (itemBankResponse && itemBankResponse.status === 'SUCCESS') {
        const itemBanks = itemBankResponse.data;

        // console.log(itemBanks);

        for (const itemBank of itemBanks) {
          const contentImgUrl = CONTENT_ITEM_TYPES.getContentImageUrl(itemBank.imageUrl, 'workspaceImages');
          const backgroundImg = contentImgUrl || itemBankIcon;
          const cardClassName = contentImgUrl ? 'item-bank customImg' : 'item-bank';
          itemBankResources.push({ id: itemBank.id, backgroundImg, cardClassName, title: itemBank.name, typeDisplayName: 'Item Bank', libraryResource: itemBank });
        }
        this.totalPages = Math.ceil(+itemBankResponse.pageTotal / pageSize);
        this.totalResults = itemBankResponse.pageTotal;
        this.activePage = page;
        this.setLibraryLoading(false);
        return itemBankResources;
      }
      this.setLibraryLoading(false);
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action
  fetchLibraryCourses = async (
    page = 0,
    pageSize,
    searchTypes = 'course_resource',
    sortColumn = 'modificationDate',
    sortDirection = 'desc',
    searchTerms = '',
    productId = null
  ) => {
    this.setLibraryLoading(true);
    try {
      const licensedCourses = [];
      let response = null;
      let apiUrl = '';
      // By default get all licensed products for admin, but if a product id was passed, use the other endpoint
      if (productId) {
        const body = {
          searchStandards: '',
          standardSearchCondition: 'OR',
          searchTerms,
          searchScope: 'LICENSE',
          searchTypes,
          searchSubTypes: '',
          searchTags: '',
          tagSearchCondition: 'OR',
          schools: '',
          workspaces: '',
          take: '25',
          skip: !pageSize ? 0 : (page ? page - 1 : 0) * pageSize,
          page,
          pageSize: pageSize || 25,
          includeBlankCard: false,
          productId
        };
        if (sortColumn) {
          body['sort[0][field]'] = sortColumn;
        }
        if (sortDirection) {
          body['sort[0][dir]'] = sortDirection;
        }
        apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.SEARCH_CONTENT_ITEMS_BY_PRODUCT}`;
        response = await Auth.fetch(apiUrl, { body, method: 'POST' });
      } else {
        const skip = !pageSize ? 0 : (page ? page - 1 : 0) * pageSize;
        apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.SEARCH_CONTENT_ITEMS_BY_ADMIN_PRODUCT_LICENSE}?includeBlankCard=false&skip=${skip}&pageSize=${pageSize}`;
        if (sortColumn && sortDirection) {
          apiUrl += `&sort[0][field]=${sortColumn}&sort[0][dir]=${sortDirection}`;
        }
        apiUrl += '&searchScope=LICENSE';
        apiUrl += `&searchTypes=${searchTypes}`;
        if (searchTerms) {
          apiUrl += `&searchTerms=${searchTerms}`;
        }
        response = await Auth.fetch(apiUrl, { method: 'GET' });
      }
      if (response && response.status === 'SUCCESS') {
        const responseData = response?.contentItems || null;
        if (responseData) {
          responseData.forEach((licensedCourse) => {
            licensedCourse.isLicensedCourse = true;
            const metaData = getLibraryResourceMetadata(licensedCourse);
            const { backgroundImg, cardClassName, cardTypeName, typeDisplayName } = metaData;
            licensedCourses.push({ id: licensedCourse.id, backgroundImg, cardClassName, cardTypeName, title: licensedCourse.name, typeDisplayName, libraryResource: licensedCourse });
          });
        }
        this.totalPages = Math.ceil(+response.pageTotal / pageSize);
        this.totalResults = response.pageTotal;
        this.activePage = page;
        this.setLibraryLoading(false);
        return licensedCourses;
      }
      this.setLibraryLoading(false);
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action
  fetchLibraryResourceBanks = async (
    userId,
    page = 0,
    pageSize = 25,
    sortColumn = 'modificationDate',
    sortDirection = 'desc'
  ) => {
    this.setLibraryLoading(true);
    try {
      const {
        VIEW_LICENSED_RESOURCE_PRODUCTS_FOR_USER,
      } = PRODUCT_ENDPOINTS;
      const resourceBanks = [];
      const skip = !pageSize ? 0 : (page ? page - 1 : 0) * pageSize;
      let url = `${Auth.ecms}${VIEW_LICENSED_RESOURCE_PRODUCTS_FOR_USER}?includeBlankCard=false&skip=${skip}&pageSize=${pageSize}`;
      url += userId ? `&userId=${userId}` : '';
      if (sortColumn) {
        url += `&sort[0][field]=${sortColumn}&sort[0][dir]=${sortDirection}`;
      }
      const response = await Auth.fetch(url, { method: 'GET' });
      if (response && response.status === 'SUCCESS') {
        const responseData = response.data;
        responseData.forEach((userResource) => {
          const metaData = getLibraryResourceMetadata(userResource);
          const { backgroundImg, cardClassName, cardTypeName, typeDisplayName } = metaData;
          resourceBanks.push({ id: userResource.id, backgroundImg, cardClassName, cardTypeName, title: userResource.name, typeDisplayName, libraryResource: userResource });
        });
        this.totalPages = Math.ceil(+response.pageTotal / pageSize);
        this.totalResults = response.pageTotal;
        this.activePage = page;
        this.setLibraryLoading(false);
        return resourceBanks;
      }
      this.setLibraryLoading(false);
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action
  getOptionProfiles = async (printable, getAll = false) => {
    try {
      let apiUrlParams = `?printable=${printable}&getAll=${getAll}`;
      const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.VIEW_LESSON_OPTION_PROFILES}${apiUrlParams}`;
      const optionProfileResponse = await Auth.fetch(apiUrl, { method: 'GET' });
      const profileOptions = [];
      if (optionProfileResponse && optionProfileResponse.status === 'SUCCESS') {
        const optionProfiles = optionProfileResponse?.data;
        if (optionProfiles) {
          optionProfiles.forEach((optionProfile) => {
            const profileOption = {
              key: optionProfile.id,
              value: optionProfile.id,
              text: optionProfile.name
            };
            profileOptions.push(profileOption);
          });
        }
        return profileOptions;
      }
    } catch (e) {
      console.log(e);
    }
  }

  @action
  getStandardAlignmentsByContentId = async (contentItemId) => {
    try {
      const apiUrlParams = `?id=${contentItemId}`;
      const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.GET_STANDARD_ALIGNMENTS_BY_CONTENT_ITEM_ID}${apiUrlParams}`;
      const response = await Auth.fetch(apiUrl, { method: 'GET' });
      if (response && response.status === 'SUCCESS') {
        const alignedStandards = response.standardFrameworkElements;
        alignedStandards.map((item) => {
          this.addCollectedStandardAlignment(item.id, item);
        });
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action
  getStandardTagAlignmentsByContentId = async (contentItemId) => {
    try {
      const apiUrlParams = `?contentItemId=${contentItemId}`;
      const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.GET_STANDARD_TAG_ALIGNMENTS_BY_CONTENT_ITEM_ID}${apiUrlParams}`;
      const response = await Auth.fetch(apiUrl, { method: 'GET' });
      if (response && response.status === 'SUCCESS') {
        const tags = this.filterSelectedTags(response.tags);
        const results = {
          standards: response.standards,
          tags
        };
        return results;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  filterSelectedTags = (categories) => {
    const categoriesWithSelectedTags = [];
    categories.forEach((category, i) => {
      const selectedTags = [];
      // only show visible categories
      if (category.visible) {
        category.tags.forEach((tag, i) => {
          // determine if the tag is selected for this content item
          if (tag.selected) {
            selectedTags.push(tag);
          }
        });
        // only show the category if it has selected tags.
        if (selectedTags.length > 0) {
          category.tags = selectedTags;
          categoriesWithSelectedTags.push(category);
        }
      }
    });
    return categoriesWithSelectedTags;
  }

  @action
  getResourceEditable = async (contentItemId) => {
    try {
      const apiUrlParams = `?lessonContentItemId=${contentItemId}`;
      const apiUrl = `${Auth.ecms}${LIBRARY_ENDPOINTS.IS_RESOURCE_EDITABLE}${apiUrlParams}`;
      const response = await Auth.fetch(apiUrl, { method: 'GET' });
      if (response.status === 'SUCCESS') {
        return response?.editable || false;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}

export default new LibraryManager();
